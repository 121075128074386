import { rolesConstants } from "../../constants/roles_constants";

const initialState = {
    pending: false,
    roles: [],
    users: [],
    role: {},
    error: null
};


export function rolesReducer(state = initialState, action) {
    switch (action.type) {
        case rolesConstants.GETALL_REQUEST:
            return {
                ...state,
                pending: true
            }
        case rolesConstants.GETALL_SUCCESS:
            return {
                ...state,
                pending: false,
                roles: action.results
            }
        case rolesConstants.GETALL_FAILURE:
            return {
                ...state,
                pending: false,
                roles: [],
                error: action.error
            }
        case rolesConstants.INSERT_REQUEST:
            return {
                ...state,
                pending: true
            }
        case rolesConstants.INSERT_SUCCESS:
            return {
                ...state,
                pending: false,
                roles: action.roles
            }
        case rolesConstants.INSERT_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case rolesConstants.DELETE_REQUEST:
            return {
                ...state,
                pending: true
            }
        case rolesConstants.DELETE_SUCCESS:
            return {
                ...state,
                pending: false,
                roles: state.roles.filter(role => role.id !== action.id)
            }
        case rolesConstants.DELETE_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case rolesConstants.UPDATE_REQUEST:
            return {
                ...state,
                pending: true
            }
        case rolesConstants.UPDATE_SUCCESS:
            return {
                ...state,
                pending: false,
                role: action.role
            }
        case rolesConstants.UPDATE_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case rolesConstants.DETAIL_REQUEST:
            return {
                ...state,
                pending: true
            }
        case rolesConstants.DETAIL_SUCCESS:
            return {
                ...state,
                pending: false,
                role: action.role
            }
        case rolesConstants.DETAIL_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case rolesConstants.GET_USERS_REQUEST:
            return {
                ...state,
                pending: true,
            }

        case rolesConstants.GET_USERS_SUCCESS:
            return {
                ...state,
                pending: false,
                users: action.users
            }

        case rolesConstants.GET_USERS_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            }

        default:
            return state
    }
}