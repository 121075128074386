
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ClearIcon from '@material-ui/icons/Clear';
import React, { useState } from "react";
import HelpIcon from '@material-ui/icons/Help'
import { Autocomplete } from "@material-ui/lab";
import { Checkbox, FormControlLabel, Grid, TextField, Tooltip } from "@material-ui/core";
import i18next from "i18next";
import { SERVICES_TYPE } from "../../../constants/types";
import DragDrop from "../../shared/dragDrop";

const ItemListStop = ({ title, items, line, setItems, setAllowChangeDirection, allowChangeDirection, serviceType }) => {

    const getItems = (line) =>
        line.zoneStops.map(i => { return { id: i.id, name: i.name, address: i.address, point: i.point, distance: i.distance } })

    const getOptions = (items) =>
        items?.map(i => { return { id: i.id, name: i.name, address: i.address, point: i.point, distance: i.distance } })

    const IconWithTooltip = props => (
        <Tooltip title={props.text}>
            <HelpIcon />
        </Tooltip>
    )
    // cuando line es null es una linea nueva y se inicializa con la estructura que necesita el componente
    const [state, setState] = useState(line ? [getItems(line)] : [[]]);
    const [allowChangeDirectionLocal, setAllowChangeDirectionLocal] = useState(allowChangeDirection);
    const [options, setOptions] = useState(getOptions(items));

    const [selectAll, setSelectAll] = useState(false)

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    /**
     * Moves an item from one list to another list.
     */
    const move = (source, destination, droppableSource, droppableDestination) => {
        const sourceClone = Array.from(source);
        const destClone = Array.from(destination);
        const [removed] = sourceClone.splice(droppableSource.index, 1);
        destClone.splice(droppableDestination.index, 0, removed);
        const result = {};
        result[droppableSource.droppableId] = sourceClone;
        result[droppableDestination.droppableId] = destClone;

        return result;
    };
    const grid = 8;

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        padding: grid * 2,
        margin: `0 0 ${grid}px 0`,
        // change background colour if dragging
        background: isDragging ? "rgb(191, 228, 244)" : "#80cae9",
        // styles we need to apply on draggables
        ...draggableStyle
    });
    const getListStyle = isDraggingOver => ({
        background: 'white',
        padding: grid,
        width: 350
    });

    function onDragEnd(result) {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }
        const sInd = +source.droppableId;
        const dInd = +destination.droppableId;

        if (sInd === dInd) {
            const items = reorder(state[sInd], source.index, destination.index);
            const newState = [...state];
            newState[sInd] = items;
            setState(newState);
            setItems(newState);
        } else {
            const result = move(state[sInd], state[dInd], source, destination);
            const newState = [...state];
            newState[sInd] = result[sInd];
            newState[dInd] = result[dInd];
            setState(newState.filter(group => group.length));
            setItems(newState.filter(group => group.length));
        }
    }

    const onChangeZones = (event, newValues) => {
        if (newValues[0]) {
            setState([[...state[0], { id: newValues[0].id, name: newValues[0].name, address: newValues[0].address, point: newValues[0].point, distance: newValues[0].distance }]])
            setItems([[...state[0], { id: newValues[0].id, name: newValues[0].name, address: newValues[0].address, point: newValues[0].point, distance: newValues[0].distance }]])
            setOptions([...options.filter(x => x.name != newValues[0].name)])
        }
    }


    const renderChildren = (item, ind, index) => {
        return (
            <>
                {item.name + ' - ' + item.address}
                <ClearIcon
                    onClick={() => {
                        const newState = [...state];
                        const deleted = newState[ind].splice(index, 1);
                        setItems(
                            newState.filter(group => group.length).length > 0 ? newState.filter(group => group.length) : [[]]
                        );
                        setState(
                            newState.filter(group => group.length).length > 0 ? newState.filter(group => group.length) : [[]]
                        );
                        setOptions([...options, { name: deleted[0].name, address: deleted[0].address, id: deleted[0].id, point: deleted[0].point, distance: deleted[0].distance }])
                        if (newState[0].length == 0 && serviceType == SERVICES_TYPE.regularService)
                            setAllowChangeDirection(false)
                        setSelectAll(false)
                    }}
                >
                </ClearIcon>
            </>
        )

    }

    return (
        <React.Fragment>
            <h2>{title}</h2>
            <Grid item md={10}>
                {
                    serviceType == SERVICES_TYPE.regularService && (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={allowChangeDirection}
                                    disabled={state[0]?.length > 0 ? false : true}
                                    onChange={() => {
                                        setAllowChangeDirectionLocal(!allowChangeDirection)
                                        setAllowChangeDirection(!allowChangeDirection)

                                    }}
                                    color="primary"
                                />
                            }
                            label={
                                <div>
                                    {i18next.t('services_comp.lines.allowChangeDirection')}
                                    <IconWithTooltip
                                        text={i18next.t('services_comp.lines.allowChangeDirectionToolTip')}
                                    />
                                </div>
                            }
                        />
                    )
                }

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={selectAll}
                            disabled={options.length == 0}
                            onChange={() => {
                                setSelectAll(true)
                                setState([[...state[0].concat(options)]])
                                setItems([[...state[0].concat(options)]])
                                setOptions([])

                            }}
                            color="primary"
                        />
                    }
                    label={
                        <div>
                            {i18next.t('services_comp.lines.selectAll')}
                        </div>
                    }
                />

            </Grid>
            <Grid item md={10}>
                <Autocomplete
                    id="zones"
                    value={[]}
                    onChange={onChangeZones}
                    options={options}
                    multiple
                    getOptionLabel={option =>
                        option.name
                            ? option.name + ' - ' + option.address
                            :
                            ''
                    }
                    renderInput={params => (
                        <TextField
                            {...params}
                            variant={'outlined'}
                            label={i18next.t('services_comp.lines.stops',)}
                            name="stops"
                        />
                    )}
                />
            </Grid>

            <DragDrop
                state={state}
                onDragEnd={onDragEnd}
                children={renderChildren}
                getItemStyle={getItemStyle}
                getListStyle={getListStyle}
            />
        </React.Fragment>
    )
}

export default ItemListStop