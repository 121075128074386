import { operatorsConstants } from '../../constants/operator_constants';

let initialState = {
    pending: false,
    results: [],
    profilesAllowed: [],
    tenants: [],
    currentPage: 0
};

export function operatorReducer(state = initialState, action) {
    switch (action.type) {
        case operatorsConstants.FILTER_REQUEST:
            return {
                ...state,
                pending: true
            };
        case operatorsConstants.FILTER_SUCCESS:
            return {
                ...state,
                pending: false,
                results: action.results
            };
        case operatorsConstants.FILTER_FAILURE:
            return {
                ...state,
                pending: false,
                results: [],
                error: action.error
            };
        case operatorsConstants.DETAIL_REQUEST:
            return {
                ...state,
                pending: true
            };
        case operatorsConstants.DETAIL_SUCCESS:
            return {
                ...state,
                operator: action.operator,
                pending: false
            };
        case operatorsConstants.DETAIL_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            };
        case operatorsConstants.GETALL_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case operatorsConstants.GETALL_SUCCESS:
            return {
                ...state,
                pending: false,
                results: action.results
            };
        case operatorsConstants.GETALL_FAILURE:
            return {
                ...state,
                pending: false,
                results: [],
                error: action.error
            };
        case operatorsConstants.PROFILES_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case operatorsConstants.PROFILES_SUCCESS:
            return {
                ...state,
                pending: false,
                profilesAllowed: action.profiles
            };
        case operatorsConstants.PROFILES_FAILURE:
            return {
                ...state,
                pending: false,
                results: [],
                error: action.error
            };
        case operatorsConstants.CLEAR_RESULTS:
            return {
                ...state,
                results: [],
                pending: false
            };
        case operatorsConstants.REGISTER_REQUEST:
            return {
                ...state,
                pending: true
            };
        case operatorsConstants.REGISTER_SUCCESS:
            return {
                ...state,
                pending: false,
                operator: action.operator
            };
        case operatorsConstants.REGISTER_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            };
        case operatorsConstants.EDIT_REQUEST:
            return {
                ...state,
                pending: true
            };
        case operatorsConstants.EDIT_SUCCESS:
            return {
                ...state,
                pending: false,
                operator: action.operator
            };
        case operatorsConstants.EDIT_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            };
        case operatorsConstants.DELETE_REQUEST:
            return {
                ...state,
                pending: true
            };
        case operatorsConstants.DELETE_SUCCESS:
            return {
                ...state,
                pending: false,
                results: state.results.filter(x => x.id !== action.id)
            };
        case operatorsConstants.DELETE_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            };
        case operatorsConstants.RESET_PSW_REQUEST:
            return {
                ...state,
                pending: true
            };
        case operatorsConstants.RESET_PSW_SUCCESS:
            return {
                ...state,
                pending: false
            };
        case operatorsConstants.RESET_PSW_FAILURE:
            return {
                ...state,
                pending: false
            };
        case operatorsConstants.UPDATE_PSW_REQUEST:
            return {
                ...state,
                pending: true
            };
        case operatorsConstants.UPDATE_PSW_SUCCESS:
            return {
                ...state,
                pending: false
            };
        case operatorsConstants.UPDATE_PSW_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            };
        case operatorsConstants.CLEAR_OPERATOR:
            return {
                ...state,
                operator: null
            };
        case operatorsConstants.OP_SET_PAGE_TABLE:
            return {
                ...state,
                currentPage: action.page
            };
        case operatorsConstants.OP_GET_ALL_TENANTS_REQUEST:
            return {
                ...state,
                pending: true
            }
        case operatorsConstants.OP_GET_ALL_TENANTS_SUCCESS:
            return {
                ...state,
                pending: false,
                tenants: action.results
            }
        case operatorsConstants.OP_GET_ALL_TENANTS_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        default:
            return state;
    }
}