import { notificationsConstants } from '../../constants/notifications_constants'

let initialState = {
    pending: false,
    results: [],
    notificationTypes: [],
    uncheckedNotifications: 0,
}

export function notificationReducer(state = initialState, action) {
    switch (action.type) {
        case notificationsConstants.GETALL_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case notificationsConstants.GETALL_SUCCESS:
            return {
                ...state,
                pending: false,
                results: action.results,
            }
        case notificationsConstants.GETALL_FAILURE:
            return {
                ...state,
                pending: false,
                results: [],
                error: action.error,
            }
        case notificationsConstants.TYPES_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case notificationsConstants.TYPES_SUCCESS:
            return {
                ...state,
                pending: false,
                notificationTypes: action.notificationTypes,
            }
        case notificationsConstants.TYPES_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            }
        case notificationsConstants.GET_NOTIFICATIONS_COUNT:
            return {
                ...state,
                uncheckedNotifications: action.results,
            }
        default:
            return state
    }
}
