import React from 'react';
import ServiceCalendarRangeView from './page'


class ServiceCalendarRange extends React.Component {

    render() {

        return (
            <ServiceCalendarRangeView
                {...this.props}
            />

        );
    }
}


export default ServiceCalendarRange;