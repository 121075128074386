import React from "react";
import Dashboard from "../../dashboard";
import VehiclesDetailsView from "./page";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { otpActions } from "../../../redux/actions/otp_actions";
import { vehiclesActions } from "../../../redux/actions/vehicles_actions";
import { driversActions } from "../../../redux/actions/driver_actions";
import { serviceActions } from "../../../redux/actions/services_actions";
import { history } from "../../../helpers/history";
import { allowAccessRole } from "../../../helpers/roles";
import { MODULES, OPERATIONS_ALLOWED } from "../../roles/constants";

class VehicleDetail extends React.Component {

    onClick(vehicle) {
        history.push("/vehicles/edit/" + vehicle.id);
    }

    componentDidMount() {
        const params = this.props.location.pathname.split('/')
        const id = params[params.length - 1]

        if (allowAccessRole(MODULES.OTP, OPERATIONS_ALLOWED.read)) this.props.getAllOTP();
        this.props.getAllBrands();
        this.props.getWeekDays();
        this.props.getPrioritiesVehicle();
        this.props.getById(id, false)
    }

    render() {
        const { t } = this.props;
        return (
            <Dashboard
                component={
                    <VehiclesDetailsView
                        {...this.props}
                        title={t("vehicles.details.title")}
                        buttonRight={t("vehicles.form.page.buttonDetail")}
                        goBack={t("vehicles.form.page.goBack")}
                        sendVehicle={() => this.onClick(this.props.vehicleReducer.vehicle)}
                        readOnly={true}
                        nextAction={"edit"}
                        detail={true}
                        vehicle={this.props.vehicleReducer.vehicle}
                    />
                }
            />
        );
    }
}

function mapState(state) {
    const { otpReducer, vehicleReducer, serviceReducer, driverReducer } = state;
    return { otpReducer, vehicleReducer, serviceReducer, driverReducer };
}

const actionCreators = {
    getById: vehiclesActions.getById,
    getAllBrands: vehiclesActions.getAllBrands,
    getModeldsByBrandString: vehiclesActions.getModeldsByBrandString,
    getAllOTP: otpActions.getAll,
    getWeekDays: serviceActions.getWeekDays,
    getPrioritiesVehicle: vehiclesActions.getPrioritiesVehicle,
    getDriversFullName: driversActions.getDriversFullName,
    cleanDriversFullName: driversActions.cleanDriversFullName,
    clearVehicle: vehiclesActions.clearVehicle
};

export default compose(
    withTranslation("common"),
    connect(mapState, actionCreators)
)(VehicleDetail);
