import { FormControl, Grid, Hidden, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
    ' & .MuiFormControl-root': {
        width: '100%'
    },
}));

class FilterMarkers extends React.Component {

    constructor(props) {
        super()
        this.state = {
            otp: null,
            service: null,
            plate: null,
            zone: null
        }
    }

    onChangeOtp = (event, newValue) => {
        this.setState({
            otp: newValue
        })
        if (newValue) {
            this.props.setFilter('otp', newValue.id)
        } else {
            this.props.setFilter('otp', '')
        }
    }

    onChangeService = (event, newValue) => {
        this.setState({
            service: newValue
        })
        if (newValue) {
            this.props.setFilter('service', newValue.id)
        } else {
            this.props.setFilter('service', '')
        }
    }

    onChangePlate = (event, newValue) => {
        this.setState({
            plate: newValue
        })
        if (newValue) {
            this.props.setFilter('plate', newValue)
        } else {
            this.props.setFilter('plate', '')
        }
    }

    onChangeZone = (event, newValue) => {
        this.setState({
            zone: newValue
        })
        if (newValue) {
            this.props.setFilter('zone', newValue)
        } else {
            this.props.setFilter('zone', '')
        }
    }


    render() {
        const { vehicleReducer, zoneReducer, otpReducer, serviceReducer, t } = this.props
        const plates = vehicleReducer.results.map(v => v.plateNumber)
        const zones = zoneReducer.results
        return (
            <React.Fragment>
                <Grid container className={styles.root} spacing={3} style={{
                    marginBottom: '16px', marginTop: '16px'
                }}>
                    <Grid item md={1} implementation="css" smDown component={Hidden} />
                    <Grid item md={2} xs={6} sm={3}>
                        <FormControl fullWidth>
                            <Autocomplete
                                id="otpId"
                                value={this.state.otp || ''}
                                onChange={this.onChangeOtp}
                                options={otpReducer.results}
                                disabled={this.state.service != null || this.state.plate != null || this.state.zone != null}
                                getOptionSelected={(option, value) => option === value}
                                getOptionLabel={(option) => option.name ? option.name : ''}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant={'outlined'}
                                        label={t('maps.filter.otp')}
                                        name="otp"
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={2} xs={6} sm={3}>
                        <FormControl fullWidth>
                            <Autocomplete
                                id="serviceId"
                                value={this.state.service || ''}
                                onChange={this.onChangeService}
                                options={serviceReducer.results}
                                disabled={this.state.otp != null || this.state.plate != null || this.state.zone != null}
                                getOptionSelected={(option, value) => option.id == value || option.id == value.id}
                                getOptionLabel={(option) => option.name ? option.name : ''}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant={'outlined'}
                                        label={t('maps.filter.service')}
                                        name="serviceId"
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={2} xs={6} sm={3}>
                        <FormControl fullWidth>
                            <Autocomplete
                                id="plate"
                                value={this.state.plate || ''}
                                onChange={this.onChangePlate}
                                options={plates}
                                disabled={this.state.service != null || this.state.otp != null || this.state.zone != null}
                                getOptionSelected={(option, value) => option.id == value || option.id == value.id}
                                getOptionLabel={(option) => option ? option : ''}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant={'outlined'}
                                        label={t('maps.filter.plate')}
                                        name="plate"
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={2} xs={6} sm={3}>
                        <FormControl fullWidth>
                            <Autocomplete
                                id="zone"
                                value={this.state.zone || ''}
                                onChange={this.onChangeZone}
                                options={zones}
                                disabled={this.state.service != null || this.state.otp != null || this.state.plate != null}
                                getOptionSelected={(option, value) => option.id == value || option.id == value.id}
                                getOptionLabel={(option) => option ? option.name : ''}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant={'outlined'}
                                        label={t('maps.filter.zone')}
                                        name="zone"
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>

                </Grid>
            </React.Fragment>
        );
    }
}

export default FilterMarkers;