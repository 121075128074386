export const customerConstants = {

    ADD_REQUEST: 'CUSTOMER_ADD_REQUEST',
    ADD_SUCCESS: 'CUSTOMER_ADD_SUCCESS',
    ADD_FAILURE: 'CUSTOMER_ADD_FAILURE',

    GETALL_REQUEST: 'CUSTOMER_GETALL_REQUEST',
    GETALL_SUCCESS: 'CUSTOMER_GETALL_SUCCESS',
    GETALL_FAILURE: 'CUSTOMER_GETALL_FAILURE',

    GETBYID_REQUEST: 'CUSTOMER_GETBYID_REQUEST',
    GETBYID_SUCCESS: 'CUSTOMER_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'CUSTOMER_GETBYID_FAILURE',

    DELETE_REQUEST: 'CUSTOMER_DELETE_REQUEST',
    DELETE_SUCCESS: 'CUSTOMER_DELETE_SUCCESS',
    DELETE_FAILURE: 'CUSTOMER_DELETE_FAILURE',

    CHECK_PROFILE_REQUEST: 'CUSTOMER_CHECK_PROFILE_REQUEST',
    CHECK_PROFILE_SUCCESS: 'CUSTOMER_CHECK_PROFILE_SUCCESS',
    CHECK_PROFILE_FAILURE: 'CUSTOMER_CHECK_PROFILE_FAILURE',

    UPDATE_REQUEST: 'CUSTOMER_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'CUSTOMER_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'CUSTOMER_UPDATE_FAILURE',

    RESET_PSW_REQUEST: 'CUSTOMER_RESET_PSW_REQUEST',
    RESET_PSW_SUCCESS: 'CUSTOMER_RESET_PSW_SUCCESS',
    RESET_PSW_FAILURE: 'CUSTOMER_RESET_PSW_FAILURE',

    CUSTOMER_ADDRESSES_REQUEST: 'CUSTOMER_ADDRESSES_REQUEST',
    CUSTOMER_ADDRESSES_SUCCESS: 'CUSTOMER_ADDRESSES_SUCCESS',
    CUSTOMER_ADDRESSES_FAILURE: 'CUSTOMER_ADDRESSES_FAILURE',

    CUSTOMER_ENABLED_REQUEST: 'CUSTOMER_ENABLED_REQUEST',
    CUSTOMER_ENABLED_SUCCESS: 'CUSTOMER_ENABLED_SUCCESS',
    CUSTOMER_ENABLED_FAILURE: 'CUSTOMER_ENABLED_FAILURE',

    CUSTOMER_DISABLED_SUCCESS: 'CUSTOMER_DISABLED_SUCCESS',
    CUSTOMER_DISABLED_REQUEST: 'CUSTOMER_DISABLED_REQUEST',
    CUSTOMER_DISABLED_FAILURE: 'CUSTOMER_DISABLED_FAILURE',

    SET_CUSTOMER: 'CUSTOMER_SET',
    CUSTOMER_SET_PAGE_TABLE: 'CUSTOMER_SET_TABLE_PAGE',
    CUSTOMER_CLEAN_RELATED: 'CUSTOMER_CLEAN_RELATED',
    REFRESH_CUSTOMER: 'CUSTOMER_REFRESH_ACTION',

    CUSTOMER_GET_KPI_REQUEST: 'CUSTOMER_GET_KPI_REQUEST',
    CUSTOMER_GET_KPI_SUCCESS: 'CUSTOMER_GET_KPI_SUCCESS',
    CUSTOMER_GET_KPI_FAILURE: 'CUSTOMER_GET_KPI_FAILURE',

    SET_ONLYONE_CUSTOMER: 'CUSTOMER_ONLYONE_CUSTOMER',

    GET_DOCUMENT_TYPES_SUCCESS: 'CUSTOMER_DOCUMENT_TYPES_SUCCESS',
    GET_DOCUMENT_TYPES_FAILURE: 'CUSTOMER_DOCUMENT_TYPES_FAILURE',
    GET_DOCUMENT_TYPES_REQUEST: 'CUSTOMER_DOCUMENT_TYPES_REQUEST',
  
    SAVE_TABLE_STATE: "CUSTOMER_SAVE_TABLE_STATE"
};