import { userConstants } from '../../constants/user_constants'
import { userService } from '../../services/userService'
import { operatorService } from '../../services/operatorService'
import { alertActions } from '../actions/alert_actions'
import { operatorsActions } from '../actions/operators_actions'
import { customerActions } from '../actions/customer_actions'
import { driversActions } from '../actions/driver_actions'
import { history } from '../../helpers/history'
import crypto from '../../helpers/crypto'
import i18next from 'i18next'
import store from '../store'

import { initNotifications } from '../../firebase'
import moment from 'moment'
export const userActions = {
    login,
    getTokenId,
    register,
    logout,
    forgotPassword,
    getById,
    refreshToken,
    updatePassword,
    updateUserPasswordFromEmailChange,
    saveMessagingToken,
    subscribeToTopic,
}
function subscribeToTopic(token) {
    return dispatch => {
        userService.subscribeToTopic(token).then(
            data => {
                //
            },
            error => {
                console.log('error subscribeToTopic', error)
            },
        )
    }
}

function saveMessagingToken(token) {
    return dispatch => {
        dispatch(success(token))
    }

    function success(token) {
        return { type: userConstants.SAVE_MESSAGING_TOKEN, token }
    }
}

function login(loginId, password, remember, tenantId) {
    return dispatch => {
        dispatch(request())
        if (remember) {
            let infoLogin = { loginId, password, remember }
            localStorage.setItem('remember', crypto.encryptObject(infoLogin))
        }
        operatorService.isActive(loginId.toLowerCase()).then(
            resultActive => {
                if (resultActive && resultActive.length != 0) {
                    userService.login(loginId, password).then(
                        data => {
                            if (data.user.emailVerified) {
                                dispatch(success(data))
                                // save 1 hour
                                userService.setWithExpiry(
                                    'token_webAdmin',
                                    data.token,
                                    moment().add(1, 'h').toDate().getTime(),
                                )
                                userService.setWithExpiry(
                                    'refreshToken_webAdmin',
                                    data.user.refreshToken,
                                    moment().add(2, 'years').toDate().getTime(),
                                )
                                userService.setWithExpiry('user_webAdmin', data.user, moment().add(3, 'years').toDate().getTime())
                                dispatch(getTokenId(tenantId))
                            } else {
                                dispatch(
                                    alertActions.error(
                                        i18next.t('services.login.confirmEmailerror'),
                                    ),
                                )
                                //data.user.sendEmailVerification()
                                operatorsActions.sendVerifyEmail(loginId)
                                dispatch(failure(''))
                            }
                        },
                        error => {
                            dispatch(
                                alertActions.error(i18next.t('services.login.loginError')),
                            )
                            dispatch(failure(error))
                        },
                    )
                } else {
                    dispatch(
                        alertActions.error(
                            resultActive.length == 0 ?
                                i18next.t('services.login.loginError') : i18next.t('services.login.isNotActive'),
                        ),
                    )
                    dispatch(resultActive.length == 0 ? failure("loginError") : failure("isNotActive"))
                }
            },
            error => {
                dispatch(
                    alertActions.error(i18next.t('services.login.loginError')),
                )
                dispatch(failure(error))
            },
        )

    }

    function request() {
        return { type: userConstants.LOGIN_REQUEST }
    }
    function success(data) {
        return { type: userConstants.LOGIN_SUCCESS, data }
    }
    function failure(error) {
        return { type: userConstants.LOGIN_FAILURE, error }
    }
}

function updatePassword(email, newPassword, urlRedirect) {
    operatorService.getRoleByEmail(email).then(result => {
        switch (result) {
            case 10:
                store.dispatch(
                    operatorsActions.updatePassword(
                        email,
                        newPassword,
                        urlRedirect,
                    ),
                )
                break
            case 20:
                store.dispatch(
                    customerActions.updatePassword(
                        email,
                        newPassword,
                        urlRedirect,
                    ),
                )
                break
            case 30:
                store.dispatch(
                    driversActions.updatePassword(
                        email,
                        newPassword,
                        urlRedirect,
                    ),
                )
                break
            default:
                break
        }
    })
}

function updateUserPasswordFromEmailChange(email, newPassword, urlRedirect) {
    operatorService.getRoleByEmail(email).then(result => {
        switch (result) {
            case 10:
                store.dispatch(
                    operatorsActions.updateUserPasswordFromEmailChange(
                        email,
                        newPassword,
                        urlRedirect,
                    ),
                )
                break
            case 20:
                store.dispatch(
                    customerActions.updateUserPasswordFromEmailChange(
                        email,
                        newPassword,
                        urlRedirect,
                    ),
                )
                break
            case 30:
                store.dispatch(
                    driversActions.updateUserPasswordFromEmailChange(
                        email,
                        newPassword,
                        urlRedirect,
                    ),
                )
                break
            default:
                break
        }
    })
}

function getById(id) {
    return dispatch => {
        dispatch(request())
        userService.getById(id).then(
            data => {
                dispatch(success(data))
            },
            error => {
                if (error === 401) {
                    dispatch(refreshToken())
                    dispatch(getById(id))
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: userConstants.GETBYID_REQUEST }
    }
    function success(data) {
        return { type: userConstants.GETBYID_SUCCESS, data }
    }
    function failure(error) {
        return { type: userConstants.GETBYID_FAILURE, error }
    }
}

function getTokenId(tenantId) {
    return dispatch => {
        dispatch(request())
        userService.getTokenId().then(
            data => {
                if ([10, 20, 30, 40, 50, 60].includes(data.claims.profile) && tenantId == data.claims.tenant_id) {

                    // let claims = { ...data.claims, permissions: [{ Module: 16, OperationsAllowed: 0 }, { Module: 5, OperationsAllowed: 0 }] }
                    userService.setWithExpiry('claims', data.claims, moment().add(2, 'years').toDate().getTime())
                    localStorage.setItem('profile', data.claims.profile)

                    dispatch(operatorsActions.getById(data.claims.mongo_id))
                    dispatch(success(data))
                    initNotifications()
                    history.push('/home')
                } else {
                    dispatch(failure('access not allowed'))
                    dispatch(
                        alertActions.error(
                            i18next.t('services.login.loginError'),
                        ),
                    )
                    userService.logout().then(result => {
                        localStorage.removeItem('token_webAdmin')
                        localStorage.removeItem('customerId')
                        localStorage.removeItem('remember')
                        localStorage.removeItem('refreshToken_webAdmin')
                        localStorage.removeItem('user_webAdmin')
                        history.push('/login')
                    })
                }
            },
            error => {
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: userConstants.TOKEN_REQUEST }
    }
    function success(data) {
        return { type: userConstants.TOKEN_SUCCESS, data }
    }
    function failure(error) {
        return { type: userConstants.TOKEN_FAILURE, error }
    }
}

function register(
    email,
    password,
    firstName,
    lastName,
    document,
    gdprAccepted,
) {
    return dispatch => {
        dispatch(request())
        userService
            .registerCustomer(
                email,
                password,
                firstName,
                lastName,
                document,
                gdprAccepted,
            )
            .then(
                user => {
                    dispatch(success(user))
                    dispatch(
                        alertActions.success(
                            i18next.t('services.login.registerSuccess'),
                        ),
                    )
                    userService.authorizationToSendEmail(email, password)
                    history.push('/login')
                },
                error => {
                    //dispatch(alertActions.error("Ha ocurrido un error, intente nuevamente"))
                    dispatch(alertActions.error(JSON.parse(error).detail))
                    dispatch(failure(error))
                },
            )
    }

    function request() {
        return { type: userConstants.REGISTER_REQUEST }
    }
    function success(user) {
        return { type: userConstants.REGISTER_SUCCESS, user }
    }
    function failure(error) {
        return { type: userConstants.REGISTER_FAILURE, error }
    }
}

function forgotPassword(email) {
    return dispatch => {
        dispatch(request())
        operatorService.checkOpuser(email.toLowerCase()).then(result => {
            if (result) {
                userService.forgotPassword(email.toLowerCase()).then(
                    data => {
                        dispatch(success(data))
                        dispatch(
                            alertActions.success(
                                i18next.t(
                                    'services.login.forgetPasswordSuccess',
                                ),
                            ),
                        )
                    },
                    error => {
                        dispatch(failure(error))
                        dispatch(
                            alertActions.error(
                                i18next.t('services.login.forgetPasswordError'),
                            ),
                        )
                    },
                )
            } else {
                dispatch(
                    alertActions.error(
                        i18next.t('services.login.forgetPasswordError'),
                    ),
                )
            }
        })
    }

    function request() {
        return { type: userConstants.RESETPSW_REQUEST }
    }
    function success(user) {
        return { type: userConstants.RESETPSW_SUCCESS, user }
    }
    function failure(error) {
        return { type: userConstants.RESETPSW_FAILURE, error }
    }
}

function refreshToken() {
    return dispatch => {
        dispatch(request())
        userService.refreshToken().then(
            data => {
                dispatch(success(data))
            },
            error => {
                dispatch(logout())
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: userConstants.REFRESH_REQUEST }
    }
    function success(token) {
        return { type: userConstants.REFRESH_SUCCESS, token }
    }
    function failure(error) {
        return { type: userConstants.REFRESH_FAILURE, error }
    }
}

function logout() {
    return dispatch => {
        dispatch(request())
        userService.logout().then(result => {
            localStorage.removeItem('token_webAdmin')
            localStorage.removeItem('customerId')
            localStorage.removeItem('remember')
            localStorage.removeItem('claims')
            localStorage.removeItem('profile')
            localStorage.removeItem('refreshToken_webAdmin')
            localStorage.removeItem('user_webAdmin')
            history.push('/login')
        })
    }

    function request() {
        return { type: userConstants.LOGOUT }
    }
}
