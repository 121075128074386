import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';

class FilterComponent extends React.Component {

    onChange = (event, newValue) => {
        this.props.setValue(this.props.prop, newValue)
    }

    getOptionLabel = (option) => {
        // e.g value selected with enter, right from the input
        const { t } = this.props
        if (typeof option === "string") {
            return option;
        }
        if (typeof option === "boolean") {
            if (option) {
                return t('services_comp.page.yes')
            } else {
                return t('services_comp.page.no')
            }
        }
        return option.brandName;
    };

    render() {
        const { t, optionsArray } = this.props
        return (

            <Autocomplete
                style={{ width: 150 }}
                multiple
                value={this.props.values}
                onChange={this.onChange}
                options={optionsArray}
                getOptionLabel={this.getOptionLabel}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant={'outlined'}
                    />
                )}
            />

        )
    }
}

export default FilterComponent;