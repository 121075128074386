import store from '../redux/store'
import { alertActions } from '../redux/actions/alert_actions'
import i18next from 'i18next'
import { history } from './history'
import { OPERATIONS_ALLOWED } from '../components/roles/constants'


export const checkAction = (entity, action) => {
    let permissionType = allowAccessRole(entity, action)

    if (!permissionType) {
        store.dispatch(alertActions.error(i18next.t('services.login.notAllow')))
        // history.push('/admin/home')
        return false
    }

    return true
}

export const allowAccessRole = (entity, action) => {
    const permissions = JSON.parse(localStorage.getItem('claims'))?.value.permissions
    if (permissions == null) return false

    const permissionType = permissions.find((elem) => elem.Module === entity)?.OperationsAllowed

    switch (action) {
        case OPERATIONS_ALLOWED.read:
            if (permissionType === OPERATIONS_ALLOWED.read || permissionType === OPERATIONS_ALLOWED.manage) return true
            return false

        case OPERATIONS_ALLOWED.manage:
            if (permissionType === OPERATIONS_ALLOWED.manage) return true
            return false

        case OPERATIONS_ALLOWED.non:
            return false

        default:
            return false
    }
}
