import { Grid, Paper, makeStyles, Box } from '@material-ui/core';
import React, { forwardRef } from 'react';
import MultipleTrips from './multipleTrip';
import TripMap from '../maps/index'
import moment from "moment";
import i18next from 'i18next';

import { Alert, AlertTitle } from '@material-ui/lab';


export const stylesMultipleTrip = makeStyles((theme) => ({
    container: {
        paddingTop: 30,
        paddingBottom: 75
    },
    colorPrimary: {
        color: theme.palette.primary.main,
        paddingTop: 5
    },
    center: {
        textAlign: 'center',
        padding: 10,
    },
    defaultPadding: {
        padding: theme.spacing(2)
    },
    divContainerTrip: {
        display: 'flex',
        cursor: 'pointer'
    },
    border: {
        borderBottom: '1px solid #E0E0E0',
        //borderTop: '1px solid #E0E0E0'
    },
    tripProperty: {
        padding: 8,
        fontSize: '13.5px'
    },
    bold: {
        fontWeight: 'bold'
    },
    width100: {
        width: '100%'
    },
    errorContainer: {
        width: '100%',
        fontSize: theme.typography.fontSize,
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    tripSelected: {
        borderTop: `1px solid ${theme.palette.primary.main}`,
        borderBottom: `1px solid ${theme.palette.primary.main}`,
        borderLeft: `8px solid ${theme.palette.primary.main}`
    },
    containerSelectRequestType: {
        paddingTop: 40,
        paddingLeft: 20
    },
    separatorTrip: {
        borderBottom: `2px solid #757575 !important`
    }
}))


export const TripProperty = ({ children, ...props }) => {
    const classes = stylesMultipleTrip()
    return (
        <div className={classes.tripProperty} {...props}>
            <div className={props.isBold ? classes.bold : ''} >
                {children}
            </div>
        </div >
    )
}

export const ResumeTripWrapped = (Component) => {
    return function (props) {
        const classes = stylesMultipleTrip()
        const { multipleTripReducer, tripReducer } = props
        const isReturn = tripReducer?.trip ? tripReducer.trip.isReturn : false
        const hasReturn = tripReducer?.trip ? tripReducer.trip.hasReturn : false

        const displayMultiTrip = multipleTripReducer.executeMultipleTrips || ((isReturn || hasReturn) && tripReducer.results.length > 0)
        return (
            <>
                <Grid item md={displayMultiTrip ? 8 : 12} className={classes.defaultPadding}>
                    <Paper elevation={1}>
                        <Component {...props} />
                        <Grid item md={12}>
                            <TripMap {...props} />
                        </Grid>
                    </Paper>
                </Grid>
                {
                    displayMultiTrip && (
                        <Grid item md={4} className={classes.defaultPadding}>
                            <Paper elevation={1}>
                                <MultipleTrips
                                    {...props}
                                />
                            </Paper>
                        </Grid>
                    )
                }
            </>
        )
    }
}

export const GridCenter = ({ children }) => {
    const classes = stylesMultipleTrip()
    return (
        <Grid item md={5} xl={4} className={`${classes.center}`}>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight={60}
            >
                {children}
            </Box>
        </Grid>
    )
}

export const ErrorsMultipleRequestTripComponent = ({ errors }) => {
    const classes = stylesMultipleTrip()
    return (
        <div className={classes.errorContainer}>
            <Alert severity='error'>
                <AlertTitle><strong>{i18next.t('trips.multipleBook.errorsOfTrip')}</strong></AlertTitle>
                <ul>
                    {
                        errors.map(item => <li key={item}>
                            {item?.detail}
                            {(item?.object?.nextAvailableTime)
                                && (
                                    `. ${i18next.t('services.trips.nextTimeAvailable')} ${moment(item?.object?.nextAvailableTime).format('HH:mm')}`
                                )
                            }
                        </li>)
                    }
                </ul>
            </Alert>
        </div>
    )
}

export const withListOfTrips = (Component) => {
    const classes = stylesMultipleTrip()
    return function (props) {
        if (props.listOfTrips?.length == 0)
            return (
                <Grid item md={12} className={classes.center}>
                    {i18next.t('trips.multipleBook.emptyTrips')}
                </Grid>
            )
        return <Component {...props} />
    }
}