import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import KpiWrapper from "../../../components/kpiWrapper";
import Chart from "react-google-charts";
import i18next from "i18next";

export function TotalDriversChart({ isLoading }) {
    const { t } = useTranslation()

    const drivers = useSelector(state => state.kpiReducer.drivers);
    const isDriversLoading = useSelector(state => state.kpiReducer.driversLoading)
    const data = drivers.length > 0 ? drivers : undefined

    const totalDrivers = data && data.slice(1).reduce((acc, act) => {
        return acc + act[1].f
    }, 0)

    isLoading = isLoading || isDriversLoading
    const displayNoData = !isLoading && data?.length === 1

    return (
        <KpiWrapper
            title={t("commandBox.drivers.totalDriversChart.title")}
            displayNoData={displayNoData}
            total={`${String(totalDrivers).toLocaleString()}`}
            isLoading={isLoading}
            skeletonVariant="rect">
            <Chart
                chartType="ColumnChart"
                graphID={'chart_drivers'}
                data={data}
                chartLanguage={i18next.language} options={{
                    width: '100%',
                    vAxis: { title: t("commandBox.drivers.totalDriversChart.label") },
                    seriesType: "bars",
                    legend: { position: "none" },
                }}
            />
        </KpiWrapper>
    )
}