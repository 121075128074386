import React from 'react'
import DashboardView from './page'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import i18next from 'i18next'
import { userActions } from '../../redux/actions/user_actions'
import { alertActions } from '../../redux/actions/alert_actions'
import { operatorsActions } from '../../redux/actions/operators_actions'
import { userService } from '../../services/userService'
import { tripActions } from '../../redux/actions/trip_actions'
import { serviceActions } from '../../redux/actions/services_actions'
import { notificationActions } from '../../redux/actions/notification_actions'

class Dashboard extends React.Component {
    constructor(props) {
        super()
        this.state = {
            lng: i18next.language,
        }
    }

    changeLanguage = lng => {
        this.setState({ lng: lng }, () => {
            i18next.changeLanguage(lng)
        })
        localStorage.setItem('lang', lng)
        const claims = userService.getWithExpiry('claims')
        if (claims) {
            this.props.setLanguage(claims.mongo_id, lng)
        }
        setTimeout(function () {
            document.title = i18next.t('dashboard.title')
        }, 400)
        window.location.reload()
    }

    componentDidMount() {
        const operator = this.props.operatorReducer.operator
        if (operator && operator.language) {
            i18next.changeLanguage(operator.language)
        }
    }

    render() {
        return (
            <DashboardView
                component={this.props.component}
                changeLanguage={this.changeLanguage}
                {...this.props}
            />
        )
    }
}

function mapState(state) {
    const {
        alertReducer,
        authenticationReducer,
        chatReducer,
        incidentReducer,
        operatorReducer,
        notificationReducer,
        tripReducer,
        serviceReducer
    } = state
    return {
        alertReducer,
        authenticationReducer,
        chatReducer,
        incidentReducer,
        operatorReducer,
        notificationReducer,
        tripReducer,
        serviceReducer
    }
}

const actionCreators = {
    clear: alertActions.clear,
    logout: userActions.logout,
    clearAllResults: tripActions.clearAllResults,
    getTrips: tripActions.getTrips,
    getTripsPending: tripActions.getTripsPending,
    getJourneys: serviceActions.getJourneys,
    getWheelJourneys: serviceActions.getWheelJourneys,
    setLanguage: operatorsActions.setLanguage,
    getNotificationsCount: notificationActions.getNotificationsCount,
}

export default compose(
    withTranslation('common'),
    connect(mapState, actionCreators),
)(Dashboard)
