import React from "react";
import { useSelector } from "react-redux";
import SimpleTable from "../../../components/simpleTable";
import { Skeleton } from "@material-ui/lab";
import AlertSVG from "../../../../../resources/svgs/alertSVG";
import { useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export default function AlertTable({ isLoading }) {
    const { t } = useTranslation()
    const alerts = useSelector(state => state.kpiReducer.alerts)
    const isAlertsLoading = useSelector(state => state.kpiReducer.alertsLoading)

    const theme = useTheme()
    const total = alerts.tripPickupDelayed + alerts.tripRejectedByAllDrivers + alerts.isLateCancellation + alerts.nonAttendance + alerts.driverDelayed

    function createData(name, unit) {
        return { name, unit };
    }

    const rows = [
        createData(t("commandBox.summary.alertTable.row1"), alerts.tripRejectedByAllDrivers,),
        createData(t("commandBox.summary.alertTable.row2"), alerts.tripPickupDelayed),
        createData(t("commandBox.summary.alertTable.row3"), alerts.isLateCancellation),
        createData(t("commandBox.summary.alertTable.row4"), alerts.nonAttendance),
        createData(t("commandBox.summary.alertTable.row5"), alerts.driverDelayed),
    ];

    isLoading = isAlertsLoading || isLoading

    return (
        <>
            {
                isLoading
                    ? <Skeleton variant="rect" height={"100%"} />
                    : <SimpleTable
                        icon={<AlertSVG width={40} height={30} stroke={theme.palette.primary.main} />}
                        title={t("commandBox.summary.alertTable.title")}
                        subtitle={t("commandBox.summary.alertTable.subtitle")}
                        rows={rows}
                        total={total} />
            }
        </>
    )

}