import { otpConstants } from '../../constants/otp_constants'
import { history } from '../../helpers/history'
import { otpService } from '../../services/otpService'
import { alertActions } from '../actions/alert_actions'
import { userActions } from '../actions/user_actions'
import i18next from 'i18next'
import { userService } from '../../services/userService'
import { ERROR_CODES } from '../../constants/types'

export const otpActions = {
    getAll,
    getById,
    insert,
    update,
    clearResults,
    remove,
    positionInitialIsInOtpServiceZones,
    filterByTenant,
}

const DETAIL_ERROR_CODES = {
    OTPIdNotFound: 'OTPIdNotFound',
    OTPExists: 'OTPExists',
    OTPEmailAlreadyExists: 'OTPEmailAlreadyExists',
    OTPManagerCannotCreateOTP: 'OTPManagerCannotCreateOTP',
    OTPManagerCannotUpdateOTP: 'OTPManagerCannotUpdateOTP',
    UserEmailAlreadyExists: 'UserEmailAlreadyExists',
}

function getAll() {
    return dispatch => {
        dispatch(request())
        otpService.getAll().then(
            results => {
                dispatch(success(results))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return

                        dispatch(userActions.refreshToken())
                        dispatch(getAll())
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.otps.forbiddenGetAll')))
                        break
                    default:
                        try {
                            let parsedError = JSON.parse(error)
                            let detailError = parsedError.detail
                            switch (detailError) {
                                case DETAIL_ERROR_CODES.OTPIdNotFound:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.otps.OTPIdNotFound'),
                                        ),
                                    )
                                    break
                                default:
                                    dispatch(
                                        alertActions.error(i18next.t('services.otps.getAllError')),
                                    )
                            }
                        } catch (e) {
                            dispatch(alertActions.error(error.toString()))
                        }
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: otpConstants.GETALL_REQUEST }
    }

    function success(results) {
        return { type: otpConstants.GETALL_SUCCESS, results }
    }

    function failure(error) {
        return { type: otpConstants.GETALL_FAILURE, error }
    }
}

function filterByTenant(tenantId) {
    return dispatch => {
        if (tenantId) dispatch(request(tenantId))
    }

    function request(tenantId) {
        return { type: otpConstants.FILTER_BY_TENANT, tenantId }
    }
}

function getById(id) {
    return dispatch => {
        dispatch(request(id))
        otpService.getById(id).then(
            otp => {
                dispatch(success(otp))
                history.push('/otps/' + id)
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return

                        dispatch(userActions.refreshToken())
                        dispatch(getById(id))
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.otps.forbiddenGetById')))
                        break
                    default:
                        dispatch(alertActions.error(i18next.t('services.otps.getByIdError')))
                        break
                }
                dispatch(failure(error))
            },
        )
    }

    function request(id) {
        return { type: otpConstants.DETAIL_REQUEST, id }
    }

    function success(otp) {
        return { type: otpConstants.DETAIL_SUCCESS, otp }
    }

    function failure(error) {
        return { type: otpConstants.DETAIL_FAILURE, error }
    }
}

function positionInitialIsInOtpServiceZones(otpId, lat, lng) {
    return dispatch => {
        otpService.positionInitialIsInOtpServiceZones(otpId, lat, lng).then(result => {
            if (!result) {
                dispatch(alertActions.warning(i18next.t('services.otps.positionOutOTP')))
            }
        })
    }
}

function update(otp) {
    return dispatch => {
        dispatch(request())
        otpService.update(otp).then(
            otp => {
                dispatch(success(otp))
                history.push('/otps')
                dispatch(alertActions.success(i18next.t('services.otps.updateSuccess')))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return

                        dispatch(userActions.refreshToken())
                        dispatch(update(otp))
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.otps.forbiddenUpdate')))
                        break
                    default:
                        try {
                            let parsedError = JSON.parse(error)
                            let detailError = parsedError.detail
                            switch (detailError) {
                                case DETAIL_ERROR_CODES.OTPIdNotFound:
                                    dispatch(
                                        alertActions.error(i18next.t('services.otps.otpRepeat')),
                                    )
                                    break
                                case DETAIL_ERROR_CODES.OTPEmailAlreadyExists:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.otps.OTPEmailAlreadyExists'),
                                        ),
                                    )
                                    break
                                case DETAIL_ERROR_CODES.OTPManagerCannotCreateOTP:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.otps.OTPManagerCannotCreateOTP'),
                                        ),
                                    )
                                    break
                                case DETAIL_ERROR_CODES.OTPManagerCannotUpdateOTP:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.otps.OTPManagerCannotUpdateOTP'),
                                        ),
                                    )
                                    break
                                case DETAIL_ERROR_CODES.UserEmailAlreadyExists:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.otps.UserEmailAlreadyExists'),
                                        ),
                                    )
                                    break
                                default:
                                    dispatch(
                                        alertActions.error(i18next.t('services.otps.updateError')),
                                    )
                            }
                        } catch (e) {
                            dispatch(alertActions.error(error.toString()))
                        }
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: otpConstants.EDIT_REQUEST }
    }

    function success(otp) {
        return { type: otpConstants.EDIT_SUCCESS, otp }
    }

    function failure(error) {
        return { type: otpConstants.EDIT_FAILURE, error }
    }
}

function clearResults() {
    return dispatch => {
        dispatch(clear())
    }

    function clear() {
        return { type: otpConstants.CLEAR_RESULTS }
    }
}

function insert(otp) {
    return dispatch => {
        dispatch(request())
        otpService.insert(otp).then(
            otp => {
                dispatch(success(otp))
                dispatch(alertActions.success(i18next.t('services.otps.insertSuccess')))
                history.push('/otps')
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return

                        dispatch(userActions.refreshToken())
                        dispatch(insert(otp))
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.otps.forbiddenInsert')))
                        break
                    default:
                        try {
                            let parsedError = JSON.parse(error)
                            let detailError = parsedError.detail
                            switch (detailError) {
                                case DETAIL_ERROR_CODES.OTPExists:
                                    dispatch(
                                        alertActions.error(i18next.t('services.otps.otpRepeat')),
                                    )
                                    break
                                case DETAIL_ERROR_CODES.OTPEmailAlreadyExists:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.otps.OTPEmailAlreadyExists'),
                                        ),
                                    )
                                    break
                                case DETAIL_ERROR_CODES.OTPManagerCannotCreateOTP:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.otps.OTPManagerCannotCreateOTP'),
                                        ),
                                    )
                                    break
                                case DETAIL_ERROR_CODES.OTPManagerCannotUpdateOTP:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.otps.OTPManagerCannotUpdateOTP'),
                                        ),
                                    )
                                    break
                                case DETAIL_ERROR_CODES.UserEmailAlreadyExists:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.otps.UserEmailAlreadyExists'),
                                        ),
                                    )
                                    break
                                default:
                                    dispatch(
                                        alertActions.error(i18next.t('services.otps.insertError')),
                                    )
                            }
                        } catch (e) {
                            dispatch(alertActions.error(error.toString()))
                        }
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: otpConstants.ADD_REQUEST }
    }

    function success(otp) {
        return { type: otpConstants.ADD_SUCCESS, otp }
    }

    function failure(error) {
        return { type: otpConstants.ADD_FAILURE, error }
    }
}

function remove(id) {
    return dispatch => {
        dispatch(request())
        otpService._delete(id).then(
            otp => {
                dispatch(success(id))
                dispatch(alertActions.success(i18next.t('services.otps.deleteSuccess')))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return

                        dispatch(userActions.refreshToken())
                        dispatch(remove(id))
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.otps.forbiddenDelete')))
                        break
                    default:
                        dispatch(alertActions.error(i18next.t('services.otps.deleteError')))
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: otpConstants.DELETE_REQUEST }
    }

    function success(id) {
        return { type: otpConstants.DELETE_SUCCESS, id }
    }

    function failure(error) {
        return { type: otpConstants.DELETE_FAILURE, error }
    }
}
