import React from "react";
import VehicleEditView from "./page";
import Dashboard from "../../dashboard";
import { connect } from "react-redux";
import { vehiclesActions } from "../../../redux/actions/vehicles_actions";
import { serviceActions } from "../../../redux/actions/services_actions";
import { driversActions } from "../../../redux/actions/driver_actions";
import { otpActions } from "../../../redux/actions/otp_actions";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { alertActions } from "../../../redux/actions/alert_actions";
class VehicleEdit extends React.Component {
    componentDidMount() {
        this.props.getWeekDays();
        this.props.getPrioritiesVehicle();
        this.props.filterByTenant(this.props.vehicleReducer?.vehicle?.tenantId)
    }

    componentWillUnmount() {
        this.props.cleanDriversFullName()
    }

    render() {
        const { t } = this.props;
        return (
            <Dashboard
                component={
                    <VehicleEditView
                        title={t("vehicles.edit.title")}
                        buttonRight={t("vehicles.form.page.buttonEdit")}
                        goBack={t("vehicles.form.page.goBack")}
                        vehicle={this.props.vehicleReducer.vehicle}
                        sendVehicle={this.props.update}
                        edit={true}
                        nextAction={"edit"}
                        readOnly={false}
                        {...this.props}
                    />
                }
            />
        );
    }
}

function mapState(state) {
    const { vehicleReducer, otpReducer, serviceReducer, driverReducer } = state;
    return { vehicleReducer, otpReducer, serviceReducer, driverReducer };
}

const actionCreators = {
    update: vehiclesActions.update,
    getAllBrands: vehiclesActions.getAllBrands,
    getModeldsByBrands: vehiclesActions.getModeldsByBrands,
    getModeldsByBrandString: vehiclesActions.getModeldsByBrandString,
    clearModels: vehiclesActions.clearModels,
    getVehiclesByOTP: vehiclesActions.getByOtpId,
    getWeekDays: serviceActions.getWeekDays,
    getPrioritiesVehicle: vehiclesActions.getPrioritiesVehicle,
    positionInitialIsInOtpServiceZones: otpActions.positionInitialIsInOtpServiceZones,
    setServiceDataEdit: serviceActions.setServiceDataEdit,
    getDriversFullName: driversActions.getDriversFullName,
    getServicesByOtp: serviceActions.getServicesByOtp,
    cleanDriversFullName: driversActions.cleanDriversFullName,
    warning: alertActions.warning,
    clearVehicle: vehiclesActions.clearVehicle,
    filterByTenant: otpActions.filterByTenant
};

export default compose(
    withTranslation("common"),
    connect(mapState, actionCreators)
)(VehicleEdit);
