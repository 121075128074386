import React from 'react'
import Dashboard from '../dashboard';
import OtpView from './page';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { otpActions } from '../../redux/actions/otp_actions';

class Otps extends React.Component {

    componentDidMount() {
        this.props.getAll()
    }

    render() {
        return (
            <Dashboard
                component={
                    <OtpView
                        {...this.props}
                    />
                }

            />
        );
    }
}

function mapState(state) {
    const { otpReducer } = state;
    return { otpReducer };
}

const actionCreators = {
    getAll: otpActions.getAll,
    getById: otpActions.getById,
    remove: otpActions.remove
}

export default compose(withTranslation('common'), connect(mapState, actionCreators))(Otps);