export const OPERATIONS_ALLOWED = {
    read: 0,
    manage: 1,
    non: "empty",
}

export const MODULES = {
    BO_Profiles: 0,
    Drivers: 1,
    Vehicles: 2,
    OTP: 3,
    Zones: 4,
    Services: 5,
    Journeys: 6,
    Chat: 7,
    Incidents: 8,
    My_Profile: 9,
    Users: 10,
    Trips: 11,
    Notifications: 12,
    Tenants: 13,
    KPIs_dashboard: 14,
    Reports: 15,
    Home: 16
}


export const CUSTOM_PROFILE = 60
export const ADMIN_PROFILE = 10