import { Grid, Hidden } from '@material-ui/core';
import React from 'react';
import ItemListStop from '../item';
import i18next from 'i18next';
import ModalSetDistanceBetweenStops from './modal';


const LineRegularServiceWithSchedule = (props) => {

    const { lineObject, serviceReducer, serviceType, openModal, setModal, stopsSingle, saveLineWithSchedule } = props

    return (
        <>

            {serviceReducer.serviceLineSingleStops != null && (
                <>
                    <Grid item md={3} implementation="css" smDown component={Hidden} />
                    <Grid item md={5}>
                        <ItemListStop
                            serviceType={serviceType}
                            title={i18next.t('services_comp.lines.configSingle')}
                            items={serviceReducer.serviceLineSingleStops}
                            line={lineObject.outbound}
                            setItems={props.setstopsSingle}
                            setAllowChangeDirection={props.setallowChangeDirectionSingle}
                            allowChangeDirection={props.allowChangeDirectionSingle}
                        />
                    </Grid>
                    <Grid item md={4} implementation="css" smDown component={Hidden} />
                </>
            )}
            <ModalSetDistanceBetweenStops
                stopsSingle={stopsSingle}
                open={openModal}
                setItems={props.setstopsSingle}
                setModal={setModal}
                saveLineWithSchedule={saveLineWithSchedule}
            />
        </>
    )
}

export default LineRegularServiceWithSchedule