import React from 'react';
import DriverForm from '../form';

class CreateDriverView extends React.Component {

    render() {
        return (
            <DriverForm
                {...this.props}
            />
        );
    }
}

export default CreateDriverView;