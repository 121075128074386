import moment from 'moment'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSelectTrips } from '../../hooks/selectTrips.hook'
import { Button, Checkbox, Grid } from '@material-ui/core'
import { stylesMultipleTrip } from '../../details'
import { tripActions } from '../../../../../redux/actions/trip_actions'
import { multipleTripsActions } from '../../../../../redux/actions/multipleTrips_action'
import i18next from 'i18next'
import { PlanificatedTripItem } from './item'


const ListOfTripsPlannedComponentC = ({ handleMouseOver, handleCancelTrip, handleRetryTrip, handleScheduleReturn, listOfTrips }) => {
    const classes = stylesMultipleTrip()

    const areInReturnProcess = useSelector((state) => state.multipleTripReducer.areInReturnProcess)
    const disableCheck = useCallback((trip) => {
        if (trip.tripResponse == null || trip.hasErrors)
            return true
        return moment(trip.tripResponse?.schedulePickUpTime).isBefore(moment()) || areInReturnProcess || trip.tripResponse?.isReturn
    }, [listOfTrips, areInReturnProcess])

    const { selectedTrips, handleChangeSelectTrip, canScheduleReturn, selectAll, selectedAll, tripToPreview, changeTripSelectedToPreview, canScheduleSomeTrip } = useSelectTrips(disableCheck)
    const dispatch = useDispatch()


    const handleClickTrip = useCallback((e, trip) => {
        if (e.target.nodeName == 'DIV' && trip.tripResponse != null && !areInReturnProcess)
            changeTripSelectedToPreview(trip.tripResponse)
    }, [areInReturnProcess])

    const handleOnClickButtonSchedule = useCallback(() => {
        if (!areInReturnProcess)
            handleScheduleReturn()
        else {
            dispatch(tripActions.setTrip(listOfTrips.find(item => item.tripResponse).tripResponse))
            dispatch(multipleTripsActions.updateScheduleReturn(false))
        }
    }, [areInReturnProcess])


    return (
        <>
            <Grid container direction="column" alignItems="center" justify="center" spacing={2}>
                <h2 data-testId={'plannedTripsTitle'}>{i18next.t('trips.resume.plannedTripsTitle')}</h2>
                {canScheduleSomeTrip ?
                    <Button variant="contained" color="primary" disabled={!canScheduleReturn} onClick={handleOnClickButtonSchedule} >
                        {!areInReturnProcess ? i18next.t('trips.resume.scheduleReturnTripButton') : i18next.t('trips.form.page.buttonCancel')}
                    </Button>
                    : null
                }

            </Grid>
            <Grid item md={12} sm={12} style={{ display: 'flex', marginTop: 15 }}>
                <Grid item xl={3} md={2} className={`${classes.center}`}>
                    {canScheduleSomeTrip ?
                        <Checkbox checked={selectedAll} onChange={selectAll} color='primary' disabled={areInReturnProcess} />
                        : null
                    }
                </Grid>
            </Grid>
            {
                listOfTrips.map(trip => {
                    return (
                        <PlanificatedTripItem
                            key={trip.tripId}
                            trip={trip}
                            listOfTrips={listOfTrips}
                            handleClickTrip={handleClickTrip}
                            handleMouseOver={handleMouseOver}
                            handleRetryTrip={handleRetryTrip}
                            handleCancelTrip={handleCancelTrip}
                            selectedTrips={selectedTrips}
                            tripToPreview={tripToPreview}
                            handleChangeSelectTrip={handleChangeSelectTrip}
                            disableCheck={disableCheck}
                        />
                    )
                })
            }
        </>
    )
}

export const ListOfTripsPlannedComponent = React.memo(ListOfTripsPlannedComponentC, (prevProps, nextProps) => prevProps.listOfTrips == nextProps.listOfTrips)