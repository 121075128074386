import React from 'react';
import ServiceStopsView from './page'
import Dashboard from '../../dashboard';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { serviceActions } from '../../../redux/actions/services_actions';
import Spinner from '../../shared/spinner';
import { allowAccessRole } from '../../../helpers/roles';
import { MODULES, OPERATIONS_ALLOWED } from '../../roles/constants';

class ServiceStops extends React.Component {

    componentDidMount() {
        allowAccessRole(MODULES.Services, OPERATIONS_ALLOWED.manage)
        const params = this.props.location.pathname.split('/')
        const id = params[params.length - 1]
        this.props.getZonesWithStops(id)

    }

    render() {
        const { serviceReducer } = this.props
        return (
            <Dashboard
                component={
                    serviceReducer.pending ?
                        <Spinner loading={
                            serviceReducer.pending
                        }
                            {...this.props}
                        />
                        :
                        <ServiceStopsView
                            {...this.props}
                        />
                }
            />
        );
    }
}

function mapState(state) {
    const { serviceReducer } = state;
    return { serviceReducer };
}

const actionCreators = {
    getZonesWithStops: serviceActions.getZonesWithStops,
    saveStops: serviceActions.updateServiceStops
}

export default compose(withTranslation('common'), connect(mapState, actionCreators))(ServiceStops);