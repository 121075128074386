import { tenantConstants } from '../../constants/tenant_constants';

let initialState = {
    pending: false,
    results: []
};

export function tenantReducer(state = initialState, action) {
    switch (action.type) {
        case tenantConstants.CREATE_REQUEST:
            return {
                ...state,
                pending: true
            };
        case tenantConstants.CREATE_SUCCESS:
            return {
                ...state,
                pending: false,
                results: action.results
            };
        case tenantConstants.CREATE_FAILURE:
            return {
                ...state,
                pending: false,
                results: [],
                error: action.error
            };

        case tenantConstants.UPDATE_REQUEST:
            return {
                ...state,
                pending: true
            };
        case tenantConstants.UPDATE_SUCCESS:
            return {
                ...state,
                tenant: action.tenant,
                pending: false
            };
        case tenantConstants.UPDATE_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            };

        case tenantConstants.GET_TENANTS_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case tenantConstants.GET_TENANTS_SUCCESS:
            return {
                ...state,
                pending: false,
                results: action.results
            };
        case tenantConstants.GET_TENANTS_FAILURE:
            return {
                ...state,
                pending: false,
                results: [],
                error: action.error
            };
        case tenantConstants.CLEAR_RESULTS:
            return {
                ...state,
                results: [],
                pending: false
            };
        case tenantConstants.GET_TENANT_REQUEST:
            return {
                ...state,
                pending: true
            };
        case tenantConstants.GET_TENANT_SUCCESS:
            return {
                ...state,
                pending: false,
                tenant: action.tenant
            };
        case tenantConstants.GET_TENANT_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            };

        case tenantConstants.GET_TENANT_NAME_AND_COLORS_REQUEST:
            return {
                ...state,
                pending: true
            };
        case tenantConstants.GET_TENANT_NAME_AND_COLORS_SUCCESS:
            return {
                ...state,
                pending: false,
                results: action.results
            };
        case tenantConstants.GET_TENANT_NAME_AND_COLORS_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            };

        case tenantConstants.GET_TENANT_ICON_REQUEST:
            return {
                ...state,
                pending: true
            };
        case tenantConstants.GET_TENANT_ICON_SUCCESS:
            return {
                ...state,
                pending: false,
                results: action.results
            };
        case tenantConstants.GET_TENANT_ICON_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            };
        case tenantConstants.GET_TENANT_NAME_REQUEST:
            return {
                ...state,
                pending: true
            };
        case tenantConstants.GET_TENANT_NAME_SUCCESS:
            return {
                ...state,
                pending: false,
                results: action.results
            };
        case tenantConstants.GET_TENANT_NAME_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            };
        case tenantConstants.DELETE_REQUEST:
            return {
                ...state,
                pending: true
            };
        case tenantConstants.DELETE_SUCCESS:
            return {
                ...state,
                pending: false,
                results: action.results
            };
        case tenantConstants.DELETE_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            };
        default:
            return state;
    }
}