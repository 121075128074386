import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Chart from "react-google-charts";
import i18next from "i18next";
import KpiWrapper from "../../../components/kpiWrapper";
import { getVehicleCapacityKPI } from "../../../helpers/kpiHelper";

export default function VehicleCapacityChart({ isLoading }) {
    const { t } = useTranslation()

    const vehicleData = useSelector(state => state.kpiReducer.vehicleGeneralData)
    const isVehicleGeneralDataLoading = useSelector(state => state.kpiReducer.vehicleGeneralDataLoading)

    const data = useMemo(() => {
        if (!vehicleData.length) return []
        return getVehicleCapacityKPI(vehicleData)
    }, [vehicleData])

    isLoading = isLoading || isVehicleGeneralDataLoading
    const displayNoData = !isLoading && (!data || data.length == 0)

    return (
        <KpiWrapper
            title={t("commandBox.vehicles.vehicleCapacityChart.title")}
            displayNoData={displayNoData}
            isLoading={isLoading}
            skeletonVariant="rect"
        >
            <Chart
                chartType="ColumnChart"
                graphID={'chart_vehicle_capacity'}
                data={data}
                chartLanguage={i18next.language}
            />
        </KpiWrapper>
    )
}