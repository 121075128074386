import { Checkbox, FormControlLabel, Grid } from "@material-ui/core"
import SectionStyled, { TitleSection } from './section'
import React from 'react'
import i18next from "i18next"
import { InputNumberWithLabel } from "./inputNumberWithLabel"
import { SERVICES_TYPE } from "../../../../constants/types"


export const PlannerSection = ({ state, readOnly, handleChangeCheckBox, handleChange }) => {


    return (
        <SectionStyled>
            <TitleSection>{i18next.t('services_comp.form.page.titlePlanner')}</TitleSection>

            <Grid container spacing={3}>
                <Grid item md={6} xl={4} sm={9} xs={12}>
                    <InputNumberWithLabel
                        name={"maxTravelTimeFactor"}
                        onChange={handleChange}
                        readOnly={readOnly}
                        error={state.errors.maxTravelTimeFactor.message.length > 0}
                        helperText={state.errors.maxTravelTimeFactor.message}
                        value={state.maxTravelTimeFactor}
                        min={0}
                        label={i18next.t('services_comp.form.page.maxTravelTimeFactor')}
                        tooltip={i18next.t('services_comp.form.page.tooltip.maxTravelTimeFactor')}
                        step={'.01'}
                    />
                </Grid>
                <Grid item md={6} xl={4} sm={9} xs={12}>
                    <InputNumberWithLabel
                        name={"serviceTimePickUpSeconds"}
                        onChange={handleChange}
                        readOnly={readOnly}
                        error={state.errors.serviceTimePickUpSeconds.message.length > 0}
                        helperText={state.errors.serviceTimePickUpSeconds.message}
                        value={state.serviceTimePickUpSeconds}
                        min={0}
                        label={i18next.t('services_comp.form.page.serviceTimePickUpSeconds')}
                        tooltip={i18next.t('services_comp.form.page.tooltip.serviceTimePickUpSeconds')}
                    />
                </Grid>
                {state.serviceType?.id != SERVICES_TYPE.regularWithSchedule && (
                    <Grid item md={6} xl={4} sm={9} xs={12}>
                        <InputNumberWithLabel
                            name={"notificationDeviationTimeMinutes"}
                            onChange={handleChange}
                            readOnly={readOnly}
                            error={state.errors.notificationDeviationTimeMinutes.message.length > 0}
                            helperText={state.errors.notificationDeviationTimeMinutes.message}
                            value={state.notificationDeviationTimeMinutes}
                            min={0}
                            label={i18next.t('services_comp.form.page.notificationDeviationTimeMinutes')}
                            tooltip={i18next.t('services_comp.form.page.tooltip.notificationDeviationTimeMinutes')}
                        />
                    </Grid>
                )}
                {state.serviceType?.id != SERVICES_TYPE.regularWithSchedule && (
                    <Grid item md={6} xl={4} sm={9} xs={12}>
                        <InputNumberWithLabel
                            name={"pickupDeviationTimeMinutes"}
                            onChange={handleChange}
                            readOnly={readOnly}
                            error={state.errors.pickupDeviationTimeMinutes.message.length > 0}
                            helperText={state.errors.pickupDeviationTimeMinutes.message}
                            value={state.pickupDeviationTimeMinutes}
                            min={0}
                            label={i18next.t('services_comp.form.page.pickupDeviationTimeMinutes')}
                            tooltip={i18next.t('services_comp.form.page.tooltip.pickupDeviationTimeMinutes')}
                        />
                    </Grid>
                )}
                {state.serviceType?.id != SERVICES_TYPE.regularWithSchedule && (
                    <Grid item md={6} xl={4} sm={9} xs={12}>
                        <InputNumberWithLabel
                            name={"assignedPickupDeviationTimeMinutes"}
                            onChange={handleChange}
                            readOnly={readOnly}
                            error={state.errors.assignedPickupDeviationTimeMinutes.message.length > 0}
                            helperText={state.errors.assignedPickupDeviationTimeMinutes.message}
                            value={state.assignedPickupDeviationTimeMinutes}
                            min={0}
                            label={i18next.t('services_comp.form.page.assignedPickupDeviationTimeMinutes')}
                            tooltip={i18next.t('services_comp.form.page.tooltip.assignedPickupDeviationTimeMinutes')}
                        />
                    </Grid>
                )}
                <Grid item md={6} xl={4} sm={9} xs={12}>
                    <InputNumberWithLabel
                        name={"serviceTimeWheelChairSeconds"}
                        onChange={handleChange}
                        readOnly={readOnly}
                        error={state.errors.serviceTimeWheelChairSeconds.message.length > 0}
                        helperText={state.errors.serviceTimeWheelChairSeconds.message}
                        value={state.serviceTimeWheelChairSeconds}
                        min={0}
                        label={i18next.t('services_comp.form.page.serviceTimeWheelChairSeconds')}
                        tooltip={i18next.t('services_comp.form.page.tooltip.serviceTimeWheelChairSeconds')}
                    />
                </Grid>
                <Grid item md={6} xl={4} sm={9} xs={12}>
                    <InputNumberWithLabel
                        name={"serviceTimeLuggageSeconds"}
                        onChange={handleChange}
                        readOnly={readOnly}
                        error={state.errors.serviceTimeLuggageSeconds.message.length > 0}
                        helperText={state.errors.serviceTimeLuggageSeconds.message}
                        value={state.serviceTimeLuggageSeconds}
                        min={0}
                        label={i18next.t('services_comp.form.page.serviceTimeLuggageSeconds')}
                        tooltip={i18next.t('services_comp.form.page.tooltip.serviceTimeLuggageSeconds')}
                    />
                </Grid>
                {state.serviceType?.id != SERVICES_TYPE.assignmentWheelService && state.serviceType?.id != SERVICES_TYPE.regularWithSchedule && (
                    <Grid item md={6} xl={4} sm={9} xs={12}>
                        <InputNumberWithLabel
                            name={"alternativePickupTimeOffset"}
                            onChange={handleChange}
                            readOnly={readOnly}
                            error={state.errors.alternativePickupTimeOffset.message.length > 0}
                            helperText={state.errors.alternativePickupTimeOffset.message}
                            value={state.alternativePickupTimeOffset}
                            min={0}
                            label={i18next.t('services_comp.form.page.alternativePickupTimeOffset')}
                            tooltip={i18next.t('services_comp.form.page.tooltip.alternativePickupTimeOffset')}
                        />
                    </Grid>
                )}
                {state.serviceType?.id == SERVICES_TYPE.regularWithSchedule && <Grid item md={6} xl={4} sm={9} xs={12}>
                    <FormControlLabel fullWidth
                        control={
                            <Checkbox
                                checked={state.avoidDistanceMatrixCalculationService}
                                name="avoidDistanceMatrixCalculationService"
                                onChange={handleChangeCheckBox}
                                color={"primary"}
                                disabled={readOnly}
                            />
                        }
                        label={i18next.t('services_comp.form.page.avoidDistanceMatrixCalculationService')}
                    />
                </Grid>}
                <Grid item md={6} xl={4} sm={9} xs={12}>
                    <InputNumberWithLabel
                        name={"driverStopDeviationMinutes"}
                        onChange={handleChange}
                        readOnly={readOnly}
                        error={state.errors.driverStopDeviationMinutes.message.length > 0}
                        helperText={state.errors.driverStopDeviationMinutes.message}
                        value={state.driverStopDeviationMinutes}
                        min={0}
                        label={i18next.t('services_comp.form.page.driverStopDeviationMinutes')}
                        tooltip={i18next.t('services_comp.form.page.tooltip.driverStopDeviationMinutes')}
                    />
                </Grid>

            </Grid>
        </SectionStyled>

    )
}