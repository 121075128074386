
import { handleResponse } from '../helpers/services';
import { authHeader } from '../helpers/authHeader';
import stringConstants from '../constants/strings';

const COMMON_URL = `/opusers/v1/Profile`

export const profileService = {
    getAll,
    add,
    delate,
    update,
    getById,
    getModules,
    getUsersById,
};

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${stringConstants.URL_ApiGateway}${COMMON_URL}/GetProfiles`, requestOptions)
        .then(handleResponse);
}

function add(role) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(role)
    };
    return fetch(`${stringConstants.URL_ApiGateway}${COMMON_URL}/Add`, requestOptions).then(handleResponse);
}

function update(id, role) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(role)
    };

    return fetch(`${stringConstants.URL_ApiGateway}${COMMON_URL}/Update?id=${id}`, requestOptions).then(handleResponse);
}

function delate(id) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader()
    };

    return fetch(`${stringConstants.URL_ApiGateway}${COMMON_URL}/Remove?id=${id}`, requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${stringConstants.URL_ApiGateway}${COMMON_URL}/${id}`, requestOptions).then(handleResponse);
}

function getModules(lang) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${stringConstants.URL_ApiGateway}${COMMON_URL}/Modules?lang=${lang}`, requestOptions).then(handleResponse).then(handleModulesFormat)
}

function getUsersById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${stringConstants.URL_ApiGateway}${COMMON_URL}/GetProfileOpUsers?profileId=${id}`, requestOptions).then(handleResponse)
}

function handleModulesFormat(res) {
    return Object.entries(res).map(([k, v]) => {
        return { id: Number(k), name: v }
    })
}

