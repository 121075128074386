import React from 'react';
import Spinner from '../../shared/spinner';
import VehicleForm from '../form';

class VehicleDetailsView extends React.Component {

    render() {
        const { vehicleReducer, otpReducer, serviceReducer, driverReducer } = this.props
        return (
            <React.Fragment>
                {vehicleReducer.pending || otpReducer.pending || serviceReducer.pending || driverReducer.pending || vehicleReducer.vehicle == null || vehicleReducer.vehicle.id == null ?
                    <Spinner loading={vehicleReducer.pending || otpReducer.pending || serviceReducer.pending || driverReducer.pending || vehicleReducer.vehicle == null || driverReducer.driverFullName == null} /> :
                    <VehicleForm
                        {...this.props}
                    />
                }
            </React.Fragment>
        );
    }
}

export default VehicleDetailsView;