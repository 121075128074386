import React from 'react'
import ServicesView from './page'
import Dashboard from '../dashboard'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { serviceActions } from '../../redux/actions/services_actions'

class Services extends React.Component {
    componentDidMount() {
        const { serviceReducer } = this.props
        this.props.getAllWithZonesAndVehicles(serviceReducer.service_filters)
        this.props.getServicesTypes()
    }

    render() {
        return <Dashboard
            component={
                <ServicesView {...this.props} />
            }
        />
    }
}

function mapState(state) {
    const { serviceReducer } = state
    return { serviceReducer }
}

const actionCreators = {
    getAllWithZonesAndVehicles: serviceActions.getAllWithZonesAndVehicles,
    getById: serviceActions.getById,
    remove: serviceActions.remove,
    setServiceFilter: serviceActions.setServiceFilter,
    getServicesTypes: serviceActions.getServicesTypes,
    disable: serviceActions.disable,
    enable: serviceActions.enable,
    saveTableFilters: serviceActions.saveTableFilters
}

export default compose(withTranslation('common'), connect(mapState, actionCreators))(Services)
