import React, { useCallback, useRef, useState } from "react";
import { FormControl, Grid, TextField, Tooltip } from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import { factory } from "../../../../helpers/factory";
import Brightness2Icon from "@material-ui/icons/Brightness2";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { Fragment } from "react";
import i18next from "i18next";

const ShiftDays = (props) => {
    const { checkUpdates, value, state, weekDaysOptions, weekDaysAllOptions, filterWeekOptions, restrictWeekDays = false, showButtons = true } = props;

    const [radioValue, setradioValue] = useState("");

    const handleChange = (event) => {
        setradioValue(event.target.value);
        checkUpdates(null, event.target.value);
    };

    const onChangeWeekDays = useCallback((body) => {
        checkUpdates(body);
        if (showButtons)
            setradioValue("");
        if (restrictWeekDays) {
            filterWeekOptions(Object.assign([], body.map(item => item.id ? parseInt(item.id) : item)))
        }
    }, [weekDaysOptions]);


    return (
        <Grid container spacing={2}>
            <Grid item md={2} xs={12} sm={6}>
                <FormControl fullWidth>
                    <TextField
                        label={i18next.t("services_comp.calendar.shiftDays.earliestStart")}
                        value={props.value.earliestStart || ""}
                        variant="outlined"
                        name={"earliestStart_" + props.index}
                        fullWidth
                        type={"time"}
                        InputLabelProps={{ shrink: true }}
                        onChange={props.onChange}
                        helperText={
                            state.errors[props.prop][props.index].earliestStart.message
                        }
                        error={
                            state.errors[props.prop][props.index].earliestStart.message
                                .length !== 0
                        }
                        inputProps={{
                            readOnly: props.disabled,
                        }}
                    />
                </FormControl>
            </Grid>
            <Grid item md={2} xs={12} sm={6}>
                <FormControl fullWidth>
                    <TextField
                        label={i18next.t("services_comp.calendar.shiftDays.latestArrival")}
                        value={props.value.latestArrival || ""}
                        variant="outlined"
                        name={"latestArrival_" + props.index}
                        fullWidth
                        type={"time"}
                        InputLabelProps={{ shrink: true }}
                        onChange={props.onChange}
                        helperText={
                            state.errors[props.prop][props.index].latestArrival.message
                        }
                        error={
                            state.errors[props.prop][props.index].latestArrival.message
                                .length === 0
                                ? false
                                : true
                        }
                        inputProps={{
                            readOnly: props.disabled,
                        }}
                    />
                </FormControl>
            </Grid>

            <Grid item md={6} sm={11}>
                {weekDaysAllOptions.length > 0 && (
                    <Fragment>
                        <Autocomplete
                            options={weekDaysOptions.filter(item => !value.weekDays.includes(item.id))}
                            freeSolo
                            multiple
                            value={value.weekDays}
                            onChange={(_, newValue) => {
                                onChangeWeekDays(newValue);
                            }}
                            getOptionSelected={(option, value) => option.id == value}
                            getOptionLabel={(option) =>
                                option && option.name
                                    ? option && option.name
                                    : weekDaysAllOptions.find((x) => x.id == option)?.name
                            }
                            disabled={props.disabled}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant={"outlined"}
                                    label={i18next.t("services_comp.calendar.workDays.input")}
                                    helperText={
                                        state.errors[props.prop][props.index].weekDays.message
                                    }
                                    name="brand"
                                    error={
                                        state.errors[props.prop][props.index].weekDays.message
                                            .length === 0
                                            ? false
                                            : true
                                    }
                                />
                            )}
                        />
                        {showButtons && (
                            <FormControl component="fieldset">
                                <RadioGroup row value={radioValue} onChange={handleChange}>
                                    <FormControlLabel
                                        value={"2"}
                                        control={<Radio checked={JSON.stringify(value.weekDays) == JSON.stringify([1, 2, 3, 4, 5, 6, 0])} color="primary" />}
                                        label={i18next.t('services_comp.calendar.shiftDays.allDays')}
                                        disabled={props.disabled}
                                    />
                                    <FormControlLabel
                                        value={"3"}
                                        control={<Radio checked={JSON.stringify(value.weekDays) == JSON.stringify([1, 2, 3, 4, 5])} color="primary" />}
                                        label={i18next.t('services_comp.calendar.shiftDays.workDays')}
                                        disabled={props.disabled}
                                    />
                                    <FormControlLabel
                                        value={"1"}
                                        control={<Radio checked={JSON.stringify(value.weekDays) == JSON.stringify([6, 0])} color="primary" />}
                                        label={i18next.t('services_comp.calendar.shiftDays.weekend')}
                                        disabled={props.disabled}
                                    />
                                </RadioGroup>
                            </FormControl>
                        )}

                    </Fragment>
                )}
            </Grid>

            <Grid
                item
                md={1}
                style={{
                    display: "flex",
                    alignItems: "center",
                }}
            >
                {!props.detail &&
                    (props.index + 1 == props.length ? (
                        <React.Fragment>
                            {props.length >= 1 && (
                                <Remove onClick={() => props.remove(props.index)} />
                            )}
                            <Add onClick={() => props.add()} />
                        </React.Fragment>
                    ) : (
                        <Remove onClick={() => props.remove(props.index)} />
                    ))}
                {factory.checkTimesAfterMidth(
                    props.value.earliestStart,
                    props.value.latestArrival
                ) && (
                        <Tooltip title={i18next.t("vehicles.form.page.nightTime")}>
                            <Brightness2Icon />
                        </Tooltip>
                    )}
            </Grid>
        </Grid>

    );
};

export default ShiftDays;