import React from 'react';
import Spinner from '../../shared/spinner';
import DriverForm from '../form';

class DriverDetailsView extends React.Component {

    componentDidMount() {
        if (this.props.driver)
            this.props.getVehiclesByOTP(this.props.driver.otpId)
    }

    render() {
        const { driverReducer, otpReducer, vehicleReducer } = this.props
        return (
            <React.Fragment>
                {driverReducer.pending || otpReducer.pending || vehicleReducer.pending ?
                    <Spinner loading={driverReducer.pending || otpReducer.pending || vehicleReducer.pending} /> :
                    <DriverForm
                        {...this.props}
                    />
                }
            </React.Fragment>
        );
    }
}

export default DriverDetailsView;