import React from 'react'
import Dashboard from '../dashboard';
import DriverView from './page';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { driversActions } from '../../redux/actions/driver_actions';

class Drivers extends React.Component {

    componentDidMount() {
        this.props.getAll()
    }

    render() {
        return (
            <Dashboard
                component={
                    <DriverView
                        {...this.props}
                    />
                }

            />
        );
    }
}

function mapState(state) {
    const { driverReducer } = state;
    return { driverReducer };
}

const actionCreators = {
    getAll: driversActions.getAll,
    enable: driversActions.enable,
    disable: driversActions.disable,
    getByIdWithVehicles: driversActions.getByIdWithVehicles,
    remove: driversActions.remove,
    validate: driversActions.validate,
    sendResetPasswordEmail: driversActions.forgotPassword,
    setCurrentPage: driversActions.setCurrentPage,
    getResetPasswordLink: driversActions.getResetPasswordLink,
    saveDriverFilters: driversActions.saveDriverFilters
}

export default compose(withTranslation('common'), connect(mapState, actionCreators))(Drivers);
