import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core'
import i18next from 'i18next'
import React from 'react'

export const SelectRequestType = ({ isRequestByDropOff, editable, onChange }) => {
    return (
        <>
            <FormLabel id="requestTypeLabel">{i18next.t('trips.form.page.requestType')}</FormLabel>
            <FormControl fullWidth>
                <RadioGroup
                    row
                    aria-labelledby='requestTypeLabel'
                    defaultValue="pickup"
                    name="requestType"
                    onChange={(event) =>
                        onChange(event.target.value === "dropoff")
                    }
                >
                    <FormControlLabel value="pickup" control={<Radio color="primary" disabled={!editable} />} label={i18next.t('trips.form.page.pickup')} checked={!isRequestByDropOff} />
                    <FormControlLabel value="dropoff" control={<Radio color="primary" disabled={!editable} />} label={i18next.t('trips.form.page.dropoff')} checked={isRequestByDropOff} />
                </RadioGroup>
            </FormControl >
        </>
    )
}