import React from 'react';
import ServiceForm from '../form';
import Spinner from '../../shared/spinner';

class CreateServiceView extends React.Component {

    render() {
        const { zoneReducer, serviceReducer, otpReducer } = this.props

        return (
            <React.Fragment>
                {zoneReducer.pending || serviceReducer.pending || otpReducer.pending ?
                    <Spinner loading={zoneReducer.pending || serviceReducer.pending || otpReducer.pending} /> :
                    <ServiceForm
                        {...this.props}
                    />
                }
            </React.Fragment>
        );
    }
}

export default CreateServiceView;