import React, { useState } from 'react';
import { Button, IconButton, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddBox'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import MaterialTableExtend from '../../../material-table/index'
import i18next from 'i18next';
import { history } from '../../../../helpers/history';
import { SERVICES_TYPE } from '../../../../constants/types';
import AlertDialog from '../../../alert-dialog';
import { allowAccessRole } from '../../../../helpers/roles';
import { MODULES, OPERATIONS_ALLOWED } from '../../../roles/constants';


const ListOfLinesOfService = (props) => {

    const { lines, id } = props
    const onClickAddButton = () => {
        history.push(`/services/setLines/${id}/` + SERVICES_TYPE.regularWithSchedule.toString())
    }

    const [openModal, setOpenModal] = useState(false)
    const [itemSelected, setItemSelected] = useState(null)

    const Wrapper = () => (
        <React.Fragment>
            {allowAccessRole(MODULES.Services, OPERATIONS_ALLOWED.manage) && (
                <Button
                    onClick={onClickAddButton}
                    variant="contained"
                    style={{ marginLeft: '24px' }}
                    color="primary"
                    size="large">
                    {i18next.t('services_comp.lines.wrapper.add')}
                </Button>
            )}
        </React.Fragment>
    )

    const onClickDetailLine = (id, type, lineId) => {
        history.push(`/services/setLines/${id}/${type}/${lineId}`)
    }

    const onClickExpeditionLine = (id, type, lineId) => {
        history.push(`/services/setExpeditions/${id}/${type}/${lineId}`)
    }

    const onClickPricesLine = (lineId, name) => {
        history.push({
            pathname: `/services/setCustomerTypologyLine/${lineId}`,
            state: { lineName: name }
        })
    }

    const onClickDelete = (item) => {
        setOpenModal(true)
        setItemSelected(item)
    }

    const onClickAcceptDelete = () => {
        props.removeLine(itemSelected.id, itemSelected.serviceId)
        setOpenModal(false)
    }

    const onClickConfigCalendar = (id, serviceType) => {
        history.push('/services/configCalendar/' + id + '/' + serviceType)
    }

    return (
        <>
            <MaterialTableExtend
                title={i18next.t('services_comp.lines.listServices')}
                buttons={<Wrapper />}
                columns={[
                    {
                        title: i18next.t('services_comp.lines.name'),
                        field: 'name'
                    }
                ]}
                data={lines}
                actionsColumnIndex={1}
                actions={[
                    rowData => ({
                        icon: 'view_list',
                        tooltip: i18next.t('services_comp.page.actions.detail'),
                        onClick: (event, rowData) => onClickDetailLine(rowData.serviceId, SERVICES_TYPE.regularWithSchedule, rowData.id)
                    }),
                    rowData => ({
                        icon: 'person_add',
                        iconProps: { style: { color: rowData && !rowData.hasCustomerBilling ? 'orange' : '' } },
                        onClick: (event, rowData) => onClickPricesLine(rowData.id, rowData.name),
                        tooltip: i18next.t('services_comp.page.actions.set_typologyCustomer')
                    }),
                    rowData => ({
                        icon: 'schedule',
                        iconProps: { style: { color: !rowData.hasExpeditions ? "orange" : "" } },
                        onClick: (event, rowData) => onClickExpeditionLine(rowData.serviceId, SERVICES_TYPE.regularWithSchedule, rowData.id),
                        tooltip: rowData?.hasExpeditions ? i18next.t('services_comp.page.actions.expedition') : i18next.t('services_comp.page.actions.expeditionDisable')
                    }),
                    rowData => ({
                        icon: 'date_range',
                        iconProps: { style: { color: !rowData.hasCalendar ? 'orange' : '' } },
                        tooltip: i18next.t('services_comp.page.actions.calendar'),
                        onClick: (event, rowData) => onClickConfigCalendar(rowData.id, SERVICES_TYPE.regularWithSchedule),
                    }),
                    rowData => ({
                        icon: 'delete',
                        onClick: (event, rowData) => onClickDelete(rowData),
                        tooltip: i18next.t('services_comp.page.actions.delete')
                    })

                ]}
            />
            <AlertDialog
                open={openModal}
                title={`${i18next.t('services_comp.lines.alert_dialog.titleDelete')} ${itemSelected?.name}`}
                onClickCancel={() => setOpenModal(false)}
                onClickAccept={() => onClickAcceptDelete()}
            />
        </>
    )

}

export default ListOfLinesOfService