import { authHeader } from '../helpers/authHeader'
import stringConstants from '../constants/strings'
import i18next from 'i18next'
import { handleResponse } from '../helpers/services';

export const notificationService = {
    getAll,
    getTypes,
    checkNotification,
    getNotificationsCount,
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    const lang = i18next.language
    return fetch(
        stringConstants.URL_ApiGateway +
        '/services/v1/notifications/getNotifications?lang=' +
        lang,
        requestOptions,
    ).then(handleResponse)
}

function getTypes() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    const lang = i18next.language
    return fetch(
        stringConstants.URL_ApiGateway +
        '/services/v1/notifications/notificationTypes?lang=' +
        lang,
        requestOptions,
    ).then(handleResponse)
}

function checkNotification(id) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    }
    return fetch(
        stringConstants.URL_ApiGateway +
        '/services/v1/notifications/checkNotification?notificationId=' +
        id,
        requestOptions,
    ).then(handleResponse)
}

function getNotificationsCount() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return fetch(
        stringConstants.URL_ApiGateway +
        '/services/v1/notifications/getNotificationsCount',
        requestOptions,
    ).then(handleResponse)
}
