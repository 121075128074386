import React from 'react'
import Dashboard from '../dashboard'
import TripsView from './page'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { tripActions } from '../../redux/actions/trip_actions'
import { driversActions } from '../../redux/actions/driver_actions'
import { multipleTripsActions } from '../../redux/actions/multipleTrips_action'

class Trips extends React.Component {

    componentDidMount() {
        const { tripReducer } = this.props
        this.props.getTripStatuses()
        const params = this.props.location.pathname.split('/')
        const id = params[params.length - 1]
        if (params.length === 3) this.props.getById(id)
        else if (this.props.location.pathname.includes('tripsPending'))
            this.props.getTripsPending(null, tripReducer.filterStartDate != '' ? tripReducer.filterStartDate : null, tripReducer.filterEndDate != '' ? tripReducer.filterStartDate : null)
        else this.props.getTrips(tripReducer.filterTrips, tripReducer.filterStartDate != '' ? tripReducer.filterStartDate : null, tripReducer.filterEndDate != '' ? tripReducer.filterEndDate : null)
    }

    componentDidUpdate(prevProps) {
        if (this.props.tripReducer != prevProps.tripReducer) {
            if (this.props.tripReducer.typesTrips != prevProps.tripReducer.typesTrips) {
                return true
            }
        }
        return false
    }

    render() {
        return <Dashboard component={<TripsView {...this.props} />} />
    }
}

function mapState(state) {
    const { tripReducer, driverReducer } = state
    return { tripReducer, driverReducer }
}

const actionCreators = {
    getTripStatuses: tripActions.getTripStatuses,
    setTrip: tripActions.setTrip,
    cancelTrip: tripActions.cancelTrip,
    cancelTripByDriverUnavailable: tripActions.cancelTripByDriverUnavailable,
    getById: tripActions.getById,
    getTrips: tripActions.getTrips,
    getTripsPending: tripActions.getTripsPending,
    confirmTripVehicle: tripActions.confirmTripVehicle,
    delegateTripVehicle: tripActions.delegateTripVehicle,
    setFilterDate: tripActions.setTripsFilterDate,
    closeModal: driversActions.closeModalToAssing,
    getPosibleDriversToAssing: driversActions.getPosibleDriversToAssing,
    filterTrips: tripActions.filterTrips,
    saveTableFilters: tripActions.saveTableFilters,
    cleanFilters: tripActions.cleanFilters,
    getTripsFromMultipleRequest: multipleTripsActions.getTripsFromMultipleRequest,
    cleanMultipleTripsResults: multipleTripsActions.cleanResults
}

export default compose(
    withTranslation('common'),
    connect(mapState, actionCreators),
)(Trips)