import React from 'react';
import DayOfWeekExpedition from './DayOfWeekExpedition';

const GridExpedition = ({ entries, daysOfWeek, remove, readOnly, setEntries }) => {

    const updateState = (newValue) => {
        setEntries(newValue)
    }

    return (
        <>
            {
                Object.getOwnPropertyNames(entries).map(key =>
                    <DayOfWeekExpedition
                        readOnly={readOnly}
                        key={key}
                        remove={remove}
                        dayInWeek={key}
                        dayOfTheWeek={daysOfWeek.find(item => item.id == key)?.name}
                        updateState={updateState}
                        entries={entries}
                    />
                )

            }
        </>
    );
}


export default GridExpedition;