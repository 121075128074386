import React from 'react';
import Dashboard from '../../dashboard'
import OtpDetailsView from './page';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { otpActions } from '../../../redux/actions/otp_actions';
import { history } from '../../../helpers/history';

class OtpDetail extends React.Component {

    onClick(otp) {
        history.push('/otps/edit/' + otp.id)
    }

    render() {
        const { t } = this.props
        return (
            <Dashboard
                component={
                    <OtpDetailsView
                        {...this.props}
                        title={t('otp.details.title')}
                        buttonRight={t('otp.form.page.buttonDetail')}
                        goBack={t('otp.form.page.goBack')}
                        sendOtp={() => this.onClick(this.props.otpReducer.otp)}
                        readOnly={true}
                        nextAction={'edit'}
                        detail={true}
                        otp={this.props.otpReducer.otp}
                    />
                }
            />
        );
    }
}

function mapState(state) {
    const { otpReducer } = state;
    return { otpReducer };
}

const actionCreators = {
    getById: otpActions.getById
}


export default compose(withTranslation('common'), connect(mapState, actionCreators))(OtpDetail);