import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getOriginDestinationMatrix } from "../../../helpers/kpiHelper";
import { useSelector } from "react-redux";
import KpiWrapper from "../../../components/kpiWrapper";
import MaterialTable from "material-table";

export default function OriginDestinationChart({ isLoading }) {
    const { t } = useTranslation();
    const groupedTrips = useSelector(state => state.kpiReducer.confirmGroupedTrips)
    const isGroupedTripsLoading = useSelector(state => state.kpiReducer.confirmGroupedTripsLoading)
    const { type } = useSelector(state => state.kpiFiltersReducer)

    const { header, body } = useMemo(() => {
        if (!groupedTrips.length) return { data: [] }
        const data = getOriginDestinationMatrix(groupedTrips)

        const header = [{ name: "origin", field: "origin" }, ...data[0].slice(1).map(cell => ({ title: cell, field: cell }))]
        const body = data.slice(1).map(row => ({ origin: row[0], ...row.slice(1).reduce((acc, cell, index) => ({ ...acc, [data[0][index + 1]]: cell }), {}) }))

        return { header, body }
    }, [groupedTrips, type])

    isLoading = isLoading || isGroupedTripsLoading
    const displayNoData = !isLoading && !body || body?.length === 0

    return (
        <KpiWrapper
            isLoading={isLoading}
            skeletonVariant="rect"
            skeletonHeight="800px"
            displayNoData={displayNoData}
        >
            <MaterialTable
                options={{
                    sorting: true,
                    search: false,
                    exportButton: false,
                    toolbar: false,
                    paging: false
                }}
                columns={header}
                data={body}
            >
            </MaterialTable>
        </KpiWrapper>
    )
}