import { Button, Grid } from "@material-ui/core"
import React from 'react'
import ServiceCalendarRange from "../ranges"
import moment from "moment"
import i18next from "i18next"
import { Remove } from '@material-ui/icons'
import { useSelector } from "react-redux"


export const NoOperativeHolidays = ({ calendar, removeExcludeDays, ...rest }) => {
    const serviceReducer = useSelector((state) => state.serviceReducer)
    let ranges = serviceReducer.ranges

    return (
        <Grid container spacing={2}>
            <Grid item md={12}>
                <h2>{i18next.t('services_comp.calendar.range.title')}</h2>
            </Grid>
            <Grid item md={6} sm={12}>
                <ServiceCalendarRange {...rest} />
            </Grid>
            <Grid item md={6} sm={12}>
                {calendar.excludedPeriods?.map((item, index) => (
                    <React.Fragment key={'e_' + index}>
                        <Grid container spacing={2} justify="center">
                            <Grid item md={4}>
                                <span>{i18next.t('services_comp.calendar.range.start')}: </span>
                                {moment(item.startPeriod).format('DD/MM/YYYY')}
                            </Grid>
                            <Grid item md={4}>
                                <span>{i18next.t('services_comp.calendar.range.end')}: </span>
                                {moment(item.finishPeriod).format('DD/MM/YYYY')}
                            </Grid>
                            <Grid item md={2}>
                                <Remove style={{ cursor: 'pointer' }} onClick={() => removeExcludeDays(index)} />
                            </Grid>
                        </Grid>
                    </React.Fragment>
                ))}
            </Grid>
            <Grid
                container
                justify="flex-end"
                alignItems="flex-end"
            >
                <Grid item md={1}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        size="medium"
                        onClick={() => rest.setExcludeDays(ranges)}
                        style={{ marginRight: 50 }}
                    >
                        {i18next.t('services_comp.calendar.range.add')}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}