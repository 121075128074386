import {
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    Hidden,
    TextField
} from "@material-ui/core";
import React from "react";


class OtpFields extends React.Component {
    render() {
        const { t, otpReducer } = this.props;
        return (
            <React.Fragment>
                <Grid item md={1} implementation="css" smDown component={Hidden} />
                <Grid item md={4} xs={6} sm={3}>
                    <FormControl fullWidth>
                        <InputLabel shrink id="otpLabel">
                            {t("operators.form.page.otp")}
                        </InputLabel>
                        <Select
                            labelId="otpLabel"
                            id="profile"
                            name="otp"
                            value={this.props.state.otp || ""}
                            onChange={this.props.handleChange}
                            error={
                                this.props.state.errors.otp.message.length !== 0
                            }
                            displayEmpty
                            inputProps={{ readOnly: this.props.readOnly }}
                        >
                            <MenuItem key={"withoutotp"} value={""}>
                                {t("operators.form.page.optionsSelector.noOtp")}
                            </MenuItem>
                            <MenuItem key={"create"} value={"createOTP"}>
                                {t("operators.form.page.optionsSelector.create")}
                            </MenuItem>
                            {otpReducer.results.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText error>
                            {this.props.state.errors.otp.message}
                        </FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item md={7} implementation="css" smDown component={Hidden} />

                {
                    this.props.state.otp == "createOTP" && this.props.nextAction == "create" && (
                        <React.Fragment>
                            <Grid item md={1} implementation="css" smDown component={Hidden} />
                            <Grid item md={3} xs={6} sm={3} >
                                <FormControl fullWidth>
                                    <TextField
                                        label={t('otp.form.page.name')}
                                        value={this.props.state.otpNewName || ''}
                                        variant="outlined"
                                        name="otpNewName"
                                        fullWidth
                                        onChange={this.props.handleChange}
                                        error={this.props.state.errors.otpNewName.message.length === 0 ? false : true}
                                        helperText={this.props.state.errors.otpNewName.message}
                                        inputProps={
                                            { readOnly: this.props.readOnly || this.props.readEdit }
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={3} xs={6} sm={3} >
                                <FormControl fullWidth>
                                    <TextField
                                        label={t('otp.form.page.vatNumber')}
                                        value={this.props.state.otpVatNumber || ''}
                                        variant="outlined"
                                        name="otpVatNumber"
                                        fullWidth
                                        onChange={this.props.handleChange}
                                        error={this.props.state.errors.otpVatNumber.message.length === 0 ? false : true}
                                        helperText={this.props.state.errors.otpVatNumber.message}
                                        inputProps={
                                            { readOnly: this.props.readOnly || this.props.readEdit }
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={3} xs={6} sm={3} >
                                <FormControl fullWidth>
                                    <TextField
                                        label={t('otp.form.page.contact')}
                                        value={this.props.state.otpContactPerson || ''}
                                        variant="outlined"
                                        name="otpContactPerson"
                                        fullWidth
                                        onChange={this.props.handleChange}
                                        error={this.props.state.errors.otpContactPerson.message.length === 0 ? false : true}
                                        helperText={this.props.state.errors.otpContactPerson.message}
                                        inputProps={
                                            { readOnly: this.props.readOnly || this.props.readEdit }
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={2} implementation="css" smDown component={Hidden} />


                            <Grid item md={1} implementation="css" smDown component={Hidden} />
                            <Grid item md={3} xs={6} sm={3} >
                                <FormControl fullWidth>
                                    <TextField
                                        label={t('otp.form.page.email')}
                                        value={this.props.state.otpEmail || ''}
                                        variant="outlined"
                                        name="otpEmail"
                                        fullWidth
                                        onChange={this.props.handleChange}
                                        error={this.props.state.errors.otpEmail.message.length === 0 ? false : true}
                                        helperText={this.props.state.errors.otpEmail.message}
                                        inputProps={
                                            { readOnly: this.props.readOnly || this.props.readEdit }
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={2} xs={6} sm={3} >
                                <FormControl fullWidth>
                                    <TextField
                                        label={t('otp.form.page.phone')}
                                        value={this.props.state.otpPhoneNumber || ''}
                                        variant="outlined"
                                        name="otpPhoneNumber"
                                        fullWidth
                                        type={'tel'}
                                        onChange={this.props.handleChange}
                                        error={this.props.state.errors.otpPhoneNumber.message.length === 0 ? false : true}
                                        helperText={this.props.state.errors.otpPhoneNumber.message}
                                        inputProps={
                                            { readOnly: this.props.readOnly || this.props.readEdit }
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={6} implementation="css" smDown component={Hidden} />
                        </React.Fragment>
                    )
                }

            </React.Fragment>
        );
    }
}

export default OtpFields;
