import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import KpiWrapper from '../../../components/kpiWrapper';
import { useTranslation } from 'react-i18next';

export default function ReservationTable({ isLoading }) {
    const { t } = useTranslation()
    const reservation = useSelector(state => state.kpiReducer.reservations)
    const isReservationsLoading = useSelector(state => state.kpiReducer.reservationsLoading)

    function createData(name, byBackOffice, byUserApp, byUserWeb, total) {
        return { name, byBackOffice, byUserApp, byUserWeb, total };
    }

    const totalBO = reservation.acceptedByBO + reservation.rejectedByBO + reservation.cancelledByBO
    const totalUserApp = reservation.acceptedByCustomerApp + reservation.rejectedByCustomerApp + reservation.cancelledByCustomerApp
    const totalUserWeb = reservation.acceptedByCustomerWebApp + reservation.rejectedByCustomerWebApp + reservation.cancelledByCustomerWebApp

    const rows = [
        createData(t("commandBox.summary.reservationTable.row1"), reservation.acceptedByBO, reservation.acceptedByCustomerApp, reservation.acceptedByCustomerWebApp, reservation.accepted),
        createData(t("commandBox.summary.reservationTable.row2"), reservation.rejectedByBO, reservation.rejectedByCustomerApp, reservation.rejectedByCustomerWebApp, reservation.rejected),
        createData(t("commandBox.summary.reservationTable.row3"), reservation.cancelledByBO, reservation.cancelledByCustomerApp, reservation.cancelledByCustomerWebApp, reservation.cancelled),
    ];

    isLoading = isLoading || isReservationsLoading
    return (

        <KpiWrapper title={t("commandBox.summary.reservationTable.title")} isLoading={isLoading} skeletonVariant="rect">
            <TableContainer component={Box}>
                <Table arial-label="reservation table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t("commandBox.summary.reservationTable.header.cell1")}</TableCell>
                            <TableCell align="right">{t("commandBox.summary.reservationTable.header.cell2")}</TableCell>
                            <TableCell align="right">{t("commandBox.summary.reservationTable.header.cell3")}</TableCell>
                            <TableCell align="right">{t("commandBox.summary.reservationTable.header.cell4")}</TableCell>
                            <TableCell align="right">{t("commandBox.summary.reservationTable.header.cell5")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow key={row.name}>
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell align="right">
                                    <Typography style={{ fontSize: "16px" }}>
                                        {row.byBackOffice}
                                    </Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography style={{ fontSize: "16px" }}>
                                        {row.byUserApp}
                                    </Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography style={{ fontSize: "16px" }}>
                                        {row.byUserWeb}
                                    </Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography style={{ fontSize: "16px", fontWeight: "600" }}>
                                        {row.total}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell component="th" scope="row">
                                <Typography style={{ fontSize: "16px" }}>
                                    {t("commandBox.summary.reservationTable.row4")}
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography style={{ fontSize: "16px", fontWeight: "600" }}>
                                    {totalBO}
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography style={{ fontSize: "16px", fontWeight: "600" }}>
                                    {totalUserApp}
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography style={{ fontSize: "16px", fontWeight: "600" }}>
                                    {totalUserWeb}
                                </Typography>
                            </TableCell>
                            <TableCell align="right">

                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </KpiWrapper>
    )
}