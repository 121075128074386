import React from 'react';
import OTPFormView from './page'

class OTPForm extends React.Component {

    render() {
        return (
            <OTPFormView
                {...this.props}
            />
        );
    }
}

export default OTPForm;