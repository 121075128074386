import React from "react";
import CreateVehicleView from "./page";
import Dashboard from "../../dashboard";
import { connect } from "react-redux";
import { otpActions } from "../../../redux/actions/otp_actions";
import { vehiclesActions } from "../../../redux/actions/vehicles_actions";
import { driversActions } from "../../../redux/actions/driver_actions";
import { serviceActions } from "../../../redux/actions/services_actions";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { alertActions } from "../../../redux/actions/alert_actions";

class VehicleCreate extends React.Component {

    componentDidMount() {
        this.props.getAllOTP();
        this.props.getAllBrands();
        this.props.getWeekDays();
        this.props.getPrioritiesVehicle();
        this.props.cleanDriversFullName()
        this.props.cleanServicesName()
    }

    render() {
        const { t } = this.props;
        return (
            <Dashboard
                component={
                    <CreateVehicleView
                        title={t("vehicles.create.title")}
                        readOnly={false}
                        nextAction={"create"}
                        sendVehicle={this.props.insert}
                        buttonRight={t("vehicles.form.page.buttonRegister")}
                        goBack={t("vehicles.form.page.goBack")}
                        {...this.props}
                    />
                }
            />
        );
    }
}

function mapState(state) {
    const { vehicleReducer, otpReducer, serviceReducer, driverReducer } = state;
    return { vehicleReducer, otpReducer, serviceReducer, driverReducer };
}

const actionCreators = {
    insert: vehiclesActions.insert,
    getAllBrands: vehiclesActions.getAllBrands,
    getModeldsByBrands: vehiclesActions.getModeldsByBrands,
    getModeldsByBrandString: vehiclesActions.getModeldsByBrandString,
    clearModels: vehiclesActions.clearModels,
    getAllOTP: otpActions.getAll,
    getWeekDays: serviceActions.getWeekDays,
    getPrioritiesVehicle: vehiclesActions.getPrioritiesVehicle,
    positionInitialIsInOtpServiceZones: otpActions.positionInitialIsInOtpServiceZones,
    getDriversFullName: driversActions.getDriversFullName,
    getServicesByOtp: serviceActions.getServicesByOtp,
    cleanDriversFullName: driversActions.cleanDriversFullName,
    warning: alertActions.warning,
    clearVehicle: vehiclesActions.clearVehicle,
    cleanServicesName: driversActions.cleanServicesName
};

export default compose(
    withTranslation("common"),
    connect(mapState, actionCreators)
)(VehicleCreate);
