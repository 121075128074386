import React from 'react';
import CreateOtpView from './page';
import Dashboard from '../../dashboard';
import { connect } from 'react-redux';
import { otpActions } from '../../../redux/actions/otp_actions';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

class OtpCreate extends React.Component {
    render() {
        const { t } = this.props
        return (
            <Dashboard
                component={
                    <CreateOtpView
                        title={t('otp.create.title')}
                        readOnly={false}
                        sendOtp={this.props.insert}
                        nextAction={'create'}
                        buttonRight={t('otp.form.page.buttonEdit')}
                        goBack={t('otp.form.page.goBack')}
                        {...this.props}
                    />
                }
            />
        );
    }
}

function mapState(state) {
    const { otpReducer } = state;
    return { otpReducer };
}

const actionCreators = {
    insert: otpActions.insert
}

export default compose(withTranslation('common'), connect(mapState, actionCreators))(OtpCreate)