import React from 'react';
import { Grid, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DragDrop from '../../shared/dragDrop';

const DayOfWeekExpedition = ({ dayOfTheWeek, dayInWeek, remove, readOnly, updateState, entries }) => {


    const renderChildren = (item, key) => {
        return (
            <Grid item md={12} style={{ margin: 3, backgroundColor: '#80cae9', textAlign: 'center', fontSize: 17 }}>
                {item}
                {
                    !readOnly ?
                        <IconButton>
                            <CloseIcon onClick={() => remove(key, item)} style={{ margin: 0, position: 'absolute' }} />
                        </IconButton>
                        : null
                }
            </Grid>
        )
    }

    const getData = (array) => {
        return array.map(item => item)
    }

    const grid = 8

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        margin: `0 0 ${grid}px 0`,
        // change background colour if dragging
        background: isDragging ? "rgb(191, 228, 244)" : "#80cae9",
        // styles we need to apply on draggables
        ...draggableStyle
    });
    const getListStyle = isDraggingOver => ({
        background: 'white',
        padding: grid,
    });

    const move = (source, destination, droppableSource, droppableDestination) => {
        const sourceClone = Array.from(source);
        const destClone = Array.from(destination);
        const [removed] = sourceClone.splice(droppableSource.index, 1);
        destClone.splice(droppableDestination.index, 0, removed);
        const result = {};
        result[droppableSource.droppableId] = sourceClone;
        result[droppableDestination.droppableId] = destClone;

        return result;
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    function onDragEnd(result) {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }
        const sInd = +source.droppableId;
        const dInd = +destination.droppableId;
        if (sInd === dInd) {
            const items = reorder(entries[dayInWeek], source.index, destination.index);
            entries[dayInWeek] = items;
            updateState(entries);
        } else {
            const result = move(entries[dayInWeek], entries[dayInWeek], source, destination);
            entries[dayInWeek] = result[sInd];
            entries[dayInWeek] = result[dInd];
            updateState(entries.filter(group => group.length));
        }
    }


    return (
        <React.Fragment key={Math.random()}>
            <Grid item md={1}>
                <div style={{ textAlign: 'center', fontSize: 17 }} >
                    {dayOfTheWeek}
                </div>
                {
                    <React.Fragment key={Math.random()}>
                        <DragDrop
                            onDragEnd={onDragEnd}
                            children={(item) => renderChildren(item, dayInWeek)}
                            state={[getData(entries[dayInWeek])]}
                            getItemStyle={getItemStyle}
                            getListStyle={getListStyle}
                        />
                    </React.Fragment>
                }
            </Grid>
        </React.Fragment>
    )
}

export default DayOfWeekExpedition