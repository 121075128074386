import { Box, Tooltip } from '@material-ui/core'
import React from 'react'
import RepeatIcon from '@material-ui/icons/Repeat'
import ScheduleIcon from '@material-ui/icons/Schedule';
import GroupIcon from '@material-ui/icons/Group'
import NotificationsIcon from '@material-ui/icons/Notifications'
import i18next from 'i18next';

export const TripsIconsInfo = ({ rowData }) => {
    return (
        <Box display={'flex'} justifyContent={'center'}>
            {rowData.isOnRelatedCustomerBehalf && (
                <Tooltip title={i18next.t('customers.trips.page.columns.relatedTrip') + rowData.relatedCustomerName} arrow>
                    <GroupIcon
                        color="primary"
                        style={{
                            fontSize: 15,
                            marginRight: 2,
                        }}
                    />
                </Tooltip>
            )}
            {rowData.isLateCancellation && (
                <Tooltip title={i18next.t('customers.trips.page.columns.lateCancellation')} arrow>
                    <NotificationsIcon
                        style={{
                            fontSize: 15,
                            marginRight: 2,
                            color: 'orange',
                        }}
                    />
                </Tooltip>
            )}
            {rowData.isReturn && (
                <Tooltip title={i18next.t('customers.trips.page.columns.isReturn')} arrow>
                    <RepeatIcon
                        style={{
                            fontSize: 15,
                            marginRight: 2
                        }}
                    />
                </Tooltip>
            )}
            {rowData.isMultipleRequest && (
                <Tooltip title={i18next.t('customers.trips.page.columns.isMultipleRequest')} arrow>
                    <ScheduleIcon
                        style={{
                            fontSize: 15,
                            marginRight: 2
                        }}
                    />
                </Tooltip>
            )}
        </Box>
    )
}