import { userConstants } from "../../constants/user_constants";

let initialState = {
    auth: false,
    pending: false,
    mongoId: null,
    profile: null,
    name: null,
    messagingToken: "null",
    Permissions: []
};

export function authenticationReducer(state = initialState, action) {
    switch (action.type) {
        case userConstants.SAVE_MESSAGING_TOKEN:
            return {
                ...state,
                messagingToken: action.token,
            };

        case userConstants.LOGIN_REQUEST:
            return {
                ...state,
                pending: true,
                auth: false,
            };
        case userConstants.LOGIN_SUCCESS:
            return {
                ...state,
                auth: true,
                refreshToken: action.data.user.refreshToken,
                user: action.data.user,
                pending: false,
            };
        case userConstants.LOGIN_FAILURE:
            return {
                ...state,
                auth: false,
                pending: false,
                error: action.error,
            };
        case userConstants.TOKEN_REQUEST:
            return {
                ...state,
                pending: true,
            };
        case userConstants.TOKEN_SUCCESS:
            return {
                ...state,
                name: action.data.claims.name,
                pending: false,
                profile: action.data.claims.profile,
                mongoId: action.data.claims.mongo_id,
                token: action.data.token,
                customerId: action.data.claims.customer_id,
                Permissions: action.data.claims.permissions,
                roles:
                    action.data.claims[
                    "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
                    ],
            };
        case userConstants.TOKEN_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        case userConstants.LOGOUT:
            return {
                auth: false,
                pending: false,
                refreshToken: null,
                user: null,
            };
        case userConstants.REFRESH_REQUEST:
            return {
                ...state,
                pending: true,
            };
        case userConstants.REFRESH_SUCCESS:
            return {
                ...state,
                pending: false,
                token: action.token,
            };
        case userConstants.REFRESH_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}
