import { Button, FormControl, Grid, Hidden, InputLabel } from '@material-ui/core';
import React from 'react';
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-US';
import ca from 'date-fns/locale/ca';
import "react-datepicker/dist/react-datepicker.css";
import i18next from 'i18next';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';
import { withRouter } from 'react-router-dom';

class FilterDates extends React.Component {

    constructor(props) {
        super()
        switch (true) {
            case (i18next.language == "en"):
                registerLocale('en', en)
                break;
            case (i18next.language == "es"):
                registerLocale('es', es)
                break;
            case (i18next.language == "ca"):
                registerLocale('ca', ca)
                break;
            default:
                registerLocale('ca', ca)
                break;
        }
        this.onClick = this.onClick.bind(this)
        this.onChangeStartDate = this.onChangeStartDate.bind(this)
        this.onChangeEndDate = this.onChangeEndDate.bind(this)
    }

    onClick() {
        this.props.cleanFilters()
        this.props.onClickFilter(null, this.props.startDate, this.props.endDate)
    }

    onChangeStartDate(date) {
        this.props.setFilterDate('startDate', date)
        if (date > this.props.endDate) {
            this.props.setFilterDate('endDate', date)
        }
    }

    onChangeEndDate(date) {
        this.props.setFilterDate('endDate', date)
        if (date < this.props.startDate) {
            this.props.setFilterDate('startDate', date)
        }
    }

    render() {
        return (
            <Grid container spacing={2} style={{ paddingTop: 15 }}>
                <Grid item md={2} xs={1} implementation="css" smDown component={Hidden} />
                <Grid item md={1}>
                    <InputLabel>
                        {i18next.t('shared.filterDates.startDate')}
                    </InputLabel>
                </Grid>
                <Grid item md={2}>
                    <FormControl fullWidth>
                        <DatePicker
                            id={'startDate'}
                            autoComplete='off'
                            dateFormat="dd/MM/yyyy"
                            locale={i18next.language}
                            selected={this.props.startDate}
                            onChange={this.onChangeStartDate}
                            withPortal
                        />
                    </FormControl>
                </Grid>
                <Grid item md={1}>
                    <InputLabel>
                        {i18next.t('shared.filterDates.endDate')}
                    </InputLabel>
                </Grid>
                <Grid item md={2}>
                    <FormControl fullWidth>
                        <DatePicker
                            id={'endDate'}
                            autoComplete='off'
                            dateFormat="dd/MM/yyyy"
                            locale={i18next.language}
                            selected={this.props.endDate}
                            onChange={this.onChangeEndDate}
                            minDate={this.props.startDate != '' ? this.props.startDate : null}
                            maxDate={this.props.startDate != '' ? moment(this.props.startDate).add(90, 'd').toDate() : null}
                            withPortal
                        />
                    </FormControl>
                </Grid>
                <Grid item md={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        size={'small'}
                        disabled={this.props.startDate == '' || this.props.endDate == ''}
                        startIcon={<SearchIcon />}
                        onClick={this.onClick}
                    >
                        {i18next.t('shared.filterDates.search')}
                    </Button>

                </Grid>
            </Grid>


        );
    }
}

export default withRouter(FilterDates);