import { Paper, makeStyles } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import OriginDestinationChart from "./components/originDestinationChart";

export default function OriginDestinationKPI() {
    const classes = useStyles()
    const servicesLoading = useSelector(state => state.serviceReducer.pending)
    const type = useSelector(state => state.kpiFiltersReducer.type)

    return (
        <section className={classes.wrapper}>
            <Paper className={[classes.paper, classes.paddingGraph]} >
                <OriginDestinationChart isLoading={servicesLoading} groupedBy={type} />
            </Paper>
        </section>
    )
}

const useStyles = makeStyles({
    paper: {
        padding: "10px",
        minHeight: "250px"
    },

    paddingGraph: {
        padding: "10px 20px"
    },
    wrapper: {
        display: "grid",
        gap: "5px"
    }
})