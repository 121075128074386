import { authHeader } from '../helpers/authHeader';
import stringConstants from '../constants/strings';
import { handleResponse } from '../helpers/services';

export const trackingService = {
    getAllVehiclePositions,
    getAllVehiclePositionsCurrentDay,
    getVehiculePositionById
};

function getAllVehiclePositionsCurrentDay() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(stringConstants.URL_ApiGateway + '/tracking/v1/lastPosition/getAllVehiclesCurrentDay', requestOptions).then(handleResponse);

}

function getAllVehiclePositions() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(stringConstants.URL_ApiGateway + '/tracking/v1/lastPosition/getAllVehicles', requestOptions).then(handleResponse);

}

function getVehiculePositionById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(stringConstants.URL_ApiGateway + '/tracking/v1/lastPosition/getVehicleLastPosition?vehicleId' + id, requestOptions).then(handleResponse);
}