export const rolesConstants = {
    GETALL_REQUEST: 'ROLES_GETALL_REQUEST',
    GETALL_SUCCESS: 'ROLES_GETALL_SUCCESS',
    GETALL_FAILURE: 'ROLES_GETALL_FAILURE',

    REGISTER_REQUEST: 'ROLES_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'ROLES_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'ROLES_REGISTER_FAILURE',

    UPDATE_REQUEST: 'ROLES_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'ROLES_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'ROLES_UPDATE_FAILURE',

    DELETE_REQUEST: 'ROLES_DELETE_REQUEST',
    DELETE_SUCCESS: 'ROLES_DELETE_SUCCESS',
    DELETE_FAILURE: 'ROLES_DELETE_FAILURE',

    DETAIL_REQUEST: 'ROLES_DETAIL_REQUEST',
    DETAIL_SUCCESS: 'ROLES_DETAIL_SUCCESS',
    DETAIL_FAILURE: 'ROLES_DETAIL_FAILURE',

    GET_USERS_REQUEST: 'GET_USERS_REQUEST',
    GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
    GET_USERS_FAILURE: 'GET_USERS_FAILURE',
}

export const REPORT_TYPES = {
    Flexible: 0,
    AssignedWheel: 1,
    Invoice: 2,
}