export const vehiclesConstants = {
  ADD_REQUEST: "VEHICLES_REGISTER_REQUEST",
  ADD_SUCCESS: "VEHICLES_REGISTER_SUCCESS",
  ADD_FAILURE: "VEHICLES_REGISTER_FAILURE",

  DETAIL_REQUEST: "VEHICLES_DETAIL_REQUEST",
  DETAIL_SUCCESS: "VEHICLES_DETAIL_SUCCESS",
  DETAIL_FAILURE: "VEHICLES_DETAIL_FAILURE",

  GETALL_REQUEST: "VEHICLES_GETALL_REQUEST",
  GETALL_SUCCESS: "VEHICLES_GETALL_SUCCESS",
  GETALL_FAILURE: "VEHICLES_GETALL_FAILURE",

  GETBYID_REQUEST: "VEHICLES_GETBYID_REQUEST",
  GETBYID_SUCCESS: "VEHICLES_GETBYID_SUCCESS",
  GETBYID_FAILURE: "VEHICLES_GETBYID_FAILURE",

  DELETE_REQUEST: "VEHICLES_DELETE_REQUEST",
  DELETE_SUCCESS: "VEHICLES_DELETE_SUCCESS",
  DELETE_FAILURE: "VEHICLES_DELETE_FAILURE",

  GETBYOTP_REQUEST: "VEHICLES_GETBYOTP_REQUEST",
  GETBYOTP_SUCCESS: "VEHICLES_GETBYOTP_SUCCESS",
  GETBYOTP_FAILURE: "VEHICLES_GETBYOTP_FAILURE",

  GETBYSERVICE_REQUEST: "VEHICLES_GETBYSERVICE_REQUEST",
  GETBYSERVICE_SUCCESS: "VEHICLES_GETBYSERVICE_SUCCESS",
  GETBYSERVICE_FAILURE: "VEHICLES_GETBYSERVICE_FAILURE",

  EDIT_REQUEST: "VEHICLES_EDIT_REQUEST",
  EDIT_SUCCESS: "VEHICLES_EDIT_SUCCESS",
  EDIT_FAILURE: "VEHICLES_EDIT_FAILURE",

  GETALL_BRAND_REQUEST: "VEHICLES_GETALL_BRAND_REQUEST",
  GETALL_BRAND_SUCCESS: "VEHICLES_GETALL_BRAND_SUCCESS",
  GETALL_BRAND_FAILURE: "VEHICLES_GETALL_BRAND_FAILURE",

  GETALL_LOCATION_REQUEST: "VEHICLES_GETALL_LOCATION_REQUEST",
  GETALL_LOCATION_SUCCESS: "VEHICLES_GETALL_LOCATION_SUCCESS",
  GETALL_LOCATION_FAILURE: "VEHICLES_GETALL_LOCATION_FAILURE",

  GET_MODELS_BYBRAND_REQUEST: "VEHICLES_GET_MODELS_BYBRAND_REQUEST",
  GET_MODELS_BYBRAND_SUCCESS: "VEHICLES_GET_MODELS_BYBRAND_SUCCESS",
  GET_MODELS_BYBRAND_FAILURE: "VEHICLES_GET_MODELS_BYBRAND_FAILURE",

  GET_PRIORITY_REQUEST: "VEHICLES_GET_PRIORITY_REQUEST",
  GET_PRIORITY_SUCCESS: "VEHICLES_GET_PRIORITY_SUCCESS",
  GET_PRIORITY_FAILURE: "VEHICLES_GET_PRIORITY_FAILURE",

  CLEAR_MODELS: "VEHICLES_CLEAR_MODELS",
  CLEAR_VEHICLE: "VEHICLE_CLEAR_VEHICLE",

  SET_RANGE_CALENDAR: "VEHICLES_SET_RANGE_CALENDAR",

  SET_EXCLUDE_DAYS: "VEHICLES_SET_EXCLUDE_DAYS",

  REMOVE_EXCLUDE_DAYS: "VEHICLES_REMOVE_EXCLUDE_DAYS",

  SET_WORKS_DAY: "VEHICLES_SET_WORKS_DAY",

  SET_CALENDAR_REQUEST: "VEHICLE_SET_CALENDAR_REQUEST",
  SET_CALENDAR_SUCCESS: "VEHICLE_SET_CALENDAR_SUCCESS",
  SET_CALENDAR_FAILURE: "VEHICLE_SET_CALENDAR_FAILURE",

  SET_VEHICLE: "VEHICLE_SET_VEHICLE",

  SET_FILTER_VEHICLE: "VEHICLE_SET_FILTER",
  CLEAN_FILTER_VEHICLE: "VEHICLE_CLEAN_FILTER",

  GET_VEHICLES_GENERAL_DATA_KPI_REQUEST: 'GET_VEHICLES_GENERAL_DATA_KPI_REQUEST',
  GET_VEHICLES_GENERAL_DATA_KPI_SUCCESS: 'GET_VEHICLES_GENERAL_DATA_KPI_SUCCESS',
  GET_VEHICLES_GENERAL_DATA_KPI_FAILURE: 'GET_VEHICLES_GENERAL_DATA_KPI_FAILURE',

  GET_CALENDAR_DRIVERS_KPI_REQUEST: 'GET_CALENDAR_DRIVERS_KPI_REQUEST',
  GET_CALENDAR_DRIVERS_KPI_SUCCESS: 'GET_CALENDAR_DRIVERS_KPI_SUCCESS',
  GET_CALENDAR_DRIVERS_KPI_FAILURE: 'GET_CALENDAR_DRIVERS_KPI_FAILURE',

  CLEAN_VEHICLE_STATE: 'VEHICLE_CLEAN_VEHICLE_STATE',

  SAVE_TABLE_FILTERS: 'VEHICLE_SAVE_TABLE_FILTERS'
};
