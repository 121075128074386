import React from 'react'
import ServiceCalendarWorkingRange from '../workingRanges'
import moment from 'moment'
import { Button, Grid } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { Remove } from '@material-ui/icons'
import i18next from 'i18next'
import ShiftHours from '../shiftHours'

export const OperativeHolidays = ({ calendar, addErrorExcludedWorkingDay, removeErrorExcludedWorkingDay, removeExcludedWorkingDays, handleChangeTimeShiftHour, addTimeShiftHoursInternal, removeItemShiftHours, state, ...rest }) => {

    const serviceReducer = useSelector((state) => state.serviceReducer)
    let workingRanges = serviceReducer.workingRanges

    return (
        <Grid container spacing={2}>
            <Grid item md={12}>
                <h2>{i18next.t('services_comp.calendar.workingRange.title')}</h2>
            </Grid>
            <Grid item md={6} sm={12}>
                <ServiceCalendarWorkingRange {...rest} />
            </Grid>
            <Grid item md={6} sm={12}>
                {calendar.workingBankHolidays?.map((item, index) => (
                    <React.Fragment key={'e_' + index}>
                        <Grid container spacing={2} justify='center'>
                            <Grid item md={4}>
                                <span>{i18next.t('services_comp.calendar.range.start')}: </span>
                                {moment(item.startPeriod).format('DD/MM/YYYY')}
                            </Grid>
                            <Grid item md={4}>
                                <span>{i18next.t('services_comp.calendar.range.end')}: </span>
                                {moment(item.finishPeriod).format('DD/MM/YYYY')}
                            </Grid>
                            <Grid item md={2}>
                                <Remove
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        removeExcludedWorkingDays(index)
                                        removeErrorExcludedWorkingDay(index)
                                    }}
                                />
                            </Grid>
                        </Grid>
                        {item.shiftHours?.map((itemShiftHour, indexShiftHour) => (
                            <ShiftHours
                                key={'s_' + indexShiftHour}
                                item={itemShiftHour}
                                index={index}
                                indexShiftHour={indexShiftHour}
                                detail={rest.detail}
                                length={item.shiftHours.length}
                                value={itemShiftHour}
                                t={i18next.t}
                                onChange={event => handleChangeTimeShiftHour(event, index, indexShiftHour)}
                                add={addTimeShiftHoursInternal}
                                remove={removeItemShiftHours}
                                prop={'workingBankHolidays'}
                                state={state}
                                serviceReducer={serviceReducer}
                                disabled={rest.readOnly || rest.readEdit}
                                {...rest}
                            />
                        ))}
                    </React.Fragment>
                ))}

            </Grid>
            <Grid
                container
                justify="flex-end"
                alignItems="flex-end"
            >
                <Grid item md={1}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        size="medium"
                        onClick={() => {
                            rest.setExcludedWorkingDays(workingRanges)
                            addErrorExcludedWorkingDay()
                        }}
                    >
                        {i18next.t('services_comp.calendar.range.add')}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}
