import firebase from "firebase/app";
import "firebase/messaging";
import "firebase/auth";
import stringConstants from "./constants/strings";

import store from "./redux/store";
import { userActions } from "./redux/actions/user_actions";
import { chatActions } from "./redux/actions/chat_actions";
import { onIncidentsListener } from "./incidentsListener";
import { incidentActions } from "./redux/actions/incident_actions";
import { userService } from "./services/userService";

firebase.initializeApp(stringConstants.firebaseConfig);

const messaging = firebase.messaging.isSupported()
    ? firebase.messaging()
    : null;

export const onMessageListener = () =>
    new Promise((resolve) => {
        try {
            messaging.onMessage((payload) => {
                console.log(" onMessageListener payload", payload);
                const user = userService.getWithExpiry("user_webAdmin")
                if (user)
                    store.dispatch(chatActions.setNewMessageFlag(true, payload))
                // console.log("messaging", messaging);
                return resolve(payload);
            });
        } catch (error) {
            console.log("onMessageListener error: ", error);
        }
    });

export const initNotifications = () => {
    try {
        if ("serviceWorker" in navigator) {
            navigator.serviceWorker
                .register(
                    window.location.origin +
                    `/${process.env.REACT_APP_ENV}FirebaseConfig/firebase-messaging-sw.js`
                )
                .then(function (registration) {
                    firebase.messaging().useServiceWorker(registration); //deprecated
                    firebase
                        .messaging()
                        .getToken({
                            vapidKey: stringConstants.vapidKey,
                        })
                        .then((token) => {
                            onIncidentsListener();
                            console.log("current firebase messagins token =>", token);
                            store.dispatch(userActions.subscribeToTopic(token));
                            store.dispatch(incidentActions.subscribeToTopic(token));
                            store.dispatch(userActions.saveMessagingToken(token));
                        })
                        .catch((error) => {
                            console.log("error => ", error);
                        });
                })
                .catch(function (err) {
                    console.log("Service worker registration failed, error:", err);
                });
        }
    } catch (error) {
        console.log("initNotifications error: ", error);
    }
};
