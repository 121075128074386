export const otpConstants = {
    ADD_REQUEST: 'OTP_REGISTER_REQUEST',
    ADD_SUCCESS: 'OTP_REGISTER_SUCCESS',
    ADD_FAILURE: 'OTP_REGISTER_FAILURE',

    DETAIL_REQUEST: 'OTP_DETAIL_REQUEST',
    DETAIL_SUCCESS: 'OTP_DETAIL_SUCCESS',
    DETAIL_FAILURE: 'OTP_DETAIL_FAILURE',

    GETALL_REQUEST: 'OTP_GETALL_REQUEST',
    GETALL_SUCCESS: 'OTP_GETALL_SUCCESS',
    GETALL_FAILURE: 'OTP_GETALL_FAILURE',

    GETBYID_REQUEST: 'OTP_GETBYID_REQUEST',
    GETBYID_SUCCESS: 'OTP_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'OTP_GETBYID_FAILURE',

    DELETE_REQUEST: 'OTP_DELETE_REQUEST',
    DELETE_SUCCESS: 'OTP_DELETE_SUCCESS',
    DELETE_FAILURE: 'OTP_DELETE_FAILURE',

    EDIT_REQUEST: 'DRIVER_EDIT_REQUEST',
    EDIT_SUCCESS: 'DRIVER_EDIT_SUCCESS',
    EDIT_FAILURE: 'DRIVER_EDIT_FAILURE',

    FILTER_BY_TENANT: 'OTP_FILTER_BY_TENANT'


};