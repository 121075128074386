import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const DragDrop = ({ state, onDragEnd, children, getItemStyle, getListStyle }) => {


    return (
        <DragDropContext onDragEnd={onDragEnd}>
            {state?.map((el, ind) => (
                <Droppable key={ind} droppableId={`${ind}`}>
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                            {...provided.droppableProps}
                        >
                            {el?.map((item, index) => (
                                <Draggable
                                    key={item.id ?? index.toString()}
                                    draggableId={item.id ?? index.toString()}
                                    index={index}
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    color: 'black',
                                                    justifyContent: "space-around"
                                                }}
                                            >
                                                {children(item, ind, index)}
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            ))}
        </DragDropContext>
    )
}

export default DragDrop