import { authHeader } from "../helpers/authHeader";
import stringConstants from "../constants/strings";
import i18next from "i18next";
import moment from "moment";
import { handleResponse } from '../helpers/services';

export const driverService = {
    getAll,
    getById,
    insert,
    update,
    _delete,
    disable,
    enable,
    validate,
    getByIdWithVehicles,
    sendVerifyEmail,
    sendResetPasswordEmail,
    updatePassword,
    updateUserPasswordFromEmailChange,
    getDriversForChat,
    checkDriverUser,
    getDriversFullName,
    getPosibleDriversToAssing,
    getDriversFromService,
    getResetPasswordLink,
    getDriverRequests,
    getDriversKPI,
    cleanPushToken
};

function getDriversForChat(opUserProfile, otpId) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };

    return fetch(
        stringConstants.URL_ApiGateway + `/drivers/v1/Chat/GetDriversForChat`,
        requestOptions
    ).then(handleResponse);
}

function getDriverRequests(status, dateStart, dateEnd) {
    const requestOptions = {
        method: "PUT",
        headers: authHeader(),
        body: JSON.stringify({
            "requestStatuses": [status],
            "dateStart": moment(dateStart).toISOString(),
            "dateEnd": moment(dateEnd).toISOString()
        })
    };

    return fetch(
        stringConstants.URL_ApiGateway + `/drivers/v1/KPI/GetDriverRequests`,
        requestOptions
    ).then(handleResponse);
}

function getDriversFullName(otpId) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };

    return fetch(
        stringConstants.URL_ApiGateway + `/drivers/v1/drivers/GetDriversFullName?otpId=` + otpId,
        requestOptions
    ).then(handleResponse);
}

function getPosibleDriversToAssing(trip) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(trip),
        headers: authHeader(),
    };

    return fetch(
        stringConstants.URL_ApiGateway + `/drivers/v1/driverScheduler/getPotentialDriversForRequest`,
        requestOptions
    ).then(handleResponse);
}

function getResetPasswordLink(email, urlRedirect) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };
    return fetch(stringConstants.URL_ApiGateway + '/drivers/v1/password/GetResetPasswordLink?email=' + encodeURIComponent(email) + '&urlRedirect=' + urlRedirect, requestOptions).then(handleResponse)
}


function getDriversFromService(serviceId) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };

    return fetch(
        stringConstants.URL_ApiGateway + `/services/v1/services/getDriversFromService?serviceId=` + serviceId,
        requestOptions
    ).then(handleResponse);
}

function getAll() {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return fetch(
        stringConstants.URL_ApiGateway + "/drivers/v1/drivers/getDrivers",
        requestOptions
    ).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return fetch(
        stringConstants.URL_ApiGateway + "/drivers/v1/drivers/" + id,
        requestOptions
    ).then(handleResponse);
}

function getByIdWithVehicles(id) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return fetch(
        stringConstants.URL_ApiGateway +
        "/drivers/v1/drivers/getDriverWithVehicles?driverId=" +
        id,
        requestOptions
    ).then(handleResponse);
}

function insert(driver, urlRedirect) {
    driver.lang = i18next.language;
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(driver),
    };
    return fetch(
        stringConstants.URL_ApiGateway +
        "/drivers/v1/registration/add?urlRedirect=" +
        urlRedirect,
        requestOptions
    ).then(handleResponse);
}

function update(driver) {
    const requestOptions = {
        method: "PUT",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(driver),
    };
    const id = driver.id;
    return fetch(
        stringConstants.URL_ApiGateway +
        "/drivers/v1/drivers/update?driverId=" +
        id,
        requestOptions
    ).then(handleResponse);
}

function enable(id) {
    const requestOptions = {
        method: "PUT",
        headers: { ...authHeader() },
    };
    return fetch(
        stringConstants.URL_ApiGateway + "/drivers/v1/drivers/enable?id=" + id,
        requestOptions
    ).then(handleResponse);
}

function disable(id) {
    const requestOptions = {
        method: "PUT",
        headers: { ...authHeader() },
    };
    return fetch(
        stringConstants.URL_ApiGateway + "/drivers/v1/drivers/disable?id=" + id,
        requestOptions
    ).then(handleResponse);
}

function cleanPushToken(vehicleId, driverId) {
    const requestOptions = {
        method: "PUT",
        headers: { ...authHeader() },
    };
    return fetch(
        stringConstants.URL_ApiGateway + `/drivers/v1/drivers/cleanPushToken?driverId=${driverId}&vehicleId=${vehicleId}`,
        requestOptions
    ).then(handleResponse);
}

function validate(id) {
    const requestOptions = {
        method: "PUT",
        headers: { ...authHeader() },
    };
    return fetch(
        stringConstants.URL_ApiGateway + "/drivers/v1/drivers/validate?id=" + id,
        requestOptions
    ).then(handleResponse);
}

function _delete(id) {
    const requestOptions = {
        method: "PUT",
        headers: authHeader(),
    };
    return fetch(
        stringConstants.URL_ApiGateway + "/drivers/v1/drivers/remove?id=" + id,
        requestOptions
    ).then(handleResponse);
}

function sendResetPasswordEmail(email, lang, url) {
    const requestOptions = {
        method: "GET",
        headers: { ...authHeader(), "Content-Type": "application/json" },
    };
    return fetch(
        stringConstants.URL_ApiGateway +
        "/drivers/v1/mailing/sendResetPasswordEmail?email=" +
        encodeURIComponent(email) +
        "&lang=" +
        lang +
        "&urlRedirect=" +
        url,
        requestOptions
    ).then(handleResponse);
}

function sendVerifyEmail(email, lang, url) {
    const requestOptions = {
        method: "GET",
        headers: { ...authHeader(), "Content-Type": "application/json" },
    };
    return fetch(
        stringConstants.URL_ApiGateway +
        "/drivers/v1/mailing/sendVerifyEmail?email=" +
        encodeURIComponent(email) +
        "&lang=" +
        lang +
        "&urlRedirect=" +
        url,
        requestOptions
    ).then(handleResponse);
}

function updatePassword(email, newPassword) {
    const requestOptions = {
        method: "PUT",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify({ email: email, newPassword: newPassword }),
    };
    return fetch(
        stringConstants.URL_ApiGateway + "/drivers/v1/password/updateUserPassword",
        requestOptions
    ).then(handleResponse);
}

function updateUserPasswordFromEmailChange(email, newPassword) {
    const requestOptions = {
        method: "PUT",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify({ email: email, newPassword: newPassword }),
    };
    return fetch(
        stringConstants.URL_ApiGateway + "/drivers/v1/password/updateUserPasswordFromEmailChange",
        requestOptions
    ).then(handleResponse);
}

function checkDriverUser(email) {
    const requestOptions = {
        method: 'GET'
    };
    return fetch(stringConstants.URL_ApiGateway + '/drivers/v1/Drivers/IsDriver?email=' + encodeURIComponent(email), requestOptions).then(handleResponse)
}

function getDriversKPI() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return fetch(stringConstants.URL_ApiGateway + '/drivers/v1/KPI/getDriversKPI', requestOptions).then(handleResponse)
}