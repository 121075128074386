import { Button, Grid } from '@material-ui/core';
import React, { forwardRef } from 'react'
import { stylesMultipleTrip } from '../details';
import Spinner from '../../../shared/spinner';
import { SelectPosibleScheduleComponent } from '../../form/modalSelectPosibleSchedule';
import ScheduleIcon from '@material-ui/icons/Schedule';
import DatePicker from "react-datepicker";
import i18next from 'i18next';
import moment from 'moment';
import { SERVICES_TYPE } from '../../../../constants/types';
import { SelectRequestType } from '../../form/selectRequestType';

const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <Button onClick={onClick} ref={ref} variant='contained' startIcon={<ScheduleIcon />} fullWidth color='primary' >
        {value}
    </Button>
));

export const DatePickerMultipleTripComponent = ({ date, setDate, minTime, isRequestByDropOff, onChangeRequestType, serviceType }) => {
    const classes = stylesMultipleTrip()

    return (
        <>
            <Grid container justify='center'>
                <Grid item md={4} className='datePickerContainer'>
                    <DatePicker
                        wrapperClassName={classes.width100}
                        selected={date != null && date != "" ? moment(date, 'HH:mm').toDate() : date}
                        onChange={(date) => setDate(date)}
                        customInput={<CustomInput />}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        minTime={minTime}
                        maxTime={minTime ? moment().set({ 'hours': 23, minutes: 59 }).toDate() : null}
                        timeCaption={i18next.t('trips.multipleBook.time')}
                        dateFormat="HH:mm"
                        timeFormat="HH:mm"
                    />
                </Grid>
            </Grid>
            <Grid container justify='center' className={`${classes.containerSelectRequestType}`}>
                <Grid item md={4}>
                    <SelectRequestType
                        editable={serviceType != SERVICES_TYPE.regularService}
                        isRequestByDropOff={isRequestByDropOff}
                        onChange={onChangeRequestType}
                    />

                </Grid>

            </Grid >
        </>
    )
}

export const withSelectNewTimeComponent = (serviceType, expeditions, date, setDate, pending, isRequestByDropOff, onChangeRequestType, minTime = null) => {

    const setNewDateWithTime = (dateToSet) => {
        if (dateToSet instanceof Date) {
            setDate(moment(dateToSet).format('HH:mm'))
        } else {
            setDate(dateToSet)
        }
    }
    if (pending)
        return (
            <Spinner loading={pending} />
        )

    if ([SERVICES_TYPE.regularWithSchedule].includes(serviceType) && minTime == null)
        return (
            <Grid item md={6}>
                <SelectPosibleScheduleComponent
                    expeditions={expeditions}
                    pending={pending}
                    handleClickOption={(value) => setNewDateWithTime(value)}
                    value={date}
                />
            </Grid>
        )
    return (
        <DatePickerMultipleTripComponent
            setDate={setNewDateWithTime}
            date={date}
            minTime={minTime}
            isRequestByDropOff={isRequestByDropOff}
            onChangeRequestType={onChangeRequestType}
            serviceType={serviceType}
        />
    )
}