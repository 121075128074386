export const chatConstants = {
  GET_ALL_MESSAGES_FROM_CONVERSATION_REQUEST:
    "CHAT_GET_ALL_MESSAGES_FROM_CONVERSATION_REQUEST",
  GET_ALL_MESSAGES_FROM_CONVERSATION_FAILURE:
    "CHAT_GET_ALL_MESSAGES_FROM_CONVERSATION_FAILURE",
  GET_ALL_MESSAGES_FROM_CONVERSATION_SUCCESS:
    "CHAT_GET_ALL_MESSAGES_FROM_CONVERSATION_SUCCESS",

  GET_ALL_CONVERSATIONS_BY_SENDER_ID_REQUEST:
    "CHAT_GET_ALL_CONVERSATIONS_BY_SENDER_ID_REQUEST",
  GET_ALL_CONVERSATIONS_BY_SENDER_ID_FAILURE:
    "CHAT_GET_ALL_CONVERSATIONS_BY_SENDER_ID_FAILURE",
  GET_ALL_CONVERSATIONS_BY_SENDER_ID_SUCCESS:
    "CHAT_GET_ALL_CONVERSATIONS_BY_SENDER_ID_SUCCESS",

  NEW_MESSAGE_FLAG: "CHAT_NEW_MESSAGE_FLAG",

  SET_CURRENT_CONVERSATION: "CHAT_SET_CURRENT_CONVERSATION",

  GET_NUMBER_OF_UNREAD_MESSAGES_BY_SENDER_ID_SUCCESS:
    "CHAT_GET_NUMBER_OF_UNREAD_MESSAGES_BY_SENDER_ID_SUCCESS",
};
