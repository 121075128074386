import React from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import IconButton from '@material-ui/core/IconButton'
import Modal from '@material-ui/core/Modal'
import { Button, Grid } from '@material-ui/core'
import AlertDialog from '../alert-dialog'
import { history } from '../../helpers/history'
import Badge from '@material-ui/core/Badge'
import NotificationsIcon from '@material-ui/icons/Notifications'
import { Link } from 'react-router-dom'
import stringConstants from '../../constants/strings'
import About from './about'
import { allowAccessRole } from '../../helpers/roles'
import { MODULES, OPERATIONS_ALLOWED } from '../roles/constants'

class MenuUser extends React.Component {
    constructor(props) {
        super()
        this.state = {
            anchorEl: null,
            openUserMenu: false,
            openModalLogout: false,
            openModalLanguage: false,
            openModalAbout: false
        }
    }
    handleClickMenuUser = event => {
        this.setState({ anchorEl: event.currentTarget, openUserMenu: true })
    }

    handleCloseMenuUser = () => {
        this.setState({ openUserMenu: false })
    }

    handleCloseModal = () => {
        this.setState({ openModalLanguage: false })
    }

    handleCloseModalAbout = () => {
        this.setState({ openModalAbout: false })
    }

    handleopenModalLanguage = () => {
        this.setState({ openModalLanguage: true })
    }

    handleopenModalLogout = () => {
        this.setState({ openModalLogout: true })
    }

    handleopenModalAbout = () => {
        this.setState({ openModalAbout: true })
    }

    onClickCancelModalLogout = () => {
        this.setState({ openModalLogout: false })
    }

    onClickAcceptModalLogout = () => {
        this.setState({ openModalLogout: false })
        this.props.logout()
    }

    onClickLanguage(lng) {
        this.setState({ openModalLanguage: false })
        this.props.changeLanguage(lng)
        this.handleCloseMenuUser()
    }

    myProfile() {
        history.push('/profile')
    }

    render() {
        const { notificationReducer } = this.props
        return (
            <React.Fragment>
                {allowAccessRole(MODULES.Notifications, OPERATIONS_ALLOWED.read) && parseInt(notificationReducer.uncheckedNotifications) ? (
                    <IconButton>
                        <Badge
                            component={Link}
                            to={`/notifications`}
                            color="primary"
                            badgeContent={parseInt(
                                notificationReducer.uncheckedNotifications,
                            )}
                            max={9999}
                        >
                            <NotificationsIcon
                                style={{
                                    color: '#000',
                                }}
                            />
                        </Badge>
                    </IconButton>
                ) : (
                    ''
                )}
                <IconButton color="primary" onClick={this.handleClickMenuUser}>
                    <AccountCircleIcon />
                    <span className={this.props.cssClass}>
                        {this.props.name}
                    </span>
                </IconButton>
                <Menu
                    id="simple-menu"
                    anchorEl={this.state.anchorEl}
                    keepMounted
                    open={this.state.openUserMenu}
                    onClose={this.handleCloseMenuUser}
                >
                    {allowAccessRole(MODULES.My_Profile, OPERATIONS_ALLOWED.read) && <MenuItem onClick={this.myProfile}>
                        {this.props.t('dashboard.menu.profile')}
                    </MenuItem>}
                    <MenuItem onClick={this.handleopenModalLanguage}>
                        {this.props.t('dashboard.menu.changeLanguaje')}
                    </MenuItem>
                    <MenuItem onClick={this.handleopenModalAbout}>
                        {this.props.t('dashboard.menu.about')}
                    </MenuItem>
                    <MenuItem onClick={this.handleopenModalLogout}>
                        {this.props.t('dashboard.menu.logout')}
                    </MenuItem>
                </Menu>
                <AlertDialog
                    open={this.state.openModalLogout}
                    title={this.props.t('dashboard.menu.alert_dialog.title')}
                    onClickAccept={this.onClickAcceptModalLogout}
                    onClickCancel={this.onClickCancelModalLogout}
                />
                <AlertDialog
                    open={this.state.openModalAbout}
                    title={''}
                    onClickCancel={null}
                    content={() => <About
                        version={stringConstants.version}
                    />}
                    onClickAccept={this.handleCloseModalAbout}
                />
                <Modal
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        outline: 'none',
                    }}
                    open={this.state.openModalLanguage}
                    disableAutoFocus={true}
                    disableEnforceFocus={true}
                    onClose={this.handleCloseModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <Grid container alignItems="center" justify="center">
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ margin: 10 }}
                            onClick={() => this.onClickLanguage('es')}
                        >
                            Español
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ margin: 10 }}
                            onClick={() => this.onClickLanguage('ca')}
                        >
                            Català
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ margin: 10 }}
                            onClick={() => this.onClickLanguage('en')}
                        >
                            English
                        </Button>
                    </Grid>
                </Modal>
            </React.Fragment>
        )
    }
}

export default MenuUser
