
export const getUser = (user, customerReducer) => {
    return user
        ? user
        : customerReducer.edit
            ? customerReducer.userEdit
            : {}
}

export const getId = (user) => {
    return user.id || ''
}

export const getName = (user) => {
    return user.name || ''
}

export const getSurname1 = (user) => {
    return user.surname1 || ''
}

export const getSurname2 = (user) => {
    return user.surname2 || ''
}

export const getEmail = (user) => {
    return user.email || ''
}

export const getDocumentType = (user) => {
    return user.documentType || 10
}

export const getIdentityDocumentCode = (user) => {
    return user.identityDocumentCode || ''
}

export const getPhoneNumber = (user, isRelatedCustomer) => {
    if (isRelatedCustomer)
        return user.phoneNumber
    return user.prefix + user.phoneNumber || ''
}

export const getPhoneNumberWithOutCode = (user) => {
    return user.phoneNumber || ''
}

export const getPrefix = (user) => {
    return user.prefix || '+34'
}

export const getCountryCode = (user) => {
    return user.countryCode || ''
}

export const getBirthDate = (user) => {
    return user.birthDate || ''
}

export const getIsPMR = (user) => {
    return user.isPMR || false
}

export const getHasWheelChair = (user) => {
    return user.hasWheelChair || false
}

export const getIsDisabled = (user) => {
    return user.isDisabled || false
}

export const getAddress = (user) => {
    return user.addressPosition || {
        address: '',
        lat: '',
        lon: '',
    }
}