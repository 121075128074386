import React, { useEffect, useState } from 'react'
import {
    Collapse,
    ListItem,
    ListItemIcon,
    ListItemText,
    List,
    Tooltip,
} from '@material-ui/core'
import CommuteIcon from '@material-ui/icons/Commute'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import HomeIcon from '@material-ui/icons/Home'
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation'
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import ExploreIcon from '@material-ui/icons/Explore'
import { Link } from 'react-router-dom'
import CardTravelIcon from '@material-ui/icons/CardTravel'
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications'
import ChatIcon from '@material-ui/icons/Chat'
import RoomIcon from '@material-ui/icons/Room'
import TimelineIcon from '@material-ui/icons/Timeline'
import ReportProblemIcon from '@material-ui/icons/ReportProblem'
import Badge from '@material-ui/core/Badge'
import PeopleIcon from '@material-ui/icons/Group'
import { allowAccessRole } from '../../helpers/roles'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import NotificationsIcon from '@material-ui/icons/Notifications'
import AssessmentIcon from '@material-ui/icons/Assessment'
import FeaturedVideoIcon from '@material-ui/icons/FeaturedVideo'
import { useTheme } from '@material-ui/core/styles';
import { calculateTransparentColor } from '../../helpers/tools'
import { OPERATIONS_ALLOWED, MODULES } from '../roles/constants';
import { useNotificationCounts } from '../../hooks/useNotificationCount'

const ListItems = ({
    t,
    chatReducer,
    incidentReducer,
    match,
    getTrips,
    getTripsPending,
    getWheelJourneys,
    getJourneys,
    serviceReducer,
    tripReducer
}) => {
    const [incidentsBadgeCount, setincidentsBadgeCount] = useState(0)
    const [iscollapseJourneyMenuOpen, setiscollapseJourneyMenuOpen] = useState(false)
    const [isCollapseProfileManagementMenuOpen, setIsCollapseProfileManagementMenuOpen] = useState(false)
    const [iscollapseTripMenuOpen, setiscollapseTripMenuOpen] = useState(false)
    const [iscollapseNotificationMenuOpen, setiscollapseNotificacionMenuOpen] = useState(false)
    const theme = useTheme()

    useNotificationCounts()

    useEffect(() => {
        handleSetIncidentsBadgeCount()
    }, [incidentReducer])

    const handleSetIncidentsBadgeCount = () => {
        const { numberOfPendingToReview } = incidentReducer
        setincidentsBadgeCount(numberOfPendingToReview)
    }

    const degradient = calculateTransparentColor(theme.palette.primary.main, '#FFFFFF', 0.25)
    const opposite = calculateTransparentColor(theme.palette.secondary.main, '#000000', 0)

    const checkDegradient = (path, value) => {
        if (path?.includes(value))
            return { backgroundColor: degradient }
        return {}
    }

    const checkDegradientWithOutValue = (path, value, notValue) => {
        if (path?.includes(value) && !path?.includes(notValue))
            return { backgroundColor: degradient }
        return {}
    }

    const checkSecondaryColor = (path, value) => {
        if (path?.includes(value))
            return { color: opposite }
        return { color: theme.palette.secondary.main }
    }

    const checkSecondaryColorWithOutValue = (path, value, notValue) => {
        if (path?.includes(value) && !path?.includes(notValue))
            return { color: opposite }
        return { color: theme.palette.secondary.main }
    }

    return (
        <div>
            {allowAccessRole(MODULES.Home, OPERATIONS_ALLOWED.read) && (
                <ListItem
                    button
                    component={Link}
                    to={`/home`}
                    style={
                        checkDegradient(match?.path, 'home')
                    }
                >
                    <Tooltip title={t('dashboard.items.home')}>
                        <ListItemIcon>
                            <HomeIcon color={'secondary'} />
                        </ListItemIcon>
                    </Tooltip>
                    <ListItemText
                        style={
                            checkSecondaryColor(match?.path, 'home')
                        }
                        primary={t('dashboard.items.home')}
                    />
                </ListItem>

            )}
            {allowAccessRole(MODULES.Tenants, OPERATIONS_ALLOWED.read) && (
                <ListItem
                    button
                    component={Link}
                    to={`/tenants`}
                    style={
                        checkDegradient(match?.path, 'tenants')
                    }
                >
                    <Tooltip title={t('dashboard.items.tenants')}>
                        <ListItemIcon>
                            <AccountTreeIcon color={'secondary'} />
                        </ListItemIcon>
                    </Tooltip>
                    <ListItemText
                        style={
                            checkSecondaryColor(match?.path, 'tenants')
                        }
                        primary={t('dashboard.items.tenants')}
                    />
                </ListItem>

            )}
            {allowAccessRole(MODULES.Users, OPERATIONS_ALLOWED.read) && (
                <ListItem
                    button
                    component={Link}
                    to={`/customers`}
                    style={
                        checkDegradient(match?.path, 'customers')
                    }
                >
                    <Tooltip title={t('dashboard.items.customers')}>
                        <ListItemIcon>
                            <PeopleIcon color={'secondary'} />
                        </ListItemIcon>
                    </Tooltip>
                    <ListItemText
                        style={
                            checkSecondaryColor(match?.path, 'customers')
                        }
                        primary={t('dashboard.items.customers')}
                    />
                </ListItem>

            )}
            {allowAccessRole(MODULES.BO_Profiles, OPERATIONS_ALLOWED.read) && (
                <ListItem
                    button
                    style={
                        checkDegradient(match?.path, 'profilesManagement')
                    }
                    onClick={() => setIsCollapseProfileManagementMenuOpen(prev => !prev)}
                >
                    <Tooltip title={t('dashboard.items.profilesManagement')}>
                        <ListItemIcon>
                            <SupervisorAccountIcon color={'secondary'} />
                        </ListItemIcon>
                    </Tooltip>
                    <ListItemText
                        style={
                            checkSecondaryColor(match?.path, 'profilesManagement')
                        }
                        primary={t('dashboard.items.profilesManagement')}
                    />
                    {isCollapseProfileManagementMenuOpen ? (
                        <ExpandLess color={'secondary'} />
                    ) : (
                        <ExpandMore color={'secondary'} />
                    )}
                </ListItem>
            )
            }

            <Collapse
                in={isCollapseProfileManagementMenuOpen}
                timeout="auto"
                unmountOnExit
            >
                <List component="div" disablePadding>
                    <ListItem
                        button
                        component={Link}
                        to={`/roles`}
                        style={
                            checkDegradient(match?.path, 'roles')
                        }
                        onClick={() => console.log('roles')}
                    >
                        <Tooltip title={t('dashboard.items.profilesManagement_roles')}>
                            <ListItemIcon>
                                <ArrowForwardIosIcon color={'secondary'} />
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText
                            style={
                                checkSecondaryColor(match?.path, 'roles')
                            }
                            primary={t('dashboard.items.profilesManagement_roles')}

                        />
                    </ListItem>
                </List>
            </Collapse>

            <Collapse
                in={isCollapseProfileManagementMenuOpen}
                timeout="auto"
                unmountOnExit
            >
                <List component="div" disablePadding>
                    <ListItem
                        button
                        component={Link}
                        to={`/operators`}
                        style={
                            checkDegradient(match?.path, 'operators')
                        }
                    >
                        <Tooltip title={t('dashboard.items.profilesManagement_op_users')}>
                            <ListItemIcon>
                                <ArrowForwardIosIcon color={'secondary'} />
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText
                            style={
                                checkSecondaryColor(match?.path, 'operators')
                            }
                            primary={t('dashboard.items.profilesManagement_op_users')}
                        />
                    </ListItem>

                </List>
            </Collapse>

            {
                allowAccessRole(MODULES.OTP, OPERATIONS_ALLOWED.read) && (
                    <ListItem
                        button
                        component={Link}
                        to={`/otps`}
                        style={
                            checkDegradient(match?.path, 'otps')
                        }
                    >
                        <Tooltip title={t('dashboard.items.otp')}>
                            <ListItemIcon>
                                <EmojiTransportationIcon color={'secondary'} />
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText
                            style={
                                checkSecondaryColor(match?.path, 'otps')
                            }
                            primary={t('dashboard.items.otp')}
                        />
                    </ListItem>
                )
            }
            {
                allowAccessRole(MODULES.Drivers, OPERATIONS_ALLOWED.read) && (
                    <ListItem
                        button
                        component={Link}
                        to={`/drivers`}
                        style={
                            checkDegradient(match?.path, 'drivers')
                        }
                    >
                        <Tooltip title={t('dashboard.items.drivers')}>
                            <ListItemIcon>
                                <EmojiPeopleIcon color={'secondary'} />
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText
                            style={
                                checkSecondaryColor(match?.path, 'drivers')
                            }
                            primary={t('dashboard.items.drivers')}
                        />
                    </ListItem>
                )
            }
            {
                allowAccessRole(MODULES.Vehicles, OPERATIONS_ALLOWED.read) && (
                    <ListItem
                        button
                        component={Link}
                        to={`/vehicles`}
                        style={
                            checkDegradient(match?.path, 'vehicles')
                        }
                    >
                        <Tooltip title={t('dashboard.items.vehicles')}>
                            <ListItemIcon>
                                <CommuteIcon color={'secondary'} />
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText
                            style={
                                checkSecondaryColor(match?.path, 'vehicles')
                            }
                            primary={t('dashboard.items.vehicles')}
                        />
                    </ListItem>
                )
            }
            {
                allowAccessRole(MODULES.Zones, OPERATIONS_ALLOWED.manage) && (
                    <ListItem
                        button
                        component={Link}
                        to={`/zones`}
                        style={
                            checkDegradientWithOutValue(match?.path, 'zones', 'stops')
                        }
                    >
                        <Tooltip title={t('dashboard.items.zones')}>
                            <ListItemIcon>
                                <ExploreIcon color={'secondary'} />
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText
                            style={
                                checkSecondaryColorWithOutValue(match?.path, 'zones', 'stops')
                            }
                            primary={t('dashboard.items.zones')}
                        />
                    </ListItem>
                )
            }
            {
                allowAccessRole(MODULES.Zones, OPERATIONS_ALLOWED.manage) && (
                    <ListItem
                        button
                        component={Link}
                        to={`/zones/stops`}
                        style={
                            checkDegradient(match?.path, 'stops')
                        }
                    >
                        <Tooltip title={t('dashboard.items.stops')}>
                            <ListItemIcon>
                                <RoomIcon color={'secondary'} />
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText
                            style={
                                checkSecondaryColor(match?.path, 'stops')
                            }
                            primary={t('dashboard.items.stops')}
                        />
                    </ListItem>
                )
            }

            {
                allowAccessRole(MODULES.Services, OPERATIONS_ALLOWED.read) && (
                    <ListItem
                        button
                        component={Link}
                        to={`/services`}
                        style={
                            checkDegradient(match?.path, 'services')
                        }
                    >
                        <Tooltip title={t('dashboard.items.services')}>
                            <ListItemIcon>
                                <SettingsApplicationsIcon color={'secondary'} />
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText
                            style={
                                checkSecondaryColor(match?.path, 'services')
                            }
                            primary={t('dashboard.items.services')}
                        />
                    </ListItem>
                )
            }
            {
                allowAccessRole(MODULES.Services, OPERATIONS_ALLOWED.manage) && (
                    <ListItem
                        button
                        component={Link}
                        to={`/reports`}
                        style={
                            checkDegradient(match?.path, 'reports')
                        }
                    >
                        <Tooltip title={t('dashboard.items.reports')}>
                            <ListItemIcon>
                                <AssessmentIcon color={'secondary'} />
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText
                            style={
                                checkSecondaryColor(match?.path, 'reports')
                            }
                            primary={t('dashboard.items.reports')}
                        />
                    </ListItem>
                )
            }
            {
                allowAccessRole(MODULES.Journeys, OPERATIONS_ALLOWED.read) && (
                    <ListItem
                        button
                        style={
                            checkDegradient(match?.path, 'journeys')
                        }
                        onClick={() => setiscollapseJourneyMenuOpen(prev => !prev)}
                    >
                        <Tooltip title={t('dashboard.items.journeys')}>
                            <ListItemIcon>
                                <TimelineIcon color={'secondary'} />
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText
                            style={
                                checkSecondaryColor(match?.path, 'journeys')
                            }
                            primary={t('dashboard.items.journeys')}
                        />
                        {iscollapseJourneyMenuOpen ? (
                            <ExpandLess color={'secondary'} />
                        ) : (
                            <ExpandMore color={'secondary'} />
                        )}
                    </ListItem>
                )
            }

            {
                allowAccessRole(MODULES.Journeys, OPERATIONS_ALLOWED.read) && (
                    <Collapse
                        in={iscollapseJourneyMenuOpen}
                        timeout="auto"
                        unmountOnExit
                    >
                        <List component="div" disablePadding>
                            <ListItem
                                button
                                component={Link}
                                to={`/journeysWheel`}
                                style={
                                    checkDegradient(match?.path, 'journeysWheel')
                                }
                                onClick={() => getWheelJourneys(serviceReducer.journey_filters, serviceReducer.filterJourneyStartDate,
                                    serviceReducer.filterJourneyEndDate)}
                            >
                                <Tooltip title={t('dashboard.items.journeys_wheel')}>
                                    <ListItemIcon>
                                        <ArrowForwardIosIcon color={'secondary'} />
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText
                                    style={
                                        checkSecondaryColor(match?.path, 'journeysWheel')
                                    }
                                    primary={t('dashboard.items.journeys_wheel')}
                                />
                            </ListItem>

                            <ListItem
                                button
                                component={Link}
                                to={`/journeysFlex`}
                                style={
                                    checkDegradient(match?.path, 'journeysFlex')
                                }
                                onClick={() => getJourneys(serviceReducer.journey_filters, serviceReducer.filterJourneyStartDate,
                                    serviceReducer.filterJourneyEndDate)}
                            >
                                <Tooltip title={t('dashboard.items.journeys_flex')}>
                                    <ListItemIcon>
                                        <ArrowForwardIosIcon color={'secondary'} />
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText
                                    style={
                                        checkSecondaryColor(match?.path, 'journeysFlex')
                                    }
                                    primary={t('dashboard.items.journeys_flex')}
                                />
                            </ListItem>
                        </List>
                    </Collapse>
                )
            }
            {
                allowAccessRole(MODULES.Trips, OPERATIONS_ALLOWED.read) && (
                    <ListItem
                        button
                        style={
                            checkDegradient(match?.path, 'trips')
                        }
                        onClick={() => setiscollapseTripMenuOpen(prev => !prev)}
                    >
                        <Tooltip title={t('dashboard.items.trips')}>
                            <ListItemIcon>
                                <CardTravelIcon color={'secondary'} />
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText
                            style={
                                checkSecondaryColor(match?.path, 'trips')
                            }
                            primary={t('dashboard.items.trips')}
                        />
                        {iscollapseTripMenuOpen ? (
                            <ExpandLess color={'secondary'} />
                        ) : (
                            <ExpandMore color={'secondary'} />
                        )}
                    </ListItem>
                )
            }
            {
                allowAccessRole(MODULES.Trips, OPERATIONS_ALLOWED.read) && (
                    <Collapse
                        in={iscollapseTripMenuOpen}
                        timeout="auto"
                        unmountOnExit
                    >
                        <List component="div" disablePadding>
                            <ListItem
                                button
                                component={Link}
                                to={`/tripsAll`}
                                style={
                                    checkDegradient(match?.path, 'tripsAll')
                                }
                                onClick={() => getTrips(null, tripReducer.filterStartDate != '' ? tripReducer.filterStartDate : null, tripReducer.filterEndDate != '' ? tripReducer.filterEndDate : null)}
                            >
                                <Tooltip title={t('dashboard.items.trips_all')}>
                                    <ListItemIcon>
                                        <ArrowForwardIosIcon color={'secondary'} />
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText
                                    style={
                                        checkSecondaryColor(match?.path, 'tripsAll')
                                    }
                                    primary={t('dashboard.items.trips_all')}
                                />
                            </ListItem>

                            <ListItem
                                button
                                component={Link}
                                to={`/tripsPending`}
                                style={
                                    checkDegradient(match?.path, 'tripsPending')
                                }
                                onClick={() => getTripsPending(null, tripReducer.filterStartDate != '' ? tripReducer.filterStartDate : null, tripReducer.filterEndDate != '' ? tripReducer.filterEndDate : null)}
                            >
                                <Tooltip title={t('dashboard.items.trips_pending')}>
                                    <ListItemIcon>
                                        <ArrowForwardIosIcon color={'secondary'} />
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText
                                    style={
                                        checkSecondaryColor(match?.path, 'tripsPending')
                                    }
                                    primary={t('dashboard.items.trips_pending')}
                                />
                            </ListItem>
                        </List>
                    </Collapse>
                )
            }
            {
                allowAccessRole(MODULES.Chat, OPERATIONS_ALLOWED.read) && (
                    <ListItem
                        button
                        component={Link}
                        to={`/chat`}
                        style={
                            checkDegradient(match?.path, 'chat')
                        }
                    >
                        <Tooltip title={t('dashboard.items.chat')}>
                            <ListItemIcon>
                                <Badge
                                    color="secondary"
                                    badgeContent={parseInt(chatReducer.unreadMsg)}
                                >
                                    <ChatIcon
                                        style={{
                                            color: theme.palette.secondary.main,
                                        }}
                                    />
                                </Badge>
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText
                            style={
                                checkSecondaryColor(match?.path, 'chat')
                            }
                            primary={t('dashboard.items.chat')}
                        />
                    </ListItem>
                )
            }
            {
                allowAccessRole(MODULES.Incidents, OPERATIONS_ALLOWED.read) && (
                    <ListItem
                        button
                        component={Link}
                        to={`/incidents`}
                        style={
                            checkDegradient(match?.path, 'incidents')
                        }
                    >
                        <Tooltip title={t('dashboard.items.incidents')}>
                            <ListItemIcon>
                                <Badge
                                    color="secondary"
                                    badgeContent={incidentsBadgeCount}
                                    invisible={incidentsBadgeCount == 0}
                                >
                                    <ReportProblemIcon color={'secondary'} />
                                </Badge>
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText
                            style={
                                checkSecondaryColor(match?.path, 'incidents')
                            }
                            primary={t('dashboard.items.incidents')}
                        />
                    </ListItem>
                )
            }
            {
                allowAccessRole(MODULES.Notifications, OPERATIONS_ALLOWED.read) && (
                    <ListItem
                        button
                        style={
                            checkDegradient(match?.path, 'notifications')
                        }
                        onClick={() => setiscollapseNotificacionMenuOpen(prev => !prev)}
                    >
                        <Tooltip title={t('dashboard.items.notifications')}>
                            <ListItemIcon>
                                <NotificationsIcon color={'secondary'} />
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText
                            style={
                                checkSecondaryColor(match?.path, 'notifications')
                            }
                            primary={t('dashboard.items.notifications')}
                        />
                        {iscollapseNotificationMenuOpen ? (
                            <ExpandLess color={'secondary'} />
                        ) : (
                            <ExpandMore color={'secondary'} />
                        )}
                    </ListItem>
                )
            }
            {
                allowAccessRole(MODULES.Notifications, OPERATIONS_ALLOWED.read) && (
                    <Collapse
                        in={iscollapseNotificationMenuOpen}
                        timeout="auto"
                        unmountOnExit
                    >
                        <List component="div" disablePadding>
                            <ListItem
                                button
                                component={Link}
                                to={`/notifications`}
                                style={
                                    checkDegradientWithOutValue(match?.path, 'notifications', 'notificationsPush')
                                }
                            >
                                <Tooltip title={t('dashboard.items.notifications_h')}>
                                    <ListItemIcon>
                                        <ArrowForwardIosIcon color={'secondary'} />
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText
                                    style={
                                        checkSecondaryColorWithOutValue(match?.path, 'notifications', 'notificationsPush')
                                    }
                                    primary={t('dashboard.items.notifications_h')}
                                />
                            </ListItem>
                            {allowAccessRole(MODULES.Notifications, OPERATIONS_ALLOWED.manage) && (
                                <ListItem
                                    button
                                    component={Link}
                                    to={`/notificationsPush`}
                                    style={
                                        checkDegradient(match?.path, 'notificationsPush')
                                    }
                                >
                                    <Tooltip title={t('dashboard.items.notifications_c')}>
                                        <ListItemIcon>
                                            <ArrowForwardIosIcon color={'secondary'} />
                                        </ListItemIcon>
                                    </Tooltip>
                                    <ListItemText
                                        style={
                                            checkSecondaryColor(match?.path, 'notificationsPush',)
                                        }
                                        primary={t('dashboard.items.notifications_c')}
                                    />
                                </ListItem>
                            )}
                        </List>
                    </Collapse>
                )
            }
            {
                allowAccessRole(MODULES.KPIs_dashboard, OPERATIONS_ALLOWED.read) && (
                    <ListItem
                        button
                        component={Link}
                        to={`/commandBox`}
                        style={
                            checkDegradient(match?.path, 'commandBox')
                        }>
                        <Tooltip title={t('dashboard.items.commandBox')}>
                            <ListItemIcon>
                                <FeaturedVideoIcon color={'secondary'} />
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText
                            style={
                                checkSecondaryColor(match?.path, 'commandBox')
                            }
                            primary={t('dashboard.items.commandBox')}
                        />
                    </ListItem>
                )
            }
        </div >
    )
}

export default ListItems
