import React from 'react';
import { makeStyles } from '@material-ui/core/styles'
import { Box, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core';

export default function SimpleTable({
    icon,
    title,
    subtitle,
    rows,
    total,
}) {
    const classes = useStyles()

    return (
        <TableContainer component={Box}>
            <div className={classes.header}>
                <div className={classes.titleWrapper}>
                    <div className={classes.svgWrapper}>
                        {icon}
                    </div>
                    <div>
                        <Typography variant="subtitle" className={classes.subtitle}>{subtitle}</Typography>
                        <Typography variant="h6" className={classes.title}>{title}</Typography>
                    </div>
                </div>
                <div>
                    <Typography variant="h6" color='primary' className={classes.total}>{isNaN(total) ? 0 : total}</Typography>
                </div>
            </div>
            <Table arial-label="reservation table">
                <TableBody>
                    {rows.map((row) => (
                        <TableRow key={row.name}>
                            <TableCell component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell align="right">
                                <Typography color='primary' >{row.unit ? row.unit : 0}</Typography>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}


const useStyles = makeStyles({
    header: {
        display: "flex",
        gap: "12px",
        justifyContent: "space-between",
        alignItems: "center",
        width: "auto",
        padding: "0 16px 0 16px",
    },
    titleWrapper: {
        display: "flex",
        gap: "12px",
    },
    svgWrapper: {
        background: "#D6ECF2",
        padding: "12px 5px",
        borderRadius: "5px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    subtitle: {
        color: "#868997"
    },
    title: {
        fontWeight: "600"
    },
    total: {
        fontSize: "24px",
        fontWeight: '600'
    }
})