import { modulesConstants } from "../../constants/modules_constants";

const initialState = {
    pending: false,
    results: [],
    error: null,
}

export function modulesReducer(state = initialState, action) {
    switch (action.type) {
        case modulesConstants.GETALL_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case modulesConstants.GETALL_SUCCESS:
            return {
                ...state,
                pending: false,
                results: action.results,
            }
        case modulesConstants.GETALL_FAILURE:
            return {
                ...state,
                pending: false,
                results: [],
                error: action.error,
            }
        default:
            return state
    }
}