import React from 'react';
import { Button, Dialog, DialogActions, DialogTitle, TextField, InputLabel, FormControl } from '@material-ui/core';
import { withTranslation } from 'react-i18next'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import useStyles from './styles';
import i18next from 'i18next';
import utils from '../../../helpers/validations';

class CustomerParamsModal extends React.Component {

    constructor(props) {
        super();
        const customer = props.customer;
        this.state = {
            email: customer.email || '',
            lang: customer.language || i18next.language,
            errors: {
                email: { 'result': true, 'message': '' }
            }
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        let { name, value } = event.target;
        this.setState({ [name]: value }, () => this.validateEmailForm())
    }

    componentDidUpdate(prevProps) {
        if (prevProps.open != this.props.open) {
            if (this.props.open == false) {
                this.setState({
                    email: this.props.customer.email,
                    lang: this.props.customer.language || i18next.language
                }, () => this.validateEmailForm())
            }
        }
    }
    validateEmailForm() {
        let errors = this.state.errors;
        errors.email = utils.validateEmail(this.state.email)
        this.setState({ errors })
    }

    resetEmail() {
        this.setState({ email: this.props.customer.email }, () => this.validateEmailForm())
    }

    handleOnChangeLanguage = (event) => {
        this.setState({
            lang: event.target.value
        })
    }

    render() {
        const classes = this.props.styles;
        const { t, customer } = this.props
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onClickCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                <div className={classes.emailDiv}>
                    <FormControl>
                        <TextField
                            label={t('customers.form.page.personalData.email')}
                            value={this.state.email || ''}
                            variant="outlined"
                            name="email"
                            fullWidth
                            onChange={this.handleChange}
                            type={'email'}
                            error={this.state.errors.email.message.length === 0 ? false : true}
                            helperText={this.state.errors.email.message}
                        />
                    </FormControl>
                </div>
                <div className={classes.languageSelectionDiv}>
                    <InputLabel className={classes.languageLabel} id="language">{t('customers.form.page.personalData.language')}</InputLabel>
                    <Select
                        labelId="language"
                        id="languageSelect"
                        value={this.state.lang}
                        onChange={event => this.handleOnChangeLanguage(event)}
                    >
                        <MenuItem value={'en'}>{t('customers.form.page.personalData.english')}</MenuItem>
                        <MenuItem value={'es'}>{t('customers.form.page.personalData.spanish')}</MenuItem>
                        <MenuItem value={'ca'}>{t('customers.form.page.personalData.catalan')}</MenuItem>
                    </Select>
                </div>
                <DialogActions>
                    <div className={classes.buttonDiv}>
                        <Button
                            onClick={() => this.props.onClickAccept(customer, this.state.email, this.state.lang)}
                            variant="contained"
                            size="medium"
                            color="primary"
                            disabled={this.state.errors.email.result == false ? true : false}
                        >
                            {t('customers.form.page.personalData.send')}
                        </Button>
                    </div>
                </DialogActions>
                <DialogActions>
                    <div className={classes.buttonDiv}>
                        <Button
                            onClick={this.props.onClickCancel}
                            variant="contained"
                            size="medium"
                            color="primary"
                        >
                            {t('customers.form.page.personalData.close')}
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        )
    }
}

function Hook(props) {
    const classes = useStyles();
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        props.clear();

    }
    return <CustomerParamsModal
        {...props}
        handleCloseAlert={handleCloseAlert}
        styles={classes}
    />
}

export default withTranslation('common')(Hook);