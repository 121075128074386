import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import ShiftDays from '../shiftDays'
import { Autocomplete } from '@material-ui/lab'
import { Grid, TextField } from '@material-ui/core'
import i18next from 'i18next'

export const ShiftBreaksComponent = ({ calendar, addTime, removeItem, handleChangeDateShiftDay, handleShiftDaysUpdates, handleChangeStop, state, ...rest }) => {

    const serviceReducer = useSelector((state) => state.serviceReducer)
    const zoneReducer = useSelector((state) => state.zoneReducer)

    const weekDaysOriginal = useRef(serviceReducer.weekDays)
    const [weekDaysOptions, setWeekDaysOptions] = useState(serviceReducer.weekDays)
    const [optionsSelected, setOptionsSelected] = useState([])


    const filterWeekOptions = useCallback((body) => {
        setOptionsSelected(oldValue => [...oldValue.concat(body)])
        setWeekDaysOptions(weekDaysOriginal.current.filter(item => !body.includes(parseInt(item.id)) && !optionsSelected.includes(parseInt(item.id))))

    }, [weekDaysOriginal, weekDaysOptions, optionsSelected])

    useEffect(() => {
        if (calendar.shiftBreaks.length > 0) {
            let selected = []
            calendar.shiftBreaks.forEach(element => {
                const weekDaysSelected = element.shiftDays.weekDays
                selected = selected.concat(weekDaysSelected)
            });
            setWeekDaysOptions(weekDaysOriginal.current.filter(item => !selected.includes(parseInt(item.id))))
        }
    }, [calendar, optionsSelected])

    const handleUpdateStop = useCallback((index, value) => {
        if (value)
            value.type = 40
        handleChangeStop(index, value)
    }, [])

    return (
        <Grid container>
            <>
                <Grid item md={12}>
                    <h2>{i18next.t('services_comp.calendar.shiftBreaks.title')}</h2>
                </Grid>

                {calendar.shiftBreaks?.map((item, index) => (
                    <Grid container style={{ paddingBottom: 50, width: '100%' }} key={"container_" + index}>
                        <Grid container spacing={2} style={{ paddingBottom: 20 }}>
                            <Grid item md={4}>
                                <Autocomplete
                                    options={zoneReducer.stops?.filter(item => item.isBreakStop) ?? []}
                                    value={calendar.shiftBreaks[index].stop}
                                    getOptionLabel={(option) => option.name ?? ""}
                                    onChange={(e, value) => handleUpdateStop(index, value)}
                                    getOptionSelected={(option, value) => option.id == value?.id}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant={"outlined"}
                                            label={i18next.t("services_comp.calendar.shiftBreaks.stop")}
                                            helperText={
                                                state.errors["shiftBreaks"][index].stop.message
                                            }
                                            error={
                                                state.errors["shiftBreaks"][index].stop.message
                                                    .length === 0
                                                    ? false
                                                    : true
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <ShiftDays
                            key={'sb_' + index}
                            item={item}
                            index={index}
                            detail={rest.detail}
                            length={calendar.shiftBreaks.length}
                            value={calendar.shiftBreaks[index].shiftDays}
                            t={i18next.t}
                            onChange={event => handleChangeDateShiftDay(event, index)}
                            add={addTime}
                            remove={removeItem}
                            checkUpdates={(days, option) => handleShiftDaysUpdates(days, option, index)}
                            prop={'shiftBreaks'}
                            state={state}
                            disabled={rest.readOnly || rest.readEdit}
                            showButtons={false}
                            restrictWeekDays={true}
                            weekDaysOptions={weekDaysOptions.filter(item => !optionsSelected.includes(item.id))}
                            weekDaysAllOptions={weekDaysOriginal.current}
                            filterWeekOptions={filterWeekOptions}
                            {...rest}
                        />

                    </Grid>
                ))}
            </>
        </Grid>
    )
}