import {
    addDays,
    endOfDay,
    startOfDay,
    startOfMonth,
    endOfMonth,
    addMonths,
    startOfWeek,
    endOfWeek,
    isSameDay,
    differenceInCalendarDays,
} from 'date-fns';

const defineds = {
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
    startOfToday: startOfDay(new Date()),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
};

const staticRangeHandler = {
    range: {},
    isSelected(range) {
        const definedRange = this.range();
        return (
            isSameDay(range.startDate, definedRange.startDate) &&
            isSameDay(range.endDate, definedRange.endDate)
        );
    },
};

export function createStaticRanges(ranges) {
    return ranges.map(range => ({ ...staticRangeHandler, ...range }));
}


export const defaultStaticRanges = (i18next) => {
    return createStaticRanges([
        {
            label: i18next.t('services_comp.calendar.range.today'),
            range: () => ({
                startDate: defineds.startOfToday,
                endDate: defineds.endOfToday,
            }),
        },
        {
            label: i18next.t('services_comp.calendar.range.yesterday'),
            range: () => ({
                startDate: defineds.startOfYesterday,
                endDate: defineds.endOfYesterday,
            }),
        },

        {
            label: i18next.t('services_comp.calendar.range.thisWeek'),
            range: () => ({
                startDate: defineds.startOfWeek,
                endDate: defineds.endOfWeek,
            }),
        },
        {
            label: i18next.t('services_comp.calendar.range.lastWeek'),
            range: () => ({
                startDate: defineds.startOfLastWeek,
                endDate: defineds.endOfLastWeek,
            }),
        },
        {
            label: i18next.t('services_comp.calendar.range.thisMonth'),
            range: () => ({
                startDate: defineds.startOfMonth,
                endDate: defineds.endOfMonth,
            }),
        },
        {
            label: i18next.t('services_comp.calendar.range.lastMonth'),
            range: () => ({
                startDate: defineds.startOfLastMonth,
                endDate: defineds.endOfLastMonth,
            }),
        },
    ])
}
    ;

export const defaultInputRanges = (i18next) => {
    return [
        {
            label: i18next.t('services_comp.calendar.range.toToday'),
            range(value) {
                return {
                    startDate: addDays(defineds.startOfToday, (Math.max(Number(value), 1) - 1) * -1),
                    endDate: defineds.endOfToday,
                };
            },
            getCurrentValue(range) {
                if (!isSameDay(range.endDate, defineds.endOfToday)) return '-';
                if (!range.startDate) return '∞';
                return differenceInCalendarDays(defineds.endOfToday, range.startDate) + 1;
            },
        },
        {
            label: i18next.t('services_comp.calendar.range.startingToday'),
            range(value) {
                const today = new Date();
                return {
                    startDate: today,
                    endDate: addDays(today, Math.max(Number(value), 1) - 1),
                };
            },
            getCurrentValue(range) {
                if (!isSameDay(range.startDate, defineds.startOfToday)) return '-';
                if (!range.endDate) return '∞';
                return differenceInCalendarDays(range.endDate, defineds.startOfToday) + 1;
            },
        },
    ]
};