import React from 'react'
import { Chart } from 'react-google-charts'
import PropTypes from 'prop-types'
import { FilterOptions, options, getStringTimeUnits, getChartName, DownloadChart, filterDataByGroup } from '../../../customFunctions'
import moment from 'moment'
import i18next from 'i18next'

function getTitle(itemPassenger) {
    if (itemPassenger > 1) return i18next.t('commandBox.passengers', { returnObjects: true }).replace('x', itemPassenger)
    else return i18next.t('commandBox.passenger', { returnObjects: true }).replace('x', itemPassenger)
}

const filteredData = (data, filterOptions, element) => {
    const { groupedBy, value } = filterOptions
    let array = [['Journeys', 'Total']]
    // for each books set dates information to filter after
    let journeys = filterDataByGroup(data, groupedBy, element)
    let allNumPassenger = [...new Set(journeys.map(x => x.numPassengers))]
    allNumPassenger.forEach(itemPassenger => {
        array.push([getTitle(itemPassenger), journeys.filter(x => x.numPassengers == itemPassenger).length])
    })

    console.log('array => ', array)
    return array
}

/**
 * CASES
 *  groupedBy: municipalities & value != null
 *  groupedBy: regions & value != null
 *  groupedBy: otpId & value != null
 *  groupedBy: tenant & value != null
 *  filterByDate: startDate != null & finishDate != null & finishDate > startDate
 */

const TotalPassengerByJourney = ({ data, title = 'Total viatges (acumulats)', groupedBy = 'municipalities', value = [], filterByDate = true, startDate = '2022-01-01', finishDate = '2022-02-01', timeUnits }) => {
    const chartProps = { title, filterByDate, startDate, finishDate }
    const filterOptions = FilterOptions(groupedBy, value, filterByDate, startDate, finishDate, timeUnits)
    const chartType = 'PieChart'

    return (
        <>
            {value.map(function (element, i) {
                return filteredData(data, filterOptions, element).filter(([a, b]) => b > 0).length > 0 ? (
                    <React.Fragment key={Math.random()}>
                        <Chart chartType={chartType} graphID={'chart_' + i.toString()} width="100%" height="400px" data={filteredData(data, filterOptions, element)} options={options(chartProps, null, element)} chartLanguage={i18next.language} />
                        <DownloadChart key={Math.random()} img64={i} />
                    </React.Fragment>
                ) : null
            })}
        </>
    )
}
TotalPassengerByJourney.propTypes = {
    data: PropTypes.array.isRequired,
    chartType: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    groupedBy: PropTypes.oneOf(['municipalities', 'regions', 'otps', 'services', 'tenants']).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    filterByDate: PropTypes.bool.isRequired,
    startDate: PropTypes.string.isRequired,
    finishDate: PropTypes.string.isRequired,
}

export default TotalPassengerByJourney
