import React from "react";
import { Typography } from "@material-ui/core";
import i18next from "i18next";
import moment from "moment/moment";
import { useSelector } from "react-redux";
import { DatePickerMultipleTripComponent } from "../resume/components/datePickers";

export default function ReturnTripForm({ onSubmit, time, setTime, isRequestByDropOff, setRequestByDropOff }) {
    const tripReducer = useSelector((state) => state.tripReducer)
    const serviceType = useSelector((state) => state.tripReducer.trip?.serviceType)

    const { tripToReturn } = tripReducer

    return (
        <>
            <Typography variant='h6' align='center'>
                {i18next.t('trips.returnTrip.title')}
            </Typography>
            <form style={{ paddingTop: 20, justifyContent: 'center', display: 'grid' }} onSubmit={onSubmit} className='datePickerContainer'>
                <DatePickerMultipleTripComponent
                    setDate={setTime}
                    date={time}
                    minTime={moment(tripToReturn?.minPickUpTime).add(15, 'minute').toDate()}
                    isRequestByDropOff={isRequestByDropOff}
                    onChangeRequestType={setRequestByDropOff}
                    serviceType={serviceType}
                />
            </form>
        </>
    )
}