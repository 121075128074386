export const notificationsConstants = {
    GETALL_REQUEST: 'NOTIFICATIONS_GETALL_REQUEST',
    GETALL_SUCCESS: 'NOTIFICATIONS_GETALL_SUCCESS',
    GETALL_FAILURE: 'NOTIFICATIONS_GETALL_FAILURE',

    TYPES_REQUEST: 'OPERATORS_TYPES_REQUEST',
    TYPES_SUCCESS: 'OPERATORS_TYPES_SUCCESS',
    TYPES_FAILURE: 'OPERATORS_TYPES_FAILURE',

    CHECK_REQUEST: 'NOTIFICATIONS_CHECK_REQUEST',
    CHECK_SUCCESS: 'NOTIFICATIONS_CHECK_SUCCESS',
    CHECK_FAILURE: 'NOTIFICATIONS_CHECK_FAILURE',

    GET_NOTIFICATIONS_COUNT: 'GET_NOTIFICATIONS_COUNT',

    REQUEST_TRIP_FROM_CALL: 'NOTIFICATIONS_REQUEST_TRIP_FROM_CALL'
}
