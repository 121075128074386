import { Checkbox, FormControlLabel, Grid } from "@material-ui/core"
import SectionStyled, { TitleSection } from './section'
import React from 'react'
import i18next from "i18next"
import { InputNumberWithLabel } from "./inputNumberWithLabel"
import { SERVICES_TYPE } from "../../../../constants/types"



export const PenalitiesSection = ({ state, readOnly, handleChangeCheckBox, handleChange }) => {

    if (state.serviceType?.id == SERVICES_TYPE.regularWithSchedule)
        return (<></>)

    return (
        <SectionStyled>
            <TitleSection>{i18next.t('services_comp.form.page.titlePenalty')}</TitleSection>

            <Grid container spacing={3}>
                <Grid item md={6} xl={4} sm={9} xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.penaltiesEnabled}
                                name="penaltiesEnabled"
                                onChange={handleChangeCheckBox}
                                color={"primary"}
                                disabled={readOnly}
                            />
                        }
                        label={i18next.t('services_comp.form.page.enablePenalty')}
                    />
                </Grid>
                <Grid item md={6} xl={4} sm={9} xs={12}>
                    <InputNumberWithLabel
                        name={"penaltyDays"}
                        onChange={handleChange}
                        readOnly={readOnly}
                        error={state.errors.penaltyDays.message.length > 0}
                        helperText={state.errors.penaltyDays.message}
                        value={state.penaltyDays}
                        min={0}
                        label={i18next.t('services_comp.form.page.penaltyDays')}
                        tooltip={i18next.t('services_comp.form.page.tooltip.penaltyDays')}
                    />
                </Grid>
                <Grid item md={6} xl={4} sm={9} xs={12}>
                    <InputNumberWithLabel
                        name={"maxAbsences"}
                        onChange={handleChange}
                        readOnly={readOnly}
                        error={state.errors.maxAbsences.message.length > 0}
                        helperText={state.errors.maxAbsences.message}
                        value={state.maxAbsences}
                        min={0}
                        label={i18next.t('services_comp.form.page.maxAbsences')}
                        tooltip={i18next.t('services_comp.form.page.tooltip.maxAbsences')}
                    />
                </Grid>
                <Grid item md={6} xl={4} sm={9} xs={12}>
                    <InputNumberWithLabel
                        name={"absenceDaysExpiration"}
                        onChange={handleChange}
                        readOnly={readOnly}
                        error={state.errors.absenceDaysExpiration.message.length > 0}
                        helperText={state.errors.absenceDaysExpiration.message}
                        value={state.absenceDaysExpiration}
                        min={0}
                        label={i18next.t('services_comp.form.page.absenceDaysExpiration')}
                        tooltip={i18next.t('services_comp.form.page.tooltip.absenceDaysExpiration')}
                    />
                </Grid>
            </Grid>
        </SectionStyled>

    )
}
