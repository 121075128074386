import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Chart from "react-google-charts";
import i18next from "i18next";
import KpiWrapper from "../../../components/kpiWrapper";
import { getJourneyKms } from "../../../helpers/kpiHelper";
export default function DriverDistanceChart({ isLoading }) {
    const { t } = useTranslation()

    const journeys = useSelector(state => state.kpiReducer.journeys)
    const isJourneysLoading = useSelector(state => state.kpiReducer.journeysLoading)

    const { data, total } = useMemo(() => {
        if (!journeys.length) return { data: [], total: 0 }
        return getJourneyKms(journeys, "driverName")
    }, [journeys])

    isLoading = isLoading || isJourneysLoading
    const displayNoData = !isLoading && (!data || data.length == 0)

    return (
        <KpiWrapper
            title={t("commandBox.journeys.journeysDistanceChart.title")}
            displayNoData={displayNoData}
            isLoading={isLoading}
            total={`${total?.toFixed(1)} km`}
            skeletonVariant="rect"
        >
            <Chart
                chartType="ColumnChart"
                graphID={'chart_kms_journeys'}
                data={data}
                chartLanguage={i18next.language}
            />
        </KpiWrapper>
    )
}