import i18next from 'i18next';
import { userService } from '../../services/userService';
import { rolesConstants } from '../../constants/roles_constants';
import { profileService } from '../../services/profileService';
import { alertActions } from './alert_actions';
import { userActions } from './user_actions';
import { history } from '../../helpers/history';
import { ERROR_CODES } from '../../constants/types';

export const rolesActions = {
    getAll,
    insert,
    remove,
    update,
    getById,
    getUsersByRoleId,
}

const DETAIL_ERROR_CODES = {
    SameProfileExists: "SameProfileExists",
    SystemProfileCannotChangeName: "SystemProfileCannotChangeName",
    AdminProfileCannotChangePermissions: "AdminProfileCannotChangePermissions",
    SystemProfileCannotBeRemoved: 'SystemProfileCannotBeRemoved',
    ProfileNotFound: 'ProfileNotFound'
}

function getAll() {
    return (dispatch) => {
        dispatch(request())
        profileService.getAll().then(
            (result) => {
                dispatch(success(result))
            },
            (error) => {
                switch (error.status) {
                    case ERROR_CODES.Unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(getAll())
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t("services.roles.forbiddenGetAll")))
                        break;
                    default:
                        dispatch(
                            alertActions.error(i18next.t("services.roles.getAllError"))
                        )
                }
                dispatch(failure(error.status))
            }
        )
    }

    function request() {
        return { type: rolesConstants.GETALL_REQUEST }
    }

    function success(results) {
        return { type: rolesConstants.GETALL_SUCCESS, results }
    }

    function failure(error) {
        return { type: rolesConstants.GETALL_FAILURE, error }
    }
}

function insert(role) {
    return (dispatch) => {
        profileService.add(role).then(
            (role) => {
                dispatch(request(role))
                history.push('/roles')
                dispatch(
                    alertActions.success(i18next.t("services.roles.insertSuccess"))
                )
            },
            (error) => {
                switch (error.status) {
                    case ERROR_CODES.Unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(insert(role))
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t("services.roles.forbiddenInsert")))
                        break
                    default:
                        try {
                            let parsedError = JSON.parse(error)
                            let detailError = parsedError.detail;

                            switch (detailError) {
                                case DETAIL_ERROR_CODES.SameProfileExists:
                                    dispatch(
                                        alertActions.error(i18next.t("services.roles.sameProfileExists"))
                                    );
                                    break
                                default:
                                    dispatch(alertActions.error(i18next.t("services.roles.insertError")))
                            }
                        } catch (e) {
                            dispatch(alertActions.error(error.toString()))
                        }
                }
                dispatch(failure(error.status));
            }
        );
    }


    function request() {
        return { type: rolesConstants.REGISTER_REQUEST }
    }

    function failure(error) {
        return { type: rolesConstants.REGISTER_FAILURE, error }
    }

}

function update(id, role) {
    return (dispatch) => {
        dispatch(request());
        profileService.update(id, role).then(
            (role) => {
                dispatch(success(role));
                history.push('/roles');
                dispatch(
                    alertActions.success(i18next.t("services.roles.updateSuccess"))
                );
            },
            (error) => {
                switch (error.status) {
                    case ERROR_CODES.Unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(update(role))
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t("services.roles.forbiddenUpdate")))
                        break
                    default:
                        try {
                            let parsedError = JSON.parse(error)
                            let detailError = parsedError.detail

                            switch (detailError) {
                                case DETAIL_ERROR_CODES.SystemProfileCannotChangeName:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.roles.systemProfileNameChangeAttemptError')
                                        )
                                    )
                                    break
                                case DETAIL_ERROR_CODES.AdminProfileCannotChangePermissions:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.roles.adminProfilePermissionsChangeAttemptError')
                                        )
                                    )
                                    break
                                default:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t("services.roles.updateError")
                                        )
                                    );
                            }
                        } catch (e) {
                            dispatch(alertActions.error(error.toString()));
                        }
                }
                dispatch(failure(error.status));
            }
        )
    }

    function request() {
        return { type: rolesConstants.UPDATE_REQUEST }
    }

    function success(results) {
        return { type: rolesConstants.UPDATE_SUCCESS, results }
    }

    function failure(error) {
        return { type: rolesConstants.UPDATE_FAILURE, error }
    }
}

function remove(id) {
    return (dispatch) => {
        dispatch(request());
        profileService.delate(id).then(
            (_) => {
                dispatch(success(id));
                dispatch(
                    alertActions.success(i18next.t("services.roles.deleteSuccess"))
                );
            },
            (error) => {
                switch (error.status) {
                    case ERROR_CODES.Unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(remove(id))
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t("services.roles.forbiddenDelete")))
                        break
                    default:
                        try {
                            let parsedError = JSON.parse(error)
                            let detailError = parsedError.detail;

                            switch (detailError) {
                                case DETAIL_ERROR_CODES.ProfileNotFound:
                                    dispatch(
                                        alertActions.error(i18next.t("services.roles.profileNotFoundError"))
                                    );
                                    break
                                case DETAIL_ERROR_CODES.SystemProfileCannotBeRemoved:
                                    dispatch(
                                        alertActions.error(i18next.t("services.roles.systemProfileRemoveAttemptError"))
                                    );
                                    break
                                default:
                                    dispatch(alertActions.error(i18next.t("services.roles.removeError")))
                            }
                        } catch (e) {
                            dispatch(alertActions.error(error.toString()));
                        }
                }
                dispatch(failure(error.status));
            }
        )
    }

    function request() {
        return { type: rolesConstants.DELETE_REQUEST }
    }

    function success(id) {
        return { type: rolesConstants.DELETE_SUCCESS, id }
    }

    function failure(error) {
        return { type: rolesConstants.DELETE_FAILURE, error }
    }
}

function getById(id) {
    return (dispatch) => {
        dispatch(request());
        profileService.getById(id).then(
            (role) => {
                dispatch(success(role));
            },
            (error) => {
                switch (error.status) {
                    case ERROR_CODES.Unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(getById(id))
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t("services.roles.forbiddenGetById")))
                        break
                    default:
                        dispatch(
                            alertActions.error(i18next.t("services.roles.getByIdError"))
                        )
                }
                dispatch(failure(error.status));
            }
        )
    };

    function request() {
        return { type: rolesConstants.DETAIL_REQUEST }
    }

    function success(role) {
        return { type: rolesConstants.DETAIL_SUCCESS, role }
    }

    function failure(error) {
        return { type: rolesConstants.DETAIL_FAILURE, error }
    }
}

function getUsersByRoleId(id) {
    return (dispatch) => {
        dispatch(request())
        profileService.getUsersById(id).then(
            (users) => {
                dispatch(success(users));
            },
            (error) => {
                switch (error.status) {
                    case ERROR_CODES.Unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(getUsersByRoleId(id))
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t("services.roles.forbiddenGetUsersById")))
                        break
                    default:
                        dispatch(
                            alertActions.error(i18next.t("services.roles.getUsersByIdError"))
                        )
                }
                dispatch(failure(error.status));
            }
        )
    }
    function request() {
        return { type: rolesConstants.GET_USERS_REQUEST }
    }

    function success(users) {
        return { type: rolesConstants.GET_USERS_SUCCESS, users }
    }

    function failure(error) {
        return { type: rolesConstants.GET_USERS_FAILURE, error }
    }
}