export const operatorsConstants = {
    REGISTER_REQUEST: 'OPERATORS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'OPERATORS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'OPERATORS_REGISTER_FAILURE',

    DETAIL_REQUEST: 'OPERATORS_DETAIL_REQUEST',
    DETAIL_SUCCESS: 'OPERATORS_DETAIL_SUCCESS',
    DETAIL_FAILURE: 'OPERATORS_DETAIL_FAILURE',

    GETALL_REQUEST: 'OPERATORS_GETALL_REQUEST',
    GETALL_SUCCESS: 'OPERATORS_GETALL_SUCCESS',
    GETALL_FAILURE: 'OPERATORS_GETALL_FAILURE',

    GETBYID_REQUEST: 'OPERATORS_GETBYID_REQUEST',
    GETBYID_SUCCESS: 'OPERATORS_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'OPERATORS_GETBYID_FAILURE',

    DELETE_REQUEST: 'OPERATORS_DELETE_REQUEST',
    DELETE_SUCCESS: 'OPERATORS_DELETE_SUCCESS',
    DELETE_FAILURE: 'OPERATORS_DELETE_FAILURE',

    EDIT_REQUEST: 'OPERATORS_EDIT_REQUEST',
    EDIT_SUCCESS: 'OPERATORS_EDIT_SUCCESS',
    EDIT_FAILURE: 'OPERATORS_EDIT_FAILURE',

    DISABLED_REQUEST: 'OPERATORS_DISABLED_REQUEST',
    DISABLED_SUCCESS: 'OPERATORS_DISABLED_SUCCESS',
    DISABLED_FAILURE: 'OPERATORS_DISABLED_FAILURE',

    PROFILES_REQUEST: 'OPERATORS_PROFILES_REQUEST',
    PROFILES_SUCCESS: 'OPERATORS_PROFILES_SUCCESS',
    PROFILES_FAILURE: 'OPERATORS_PROFILES_FAILURE',

    UPDATE_PSW_REQUEST: 'OPERATORS_UPDATE_PSW_REQUEST',
    UPDATE_PSW_SUCCESS: 'OPERATORS_UPDATE_PSW_SUCCESS',
    UPDATE_PSW_FAILURE: 'OPERATORS_UPDATE_PSW_FAILURE',

    RESET_PSW_REQUEST: 'CUSTOMER_RESET_PSW_REQUEST',
    RESET_PSW_SUCCESS: 'CUSTOMER_RESET_PSW_SUCCESS',
    RESET_PSW_FAILURE: 'CUSTOMER_RESET_PSW_FAILURE',

    SET_LANGUAGE_REQUEST: 'CUSTOMER_SET_LANGUAGE_REQUEST',
    SET_LANGUAGE_SUCCESS: 'CUSTOMER_SET_LANGUAGE_SUCCESS',
    SET_LANGUAGE_FAILURE: 'CUSTOMER_SET_LANGUAGE_FAILURE',

    OP_ENABLED_REQUEST: 'OP_ENABLED_REQUEST',
    OP_ENABLED_SUCCESS: 'OP_ENABLED_SUCCESS',
    OP_ENABLED_FAILURE: 'OP_ENABLED_FAILURE',

    OP_DISABLED_SUCCESS: 'OP_DISABLED_SUCCESS',
    OP_DISABLED_REQUEST: 'OP_DISABLED_REQUEST',
    OP_DISABLED_FAILURE: 'OP_DISABLED_FAILURE',

    OP_GET_ALL_TENANTS_REQUEST: 'OP_GET_ALL_TENANTS_REQUEST',
    OP_GET_ALL_TENANTS_SUCCESS: 'OP_GET_ALL_TENANTS_SUCCESS',
    OP_GET_ALL_TENANTS_FAILURE: 'OP_GET_ALL_TENANTS_FAILURE',

    CLEAR_OPERATOR: 'CLEAR_OPERATOR',

    OP_SET_PAGE_TABLE: 'OP_SET_TABLE_PAGE'


};