import { Button, Grid, Hidden, IconButton, Tooltip } from '@material-ui/core';
import React from 'react';
import { history } from '../../helpers/history';
import i18next from 'i18next';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'


const onClickBack = () => {
    history.goBack()
}


export const BackAndSave = (props) => {
    return (
        <>
            <Grid item md={3} implementation="css" smDown component={Hidden} />
            <Grid item md={2}>
                <Button
                    style={{ marginTop: 40 }}
                    variant="contained"
                    color="primary"
                    onClick={onClickBack}
                    fullWidth
                    size="large">
                    {i18next.t('services_comp.typology.backButton')}
                </Button>
            </Grid>
            <Grid item md={1} implementation="css" smDown component={Hidden} />
            <Grid item md={2}>
                <Button
                    style={{ marginTop: 40 }}
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="large"
                    disabled={props.conditionDisabledSave}
                    onClick={props.onClickSave}
                >
                    {i18next.t('services_comp.typology.saveButton')}
                </Button>
            </Grid>
            <Grid item md={4} implementation="css" smDown component={Hidden} />
        </>
    )
}

export const BackButton = ({ onClick }) => {
    return (
        <Tooltip title={i18next.t('trips.resume.goBack')}>
            <IconButton onClick={onClick}>
                <KeyboardBackspaceIcon />
            </IconButton>
        </Tooltip>
    )
}