import moment from 'moment'
import { getChartName } from '../customFunctions'
import i18next from 'i18next'
import { KPI_FILTER_OPTIONS } from '../../../constants/types'

export function getJourneyKPI(journeys, filterBy) {
    const data = {}

    journeys.forEach((elm) => {
        const filterByName = getFilterByName(elm, filterBy)

        if (data.hasOwnProperty(filterByName)) {
            const entry = data[filterByName].filter((e) => e.numTrips === elm.numTrips)[0]
            if (!entry) {
                data[filterByName].push({ numTrips: elm.numTrips, rep: 1 })
            } else {
                entry.rep += 1
            }
        } else {
            data[filterByName] = [{ numTrips: elm.numTrips, rep: 1 }]
        }

    })
    return formatJourneyData(data)
}

function formatJourneyData(data) {
    const chartData = [["filtro"]]
    const flatData = Object.values(data).map(e => e).flat(1)
    const uniqueNumTrips = [...new Set(flatData.map(item => item.numTrips))].sort((a, b) => a - b)

    uniqueNumTrips.forEach(num => {
        chartData[0].push(
            `${num} ${num === 1
                ? i18next.t('commandBox.journeys.totalJourneysChart.labelSingular')
                : i18next.t('commandBox.journeys.totalJourneysChart.label')}
            `
        )
    })

    Object.entries(data).map(([key, value]) => {
        const sortedValues = Object.values(value).sort((a, b) => a.numTrips - b.numTrips)
        const row = [key]

        uniqueNumTrips.forEach(num => {
            const entry = sortedValues.find(el => el.numTrips === num)
            row.push(entry ? entry.rep : 0)
        })
        chartData.push(row)
    })

    return chartData
}

export function getJourneyPassengers(journeys, filterBy) {

    const data = {}
    const chartData = [["filtro", i18next.t("commandBox.journeys.numberPassengersChart.label")]]

    journeys.forEach((elm) => {
        const filterByName = getFilterByName(elm, filterBy)

        if (data.hasOwnProperty(filterByName)) {
            data[filterByName] += elm.numPassengers
        } else {
            data[filterByName] = elm.numPassengers
        }

    })
    Object.entries(data).map(([key, val]) => {
        chartData.push([key, val])
    })
    return chartData

}

export function getJourneyKms(journeys, filterBy) {
    const data = {}
    let total = 0;
    const chartData = [["filtro", i18next.t("commandBox.journeys.journeysDistanceChart.label")]]

    journeys.forEach((elm) => {
        const filterByName = getFilterByName(elm, filterBy)
        total += elm.distance / 1000
        if (data.hasOwnProperty(filterByName)) {
            data[filterByName] += (elm.distance / 1000)
        } else {
            data[filterByName] = (elm.distance / 1000)
        }
    })

    Object.entries(data).map(([key, val]) => {
        chartData.push([key, (Math.round(val * 100) / 100)])
    })

    return { data: chartData, total: total }
}

export function getJourneyTime(journeys, filterBy) {
    const data = {}
    const chartData = [["filtro", i18next.t("commandBox.journeys.journeysDurationChart.label")]]

    journeys.forEach((elm) => {
        const filterByName = getFilterByName(elm, filterBy)

        if (data.hasOwnProperty(filterByName)) {
            data[filterByName] += elm.duration > 0 ? Math.floor(elm.duration / 60000) : 0
        } else {
            data[filterByName] = elm.duration > 0 ? Math.floor(elm.duration / 60000) : 0
        }

    })
    Object.entries(data).map(([key, val]) => {
        chartData.push([key, val])
    })
    return chartData
}

export function getTimeDistribution(data, filterBy) {
    const res = {}

    const templateTimeSlots = {
        '00:00': 0,
        '01:00': 0,
        '02:00': 0,
        '03:00': 0,
        '04:00': 0,
        '05:00': 0,
        '06:00': 0,
        '07:00': 0,
        '08:00': 0,
        '09:00': 0,
        '10:00': 0,
        '11:00': 0,
        '12:00': 0,
        '13:00': 0,
        '14:00': 0,
        '15:00': 0,
        '16:00': 0,
        '17:00': 0,
        '18:00': 0,
        '19:00': 0,
        '20:00': 0,
        '21:00': 0,
        '22:00': 0,
        '23:00': 0,
    }

    data.forEach((elm) => {
        const filterByName = getFilterByName(elm, filterBy)

        if (elm.actualPickUpTime == null) return
        const time = moment(elm.actualPickUpTime).format('HH:00')

        if (res.hasOwnProperty(filterByName)) {
            res[filterByName] = {
                ...res[filterByName],
                [time]: res[filterByName][time] + 1
            }
        }
        else {
            res[filterByName] = {
                ...templateTimeSlots,
                [time]: 1
            }
        }
    })

    return res
}

export function getTripsKPI(trips, filterBy) {
    let totalTrips = 0
    const data = {}
    const templateTimeSlots = {
        '00:00': 0,
        '01:00': 0,
        '02:00': 0,
        '03:00': 0,
        '04:00': 0,
        '05:00': 0,
        '06:00': 0,
        '07:00': 0,
        '08:00': 0,
        '09:00': 0,
        '10:00': 0,
        '11:00': 0,
        '12:00': 0,
        '13:00': 0,
        '14:00': 0,
        '15:00': 0,
        '16:00': 0,
        '17:00': 0,
        '18:00': 0,
        '19:00': 0,
        '20:00': 0,
        '21:00': 0,
        '22:00': 0,
        '23:00': 0,
    }

    trips.forEach((elm) => {
        const filterByName = getFilterByName(elm, filterBy)

        if (elm.actualPickUpTime == null) return
        const time = moment(elm.actualPickUpTime).format('HH:00')

        if (data.hasOwnProperty(filterByName)) {
            totalTrips += 1
            data[filterByName] = {
                trips: data[filterByName].trips + 1,
                timeSlots: {
                    ...data[filterByName].timeSlots,
                    [time]: data[filterByName].timeSlots[time] + 1
                },
                numberOfLateArrival: elm.isLateArrival
                    ? data[filterByName].numberOfLateArrival + 1
                    : data[filterByName].numberOfLateArrival,
                distance: data[filterByName].distance + elm.distance
            }
        } else {
            totalTrips += 1
            data[filterByName] = {
                trips: 1,
                timeSlots: {
                    ...templateTimeSlots,
                    [time]: 1
                },
                numberOfLateArrival: elm.isLateArrival ? 1 : 0,
                distance: elm.distance
            }
        }
    })

    return { totalTrips, data }
}

export function getTripsByUserKPI(trips, filterOptions) {
    const { groupedBy } = filterOptions

    let data = [["users"]]
    let users = {}
    let services = new Set()

    trips.forEach((elem) => {
        const filterByName = getFilterByName(elem, groupedBy)
        if (filterByName == null) return

        const { customerName } = elem

        if (users.hasOwnProperty(customerName) == false) {
            users[customerName] = { tripsByOption: {} }
        }

        filterByName.forEach(option => {
            const userTrips = users[customerName]?.tripsByOption
            const newValue = (userTrips[option] || 0) + 1
            userTrips[option] = newValue
            services.add(option)
        })
    })

    data[0].push(...Array.from(services))

    Object.keys(users).forEach(user => {
        let serviceMatrix = data[0].slice(1).map(service => users[user].tripsByOption[service] || 0);
        data.push([user, ...serviceMatrix]);
    });

    return data
}

export function getUsersGroupBy(users, filterBy) {
    const res = {}
    const chartData = [["filtro", i18next.t("commandBox.users.customersChart.label")]]
    let total = 0

    users.forEach((elm) => {
        const filterByName = getFilterByName(elm, filterBy)
        total += 1
        filterByName.forEach((option) => {
            if (res.hasOwnProperty(option)) {
                res[option] += 1
            } else {
                res[option] = 1
            }
        })
    })
    chartData.push(...Object.entries(res).map(([key, val]) => [key, val]))

    return { data: chartData, total }
}

export const getDriversGroupBy = (data, filterOptions) => {
    const { groupedBy, value } = filterOptions
    const totalDrivers = groupBy(data, groupedBy, value)

    let result = [[getChartName(groupedBy), i18next.t("commandBox.drivers.totalDriversChart.label")]]

    totalDrivers.forEach(el => {
        result.push([el[0], el[1]])
    })

    return result
}

export const getEconomicData = (trips, filterBy) => {
    let data = {}

    trips.forEach((elm) => {
        const filterByName = getFilterByName(elm, filterBy)

        if (data.hasOwnProperty(filterByName)) {
            data[filterByName].customerAmount += elm.price?.customerAmount || 0
            data[filterByName].holderAmount += elm.price?.holderAmount || 0
            data[filterByName].totalAmount += elm.price?.totalAmount || 0
        } else {
            data[filterByName] = {
                customerAmount: 0,
                holderAmount: 0,
                totalAmount: 0
            }
        }
    })

    return { data }
}

export const getVehicleCapacityKPI = (vehicleData, order = true) => {
    const data = []
    const chartData = [["filtro", i18next.t("commandBox.vehicles.vehicleCapacityChart.label")]]

    vehicleData.forEach((elm) => {
        data.push([elm.displayName, elm.capacity])
    })
    if (order) {
        data.sort((a, b) => b[1] - a[1])
    }

    chartData.push(...data)
    return chartData
}

function getFilterByName(data, filterBy) {
    let filterByName = []
    switch (filterBy) {
        case KPI_FILTER_OPTIONS.services:
            filterByName = data.services
            break
        case KPI_FILTER_OPTIONS.otps:
            filterByName = data.otps
            break
        case KPI_FILTER_OPTIONS.tenant:
            filterByName = data.tenants
            break
        case KPI_FILTER_OPTIONS.region:
            filterByName = data.regions
            break
        case KPI_FILTER_OPTIONS.municipalities:
            filterByName = data.municipalities
            break
        case "driverName":
            filterByName = data.driverName
            break
        case "vehicleDisplayName":
            filterByName = data.vehicleDisplayName
            break
        default:
            break
    }
    return filterByName
}

const getGroupedTrip = (data, origin, destination) => {
    let dataFilter = data.find(item => item.origin === origin && item.groupedTrips.find(trip => trip.destination === destination))?.groupedTrips.find(object => object.destination === destination)
    if (dataFilter == null) {
        return 0
    } else {
        return dataFilter.totalTrips
    }
}

export function getOriginDestinationMatrix(trips) {
    if (!trips?.length) return []

    let optionsDestinations = Array.from(new Set(Array.from(new Set(trips.map(trips => trips.groupedTrips.map(item => item.destination)))).flat(1)))
    let results = []

    trips.map((originItem, index) => {
        let parcialDestinations = []
        optionsDestinations.forEach(destination => {
            parcialDestinations[optionsDestinations.indexOf(destination)] = getGroupedTrip(trips, originItem.origin, destination)
        })
        if (index === 0) results.push(["origen/destino"].concat(optionsDestinations))

        results.push([originItem.origin].concat(parcialDestinations))

    })
    return results
}

function groupBy(data, groupedBy, value) {
    let total = []

    value.forEach(element => {

        let numTotal = 0
        data.forEach(user => {
            if (Array.isArray(user[groupedBy])) {
                if (user[groupedBy].find(el => el == element) != undefined) {
                    numTotal++
                }
            } else {
                if (user[groupedBy] == element) {
                    numTotal++
                }
            }

        })
        if (numTotal > 0 && total.find(el => el == element) == undefined) {
            if (value.length < 7 && groupedBy !== 'tenants') {
                total.push([
                    { v: element + '\n', f: element },
                    { v: numTotal, f: numTotal },
                ])
            } else {
                total.push([
                    { v: '\n', f: element },
                    { v: numTotal, f: numTotal },
                ])
            }

        }

    })
    return total
}

export function getWheelJourneyDriversTable({ driverRequest }) {
    let driverData = {}

    driverRequest.forEach(driver => {
        if (driverData.hasOwnProperty(driver.fullName)) {
            driverData[driver.fullName].acceptedServices += driver.currentStatus === 20 ? 1 : 0
            driverData[driver.fullName].rejectedServices += driver.currentStatus === 30 ? 1 : 0
        } else {
            driverData[driver.fullName] = {
                acceptedServices: driver.currentStatus === 20 ? 1 : 0,
                rejectedServices: driver.currentStatus === 30 ? 1 : 0,
            }
        }
    })

    return driverData
}

export function getDriverTable({ journeys, confirmTrips }) {
    let driverData = {}

    journeys.forEach(trip => {
        if (driverData.hasOwnProperty(trip.driverName)) {
            driverData[trip.driverName].journeys += 1
        } else {
            driverData[trip.driverName] = {
                journeys: 1,
                totalTrips: 0,
                price: 0,
            }
        }
    })

    confirmTrips.forEach(trip => {
        if (driverData.hasOwnProperty(trip.driverName)) {
            driverData[trip.driverName].totalTrips += 1
            driverData[trip.driverName].price += trip.price ? trip.price.totalAmount : 0
            driverData[trip.driverName].punctuality += trip.isLateArrival ? 0 : 1
        } else {
            driverData[trip.driverName] = {
                totalTrips: 1,
                price: trip.price ? trip.price.totalAmount : 0,
                punctuality: trip.isLateArrival ? 0 : 1,
                journeys: 0,
            }
        }
    })

    return driverData
}