const LANGS = {
    cat: 'ca',
    es: 'es',
    en: 'en'
}
export function getRoleNameTranslated(role, lang) {
    if (role == null || lang == null) return

    switch (lang) {
        case LANGS.cat:
            return role.nameCa
        case LANGS.en:
            return role.nameEn
        case LANGS.es:
            return role.nameEs
        default:
            return role.nameEs || ''
    }
}

export function getRoleDescriptionTranslated(role, lang) {
    if (role == null || lang == null) return

    switch (lang) {
        case LANGS.cat:
            return role.descriptionCa
        case LANGS.en:
            return role.descriptionEn
        case LANGS.es:
            return role.descriptionEs
        default:
            return role.descriptionEs || ''
    }
}