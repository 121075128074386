import { Box, FormControl, FormHelperText, Grid, TextField, Tooltip, Typography } from '@material-ui/core'
import HelpIcon from '@material-ui/icons/Help'
import React from 'react'

const IconWithTooltip = ({ text }) => {
    if (text == null)
        return <></>
    return (
        <Tooltip title={text}>
            <HelpIcon fontSize='small' style={{ marginLeft: 10, color: '#0000008a' }} />
        </Tooltip>
    )
}


export const InputNumberWithLabel = ({ value, id, name, onChange, disabled, error, helperText, min, max, label, readOnly, step, tooltip = null, mdI = undefined, xlI = undefined, mdL = undefined, xlL = undefined }) => {
    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item md={mdI ?? 3} xl={xlI ?? 2} sm={6}>
                <FormControl fullWidth>
                    <TextField
                        id={id ?? undefined}
                        InputLabelProps={{
                            style: { pointerEvents: 'auto' },
                            shrink: true,
                        }}
                        value={value ?? ""}
                        variant="outlined"
                        size='small'
                        name={name}
                        fullWidth
                        type={'number'}
                        onChange={onChange}
                        disabled={disabled ?? false}
                        error={error}
                        InputProps={{
                            inputProps: {
                                readOnly: readOnly,
                                min: min ?? undefined,
                                max: max ?? undefined,
                                step: step ?? undefined,
                            }
                        }}
                    />
                </FormControl>
            </Grid>
            <Grid item md={mdL ?? 9} xl={xlL ?? 10}>
                <Box
                    display="flex"
                    alignItems="center">
                    <Typography variant='body1'>{label}</Typography>
                    <IconWithTooltip text={tooltip} />
                </Box>
                <FormHelperText error>{helperText}</FormHelperText>
            </Grid>
        </Grid>
    )
}