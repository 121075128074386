import React from 'react'
import { history } from '../../helpers/history'
import AlertDialog from '../alert-dialog'
import { Tooltip, Button } from '@material-ui/core'
import MaterialTableExtend from '../material-table'
import Spinner from '../shared/spinner'
import { allowAccessRole } from '../../helpers/roles'
import InfoIcon from '@material-ui/icons/Info'
import { CsvBuilder } from 'filefy'
import { withTheme } from '@material-ui/core/styles';
import { MODULES, OPERATIONS_ALLOWED } from '../roles/constants'

class DriverView extends React.Component {
    constructor(props) {
        super()
        this.state = {
            openModalDelete: false,
            openModalDisable: false,
            openModalEnable: false,
            openModalValidate: false,
            item: {},
            user: {},
            languageSelected: '',
            openResetPasswordModal: false,
            filterOTP: [],
            filterValidate: [],
        }
        this.onClickCancelModal = this.onClickCancelModal.bind(this)
        this.onClickAcceptModalDelete = this.onClickAcceptModalDelete.bind(this)
        this.onClickAcceptModalDisable =
            this.onClickAcceptModalDisable.bind(this)
        this.onClickAcceptModalEnable = this.onClickAcceptModalEnable.bind(this)
        this.onClickAcceptModalValidate =
            this.onClickAcceptModalValidate.bind(this)
        this.setValue = this.setValue.bind(this)
    }

    addOnClick() {
        history.push('/drivers/create')
    }

    onClickCancelModal() {
        this.setState({
            openModalDelete: false,
            openModalDisable: false,
            openModalEnable: false,
            openModalValidate: false,
            openResetPasswordModal: false,
        })
    }

    onClickOpenResetPasswordModal(data) {
        this.setState({ openResetPasswordModal: true, user: data })
    }

    onClickAcceptModalDelete() {
        this.setState({ openModalDelete: false })
        this.props.remove(this.state.item.id)
    }

    onClickAcceptModalDisable() {
        this.setState({ openModalDisable: false })
        this.props.disable(this.state.item.id)
    }

    onClickAcceptModalEnable() {
        this.setState({ openModalEnable: false })
        this.props.enable(this.state.item.id)
    }

    onClickAcceptModalValidate() {
        this.setState({ openModalValidate: false })
        this.props.validate(this.state.item.id)
    }

    onClickOpenModalDelete(data) {
        this.setState({ openModalDelete: true, item: data.driver })
    }

    onClickOpenModalEnable(data) {
        if (!data.driver.isActive)
            this.setState({ openModalEnable: true, item: data.driver })
    }

    onClickOpenModalDisable(data) {
        if (data.driver.isActive)
            this.setState({ openModalDisable: true, item: data.driver })
    }

    onClickOpenModalValidate(data) {
        if (!data.driver.isValidated)
            this.setState({ openModalValidate: true, item: data.driver })
    }

    onClickAcceptModalResetPassword = () => {
        this.props.sendResetPasswordEmail(this.state.user.driver.email)
        this.setState({ openResetPasswordModal: false })
    }

    handleChangeLanguage = event => {
        this.setState({ languageSelected: event.target.value })
    }

    onClickCommentsDriver(data) {
        history.push('/drivers/observations/' + data.driver.id)
    }

    exportCsv(columns, data, table) {
        // Turn headers into array of strings
        const headerRow = columns.map(col => {
            if (typeof col.title === 'object') {
                /** I am not sure what props the Translate component exposes
                 *  but I would need to change `text` in `col.title.props.text`
                 *  to whatever prop I need
                 */
                return col.title.props.text
            }
            return col.title
        })

        const headerFilter = columns.map(col => {
            if (typeof col.field === 'object') {
                return col.field.props.text
            }
            return col.field
        })

        // Turn data into an array of string arrays, without the `tableData` prop
        const dataRows = data.map(({ tableData, ...row }) => {
            let obj = {}
            let result = []
            let a = ''
            for (let i = 0; i < headerFilter.length; i++) {
                a = headerFilter[i].replace('driver.', '')
                obj[a] = row.driver[a]
            }

            Object.entries(obj).forEach(([key, value]) => {
                result.push(value)
            })
            return result
        })

        /** Aggregate header data and 'body' data
         *  Mirror default export behavior by joining data via
         *  the delimeter specified within material table (by default comma delimited)
         */
        const { exportDelimiter } = table.props.options
        const delimiter = exportDelimiter ? exportDelimiter : ';'

        /** This mirrors the default export behavior where the
         *  exported file name is the table title.
         */
        const csvFileName = table.props.title

        // Allow user to download file as .csv
        // eslint-disable-next-line no-unused-vars
        const builder = new CsvBuilder(csvFileName + '.csv')
            .setDelimeter(delimiter)
            .setColumns(headerRow)
            .addRows(dataRows)
            .exportFile()
    }

    setValue(prop, values) {
        switch (true) {
            case prop == 'OTP':
                this.setState({
                    filterOTP: values,
                })
                break
            case prop == 'validate':
                this.setState({
                    filterValidate: values,
                })
                break
        }
        const filters = {
            driver: { otpName: prop == 'OTP' ? values : this.state.filterOTP },
            isValidated:
                prop == 'validate' ? values : this.state.filterValidate,
        }
        this.props.getAll(filters)
    }

    onClickDetail(driver) {
        history.push('/drivers/' + driver.id)
    }

    render() {
        const { driverReducer, t } = this.props
        const Wrapper = () => (
            <React.Fragment>
                {allowAccessRole(MODULES.Drivers, OPERATIONS_ALLOWED.manage) && (
                    <Button
                        onClick={this.addOnClick}
                        variant="contained"
                        style={{ marginLeft: '24px' }}
                        color="primary"
                        size="large">
                        {t('drivers.page.wrapper.add')}
                    </Button>
                )}
            </React.Fragment>
        )
        const Content = () => (
            <React.Fragment>
                <label style={{ marginTop: -15, display: 'flex' }}>
                    {t('operators.page.alert_dialog.sendInformation')}{' '}
                    {this.state.user.driver.email}
                </label>
            </React.Fragment>
        )
        return (
            <React.Fragment>
                {!driverReducer.pending ? (
                    <React.Fragment>
                        <MaterialTableExtend
                            buttons={<Wrapper />}
                            setCurrentPage={this.props.setCurrentPage}
                            currentPage={driverReducer.currentPage}
                            title={t('drivers.page.title')}
                            onFilterChange={(filter) => {
                                console.log(filter)
                                this.props.saveDriverFilters(filter)
                            }}
                            columns={[
                                {
                                    title: t('drivers.page.columns.names'),
                                    field: 'driver.fullName',
                                    defaultFilter: driverReducer.driversFilters.filter((col) => col.column.field === "driver.fullName")[0]?.value ?? null,
                                    render: rowData => {
                                        return rowData &&
                                            !rowData.driver.hasSetPassword &&
                                            rowData.driver.email !== '' ? (
                                            <React.Fragment>
                                                <Tooltip
                                                    title={t('drivers.page.columns.tooltipHasSetPassword')}
                                                    arrow
                                                >
                                                    <InfoIcon
                                                        color="primary"
                                                        style={{
                                                            fontSize: 15,
                                                            marginRight: 2,
                                                        }}
                                                    />
                                                </Tooltip>
                                                <label>
                                                    {rowData.driver.fullName}
                                                </label>
                                            </React.Fragment>
                                        ) : (
                                            rowData.driver.fullName
                                        )
                                    },
                                },
                                {
                                    title: t('drivers.page.columns.document'),
                                    field: 'driver.identityDocumentCode',
                                    defaultFilter: driverReducer.driversFilters.filter((col) => col.column.field == 'driver.identityDocumentCode')[0]?.value ?? null,
                                },
                                {
                                    title: t('drivers.page.columns.otp'),
                                    field: 'driver.otpName',
                                    defaultFilter: driverReducer.driversFilters.filter((col) => col.column.field == 'driver.otpName')[0]?.value ?? null,
                                },
                                {
                                    title: 'Id',
                                    field: 'driver.id',
                                    hidden: true,
                                },
                                {
                                    title: t('drivers.page.columns.phone'),
                                    field: 'driver.phoneNumber',
                                    defaultFilter: driverReducer.driversFilters.filter((col) => col.column.field == 'driver.phoneNumber')[0]?.value ?? null,
                                },
                                {
                                    title: t('drivers.page.columns.email'),
                                    field: 'driver.email',
                                    defaultFilter: driverReducer.driversFilters.filter((col) => col.column.field == 'driver.email')[0]?.value ?? null,
                                },
                                {
                                    title: t('drivers.page.columns.validated'),
                                    field: 'driver.isValidated',
                                    defaultFilter: driverReducer.driversFilters.filter((col) => col.column.field == 'driver.isValidated')[0]?.value ?? null,
                                    customFilterAndSearch: (term, rowData) =>
                                        rowData.driver.isValidated
                                            ? t(
                                                'services_comp.page.yes',
                                            ).includes(term.toUpperCase())
                                            : t(
                                                'services_comp.page.no',
                                            ).includes(term.toUpperCase()),
                                    render: rowData => {
                                        return rowData &&
                                            rowData.driver.isValidated
                                            ? t('services_comp.page.yes')
                                            : t('services_comp.page.no')
                                    },
                                },
                            ]}
                            data={driverReducer.results}
                            actionsColumnIndex={10}
                            actions={[
                                {
                                    icon: 'view_list',
                                    tooltip: t('drivers.page.actions.detail'),
                                    onClick: (event, rowData) =>
                                        this.onClickDetail(rowData.driver),
                                },
                                rowData => ({
                                    icon: 'toggle_off_icon',
                                    iconProps: { style: { color: 'black' } },
                                    tooltip: t('drivers.page.actions.enable'),
                                    onClick: (event, rowData) =>
                                        this.onClickOpenModalEnable(rowData),
                                    hidden:
                                        rowData.driver.isActive ||
                                        !allowAccessRole(MODULES.Drivers, OPERATIONS_ALLOWED.manage),
                                }),
                                rowData => ({
                                    icon: 'toggle_on_icon',
                                    iconProps: { style: { color: this.props.theme.palette.primary.main } },
                                    tooltip: !rowData.isWorking ? t('drivers.page.actions.disable') : t('drivers.page.actions.isWorking'),
                                    disabled: rowData.isWorking ? true : false,
                                    onClick: (event, rowData) =>
                                        this.onClickOpenModalDisable(rowData),
                                    hidden:
                                        !rowData.driver.isActive ||
                                        !allowAccessRole(MODULES.Drivers, OPERATIONS_ALLOWED.manage),
                                }),
                                rowData => ({
                                    icon: 'check_box',
                                    iconProps: { style: { color: this.props.theme.palette.primary.main } },
                                    tooltip: t('drivers.page.actions.validate'),
                                    //onClick: (event, rowData) => this.onClickOpenModalValidate(rowData),
                                    hidden: !rowData.driver.isValidated,
                                }),
                                rowData => ({
                                    icon: 'check_box_outline_blank',
                                    //iconProps: { style: { color: "green" } },
                                    tooltip: t('drivers.page.actions.validate'),
                                    onClick: (event, rowData) =>
                                        this.onClickOpenModalValidate(rowData),
                                    hidden:
                                        rowData.driver.isValidated ||
                                        !allowAccessRole(MODULES.Drivers, OPERATIONS_ALLOWED.manage),

                                }),
                                rowData => ({
                                    icon: 'link',
                                    tooltip: t('customers.page.actions.linkRecovery'),
                                    onClick: (event, rowData) =>
                                        this.props.getResetPasswordLink(
                                            rowData.driver.email
                                        ),
                                    hidden: rowData.driver.email === '' || !allowAccessRole(MODULES.Drivers, OPERATIONS_ALLOWED.manage),
                                }),
                                rowData => ({
                                    icon: 'email_rounded',
                                    tooltip: t('drivers.page.actions.sendResetPasswordEmail'),
                                    onClick: (event, rowData) =>
                                        this.onClickOpenResetPasswordModal(rowData),
                                    hidden: rowData.driver.email === '' || !allowAccessRole(MODULES.Drivers, OPERATIONS_ALLOWED.manage),
                                }),
                                rowData => ({
                                    icon: 'note_outlined',
                                    iconProps: {
                                        style: {
                                            color: rowData.driver.hasActivities
                                                ? this.props.theme.palette.primary.main
                                                : '',
                                        },
                                    },
                                    tooltip: t('drivers.page.actions.observacions'),
                                    onClick: (event, rowData) =>
                                        this.onClickCommentsDriver(rowData),
                                }),
                                rowData => (
                                    {
                                        icon: 'delete',
                                        tooltip: !rowData.isWorking ? t('drivers.page.actions.delete') : t('drivers.page.actions.isWorking'),
                                        disabled: rowData.isWorking ? true : false,
                                        onClick: (event, rowData) =>
                                            this.onClickOpenModalDelete(rowData),
                                        hidden: rowData.driver.email === '' || !allowAccessRole(MODULES.Drivers, OPERATIONS_ALLOWED.manage),
                                    })

                            ]}
                            exportCsv={this.exportCsv}
                        />
                        <AlertDialog
                            open={this.state.openModalDelete}
                            title={`${t("drivers.page.alert_dialog.titleDelete")} (${this.state.item.fullName})`}
                            onClickAccept={this.onClickAcceptModalDelete}
                            onClickCancel={this.onClickCancelModal}
                        />
                        <AlertDialog
                            open={this.state.openModalDisable}
                            title={t('drivers.page.alert_dialog.titleDisable')}
                            onClickAccept={this.onClickAcceptModalDisable}
                            onClickCancel={this.onClickCancelModal}
                        />
                        <AlertDialog
                            open={this.state.openModalEnable}
                            title={t('drivers.page.alert_dialog.titleEnable')}
                            onClickAccept={this.onClickAcceptModalEnable}
                            onClickCancel={this.onClickCancelModal}
                        />
                        <AlertDialog
                            open={this.state.openModalValidate}
                            title={t('drivers.page.alert_dialog.titleValidate')}
                            onClickAccept={this.onClickAcceptModalValidate}
                            onClickCancel={this.onClickCancelModal}
                        />
                        <AlertDialog
                            open={this.state.openResetPasswordModal}
                            title={t('operators.page.alert_dialog.titleResetPassword')}
                            onClickAccept={this.onClickAcceptModalResetPassword}
                            onClickCancel={this.onClickCancelModal}
                            content={Content}
                        />
                    </React.Fragment>
                ) : (
                    <Spinner loading={driverReducer.pending} />
                )}
            </React.Fragment>
        )
    }
}

export default withTheme(DriverView)
