import { areasConstants } from '../../constants/areas_constants';

let initialState = {
    pending: false,
    result: [],
    municipalities: [],
    regions: []
};

export function areaReducer(state = initialState, action) {
    switch (action.type) {
        case areasConstants.DETAIL_REQUEST:
            return {
                ...state,
                pending: true
            };
        case areasConstants.DETAIL_SUCCESS:
            return {
                ...state,
                area: action.area,
                pending: false
            };
        case areasConstants.DETAIL_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            };
        case areasConstants.GETALL_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case areasConstants.GETALL_SUCCESS:
            return {
                ...state,
                pending: false,
                results: action.results
            };
        case areasConstants.GETALL_FAILURE:
            return {
                ...state,
                pending: false,
                results: [],
                error: action.error
            };
        case areasConstants.ADD_REQUEST:
            return {
                ...state,
                pending: true
            };
        case areasConstants.ADD_SUCCESS:
            return {
                ...state,
                pending: false,
                area: action.area
            };
        case areasConstants.ADD_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            };
        case areasConstants.DELETE_REQUEST:
            return {
                ...state,
                pending: true
            };
        case areasConstants.DELETE_SUCCESS:
            return {
                ...state,
                pending: false,
                results: state.results.filter(x => x.areaResponse.id !== action.id)
            };
        case areasConstants.DELETE_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            };
        case areasConstants.GET_ALL_MUNICIPALITIES_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case areasConstants.GET_ALL_MUNICIPALITIES_SUCCESS:
            return {
                ...state,
                pending: false,
                municipalities: action.results
            }
        case areasConstants.GET_ALL_MUNICIPALITIES_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case areasConstants.GET_ALL_REGIONS_REQUEST:
            return {
                ...state,
                pending: true
            }
        case areasConstants.GET_ALL_REGIONS_SUCCESS:
            return {
                ...state,
                pending: false,
                regions: action.results
            }
        case areasConstants.GET_ALL_REGIONS_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        default:
            return state;
    }
}