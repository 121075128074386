import React, { useState } from 'react'
import {
    FormControl,
    Grid,
    TextField,
    Tooltip,
    IconButton,
} from '@material-ui/core'
import { Add, Remove } from '@material-ui/icons'
import { factory } from '../../../helpers/factory'
import Brightness2Icon from '@material-ui/icons/Brightness2'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import i18next from 'i18next'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Fragment } from 'react'

const DateVehicle = props => {
    const {
        t,
        checkUpdates,
        serviceReducer,
        value,
        state,
        driverReducer,
        checkDriver,
        checkService,
    } = props

    const [radioValue, setradioValue] = useState('')

    const handleChange = event => {
        setradioValue(event.target.value)
        checkUpdates(null, event.target.value)
    }

    const onChangeWeekDays = body => {
        checkUpdates(body)
        setradioValue('')
    }

    const onChangeDriver = body => {
        checkDriver(body)
    }

    const onChangeService = body => {
        checkService(body)
    }

    return (
        <React.Fragment>
            {/* <Grid item md={1} implementation="css" smDown component={Hidden} /> */}
            <Grid item md={2} xs={12} sm={6}>
                <FormControl fullWidth>
                    <TextField
                        label={t('vehicles.form.page.earliestStart')}
                        value={props.value.earliestStart || ''}
                        variant="outlined"
                        name={'earliestStart_' + props.index}
                        fullWidth
                        type={'time'}
                        InputLabelProps={{ shrink: true }}
                        onChange={props.onChange}
                        helperText={
                            state.errors.shiftDays[props.index].earliestStart
                                .message
                        }
                        error={
                            state.errors.shiftDays[props.index].earliestStart
                                .message.length === 0
                                ? false
                                : true
                        }
                        inputProps={{
                            readOnly: props.disabled,
                        }}
                    />
                </FormControl>
            </Grid>
            <Grid item md={2} xs={12} sm={6}>
                <FormControl fullWidth>
                    <TextField
                        label={t('vehicles.form.page.latestArrival')}
                        value={props.value.latestArrival || ''}
                        variant="outlined"
                        name={'latestArrival_' + props.index}
                        fullWidth
                        type={'time'}
                        InputLabelProps={{ shrink: true }}
                        onChange={props.onChange}
                        helperText={
                            state.errors.shiftDays[props.index].latestArrival
                                .message
                        }
                        error={
                            state.errors.shiftDays[props.index].latestArrival
                                .message.length === 0
                                ? false
                                : true
                        }
                        inputProps={{
                            readOnly: props.disabled,
                        }}
                    />
                </FormControl>
            </Grid>

            <Grid item md={3} xs={12} sm={9}>
                {serviceReducer && serviceReducer.weekDays.length > 0 && (
                    <Fragment>
                        <Autocomplete
                            options={serviceReducer && serviceReducer.weekDays}
                            onChange={onChangeWeekDays}
                            freeSolo
                            multiple
                            value={value.weekDays}
                            onChange={(event, newValue) => {
                                onChangeWeekDays(newValue)
                            }}
                            getOptionSelected={(option, value) =>
                                option.id == value
                            }
                            getOptionLabel={option =>
                                option && option.name
                                    ? option && option.name
                                    : serviceReducer.weekDays.find(
                                        x => x.id == option,
                                    ).name
                            }
                            disabled={props.disabled}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    variant={'outlined'}
                                    label={t(
                                        'services_comp.calendar.workDays.input',
                                    )}
                                    helperText={
                                        state.errors.shiftDays[props.index]
                                            .weekDays.message
                                    }
                                    name="brand"
                                    error={
                                        state.errors.shiftDays[props.index]
                                            .weekDays.message.length === 0
                                            ? false
                                            : true
                                    }
                                />
                            )}
                        />

                        <FormControl component="fieldset">
                            <RadioGroup
                                row
                                value={radioValue}
                                onChange={handleChange}
                            >
                                <FormControlLabel
                                    value={'2'}
                                    control={
                                        <Radio
                                            checked={
                                                JSON.stringify(
                                                    value.weekDays,
                                                ) ==
                                                JSON.stringify([
                                                    1, 2, 3, 4, 5, 6, 0,
                                                ])
                                            }
                                            color="primary"
                                        />
                                    }
                                    label={t('vehicles.form.page.allDays')}
                                    disabled={props.disabled}
                                />
                                <FormControlLabel
                                    value={'3'}
                                    control={
                                        <Radio
                                            checked={
                                                JSON.stringify(
                                                    value.weekDays,
                                                ) ==
                                                JSON.stringify([1, 2, 3, 4, 5])
                                            }
                                            color="primary"
                                        />
                                    }
                                    label={t('vehicles.form.page.workDays')}
                                    disabled={props.disabled}
                                />
                                <FormControlLabel
                                    value={'1'}
                                    control={
                                        <Radio
                                            checked={
                                                JSON.stringify(
                                                    value.weekDays,
                                                ) == JSON.stringify([6, 0])
                                            }
                                            color="primary"
                                        />
                                    }
                                    label={t('vehicles.form.page.weekend')}
                                    disabled={props.disabled}
                                />
                            </RadioGroup>
                        </FormControl>
                    </Fragment>
                )}
            </Grid>
            <Grid item md={2} xs={12} sm={9}>
                <FormControl fullWidth>
                    <Autocomplete
                        id="driverId"
                        value={value.driverId}
                        onChange={(event, newValue) => {
                            onChangeDriver(newValue)
                        }}
                        options={driverReducer && driverReducer.driverFullName}
                        freeSolo
                        getOptionSelected={(option, value) =>
                            option.id == value
                        }
                        getOptionLabel={option =>
                            option && option.fullName
                                ? option && option.fullName
                                : driverReducer.driverFullName.find(
                                    x => x.id == option,
                                )
                                    ? driverReducer.driverFullName.find(
                                        x => x.id == option,
                                    ).fullName
                                    : i18next.t('incidents.details.none')
                        }
                        disabled={props.disabled}
                        renderOption={option =>
                            option.id == null ? (
                                <em>{option.fullName}</em>
                            ) : (
                                option.fullName
                            )
                        }
                        renderInput={params => (
                            <TextField
                                {...params}
                                variant={'outlined'}
                                label={t('vehicles.form.page.driver')}
                                name="driverId"
                                helperText={
                                    state.errors.shiftDays[props.index]
                                        .driverId.message
                                }
                                error={
                                    state.errors.shiftDays[props.index]
                                        .driverId.message.length === 0
                                        ? false
                                        : true
                                }
                            />
                        )}
                    />
                </FormControl>
            </Grid>
            <Grid item md={2} xs={12} sm={9}>
                <FormControl fullWidth>
                    <Autocomplete
                        id="serviceId"
                        value={value.serviceId}
                        onChange={(event, newValue) => {
                            onChangeService(newValue)
                        }}
                        options={serviceReducer && serviceReducer.results}
                        freeSolo
                        getOptionSelected={(option, value) =>
                            option.id == value
                        }
                        getOptionLabel={option =>
                            option && option.name
                                ? option && option.name
                                : serviceReducer.results.find(
                                    x => x.id == option,
                                )
                                    ? serviceReducer.results.find(
                                        x => x.id == option,
                                    ).name
                                    : i18next.t('incidents.details.none')
                        }
                        disabled={props.disabled}
                        renderOption={option =>
                            option.id == null ? (
                                <em>{option.name}</em>
                            ) : (
                                option.name
                            )
                        }
                        renderInput={params => (
                            <TextField
                                {...params}
                                variant={'outlined'}
                                label={t('vehicles.form.page.service')}
                                name="serviceId"
                                helperText={
                                    state.errors.shiftDays[props.index]
                                        .serviceId.message
                                }
                                error={
                                    state.errors.shiftDays[props.index]
                                        .serviceId.message.length === 0
                                        ? false
                                        : true
                                }
                            />
                        )}
                    />
                </FormControl>
            </Grid>
            <Grid
                item
                md={1}
                xs={6}
                sm={6}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {!props.detail &&
                    (props.index + 1 == props.length ? (
                        <React.Fragment>
                            {props.length > 1 && (
                                <IconButton>
                                    <Remove
                                        onClick={() => props.remove(props.index)}
                                    />
                                </IconButton>
                            )}
                            <IconButton>
                                <Add onClick={() => props.add()} />
                            </IconButton>
                        </React.Fragment>
                    ) : (
                        <IconButton>
                            <Remove onClick={() => props.remove(props.index)} />
                        </IconButton>
                    ))}
                {factory.checkTimesAfterMidth(
                    props.value.earliestStart,
                    props.value.latestArrival,
                ) && (
                        <Tooltip title={t('vehicles.form.page.nightTime')}>
                            <Brightness2Icon />
                        </Tooltip>
                    )}
            </Grid>


        </React.Fragment>
    )
}

export default DateVehicle
