import React from 'react';
import ServiceLinesView from './page';
import Dashboard from '../../dashboard/index';
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { serviceActions } from '../../../redux/actions/services_actions'
import Spinner from '../../shared/spinner';
import { vehiclesActions } from '../../../redux/actions/vehicles_actions';
import { SERVICES_TYPE } from '../../../constants/types';
import { regularLine_actions } from '../../../redux/actions/regularLine_actions';
import { allowAccessRole } from '../../../helpers/roles';
import { MODULES, OPERATIONS_ALLOWED } from '../../roles/constants';

class ServiceLines extends React.Component {

    componentDidMount() {
        allowAccessRole(MODULES.Services, OPERATIONS_ALLOWED.manage)
        const params = this.props.match.params
        const type = params.serviceType
        const id = params.serviceId
        const lineId = params.lineId ?? ''
        this.props.getByServiceId(id)
        if (type == SERVICES_TYPE.regularService) {
            this.props.getServiceLine(id)
            this.props.getServiceLineStopsAvailable(id, 10)
            this.props.getServiceLineStopsAvailable(id, 20)
        } else {
            this.props.getServiceLineStopsAvailableForServiceWithSchedule(id, lineId)
        }
        if (lineId)
            this.props.getServiceLineWithSchedule(lineId)

    }

    componentWillUnmount() {
        this.props.cleanServiceLine()
    }

    render() {
        const pendingS = this.props.serviceReducer.pending
        const pendingV = this.props.vehicleReducer.pending
        const params = this.props.match.params
        const id = params.serviceId
        const type = params.serviceType
        const lineId = params.lineId ?? ''
        const { serviceReducer } = this.props
        const isNotNewLine = (serviceReducer.serviceLine != null && lineId != null)
        return (
            <Dashboard
                component={
                    !pendingS && !pendingV && isNotNewLine ?
                        <ServiceLinesView
                            {...this.props}
                            id={id}
                            lineId={lineId}
                            type={type}
                        />
                        : <Spinner
                            loading={pendingS || pendingV}
                        />
                }
            />
        );
    }
}

function mapState(state) {
    const { serviceReducer, vehicleReducer } = state
    return { serviceReducer, vehicleReducer }
}

const actionCreators = {
    getServiceLine: serviceActions.getServiceLine,
    getServiceLineStopsAvailable: serviceActions.getServiceLineStopsAvailable,
    getByServiceId: vehiclesActions.getByServiceId,
    setServiceLine: serviceActions.setServiceLine,
    cleanServiceLine: serviceActions.cleanServiceLine,
    getServiceLineStopsAvailableForServiceWithSchedule: regularLine_actions.getServiceLineStopsAvailable,
    getServiceLineWithSchedule: regularLine_actions.getServiceLine,
    setRegularLine: regularLine_actions.setServiceLine
}


export default compose(withTranslation('common'), connect(mapState, actionCreators))(ServiceLines)