import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import utils from '../../../helpers/validations'

class ModalConfirmZone extends React.Component {

    constructor(props) {
        super()
        this.state = {
            errors: {
                name: { result: false, message: '' }
            }
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleClick = this.handleClick.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
    }

    validateForm() {
        let errors = this.state.errors;
        errors.name = utils.required(this.props.zoneName)
        this.setState({ errors })
    }

    isFormValid() {
        let valid = true;
        let properties = Object.getOwnPropertyNames(this.state.errors)
        properties.forEach(element => {
            if (!this.state.errors[element].result)
                valid = false
        });
        return valid
    }

    handleCancel() {
        let errors = this.state.errors;
        errors.name = { result: false, message: '' }
        this.setState({ errors })
        this.props.onClose()
        this.props.cleanSelection()
        this.props.clear()
    }

    handleClick() {
        this.validateForm()
        if (this.isFormValid()) {
            this.props.onConfirm()
        }
    }

    handleChange(event) {
        let { name, value } = event.target;
        this.props.onChangeName(value)
    }

    render() {
        const { t } = this.props
        return (
            <Dialog open={this.props.open} onClose={this.props.handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{this.props.isUpdate ? t('shared.modalConfirm.titleEdit') : t('shared.modalConfirm.titleCreate')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('shared.modalConfirm.text')}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        value={this.props.zoneName}
                        onChange={this.handleChange}
                        margin="dense"
                        helperText={this.state.errors.name.message}
                        error={this.state.errors.name.message.length === 0 ? false : true}
                        id="name"
                        label={t('shared.modalConfirm.name')}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCancel} color="primary">
                        {t('shared.modalConfirm.cancel')}
                    </Button>
                    <Button onClick={this.handleClick} color="primary">
                        {t('shared.modalConfirm.accept')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default ModalConfirmZone;