import { Typography, withStyles } from "@material-ui/core";
import React from 'react'


const styles = theme => ({
    section: {
        paddingTop: theme.spacing(3),

    },
    sectionTitle: {
        fontWeight: 600,
        paddingTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    }
});

const SectionStyled = ({ classes, children }) => {
    return (
        <section className={classes.section} >
            {children}
        </section>
    )
}

export default withStyles(styles)(SectionStyled);

export const TitleSection = withStyles(styles)(({ classes, children }) => {
    return (
        <Typography className={classes.sectionTitle} variant='h6' component='h4' >{children}</Typography>
    )
})