import { chatConstants } from "../../constants/chat_constants";

let initialState = {
  conversations: [],
  messages: [],
  newMessages: false,
  unreadMsg: 0,
  pendingGetAllMessagesFromConversation: false,
  currentConversation: { id: null },
};

export function chatReducer(state = initialState, action) {
  switch (action.type) {
    case chatConstants.SET_CURRENT_CONVERSATION:
      return {
        ...state,
        currentConversation: action.selected,
      };

    case chatConstants.GET_NUMBER_OF_UNREAD_MESSAGES_BY_SENDER_ID_SUCCESS:
      return {
        ...state,
        unreadMsg: action.results,
      };

    case chatConstants.NEW_MESSAGE_FLAG:
      return {
        ...state,
        newMessages: action.bool,
      };
    case chatConstants.GET_ALL_MESSAGES_FROM_CONVERSATION_REQUEST:
      return {
        ...state,
        pendingGetAllMessagesFromConversation: true,
        // messages: [],
      };
    case chatConstants.GET_ALL_MESSAGES_FROM_CONVERSATION_SUCCESS:
      return {
        ...state,
        messages: action.results,
        pendingGetAllMessagesFromConversation: false,
      };
    case chatConstants.GET_ALL_MESSAGES_FROM_CONVERSATION_FAILURE:
      return {
        ...state,
        pendingGetAllMessagesFromConversation: false,
        messages: [],
        error: action.error,
      };

    case chatConstants.GET_ALL_CONVERSATIONS_BY_SENDER_ID_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case chatConstants.GET_ALL_CONVERSATIONS_BY_SENDER_ID_SUCCESS:
      return {
        ...state,
        conversations: action.results,
        pending: false,
      };
    case chatConstants.GET_ALL_CONVERSATIONS_BY_SENDER_ID_FAILURE:
      return {
        ...state,
        pending: false,
        conversations: [],
        error: action.error,
      };

    default:
      return state;
  }
}
