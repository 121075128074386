import React from 'react'
import { Chart } from 'react-google-charts'
import PropTypes from 'prop-types'
import { FilterOptions, options, DownloadChart, filterDataByGroup } from '../../../customFunctions'
import moment from 'moment'
import i18next from 'i18next'


/**
 * CASES
 *  groupedBy: municipalities & value != null
 *  groupedBy: regions & value != null
 *  groupedBy: otpId & value != null
 *  groupedBy: tenant & value != null
 *  filterByDate: startDate != null & finishDate != null & finishDate > startDate
 */

const TimeServicesByDriver = ({ data, title, groupedBy = 'municipalities', value = [], filterByDate = true, startDate = '2022-01-01', finishDate = '2022-02-01', timeUnits, weekDays }) => {
    const getWeekDays = days => {
        let result = weekDays.filter(item => days.includes(parseInt(item.id)))
        return result
    }

    const filteredData = (data, filterOptions, title) => {
        const { groupedBy } = filterOptions
        // let dataValue = data.map(itemData => filterDataByGroup(itemData, groupedBy, value)).filter(x => x.length > 0)
        let driversOptions = Array.from(new Set(data.map(item => item.map(shiftDay => shiftDay.driverName)))).map(item => {
            return item[0]
        })
        let results = [
            [
                { type: 'string', id: 'Días' },
                { type: 'string', id: 'Servicio' },
                { type: 'date', id: 'Start' },
                { type: 'date', id: 'End' },
            ],
        ]
        driversOptions.forEach(function (itemDriver) {
            let dataDriver = data
                .map(objectDriver =>
                    objectDriver.filter(function (x) {
                        return x.driverName == itemDriver
                    }),
                )
                .map(function (a, i) {
                    return a.length > 0 ? a : null
                })
                .filter(item => item != null)[0]
            dataDriver.forEach(function (itemShift) {
                let from = moment(itemShift.earliestStart, 'HH:mm')
                let to = moment(itemShift.latestArrival, 'HH:mm')
                if (itemShift.endsNextDay) {
                    to = to.add(1, 'days')
                }
                let days = getWeekDays(itemShift.weekDays)
                results.push([days.map(item => item.name).join(', '), itemDriver + ' - ' + itemShift.serviceName, from.toDate(), to.toDate()])
            })
        })
        console.log(results)
        return results
    }

    const chartProps = { title, filterByDate, startDate, finishDate }
    const filterOptions = FilterOptions(groupedBy, value, filterByDate, startDate, finishDate, timeUnits)

    const chartType = 'Timeline'

    return (
        <>
            {filteredData(data, filterOptions, title).length > 1 ? (
                <>
                    {<h2>{title}</h2>}

                    <Chart key={Math.random()} graphID={'chart-driver'} chartType={chartType} width="100%" height="800px" data={filteredData(data, filterOptions, title)} options={options(chartProps, null)} chartLanguage={i18next.language} />
                </>
            ) : null}
        </>
    )
}

TimeServicesByDriver.propTypes = {
    data: PropTypes.array.isRequired,
    chartType: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    groupedBy: PropTypes.oneOf(['municipalities', 'regions', 'otps', 'serviceName', 'tenant']).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    filterByDate: PropTypes.bool.isRequired,
    startDate: PropTypes.string.isRequired,
    finishDate: PropTypes.string.isRequired,
}

export default TimeServicesByDriver
