import React from 'react'
import { FormControl, Grid, TextField, Tooltip } from '@material-ui/core'
import { Add, Remove } from '@material-ui/icons'
import { factory } from '../../../../helpers/factory'
import Brightness2Icon from '@material-ui/icons/Brightness2'

const ShiftHours = props => {
    const { t, state } = props

    return (
        <Grid container spacing={2} justify='center'>
            <Grid item md={4} xs={12} sm={6} >
                <FormControl fullWidth>
                    <TextField
                        label={t('services_comp.calendar.shiftDays.earliestStart')}
                        value={props.value.earliestStart || ''}
                        variant="outlined"
                        name={'earliestStart_' + props.indexShiftHour}
                        fullWidth
                        type={'time'}
                        InputLabelProps={{ shrink: true }}
                        onChange={props.onChange}
                        helperText={state.errors[props.prop][props.index].shiftHours[props.indexShiftHour].earliestStart.message}
                        error={state.errors[props.prop][props.index].shiftHours[props.indexShiftHour].earliestStart.message.length === 0 ? false : true}
                        inputProps={{
                            readOnly: props.disabled,
                        }}
                    />
                </FormControl>
            </Grid>
            <Grid item md={4} xs={12} sm={6}>
                <FormControl fullWidth>
                    <TextField
                        label={t('services_comp.calendar.shiftDays.latestArrival')}
                        value={props.value.latestArrival || ''}
                        variant="outlined"
                        name={'latestArrival_' + props.indexShiftHour}
                        fullWidth
                        type={'time'}
                        InputLabelProps={{ shrink: true }}
                        onChange={props.onChange}
                        helperText={state.errors[props.prop][props.index].shiftHours[props.indexShiftHour].latestArrival.message}
                        error={state.errors[props.prop][props.index].shiftHours[props.indexShiftHour].latestArrival.message.length === 0 ? false : true}
                        inputProps={{
                            readOnly: props.disabled,
                        }}
                    />
                </FormControl>
            </Grid>
            <Grid
                item
                md={2}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {props.indexShiftHour + 1 == props.length ? (
                    <React.Fragment>
                        {props.length > 1 && <Remove onClick={() => props.remove(props.index, props.indexShiftHour)} />}
                        <Add onClick={() => props.add(props.index)} />
                    </React.Fragment>
                ) : (
                    <Remove onClick={() => props.remove(props.index, props.indexShiftHour)} />
                )}
                {factory.checkTimesAfterMidth(props.value.earliestStart, props.value.latestArrival) && (
                    <Tooltip title={t('vehicles.form.page.nightTime')}>
                        <Brightness2Icon />
                    </Tooltip>
                )}
            </Grid>
        </Grid>
    )
}

export default ShiftHours
