import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import Chart from "react-google-charts";
import KpiWrapper from "../../../components/kpiWrapper";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { getTripsKPI } from "../../../helpers/kpiHelper";

export default function TimeSlotsChart({ isLoading }) {
    const { t } = useTranslation()
    const confirmTrips = useSelector(state => state.kpiReducer.confirmTrips)
    const isConfirmTripsLoading = useSelector(state => state.kpiReducer.confirmTripsLoading)
    const { type } = useSelector(state => state.kpiFiltersReducer)

    const { totalTrips, data } = useMemo(() => {
        if (!confirmTrips?.length) return { totalTrips: 0, data: [] }
        const slots = [
            ["00:00"],
            ["01:00"],
            ["02:00"],
            ["03:00"],
            ["04:00"],
            ["05:00"],
            ["06:00"],
            ["07:00"],
            ["08:00"],
            ["09:00"],
            ["10:00"],
            ["11:00"],
            ["12:00"],
            ["13:00"],
            ["14:00"],
            ["15:00"],
            ["16:00"],
            ["17:00"],
            ["18:00"],
            ["19:00"],
            ["20:00"],
            ["21:00"],
            ["22:00"],
            ["23:00"],
        ]

        const { totalTrips, data: dataTrips } = getTripsKPI(confirmTrips, type)

        const data = dataTrips && [
            [
                t("commandBox.summary.hour"),
                ...Object.keys(dataTrips)
            ],
            ...slots
        ]

        dataTrips && Object.entries(dataTrips).map((el) => {
            const { timeSlots } = el[1]
            Object.entries(timeSlots).forEach((slot, index) => {
                slots[index].push(slot[1])
            })
        })

        return { totalTrips, data }
    }, [confirmTrips, type])


    const chartOptions = {
        vAxis: { title: t("commandBox.trips.timeSlotChart.vAxis") },
        seriesType: "bars",
        isStacked: true,
    };

    isLoading = isLoading || isConfirmTripsLoading
    const displayNoData = !isLoading && (!totalTrips || totalTrips == 0)

    return (
        <KpiWrapper
            title={t("commandBox.trips.timeSlotChart.title")}
            displayNoData={displayNoData}
            isLoading={isLoading}
            skeletonVariant="rect">
            <Chart
                chartType="ComboChart"
                graphID={'chart_time_slots'}
                data={data}
                options={chartOptions}
                chartLanguage={i18next.language}
            />
        </KpiWrapper>
    )
}