import { otpConstants } from '../../constants/otp_constants';

let initialState = {
    pending: false,
    results: []
};

export function otpReducer(state = initialState, action) {
    switch (action.type) {
        case otpConstants.FILTER_REQUEST:
            return {
                ...state,
                pending: true
            };
        case otpConstants.FILTER_SUCCESS:
            return {
                ...state,
                pending: false,
                results: action.results
            };
        case otpConstants.FILTER_FAILURE:
            return {
                ...state,
                pending: false,
                results: [],
                error: action.error
            };
        case otpConstants.DETAIL_REQUEST:
            return {
                ...state,
                pending: true
            };
        case otpConstants.DETAIL_SUCCESS:
            return {
                ...state,
                otp: action.otp,
                pending: false
            };
        case otpConstants.DETAIL_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            };
        case otpConstants.GETALL_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case otpConstants.GETALL_SUCCESS:
            return {
                ...state,
                pending: false,
                results: action.results
            };
        case otpConstants.GETALL_FAILURE:
            return {
                ...state,
                pending: false,
                results: [],
                error: action.error
            };
        case otpConstants.CLEAR_RESULTS:
            return {
                ...state,
                results: [],
                pending: false
            };
        case otpConstants.ADD_REQUEST:
            return {
                ...state,
                pending: true
            };
        case otpConstants.ADD_SUCCESS:
            return {
                ...state,
                pending: false,
                otp: action.otp
            };
        case otpConstants.ADD_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            };
        case otpConstants.DELETE_REQUEST:
            return {
                ...state,
                pending: true
            };
        case otpConstants.DELETE_SUCCESS:
            return {
                ...state,
                pending: false,
                results: state.results.filter(x => x.id !== action.id)
            };
        case otpConstants.DELETE_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            };
        case otpConstants.FILTER_BY_TENANT:
            return {
                ...state,
                results: state.results.filter(x => x.tenantId === action.tenantId)
            }
        default:
            return state;
    }
}