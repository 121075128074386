import React from 'react';
import Dashboard from '../../dashboard'
import DriverDetailsView from './page';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { driversActions } from '../../../redux/actions/driver_actions';
import { otpActions } from '../../../redux/actions/otp_actions';
import { vehiclesActions } from '../../../redux/actions/vehicles_actions';
import { history } from '../../../helpers/history';
import Spinner from '../../shared/spinner';

class DriverDetail extends React.Component {

    onClick(driver) {
        history.push('/drivers/edit/' + driver.driver.id)
    }

    componentDidMount() {
        this.props.getAllOTP()
        const params = this.props.location.pathname.split('/')
        const id = params[params.length - 1]
        this.props.getByIdWithVehicles(id, false)
    }

    render() {
        const { t, driverReducer, otpReducer, vehicleReducer } = this.props
        return (
            <Dashboard
                component={
                    driverReducer.driver == null ?
                        <Spinner loading={driverReducer.pending || otpReducer.pending || vehicleReducer.pending} {...this.props} /> :
                        <DriverDetailsView
                            {...this.props}
                            title={t('drivers.details.title')}
                            buttonRight={t('drivers.form.page.buttonDetail')}
                            goBack={t('drivers.form.page.goBack')}
                            sendDriver={() => this.onClick(this.props.driverReducer.driver)}
                            readOnly={true}
                            detail={true}
                            nextAction={'edit'}
                            driver={this.props.driverReducer.driver.driver}
                            vehicles={this.props.driverReducer.driver.driverVehicles}
                        />
                }
            />
        );
    }
}

function mapState(state) {
    const { driverReducer, otpReducer, vehicleReducer } = state;
    return { driverReducer, otpReducer, vehicleReducer };
}

const actionCreators = {
    getById: driversActions.getById,
    getAllOTP: otpActions.getAll,
    getVehiclesByOTP: vehiclesActions.getByOtpId,
    getByIdWithVehicles: driversActions.getByIdWithVehicles,
    cleanPushToken: driversActions.cleanPushToken
}


export default compose(withTranslation('common'), connect(mapState, actionCreators))(DriverDetail);