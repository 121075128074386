import { vehiclesConstants } from "../../constants/vehicles_constants";
import { servicesConstants } from "../../constants/services_constants";

let initialState = {
    currentAvailableVehicleCapacity: null,
    pending: false,
    results: [],
    brands: [],
    models: [],
    locations: [],
    resultsByOTP: [],
    priorities: [],
    allVehicles: [],
    vehicle: { excludedPeriods: [] },
    ranges: [
        {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection",
        },
    ],
    filterBrand: [],
    filterModel: [],
    filterOTP: [],
    filters: null,
    vehiclesGeneralDataKPI: [],
    tableFilters: []
};

export function vehicleReducer(state = initialState, action) {
    switch (action.type) {
        case servicesConstants.GET_AVAIABLE_VEHICLE_CAPACITY_REQUEST:
            return {
                ...state,
                currentAvailableVehicleCapacity: null,
            };
        case servicesConstants.GET_AVAIABLE_VEHICLE_CAPACITY_SUCCESS:
            return {
                ...state,
                currentAvailableVehicleCapacity: action.results,
            };
        case servicesConstants.GET_AVAIABLE_VEHICLE_CAPACITY_FAILURE:
            return {
                ...state,
                currentAvailableVehicleCapacity: null,
            };

        case vehiclesConstants.REMOVE_EXCLUDE_DAYS:
            const filtered = state.vehicle.excludedPeriods.filter(
                (_, i) => i !== action.index
            );
            state.vehicle.excludedPeriods = filtered;
            return {
                ...state,
            };

        case vehiclesConstants.SET_EXCLUDE_DAYS:
            let excludedPeriodsUpdated = state.vehicle.excludedPeriods || [];
            excludedPeriodsUpdated.push(action.element);

            const vehicleUpdated = {
                ...state.vehicle,
                excludedPeriods: excludedPeriodsUpdated,
            };

            return {
                ...state,
                vehicle: vehicleUpdated,
                ranges: [
                    {
                        startDate: new Date(),
                        endDate: new Date(),
                        key: "selection",
                    },
                ],
            };

        case vehiclesConstants.SET_RANGE_CALENDAR:
            return {
                ...state,
                ranges: action.range,
            };

        case vehiclesConstants.FILTER_REQUEST:
            return {
                ...state,
                pending: true,
            };
        case vehiclesConstants.FILTER_SUCCESS:
            return {
                ...state,
                pending: false,
                results: action.results,
            };
        case vehiclesConstants.FILTER_FAILURE:
            return {
                ...state,
                pending: false,
                results: [],
                error: action.error,
            };
        case vehiclesConstants.DETAIL_REQUEST:
            return {
                ...state,
                pending: true,
            };
        case vehiclesConstants.DETAIL_SUCCESS:
            return {
                ...state,
                vehicle: action.vehicle,
                pending: false,
            };
        case vehiclesConstants.DETAIL_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        case vehiclesConstants.SET_VEHICLE:
            return {
                ...state,
                vehicle: action.vehicle,
            };
        case vehiclesConstants.GETALL_REQUEST:
            return {
                ...state,
                pending: true,
            };
        case vehiclesConstants.GETALL_SUCCESS:
            return {
                ...state,
                pending: false,
                results: action.results,
                allVehicles: action.all
            };
        case vehiclesConstants.GETALL_FAILURE:
            return {
                ...state,
                pending: false,
                results: [],
                error: action.error,
            };
        case vehiclesConstants.CLEAR_RESULTS:
            return {
                ...state,
                results: [],
                pending: false,
            };
        case vehiclesConstants.ADD_REQUEST:
            return {
                ...state,
                pending: true,
            };
        case vehiclesConstants.ADD_SUCCESS:
            return {
                ...state,
                pending: false,
                otp: action.otp,
            };
        case vehiclesConstants.ADD_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        case vehiclesConstants.EDIT_REQUEST:
            return {
                ...state,
                pending: true,
            };
        case vehiclesConstants.EDIT_SUCCESS:
            return {
                ...state,
                pending: false,
                operator: action.operator,
            };
        case vehiclesConstants.EDIT_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        case vehiclesConstants.GETBYOTP_REQUEST:
            return {
                ...state,
                pending: true,
            };
        case vehiclesConstants.GETBYOTP_SUCCESS:
            return {
                ...state,
                pending: false,
                resultsByOTP: action.vehicles,
            };
        case vehiclesConstants.GETBYOTP_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        case vehiclesConstants.DELETE_REQUEST:
            return {
                ...state,
                pending: true,
            };
        case vehiclesConstants.DELETE_SUCCESS:
            return {
                ...state,
                pending: false,
                results: state.results.filter((x) => x.id !== action.id),
            };
        case vehiclesConstants.DELETE_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        case vehiclesConstants.GETALL_BRAND_REQUEST:
            return {
                ...state,
                pending: true,
            };
        case vehiclesConstants.GETALL_BRAND_SUCCESS:
            return {
                ...state,
                pending: false,
                brands: action.brands,
            };
        case vehiclesConstants.GETALL_BRAND_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        case vehiclesConstants.GET_MODELS_BYBRAND_REQUEST:
            return {
                ...state,
                pending: true,
            };
        case vehiclesConstants.GET_MODELS_BYBRAND_SUCCESS:
            return {
                ...state,
                pending: false,
                models: action.models,
            };
        case vehiclesConstants.GET_MODELS_BYBRAND_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        case vehiclesConstants.CLEAR_MODELS:
            return {
                ...state,
                models: [],
            };
        case vehiclesConstants.GETALL_LOCATION_REQUEST:
            return {
                ...state,
                refreshMap: state.locations.length === 0 ? false : true,
                pending: true,
            };
        case vehiclesConstants.GETALL_LOCATION_SUCCESS:
            return {
                ...state,
                pending: false,
                locations: action.locations,
                error: null,
            };
        case vehiclesConstants.GETALL_LOCATION_SUCCESS:
            return {
                ...state,
                pending: false,
                locations: [],
                error: action.error,
            };
        case vehiclesConstants.GET_PRIORITY_REQUEST:
            return {
                ...state,
                pending: true,
            };
        case vehiclesConstants.GET_PRIORITY_SUCCESS:
            return {
                ...state,
                pending: false,
                error: null,
                priorities: action.priorities,
            };
        case vehiclesConstants.GET_PRIORITY_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        case vehiclesConstants.SET_FILTER_VEHICLE:
            let brandFilter = action.prop == 'brand' ? action.values : state.filterBrand
            let modelFilter = action.prop == 'model' ? action.values : state.filterModel
            let otpNameFilter = action.prop == 'OTP' ? action.values : state.filterOTP
            return {
                ...state,
                filterBrand: brandFilter,
                filterModel: modelFilter,
                filterOTP: otpNameFilter,
                filters: action.filters
            }
        case vehiclesConstants.CLEAN_FILTER_VEHICLE:
            return {
                ...state,
                filterBrand: [],
                filterModel: [],
                filterOTP: [],
                tableFilters: [],
                filters: null
            }
        case vehiclesConstants.CLEAR_VEHICLE:
            return {
                ...state,
                vehicle: null
            }
        case vehiclesConstants.GETBYSERVICE_REQUEST:
            return {
                ...state,
                pending: true,
                results: []
            }
        case vehiclesConstants.GETBYSERVICE_SUCCESS:
            return {
                ...state,
                pending: false,
                results: action.vehicles
            }
        case vehiclesConstants.GETBYSERVICE_FAILURE:
            return {
                ...state,
                pending: false,
                results: [],
                error: action.error
            }
        case vehiclesConstants.GET_VEHICLES_GENERAL_DATA_KPI_REQUEST:
            return {
                ...state,
                pending: true,
                vehiclesGeneralDataKPI: action.results
            };
        case vehiclesConstants.GET_VEHICLES_GENERAL_DATA_KPI_SUCCESS:
            return {
                ...state,
                pending: false,
                vehiclesGeneralDataKPI: action.results,
            };
        case vehiclesConstants.GET_VEHICLES_GENERAL_DATA_KPI_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            };

        case vehiclesConstants.CLEAN_VEHICLE_STATE:
            return {
                ...state,
                vehicle: { excludedPeriodsUpdated: [] }
            };
        
        case vehiclesConstants.SAVE_TABLE_FILTERS:
            return {
                ...state,
                tableFilters: action.tableFilters
            }

        default:
            return state;
    }
}
