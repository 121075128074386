import { Button, Grid, Tabs, Tab, Paper } from '@material-ui/core'
import React from 'react'
import { history } from '../../../helpers/history'
import { factory } from '../../../helpers/factory'
import utils from '../../../helpers/validations'
import { SERVICES_TYPE } from '../../../constants/types'
import { OperativeHolidays } from './components/OperativeHolidays'
import { TabPanel, a11yProps } from './components/_layout'
import { NoOperativeHolidays } from './components/NoOperativeHolidays'
import { ShiftDaysOperative } from './components/ShiftDaysOperative'
import { ShiftBreaksComponent } from './components/ShiftBreakComponent'

class ServiceCalendarRangeView extends React.Component {
    constructor(props) {
        super()
        const service = props.serviceReducer.service
        const { isLineCalendar } = this.checkOrigin(props)
        this.state = {
            hasRestriction: service ? service.serviceResponse.hasRestrictionsOnRequests : false,
            indexPanel: isLineCalendar ? 0 : 2,
            errors: {
                shiftDays: [
                    {
                        earliestStart: { result: true, message: '' },
                        latestArrival: { result: true, message: '' },
                        weekDays: { result: true, message: '' },
                    },
                ],
                shiftBreaks: [
                    {
                        earliestStart: { result: true, message: '' },
                        latestArrival: { result: true, message: '' },
                        weekDays: { result: true, message: '' },
                        stop: { result: true, message: '' },
                    },
                ],
                requestShiftDays: [
                    {
                        earliestStart: { result: true, message: '' },
                        latestArrival: { result: true, message: '' },
                        weekDays: { result: true, message: '' },
                    },
                ],
                workingBankHolidays: [],
            },
        }
        this.saveCalendarServiceOnClick = this.saveCalendarServiceOnClick.bind(this)
        this.saveCalendarLine = this.saveCalendarLine.bind(this)
        this.addTime = this.addTime.bind(this)
        this.addTimeShiftHours = this.addTimeShiftHours.bind(this)
        this.addRequestTime = this.addRequestTime.bind(this)
        this.addShiftBreak = this.addShiftBreak.bind(this)
        this.removeItem = this.removeItem.bind(this)
        this.removeItemShiftHours = this.removeItemShiftHours.bind(this)
        this.removeRequestItem = this.removeRequestItem.bind(this)
        this.removeShiftBreakItem = this.removeShiftBreakItem.bind(this)
        this.handleRequestShiftDaysUpdates = this.handleRequestShiftDaysUpdates.bind(this)
        this.handleShiftDaysUpdates = this.handleShiftDaysUpdates.bind(this)
        this.handleChangeDateShiftDay = this.handleChangeDateShiftDay.bind(this)
        this.handleChangeDateShiftBreak = this.handleChangeDateShiftBreak.bind(this)
        this.handleChangeDateRequestShiftDay = this.handleChangeDateRequestShiftDay.bind(this)
        this.handleChangeTimeShiftHour = this.handleChangeTimeShiftHour.bind(this)
        this.addErrorExcludedWorkingDay = this.addErrorExcludedWorkingDay.bind(this)
        this.removeErrorExcludedWorkingDay = this.removeErrorExcludedWorkingDay.bind(this)
        this.setRestrictions = this.setRestrictions.bind(this)
        this.handleChangeStop = this.handleChangeStop.bind(this)
        let calendar = props.serviceReducer.calendar
        if (calendar?.shiftDays?.length > 1) {
            const errors = this.state.errors
            calendar.shiftDays.forEach(function (element, i) {
                errors.shiftDays[i] = {
                    earliestStart: { result: false, message: '' },
                    latestArrival: { result: false, message: '' },
                    weekDays: { result: false, message: '' },
                }
            })
            this.setState({ errors })
        }
        if (calendar?.shiftBreaks?.length > 1) {
            const errors = this.state.errors
            calendar.shiftBreaks.forEach(function (element, i) {
                errors.shiftBreaks[i] = {
                    earliestStart: { result: false, message: '' },
                    latestArrival: { result: false, message: '' },
                    weekDays: { result: false, message: '' },
                    stop: { result: false, message: '' },
                }
            })
            this.setState({ errors })
        }
        if (calendar?.requestShiftDays?.length > 1) {
            const errors = this.state.errors
            calendar.requestShiftDays.forEach(function (element, i) {
                errors.requestShiftDays[i] = {
                    earliestStart: { result: false, message: '' },
                    latestArrival: { result: false, message: '' },
                    weekDays: { result: false, message: '' },
                }
            })
            this.setState({ errors })
        }
        if (calendar?.workingBankHolidays?.length > 0) {
            const errors = this.state.errors
            calendar.workingBankHolidays.forEach(function (element, i) {
                errors.workingBankHolidays[i] = { shiftHours: [] }
                element.shiftHours.forEach(function (elementShiftHour, indexShiftHour) {
                    errors.workingBankHolidays[i].shiftHours[indexShiftHour] = {
                        earliestStart: { result: false, message: '' },
                        latestArrival: { result: false, message: '' },
                    }
                })
            })
            this.setState({ errors })
        }
    }

    saveCalendarServiceOnClick() {
        const params = this.props.match.params
        const id = params.id
        const serviceType = params.serviceType
        let errors = this.state.errors
        let calendarReducer = this.props.serviceReducer.calendar
        const calendar = factory.createCalendar(calendarReducer.excludedPeriods, calendarReducer.requestShiftDays, calendarReducer.shiftDays, calendarReducer.workingBankHolidays, calendarReducer.shiftBreaks, this.state.hasRestriction)
        if (serviceType != SERVICES_TYPE.regularWithSchedule)
            this.validateCompleteForm(errors, calendarReducer)
        else {
            const originIsServiceList = this.props.history.location.state?.originIsServiceList ?? false
            if (!originIsServiceList)
                this.validateShiftDays(errors, calendarReducer)
            this.validateRequestShiftDays(errors, calendarReducer)
            this.validateShiftBreaks(errors, calendarReducer)
            this.setState({ errors })
        }
        if (this.isFormValid())
            this.props.setCalendar(calendar, id, this.state.hasRestriction)
    }

    saveCalendarLine() {
        const params = this.props.match.params
        const id = params.id
        let errors = this.state.errors
        let calendarReducer = this.props.serviceReducer.calendar
        this.validateWorkingBankHolidays(errors, calendarReducer)
        this.validateShiftBreaks(errors, calendarReducer)
        this.setState({ errors })
        if (this.isFormValid()) this.props.setCalendarLine(calendarReducer, id)
    }

    validateWorkingBankHolidays(errors, calendar) {
        errors.workingBankHolidays.forEach(function (workingBankHoliday, index) {
            workingBankHoliday.shiftHours.forEach(function (shiftHour, indexShiftHour) {
                shiftHour.earliestStart = utils.required(calendar.workingBankHolidays[index].shiftHours[indexShiftHour].earliestStart)
                shiftHour.latestArrival = utils.required(calendar.workingBankHolidays[index].shiftHours[indexShiftHour].latestArrival)
            })
        })
        if (!errors.workingBankHolidays.every(item => item.result)) {
            this.setState({
                indexPanel: 0
            })
        }
    }

    validateShiftDays(errors, calendar) {
        calendar.shiftDays.forEach(function (element, i) {
            errors.shiftDays[i].earliestStart = utils.required(element.earliestStart)
            errors.shiftDays[i].latestArrival = utils.required(element.latestArrival)
            errors.shiftDays[i].weekDays = utils.validateArray(element.weekDays)
        })
        if (!errors.shiftDays.every(item => item.result)) {
            this.setState({
                indexPanel: 2
            })
        }
    }

    validateShiftBreaks(errors, calendar) {
        calendar.shiftBreaks.forEach(function (element, i) {
            errors.shiftBreaks[i].earliestStart = utils.required(element.shiftDays.earliestStart)
            errors.shiftBreaks[i].latestArrival = utils.required(element.shiftDays.latestArrival)
            errors.shiftBreaks[i].weekDays = utils.validateArray(element.shiftDays.weekDays)
            errors.shiftBreaks[i].stop = utils.requiredObject(element.stop)
        })
        if (!errors.shiftBreaks.every(item => item.result)) {
            this.setState({
                indexPanel: 3
            })
        }
    }

    validateRequestShiftDays(errors, calendar) {
        if (this.state.hasRestriction) {
            errors.requestShiftDays.forEach(function (element, i) {
                element.earliestStart = utils.required(calendar.requestShiftDays[i].earliestStart)
                element.latestArrival = utils.required(calendar.requestShiftDays[i].latestArrival)
                element.weekDays = utils.validateArray(calendar.requestShiftDays[i].weekDays)
            })
        } else {
            errors.requestShiftDays.forEach(function (element, i) {
                element.earliestStart = { result: true, message: '' }
                element.latestArrival = { result: true, message: '' }
                element.weekDays = { result: true, message: '' }
            })
        }
        if (!errors.requestShiftDays.every(item => item.result)) {
            this.setState({
                indexPanel: 2
            })
        }
    }

    validateCompleteForm(errors, calendar) {
        this.validateShiftDays(errors, calendar)
        this.validateRequestShiftDays(errors, calendar)
        this.validateWorkingBankHolidays(errors, calendar)
        this.validateShiftBreaks(errors, calendar)
        this.setState({ errors })
    }

    isFormValid() {
        let valid = true

        let properties = Object.getOwnPropertyNames(this.state.errors)
        properties.forEach(element => {
            this.state.errors[element].forEach(function (item, i) {
                let propertiesItem = Object.getOwnPropertyNames(item)
                if (propertiesItem != 'shiftHours') {
                    propertiesItem.forEach(prop => {
                        if (!item[prop].result) valid = false
                    })
                }
            })
        })

        this.state.errors.workingBankHolidays.forEach(function (workingBankHolidayItem, index) {
            workingBankHolidayItem.shiftHours.forEach(function (shiftHour, subIndex) {
                let shiftHourProperties = Object.getOwnPropertyNames(shiftHour)
                shiftHourProperties.forEach(hourProperty => {
                    if (!shiftHour[hourProperty].result) valid = false
                })
            })
        })

        return valid
    }

    onClickBack() {
        history.goBack()
    }

    addRequestTime() {
        let errors = this.state.errors
        errors.requestShiftDays.push({
            earliestStart: { result: false, message: '' },
            latestArrival: { result: false, message: '' },
            weekDays: { result: false, message: '' },
        })
        this.setState({
            errors,
        })
        this.props.addTimeRequestShiftDays()
    }

    addShiftBreak() {
        let errors = this.state.errors
        errors.shiftBreaks.push({
            earliestStart: { result: false, message: '' },
            latestArrival: { result: false, message: '' },
            weekDays: { result: false, message: '' },
            stop: { result: false, message: '' },
        })
        this.setState({
            errors,
        })
        this.props.addTimeShiftBreaks()
    }

    removeRequestItem(index) {
        let errors = this.state.errors
        errors.requestShiftDays = errors.requestShiftDays.filter((_, i) => i !== index)
        this.props.removeTimeRequestShiftDays(index)
        this.setState({
            errors,
        })
    }

    addTime() {
        let errors = this.state.errors
        errors.shiftDays.push({
            earliestStart: { result: false, message: '' },
            latestArrival: { result: false, message: '' },
            weekDays: { result: false, message: '' },
        })
        this.props.addTimeShiftDays()
        this.setState({
            errors,
        })
    }

    addTimeShiftHours(index) {
        let errors = this.state.errors
        errors.workingBankHolidays[index].shiftHours.push({
            earliestStart: { result: false, message: '' },
            latestArrival: { result: false, message: '' },
        })
        this.props.addTimeShiftHours(index)
        this.setState({
            errors,
        })
    }

    addErrorExcludedWorkingDay() {
        let errors = this.state.errors
        errors.workingBankHolidays.push({
            shiftHours: [
                {
                    earliestStart: { result: false, message: '' },
                    latestArrival: { result: false, message: '' },
                },
            ],
        })
        this.setState({
            errors,
        })
    }

    removeItem(index) {
        let errors = this.state.errors
        errors.shiftDays = errors.shiftDays.filter((_, i) => i !== index)
        this.props.removeTimeShiftDays(index)
        this.setState({
            errors,
        })
    }

    removeShiftBreakItem(index) {
        let errors = this.state.errors
        errors.shiftBreaks = errors.shiftBreaks.filter((_, i) => i !== index)
        this.props.removeTimeShiftBreaks(index)
        this.setState({
            errors,
        })
    }

    removeItemShiftHours(index, indexShiftHour) {
        let errors = this.state.errors
        errors.workingBankHolidays[index].shiftHours = errors.workingBankHolidays[index].shiftHours.filter((_, i) => i !== indexShiftHour)
        this.props.removeTimeShiftHours(index, indexShiftHour)
        this.setState({
            errors,
        })
    }

    removeErrorExcludedWorkingDay(index) {
        let errors = this.state.errors
        errors.workingBankHolidays = errors.workingBankHolidays.filter((_, i) => i !== index)
        this.setState({
            errors,
        })
    }

    handleShiftDaysUpdates = (days, option = null, index) => {
        let updatedShiftDays = this.props.serviceReducer.calendar.shiftDays
        let errors = this.state.errors
        if (option) {
            switch (true) {
                case option === '1':
                    updatedShiftDays[index].weekDays = [6, 0]
                    break
                case option === '2':
                    updatedShiftDays[index].weekDays = [1, 2, 3, 4, 5, 6, 0]
                    break
                case option === '3':
                    updatedShiftDays[index].weekDays = [1, 2, 3, 4, 5]
                    break
                default:
                    updatedShiftDays[index].weekDays = days.map(d => (d.hasOwnProperty('id') ? parseInt(d.id) : d))
            }
        } else {
            updatedShiftDays[index].weekDays = days.map(d => (d.hasOwnProperty('id') ? parseInt(d.id) : d))
        }
        errors.shiftDays[index].weekDays.message = ''
        this.props.setShiftDays(updatedShiftDays)
    }

    handleShiftBreaksUpdates = (days, option = null, index) => {
        let updatedShiftBreaks = this.props.serviceReducer.calendar.shiftBreaks
        let errors = this.state.errors
        if (option) {
            switch (true) {
                case option === '1':
                    updatedShiftBreaks[index].shiftDays.weekDays = [6, 0]
                    break
                case option === '2':
                    updatedShiftBreaks[index].shiftDays.weekDays = [1, 2, 3, 4, 5, 6, 0]
                    break
                case option === '3':
                    updatedShiftBreaks[index].shiftDays.weekDays = [1, 2, 3, 4, 5]
                    break
                default:
                    updatedShiftBreaks[index].shiftDays.weekDays = days.map(d => (d.hasOwnProperty('id') ? parseInt(d.id) : d))
            }
        } else {
            updatedShiftBreaks[index].shiftDays.weekDays = days.map(d => (d.hasOwnProperty('id') ? parseInt(d.id) : d))
        }
        errors.shiftBreaks[index].weekDays.message = ''
        this.props.setShiftBreaks(updatedShiftBreaks)
    }

    handleRequestShiftDaysUpdates = (days, option = null, index) => {
        let updatedShiftDays = this.props.serviceReducer.calendar.requestShiftDays
        let errors = this.state.errors
        if (option) {
            switch (true) {
                case option === '1':
                    updatedShiftDays[index].weekDays = [6, 0]
                    break
                case option === '2':
                    updatedShiftDays[index].weekDays = [1, 2, 3, 4, 5, 6, 0]
                    break
                case option === '3':
                    updatedShiftDays[index].weekDays = [1, 2, 3, 4, 5]
                    break
                default:
                    updatedShiftDays[index].weekDays = days.map(d => (d.hasOwnProperty('id') ? parseInt(d.id) : d))
            }
        } else {
            updatedShiftDays[index].weekDays = days.map(d => (d.hasOwnProperty('id') ? parseInt(d.id) : d))
        }
        errors.requestShiftDays[index].weekDays.message = ''
        this.props.setRequestShiftDays(updatedShiftDays)
    }

    handleChangeDateShiftDay(event, index) {
        let { name, value } = event.target
        let errors = this.state.errors
        let date = new Date()
        let array = value.split(':')
        date.setHours(array[0], array[1])
        array = name.split('_')
        let updatedShiftDays = this.props.serviceReducer.calendar.shiftDays
        updatedShiftDays[index][array[0]] = value
        errors.shiftDays[index][array[0]].message = ''
        this.props.setShiftDays(updatedShiftDays)
    }

    handleChangeDateShiftBreak(event, index) {
        let { name, value } = event.target
        let errors = this.state.errors
        let date = new Date()
        let array = value.split(':')
        date.setHours(array[0], array[1])
        array = name.split('_')
        let updatedShiftBreaks = this.props.serviceReducer.calendar.shiftBreaks
        updatedShiftBreaks[index].shiftDays[array[0]] = value
        errors.shiftBreaks[index][array[0]].message = ''
        this.props.setShiftBreaks(updatedShiftBreaks)
    }

    handleChangeStop(index, value) {
        let errors = this.state.errors
        let updatedShiftBreaks = this.props.serviceReducer.calendar.shiftBreaks
        updatedShiftBreaks[index].stop = value
        errors.shiftBreaks[index].stop.message = ''
        this.props.setShiftBreaks(updatedShiftBreaks)
    }

    handleChangeDateRequestShiftDay(event, index) {
        let { name, value } = event.target
        let errors = this.state.errors
        let date = new Date()
        let array = value.split(':')
        date.setHours(array[0], array[1])
        array = name.split('_')
        let updatedShiftDays = this.props.serviceReducer.calendar.requestShiftDays
        updatedShiftDays[index][array[0]] = value
        errors.requestShiftDays[index][array[0]].message = ''
        this.props.setRequestShiftDays(updatedShiftDays)
    }

    handleChangeTimeShiftHour(event, index, indexShiftHour) {
        let { name, value } = event.target
        let date = new Date()
        let array = value.split(':')
        date.setHours(array[0], array[1])
        array = name.split('_')
        let updatedShiftHours = this.props.serviceReducer.calendar.workingBankHolidays[index].shiftHours
        updatedShiftHours[indexShiftHour][array[0]] = value
        this.props.setShiftHours(updatedShiftHours)
    }

    setRestrictions(value) {
        this.setState({
            hasRestriction: value
        })
    }

    checkOrigin(props) {
        const { match, serviceReducer } = props
        const id = match.params.id
        const serviceType = match.params.serviceType
        const bankHolidaysLine = serviceReducer.serviceLine?.bankHolidaysLine ?? false
        const isServiceWithSchedule = serviceType == SERVICES_TYPE.regularWithSchedule
        // when come from list of services is set a boolean into the history push
        const originIsServiceList = props.history.location.state?.originIsServiceList ?? false
        const isLineCalendar = isServiceWithSchedule && !originIsServiceList
        const isServiceCalendar = !isServiceWithSchedule && originIsServiceList
        const showWorkingRange = isLineCalendar || isServiceCalendar
        return {
            id, bankHolidaysLine, showWorkingRange, isLineCalendar, originIsServiceList, isServiceWithSchedule
        }
    }

    render() {
        const { t, serviceReducer } = this.props

        const calendar = serviceReducer.calendar
        const { bankHolidaysLine, id, isLineCalendar, isServiceWithSchedule, originIsServiceList, showWorkingRange } = this.checkOrigin(this.props)
        const title = !isLineCalendar ? serviceReducer.service?.serviceResponse : serviceReducer.serviceLine


        return (
            <Grid container style={{ overflow: 'hidden' }}>
                <Paper elevation={1} style={{ width: '100%', padding: 20, marginBottom: 20 }} >
                    <Grid item md={11}>
                        <h1>{title?.name}</h1>
                    </Grid>

                    <Tabs
                        value={this.state.indexPanel}
                        onChange={(event, newValue) => {
                            this.setState({ indexPanel: newValue })
                        }}
                        indicatorColor="primary"
                        textColor="primary"
                        scrollButtons="auto"
                        variant="scrollable"
                    >
                        {!isLineCalendar && <Tab label={t('services_comp.calendar.panel.shiftDay')} {...a11yProps(2)} value={2}></Tab>}
                        {showWorkingRange && <Tab label={t('services_comp.calendar.panel.operative')} {...a11yProps(0)} value={0}></Tab>}
                        {showWorkingRange && <Tab label={t('services_comp.calendar.panel.noOperative')} {...a11yProps(1)} value={1}></Tab>}
                        {showWorkingRange && <Tab label={t('services_comp.calendar.panel.breaks')} {...a11yProps(3)} value={3}></Tab>}
                    </Tabs>
                </Paper>

                <Paper elevation={1} style={{ width: '100%', padding: 20, marginBottom: 20 }}>

                    <TabPanel value={this.state.indexPanel} index={2}>
                        <ShiftDaysOperative
                            setRestriction={this.setRestrictions}
                            addRequestTime={this.addRequestTime}
                            addTime={this.addTime}
                            calendar={calendar}
                            handleChangeDateRequestShiftDay={this.handleChangeDateRequestShiftDay}
                            handleChangeDateShiftDay={this.handleChangeDateShiftDay}
                            handleRequestShiftDaysUpdates={this.handleRequestShiftDaysUpdates}
                            hasRestriction={this.state.hasRestriction}
                            isServiceWithSchedule={isServiceWithSchedule}
                            originIsServiceList={originIsServiceList}
                            removeItem={this.removeItem}
                            removeRequestItem={this.removeRequestItem}
                            handleShiftDaysUpdates={this.handleShiftDaysUpdates}
                            state={this.state}
                        />
                    </TabPanel>

                    <TabPanel value={this.state.indexPanel} index={0}>
                        {showWorkingRange ? (
                            <OperativeHolidays
                                addErrorExcludedWorkingDay={this.addErrorExcludedWorkingDay}
                                calendar={calendar}
                                handleChangeTimeShiftHour={this.handleChangeTimeShiftHour}
                                removeErrorExcludedWorkingDay={this.removeErrorExcludedWorkingDay}
                                removeExcludedWorkingDays={this.removeErrorExcludedWorkingDay}
                                state={this.state}
                                addTimeShiftHoursInternal={this.addTimeShiftHours}
                                removeItemShiftHours={this.removeItemShiftHours}
                                {...this.props}
                            />
                        ) : null}
                    </TabPanel>
                    <TabPanel value={this.state.indexPanel} index={1}>
                        {!bankHolidaysLine ? (
                            <NoOperativeHolidays
                                calendar={calendar}
                                {...this.props}
                            />
                        ) : null}
                    </TabPanel>

                    <TabPanel value={this.state.indexPanel} index={3}>
                        <ShiftBreaksComponent
                            addTime={this.addShiftBreak}
                            calendar={calendar}
                            handleChangeDateShiftDay={this.handleChangeDateShiftBreak}
                            removeItem={this.removeShiftBreakItem}
                            handleShiftDaysUpdates={this.handleShiftBreaksUpdates}
                            state={this.state}
                            handleChangeStop={this.handleChangeStop}
                        />
                    </TabPanel>

                </Paper>

                <Grid container justify='flex-end' spacing={2}>
                    <Grid item xl={1} md={2} sm={5}>
                        <Button
                            style={{ marginTop: 40, marginRight: 25 }}
                            variant="outlined"
                            color="primary"
                            onClick={this.onClickBack}
                            fullWidth
                            size="medium"
                        >
                            {t('services_comp.form.page.goBack')}
                        </Button>
                    </Grid>

                    <Grid item xl={1} md={2} sm={5}>
                        <Button
                            style={{ marginTop: 40, marginRight: 30 }}
                            variant="contained"
                            color="primary"
                            fullWidth
                            size="medium"
                            disabled={!!serviceReducer.pending}
                            onClick={originIsServiceList ? () => this.saveCalendarServiceOnClick() : () => this.saveCalendarLine(id)}
                        >
                            {t('services_comp.form.page.buttonEdit')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default ServiceCalendarRangeView
