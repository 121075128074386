import { zonesConstants } from '../../constants/zones_constants';

let initialState = {
    pending: false,
    zones: [],
    circles: [],
    results: [],
    zonesSelected: [],
    zonesCombination: [],
    zoneStops: [],
    allStops: [],
    refreshMarkers: false,
    changeZone: false,
    openModalConflictZones: false
};

export function zoneReducer(state = initialState, action) {
    switch (action.type) {
        case zonesConstants.DETAIL_REQUEST:
            return {
                ...state,
                pending: true
            };
        case zonesConstants.DETAIL_SUCCESS:
            return {
                ...state,
                zone: action.zone,
                pending: false
            };
        case zonesConstants.DETAIL_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            };
        case zonesConstants.GETALL_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case zonesConstants.GETALL_SUCCESS:
            return {
                ...state,
                pending: false,
                stops: action.stops,
                results: action.results,
            };
        case zonesConstants.GETALL_FAILURE:
            return {
                ...state,
                pending: false,
                results: [],
                error: action.error
            };
        case zonesConstants.ADD_REQUEST:
            return {
                ...state,
                //pending: true
            };
        case zonesConstants.ADD_SUCCESS:
            return {
                ...state,
                //pending: false,
                zone: action.zone
            };
        case zonesConstants.ADD_FAILURE:
            return {
                ...state,
                //pending: false,
                error: action.error
            };
        case zonesConstants.CHECK_STOP_REQUEST:
            return {
                ...state,
                pending: true
            }
        case zonesConstants.CHECK_STOP_SUCCESS:
            return {
                ...state,
                pending: false,
                contentToModal: action.conflictServices,
                openModalConflictZones: true
            }
        case zonesConstants.CHECK_STOP_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case zonesConstants.DELETE_REQUEST:
            return {
                ...state,
                //pending: true
            };
        case zonesConstants.DELETE_SUCCESS:
            let newResults = state.results.filter(x => x.id !== action.id)
            //newResults = state.results.circleZones.filter(x => x.id !== action.id)
            return {
                ...state,
                //pending: false,
                results: newResults
            };
        case zonesConstants.DELETE_FAILURE:
            return {
                ...state,
                //pending: false,
                error: action.error
            };
        case zonesConstants.SET_ZONES_SELECTED:
            return {
                ...state,
                zonesSelected: action.zones,
                zonesCombination: action.zonesCombination
            };
        case zonesConstants.CLEAR_ZONES_SELECTED:
            return {
                ...state,
                zonesSelected: [],
                zonesCombination: []
            };
        case zonesConstants.SET_COMBINATION_ZONE:
            let item = { zones: '' }
            item.idZone = action.idZone
            action.values.forEach(element => {
                item.zones = item.zones == '' ? element.id : (item.zones + ',' + element.id)
            });
            const filter = state.zonesCombination.filter(x => x.idZone == action.idZone)
            item.values = action.values
            if (filter.length == 0) {
                return {
                    ...state,
                    zonesCombination: [...state.zonesCombination, item]
                };
            } else {
                return {
                    ...state,
                    zonesCombination: state.zonesCombination.map((content, i) =>
                        action.idZone == content.idZone ? item : content
                    )
                };
            };
        case zonesConstants.CLEAR_COMBINATION_ZONE:
            return {
                ...state,
                zonesCombination: []
            };
        case zonesConstants.CHECK_EMPTY:
            return {
                ...state,
                zonesCombination: state.zonesCombination.filter(x => x.zones != '')
            };
        case zonesConstants.ADD_STOP_REQUEST:
            return {
                ...state,
                pending: true
            };
        case zonesConstants.ADD_STOP_SUCCESS:
            return {
                ...state,
                pending: false,
                zone: action.zone,
                results: state.results.map((content, i) =>
                    action.zone.id == content.id ? action.zone : content
                ),
                zoneStops: action.zone.stops
            };
        case zonesConstants.ADD_STOP_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            };
        case zonesConstants.SET_ZONE_STOPS:
            return {
                ...state,
                zoneStops: action.stops
            };
        case zonesConstants.SET_ZONE_STOPS_REFRESH:
            return {
                ...state,
                changeZone: action.boolean
            };
        case zonesConstants.CLOSE_MODAL:
            return {
                ...state,
                openModalConflictZones: false
            }
        case zonesConstants.GETALL_ACTIVED_STOPS_SUCCESS:
            return {
                ...state,
                allStops: action.results
            }
        case zonesConstants.GETALL_ACTIVED_STOPS_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
                allStops: []
            }
        default:
            return state;
    }
}