import React from "react";
import { history } from "../../helpers/history";
import AlertDialog from "../alert-dialog";
import { Button } from "@material-ui/core";
import MaterialTableExtend from "../material-table";
import Spinner from "../shared/spinner";
import FilterComponent from '../shared/filter'
import { allowAccessRole } from "../../helpers/roles";
import { MODULES, OPERATIONS_ALLOWED } from "../roles/constants";

class VehiclesView extends React.Component {
    constructor(props) {
        super();
        this.state = {
            openModal: false,
            item: {},
            isModalUnassignDriverOpen: false
        };
        this.onClickCancelModal = this.onClickCancelModal.bind(this);
        this.onClickAcceptModalDelete = this.onClickAcceptModalDelete.bind(this);
        this.onClickAcceptModalUnassign = this.onClickAcceptModalUnassign.bind(this);
        this.setValue = this.setValue.bind(this)
    }

    addOnClick() {
        history.push("/vehicles/create");
    }

    onClickCancelModal() {
        this.setState({ openModal: false });
    }

    onClickAcceptModalDelete() {
        this.setState({ openModal: false });
        this.props.remove(this.state.item.id);
    }

    onClickAcceptModalUnassign() {
        this.setState({ isModalUnassignDriverOpen: false });
        this.props.unassignDriverVehicle(this.state.item.id);
    }

    onClickOpenModal(data) {
        this.setState({ openModal: true, item: data });
    }

    onClickOpenModalUnassign(data) {
        this.setState({ isModalUnassignDriverOpen: true, item: data });
    }

    onClickConfigCalendar(id) {
        history.push("/vehicles/configCalendar/" + id);
    }

    setValue(prop, values) {
        const { vehicleReducer } = this.props;
        const filters = {
            brand: prop == 'brand' ? values : vehicleReducer.filterBrand,
            model: prop == 'model' ? values : vehicleReducer.filterModel,
            otpName: prop == 'OTP' ? values : vehicleReducer.filterOTP,
        }
        this.props.setVehicleFilter(prop, values, filters)
        this.props.getAll(filters)
    }

    render() {
        const { vehicleReducer, t } = this.props;
        const Wrapper = () => (
            <React.Fragment>
                {allowAccessRole(MODULES.Vehicles, OPERATIONS_ALLOWED.manage) && (
                    <Button
                        onClick={this.addOnClick}
                        variant="contained"
                        style={{ marginLeft: '24px' }}
                        color="primary"
                        size="large">
                        {t('vehicles.page.wrapper.add')}
                    </Button>
                )}
            </React.Fragment>
        );
        return (
            <React.Fragment>
                {!vehicleReducer.pending ? (
                    <React.Fragment>
                        <MaterialTableExtend
                            buttons={<Wrapper />}
                            title={t("vehicles.page.title")}
                            onFilterChange={(tableFilters) => {
                                console.log(tableFilters)
                                this.props.saveTableFilters(tableFilters)
                            }}
                            columns={[
                                {
                                    title: t("vehicles.page.columns.brand"),
                                    field: "brand",
                                    defaultFilter: vehicleReducer.tableFilters.filter((col) => col.column.field == 'brand')[0]?.value ?? null,
                                    filterComponent: rowData => (
                                        <FilterComponent
                                            {...this.props}
                                            optionsArray={[
                                                ...new Set(
                                                    vehicleReducer.allVehicles
                                                        .sort((a, b) => a.brand.localeCompare(b.brand))
                                                        .map(x => x.brand),
                                                ),
                                            ]}
                                            setValue={this.setValue}
                                            prop={'brand'}
                                            values={vehicleReducer.filterBrand}
                                        />
                                    )
                                },
                                {
                                    title: t("vehicles.page.columns.model"),
                                    field: "model",
                                    defaultFilter: vehicleReducer.tableFilters.filter((col) => col.column.field == 'model')[0]?.value ?? null,
                                    filterComponent: rowData => (
                                        <FilterComponent
                                            {...this.props}
                                            optionsArray={[
                                                ...new Set(
                                                    vehicleReducer.allVehicles
                                                        .sort((a, b) => a.model.localeCompare(b.model))
                                                        .map(x => x.model),
                                                ),
                                            ]}
                                            setValue={this.setValue}
                                            prop={'model'}
                                            values={vehicleReducer.filterModel}
                                        />
                                    )
                                },
                                { title: "id", field: "id", hidden: true },
                                {
                                    title: t("vehicles.page.columns.plate"),
                                    field: "plateNumber",
                                    defaultFilter: vehicleReducer.tableFilters.filter((col) => col.column.field == 'plateNumber')[0]?.value ?? null,
                                },
                                {
                                    title: t("vehicles.page.columns.otp"),
                                    field: "otpName",
                                    defaultFilter: vehicleReducer.tableFilters.filter((col) => col.column.field == 'otpName')[0]?.value ?? null,
                                    filterComponent: rowData => (
                                        <FilterComponent
                                            {...this.props}
                                            optionsArray={[
                                                ...new Set(
                                                    vehicleReducer.allVehicles
                                                        .sort((a, b) => a.otpName.localeCompare(b.otpName))
                                                        .map(x => x.otpName),
                                                ),
                                            ]}
                                            setValue={this.setValue}
                                            prop={'OTP'}
                                            values={vehicleReducer.filterOTP}
                                        />
                                    ),
                                },
                                {
                                    title: t("vehicles.page.columns.drivers"),
                                    field: 'drivers',
                                    defaultFilter: vehicleReducer.tableFilters.filter((col) => col.column.field == 'drivers')[0]?.value ?? null,
                                    render: rowData => {
                                        return rowData.drivers?.map(driver => (
                                            <React.Fragment key={Math.random()}>
                                                {driver}
                                                <br />
                                            </React.Fragment>
                                        ))
                                    }
                                },
                                {
                                    title: t("vehicles.page.columns.services"),
                                    field: 'services',
                                    defaultFilter: vehicleReducer.tableFilters.filter((col) => col.column.field == 'services')[0]?.value ?? null,
                                    render: rowData => {
                                        return rowData.services?.map(service => (
                                            <React.Fragment key={Math.random()}>
                                                {service}
                                                <br />
                                            </React.Fragment>
                                        ))
                                    }
                                },
                            ]}
                            data={vehicleReducer.results}
                            actionsColumnIndex={7}
                            actions={[
                                {
                                    icon: "view_list",
                                    tooltip: t("vehicles.page.actions.detail"),
                                    onClick: (event, rowData) =>
                                        history.push("/vehicles/" + rowData.id)
                                },

                                {
                                    icon: "date_range",
                                    tooltip: t("services_comp.page.actions.calendar"),
                                    onClick: (event, rowData) =>
                                        this.onClickConfigCalendar(rowData.id),
                                    hidden: !allowAccessRole(MODULES.Vehicles, OPERATIONS_ALLOWED.manage),
                                },
                                {
                                    icon: "delete",
                                    tooltip: t("vehicles.page.actions.delete"),
                                    onClick: (event, rowData) => this.onClickOpenModal(rowData),
                                    hidden: !allowAccessRole(MODULES.Vehicles, OPERATIONS_ALLOWED.manage),
                                },

                                (rowData) => ({
                                    icon: "link_off",
                                    tooltip: t("vehicles.page.actions.unassignDriver"),
                                    onClick: (event, rowData) =>
                                        this.onClickOpenModalUnassign(rowData),
                                    hidden:
                                        !allowAccessRole(MODULES.Vehicles, OPERATIONS_ALLOWED.manage) ||
                                        !rowData.hasDriverAssigned,
                                }),
                            ]}
                        />
                        <AlertDialog
                            open={this.state.openModal}
                            title={`${t("vehicles.page.alert_dialog.titleDelete")} (${this.state.item.displayName})`}
                            onClickAccept={this.onClickAcceptModalDelete}
                            onClickCancel={this.onClickCancelModal}
                        />
                        <AlertDialog
                            open={this.state.isModalUnassignDriverOpen}
                            title={`${t("vehicles.page.alert_dialog.titleUnassignDriver")} (${this.state.item.currentDriverName})`}
                            onClickAccept={this.onClickAcceptModalUnassign}
                            onClickCancel={() => {
                                this.setState({ isModalUnassignDriverOpen: false });
                            }}
                        />
                    </React.Fragment>
                ) : (
                    <Spinner loading={vehicleReducer.pending} />
                )}
            </React.Fragment>
        );
    }
}

export default VehiclesView;
