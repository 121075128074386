import React from 'react';
import Spinner from '../../shared/spinner';
import VehicleForm from '../form';

class VehicleEditView extends React.Component {

    render() {
        const { otpReducer, vehicleReducer } = this.props
        return (
            <React.Fragment>
                {otpReducer.pending || vehicleReducer.pending ?
                    <Spinner loading={otpReducer.pending || vehicleReducer.pending} /> :
                    <VehicleForm
                        {...this.props}
                    />
                }
            </React.Fragment>
        );
    }
}

export default VehicleEditView;