import React from 'react';
import OperatorForm from '../form';

class CreateOperatorView extends React.Component {

    render() {
        return (
            <OperatorForm
                {...this.props}
            />
        );
    }
}

export default CreateOperatorView;