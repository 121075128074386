import React, { useEffect, useState } from 'react'
import Root from './Root'
import { Provider } from 'react-redux'
import store from './redux/store'
import { ThemeProvider } from '@material-ui/core'
import theme from './components/theme'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'
import { onMessageListener } from './firebase'
import { onIncidentsListener } from './incidentsListener'
import { SnackbarProvider } from 'notistack';
import CloseIcon from '@material-ui/icons/Close';
import { tenantService } from './services/tenantService'
import './global.css'
import Spinner from './components/shared/spinner'

const App = () => {

    const [tenantInfo, setTenantInfo] = useState({
        isCalled: false,
        primaryColor: '#80CAE9',
        secondaryColor: '#FFFFFF',
        nameTenant: '',
        idTenant: '',
        isAmtu: false
    })

    onMessageListener()
        .then(payload => {
            // const { data, body, title } = payload.notification;
            // const bodyNewMsg = {
            //   senderId: data && data.SenderId,
            //   receiverId: data && data.RecipientId,
            // };
            // store.dispatch(chatActions.setNewMessageFlag(true, bodyNewMsg));
            // store.dispatch(alertActions.info(`${title}`));
            //histgory.push('/admin/chat')
        })
        .catch(err => console.log('failed: ', err))

    useEffect(() => {
        onIncidentsListener()
        tenantService.getTenantNameAndColorsById().then(
            result => {
                setTenantInfo({
                    primaryColor: result.primaryColor,
                    secondaryColor: result.secondaryColor,
                    nameTenant: result.name,
                    idTenant: result.id,
                    isAmtu: result.isAmtu,
                    isCalled: true

                })
            },
            error => {
                setTenantInfo((prevValue) => ({
                    ...prevValue,
                    primaryColor: '#80CAE9',
                    secondaryColor: '#FFFFFF',
                    isCalled: true
                }))
            }
        )
    }, [])



    const notistackRef = React.createRef();
    const onClickDismiss = key => () => {
        notistackRef.current.closeSnackbar(key);
    }



    if (process.env.NODE_ENV === 'production') {
        console.log = () => { }
        console.error = () => { }
        console.debug = () => { }
    }

    return (
        <I18nextProvider i18n={i18n}>
            <Provider store={store}>
                <ThemeProvider theme={theme({ primary: tenantInfo.primaryColor, secondary: tenantInfo.secondaryColor, nameTenant: tenantInfo.nameTenant, idTenant: tenantInfo.idTenant, isAmtu: tenantInfo.isAmtu })}>
                    <SnackbarProvider
                        preventDuplicate
                        ref={notistackRef}
                        maxSnack={5}
                        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                        action={(key) => (
                            <CloseIcon onClick={onClickDismiss(key)} />
                        )}
                    >
                        {tenantInfo.isCalled ? <Root /> : <Spinner loading={true} />}

                    </SnackbarProvider>
                </ThemeProvider>
            </Provider>
        </I18nextProvider>
    )
}
export default App
