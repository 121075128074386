import React from 'react';
import VehiclesFormView from './page'

class VehiclesForm extends React.Component {

    render() {
        return (
            <VehiclesFormView
                {...this.props}
            />
        );
    }
}

export default VehiclesForm;