import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import Chart from "react-google-charts";
import KpiWrapper from "../../../components/kpiWrapper";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { getTimeDistribution } from "../../../helpers/kpiHelper";

export default function ReservationTimeSlotsChart({ isLoading }) {
    const { t } = useTranslation()
    const tripBooking = useSelector(state => state.kpiReducer.tripBooking)
    const isTripBookingLoading = useSelector(state => state.kpiReducer.tripBookingLoading)
    const { type } = useSelector(state => state.kpiFiltersReducer)

    const { data } = useMemo(() => {
        if (!tripBooking?.length) return { data: [] }

        const slots = [
            ["00:00"],
            ["01:00"],
            ["02:00"],
            ["03:00"],
            ["04:00"],
            ["05:00"],
            ["06:00"],
            ["07:00"],
            ["08:00"],
            ["09:00"],
            ["10:00"],
            ["11:00"],
            ["12:00"],
            ["13:00"],
            ["14:00"],
            ["15:00"],
            ["16:00"],
            ["17:00"],
            ["18:00"],
            ["19:00"],
            ["20:00"],
            ["21:00"],
            ["22:00"],
            ["23:00"],
        ]

        const res = getTimeDistribution(tripBooking, type)

        const data = res && [
            [
                t("commandBox.summary.hour"),
                ...Object.keys(res)
            ],
            ...slots
        ]

        res && Object.entries(res).map((el) => {
            const timeSlots = el[1]
            Object.entries(timeSlots).forEach((slot, index) => {
                slots[index].push(slot[1])
            })
        })

        return { data }
    }, [tripBooking, type])


    const chartOptions = {
        vAxis: { title: t("commandBox.trips.reservationTimeSlotsChart.vAxis") },
        seriesType: "bars",
        isStacked: true,
    };

    isLoading = isLoading || isTripBookingLoading
    const displayNoData = !isLoading && (!tripBooking || tripBooking.length == 0)

    return (
        <KpiWrapper
            title={t("commandBox.trips.timeSlotChart.title")}
            displayNoData={displayNoData}
            isLoading={isLoading}
            skeletonVariant="rect">
            <Chart
                chartType="ComboChart"
                graphID={'chart_reservation_time_slots'}
                data={data}
                options={chartOptions}
                chartLanguage={i18next.language}
            />
        </KpiWrapper>
    )
}