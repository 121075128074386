import { Grid, Paper, makeStyles } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import UserPayFeeChart from "./components/userPayFeeChart";
import CostChart from "./components/costChart";
import TotalCostChart from "./components/totalCostChart";

export default function EconomyDataKPI() {
    const classes = useStyles()
    const servicesLoading = useSelector(state => state.serviceReducer.pending)

    return (
        <section className={classes.wrapper}>
            <Grid container spacing={1}>
                <Grid item sm={12}>
                    <Paper className={[classes.paper, classes.paddingGraph]} >
                        <UserPayFeeChart isLoading={servicesLoading} />
                    </Paper>
                </Grid>
                <Grid item sm={12}>
                    <Paper className={[classes.paper, classes.paddingGraph]} >
                        <CostChart isLoading={servicesLoading} />
                    </Paper>
                </Grid>
                <Grid item sm={12}>
                    <Paper className={[classes.paper, classes.paddingGraph]} >
                        <TotalCostChart isLoading={servicesLoading} />
                    </Paper>
                </Grid>
            </Grid>
        </section>
    )
}

const useStyles = makeStyles({
    paper: {
        padding: "10px",
        minHeight: "250px"
    },

    paddingGraph: {
        padding: "10px 20px"
    },
    wrapper: {
        display: "grid",
        gap: "5px"
    }
})