import React from 'react';
import Spinner from '../../shared/spinner';
import OperatorForm from '../form';

class OperatorDetailsView extends React.Component {

    render() {
        const { otpReducer } = this.props
        return (
            <React.Fragment>
                {otpReducer.pending ?
                    <Spinner loading={otpReducer.pending} /> :
                    <OperatorForm
                        {...this.props}
                    />
                }
            </React.Fragment>
        );
    }
}

export default OperatorDetailsView;