import { Chip, Tooltip, useTheme } from "@material-ui/core"
import React from 'react';
import Spinner from "../../shared/spinner";
import i18next from "i18next";

const ButtonDay = ({ label, dayOffWeek, value, handleClick }) => {
    const theme = useTheme()
    const primary = theme.palette.primary.main
    const secondary = theme.palette.secondary.main

    const days = i18next.t(`trips.multipleBook.dayOfWeeks`, { returnObjects: true })

    return (
        <Tooltip title={label}>
            <Chip
                data-testId={`day-${dayOffWeek}`}
                label={days[label] ?? label?.subString(0, 3)}
                size="medium"
                variant="default"
                style={{
                    backgroundColor: value ? primary : theme.palette.grey[300],
                    color: value ? secondary : theme.palette.black.main,
                    borderRadius: 0,
                    margin: 5
                }}
                clickable={true}
                onClick={() => handleClick(dayOffWeek)}
            />
        </Tooltip>
    )
}

export default ButtonDay


export const withSpinner = (Component) => {
    return (props) => {
        if (props.isPending || !props.weekDays)
            return <Spinner loading={props.isPending} />
        return <Component {...props} />
    }
}