import { positionService } from '../../services/positionService';
import { positionConstants } from '../../constants/position_constants';

export const positionActions = {
    getPositionForTrip
}

function getPositionForTrip(vehicleId, pickUpDate, dropOffDate) {
    return dispatch => {
        dispatch(request())
        positionService.getPositionForTrip(vehicleId, pickUpDate, dropOffDate).then(
            position => {
                dispatch(success(position))
                localStorage.setItem('vehiclePosition', JSON.stringify(position))
            },
            error => {
                dispatch(failure(error))
            }
        )

    }

    function request() {
        return { type: positionConstants.GET_POSITION_TRIP_REQUEST }
    }

    function success(position) {
        return { type: positionConstants.GET_POSITION_TRIP_SUCCESS, position }
    }

    function failure(error) {
        return { type: positionConstants.GET_POSITION_TRIP_FAILURE, error }
    }

}