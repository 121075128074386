import React from 'react'
import CreateServiceView from './page'
import Dashboard from '../../dashboard'
import { connect } from 'react-redux'
import { otpActions } from '../../../redux/actions/otp_actions'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { zoneActions } from '../../../redux/actions/zones_actions'
import { serviceActions } from '../../../redux/actions/services_actions'
import { vehiclesActions } from '../../../redux/actions/vehicles_actions'
import { customerActions } from '../../../redux/actions/customer_actions'

class ServiceCreate extends React.Component {
    componentDidMount() {
        this.props.getAllZones()
        this.props.getAllOtps()
        this.props.getServicesTypes()
        this.props.getAnticipationTypes()
        this.props.getAllCustomers()
    }

    componentWillUnmount() {
        this.props.clearCombination()
    }

    render() {
        const { t } = this.props
        return (
            <Dashboard
                component={
                    <CreateServiceView
                        title={t('services_comp.create.title')}
                        readOnly={false}
                        sendService={this.props.insert}
                        nextAction={'create'}
                        buttonRight={t('services_comp.form.page.buttonEdit')}
                        goBack={t('services_comp.form.page.goBack')}
                        {...this.props} />
                }
            />)
    }
}

function mapState(state) {
    const { serviceReducer, zoneReducer, otpReducer, vehicleReducer, customerReducer } = state
    return { serviceReducer, zoneReducer, otpReducer, vehicleReducer, customerReducer }
}

const actionCreators = {
    insert: serviceActions.insert,
    getTypesService: serviceActions.getTypesService,
    getServicesTypes: serviceActions.getServicesTypes,
    getTypesPrices: serviceActions.getTypesPrices,
    getAllZones: zoneActions.getAll,
    getAllOtps: otpActions.getAll,
    getByOtpIdWithOutServices: vehiclesActions.getByOtpIdWithOutServices,
    setZones: zoneActions.setZonesSelected,
    clearZones: zoneActions.clearZonesSelected,
    setCombination: zoneActions.setCombinationZone,
    clearCombination: zoneActions.clearZonesCombination,
    setServiceDataEdit: serviceActions.setServiceDataEdit,
    getAnticipationTypes: serviceActions.getAnticipationTypes,
    getAllCustomers: customerActions.getAll,
}

export default compose(withTranslation('common'), connect(mapState, actionCreators))(ServiceCreate)
