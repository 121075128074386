import React from 'react';
import Spinner from '../../shared/spinner';
import ServiceForm from '../form';

class ServiceEditView extends React.Component {

    render() {
        const { serviceReducer, zoneReducer, otpReducer, vehicleReducer } = this.props
        return (
            <React.Fragment>
                {otpReducer.pending || serviceReducer.pending || zoneReducer.pending || vehicleReducer.pending ?
                    <Spinner loading={otpReducer.pending || serviceReducer.pending || zoneReducer.pending || vehicleReducer.pending} /> :
                    <ServiceForm
                        {...this.props}
                    />
                }
            </React.Fragment>
        );
    }
}

export default ServiceEditView;