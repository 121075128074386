import { Button, Grid, Hidden, MenuItem, TextField } from '@material-ui/core';
import React from 'react';
import { SERVICES_TYPE } from '../../../constants/types';
import i18next from 'i18next';
import { history } from '../../../helpers/history';

const SwitchConfigOptions = (props) => {

    const { type, direction, serviceReducer, checkDisabledSave, conditionDisabledSaveButton, stopSelected } = props

    return (
        <>
            <Grid item md={1} implementation="css" smDown component={Hidden} />
            <Grid item md={2}>
                {
                    type == SERVICES_TYPE.regularService ?
                        <TextField
                            label={i18next.t('services_comp.expeditions.direction')}
                            value={direction}
                            select
                            variant="outlined"
                            name="direction"
                            fullWidth
                            onChange={(e) => props.onChangeDirection(e.target.value)}
                        >
                            <MenuItem
                                value={'0'}
                            >
                                {i18next.t('services_comp.expeditions.outbound')}
                            </MenuItem>
                            <MenuItem
                                value={'1'}
                            >
                                {i18next.t('services_comp.expeditions.return')}
                            </MenuItem>
                        </TextField>
                        :
                        <TextField
                            select
                            variant="outlined"
                            name="stops"
                            fullWidth
                            value={stopSelected}
                            label={i18next.t('services_comp.lines.stops')}
                            onChange={(e) => props.onChangeStop(e.target.value)}
                        >
                            {
                                serviceReducer?.serviceLine?.outbound?.zoneStops?.map(item =>
                                    <MenuItem
                                        key={item.id}
                                        value={item.id}
                                    >
                                        {item.name}
                                    </MenuItem>
                                )
                            }
                        </TextField>
                }

            </Grid>
            <Grid item md={2} implementation="css" smDown component={Hidden} />
            <Grid item md={2}>
                <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={() => history.goBack()}
                    size="medium"
                >{i18next.t('services_comp.lines.backButton')}
                </Button>
            </Grid>
            <Grid item md={2}>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => props.onSave()}
                    disabled={checkDisabledSave && conditionDisabledSaveButton}
                    size="medium"
                >{i18next.t('services_comp.lines.saveButton')}
                </Button>
            </Grid>
            <Grid item md={3} implementation="css" smDown component={Hidden} />

        </>
    )
}

export default SwitchConfigOptions