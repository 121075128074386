import React from 'react';
import OtpForm from '../form'

class CreateOtpView extends React.Component {

    render() {
        return (
            <OtpForm
                {...this.props}
            />
        );
    }
}

export default CreateOtpView;