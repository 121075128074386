import { notificationsConstants } from '../../constants/notifications_constants'
import { notificationService } from '../../services/notificationService'
import { alertActions } from '../actions/alert_actions'
import { userActions } from '../actions/user_actions'
import { customerActions } from '../actions/customer_actions'
import i18next from 'i18next'
import { userService } from '../../services/userService'
import { customerService } from '../../services/customerService'
import { history } from '../../helpers/history'

export const notificationActions = {
    getAll,
    getTypes,
    check,
    getNotificationsCount,
    requestTripFromCall
}

function getAll() {
    return dispatch => {
        dispatch(request())
        const lang = i18next.language
        notificationService.getAll().then(
            results => {
                dispatch(success(results))
            },
            error => {
                if (error === 401 && userService.existRefreshToken()) {
                    dispatch(userActions.refreshToken())
                    dispatch(getAll(lang))
                } else {
                    dispatch(
                        alertActions.error(
                            i18next.t('services.notifications.getAllError'),
                        ),
                    )
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: notificationsConstants.GETALL_REQUEST }
    }

    function success(results) {
        return { type: notificationsConstants.GETALL_SUCCESS, results }
    }

    function failure(error) {
        return { type: notificationsConstants.GETALL_FAILURE, error }
    }
}

function getTypes() {
    return dispatch => {
        dispatch(request())
        const lang = i18next.language
        notificationService.getTypes().then(
            notificationTypes => {
                const prof = makeEnum(notificationTypes)
                dispatch(success(prof))
            },
            error => {
                if (error === 401 && userService.existRefreshToken()) {
                    dispatch(userActions.refreshToken())
                    dispatch(getTypes(lang))
                } else {
                    dispatch(
                        alertActions.error(
                            i18next.t('services.notifications.getAllError'),
                        ),
                    )
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: notificationsConstants.TYPES_REQUEST }
    }

    function success(notificationTypes) {
        return { type: notificationsConstants.TYPES_SUCCESS, notificationTypes }
    }

    function failure(error) {
        return { type: notificationsConstants.TYPES_FAILURE, error }
    }
}

function check(id) {
    return dispatch => {
        dispatch(request())
        notificationService.checkNotification(id).then(
            notification => {
                dispatch(success(id))
                dispatch(getNotificationsCount())
                dispatch(
                    alertActions.success(
                        i18next.t('services.notifications.checkSuccess'),
                    ),
                )
                dispatch(getAll())
            },
            error => {
                if (error === 401 && userService.existRefreshToken()) {
                    dispatch(userActions.refreshToken())
                    dispatch(check(id))
                }
                dispatch(failure(error))
                dispatch(
                    alertActions.error(
                        i18next.t('services.notifications.checkError'),
                    ),
                )
            },
        )
    }

    function request() {
        return { type: notificationsConstants.CHECK_REQUEST }
    }

    function success(id) {
        return { type: notificationsConstants.CHECK_SUCCESS, id }
    }

    function failure(error) {
        return { type: notificationsConstants.CHECK_FAILURE, error }
    }
}

function requestTripFromCall(data, themeColor) {
    return dispatch => {
        dispatch(customerActions.checkProfile(data.customerId))
        customerService.getById(data.customerId).then(
            customer => {
                customer.customerId = customer.id
                const requestObject = {
                    dropOffLocation: {
                        desc: {
                            description: data.ivrResponse.dropOffLocation.address,
                        },
                        location: {
                            address: data.ivrResponse.dropOffLocation.address,
                            desc: {
                                description: data.ivrResponse.dropOffLocation.address,
                            },
                            lat: data.ivrResponse.dropOffLocation.lat,
                            lng: data.ivrResponse.dropOffLocation.lon,
                        }
                    },
                    pickUpLocation: {
                        desc: {
                            description: data.ivrResponse.pickUpLocation.address,
                        },
                        location: {
                            address: data.ivrResponse.pickUpLocation.address,
                            desc: {
                                description: data.ivrResponse.pickUpLocation.address,
                            },
                            lat: data.ivrResponse.pickUpLocation.lat,
                            lng: data.ivrResponse.pickUpLocation.lon,
                        }
                    },
                    pickUpDate: new Date(data.ivrResponse.requestPickUpStartTime),
                    customerToRequest: customer,
                    numPassengers: data.ivrResponse.numPassengers
                }
                dispatch(request(requestObject))
                dispatch(customerActions.setCustomer(
                    customer.id,
                    customer.relatedCustomersCount,
                    customer.fullName,
                    customer.addressPosition,
                    customer.favouriteAddresses,
                    customer.name,
                    customer.surname1,
                    customer.birthDate,
                    customer.relatedCustomers,
                    customer.language,
                    customer.pushToken,
                    themeColor
                ))
                history.push("/requestTrip")
            }
        )


    }
    function request(request) {
        return { type: notificationsConstants.REQUEST_TRIP_FROM_CALL, request }
    }
}

function getNotificationsCount() {
    return dispatch => {
        notificationService.getNotificationsCount().then(
            results => {
                dispatch(success(results))
            },
            error => {
                if (error === 401 && userService.existRefreshToken()) {
                    dispatch(userActions.refreshToken())
                    dispatch(getNotificationsCount())
                }
            },
        )
    }

    function success(results) {
        return {
            type: notificationsConstants.GET_NOTIFICATIONS_COUNT,
            results,
        }
    }
}

function makeEnum(enumObject) {
    let all = []
    for (let key in enumObject) {
        all.push({
            id: key,
            name: enumObject[key],
        })
    }
    return all
}
