import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getJourneyTime } from "../../../helpers/kpiHelper";
import Chart from "react-google-charts";
import i18next from "i18next";
import KpiWrapper from "../../../components/kpiWrapper";

export default function JourneysDurationChart({ isLoading }) {
    const { t } = useTranslation()

    const journeys = useSelector(state => state.kpiReducer.journeys)
    const isJourneysLoading = useSelector(state => state.kpiReducer.journeysLoading)
    const { type } = useSelector(state => state.kpiFiltersReducer)

    const { data } = useMemo(() => {
        if (!journeys.length) return { data: [] }

        const data = getJourneyTime(journeys, type)
        return { data }
    }, [journeys, type])

    isLoading = isLoading || isJourneysLoading
    const displayNoData = !isLoading && (!data || data.length == 0)

    return (
        <KpiWrapper
            title={t("commandBox.journeys.journeysDurationChart.title")}
            displayNoData={displayNoData}
            isLoading={isLoading}
            skeletonVariant="rect">
            <Chart
                chartType="ComboChart"
                graphID={'chart_duration_journeys'}
                data={data}
                chartLanguage={i18next.language}
                options={{
                    seriesType: "bars",
                    isStacked: true,
                }}
            />
        </KpiWrapper>
    )
}