import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import AlertDialog from '../alert-dialog';
import Spinner from '../shared/spinner';
import { Alert } from '@material-ui/lab';

class SelectDriverToTrip extends React.Component {

    constructor(props) {
        super()
        this.state = {
            driver: '',
            errors: {
                driver: { 'result': false, 'message': '' }
            }
        }
        this.onClickAccept = this.onClickAccept.bind(this)
    }

    onChangeDriver = (event, newValue) => {
        let errors = this.state.errors;
        errors.driver.message = ''
        this.setState({
            driver: newValue
        })
    }

    onClickAccept() {
        this.props.onClickAccept(this.props.tripId, this.state.driver.id, this.state.driver.vehicleId, this.state.driver.fullName, this.props.startDate, this.props.endDate)
    }

    render() {
        const { t, driverReducer } = this.props
        const Content = React.memo(() => {
            if (driverReducer.driversToAssing?.length > 0)
                return (
                    <Autocomplete
                        id="driverId"
                        value={this.state.driver || ''}
                        onChange={this.onChangeDriver}
                        options={driverReducer.driversToAssing}
                        getOptionLabel={(option) => option.fullName || ''}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant={'outlined'}
                                label={t('drivers.form.page.name')}
                                helperText={this.state.errors.driver.message}
                                name="otpId"
                                error={this.state.errors.driver.message.length === 0 ? false : true}
                            />
                        )}
                    />
                )
            else
                return (
                    <div>
                        <Alert severity='error'>
                            <span>{t('customers.trips.page.alert_dialog.possibleDriversEmpty')}</span>
                        </Alert>
                    </div>
                )
        }, (prevProps, nextProps) => prevProps.driverReducer === nextProps.driverReducer)
        return (
            <AlertDialog
                open={this.props.open}
                fullWidth
                maxWidth="sm"
                title={t('drivers.selectDriver.title')}
                onClose={this.props.onClickCancel}
                onClickCancel={this.props.onClickCancel}
                onClickAccept={this.onClickAccept}
                conditionDisableAccept={this.state.driver === '' || this.state.driver === null}
                content={() =>
                    this.props.pending ? <Spinner loading={this.props.pending} />
                        : <Content />
                }
            />
        );
    }
}

export default SelectDriverToTrip;