import React from 'react';
import MaterialTable from 'material-table'
import { FormLabel } from '@material-ui/core';

class UserTariffTypology extends React.Component {

    constructor(props) {
        super()
        this.state = {
            tariff: props.tariff
        }
    }

    convertObjectToArray() {
        const keys = Object.keys(this.state.tariff)
        const values = Object.values(this.state.tariff)
        let result = []
        keys.forEach(function (key, index) {
            result.push(
                { 'countPassenger': keys[index], 'price': values[index] }
            )
        })
        return result
    }

    render() {
        const { t } = this.props
        const translations = t('material_table.tableStrings', { returnObjects: true })
        const titleTable = this.props.isOwner ? t('services_comp.typology.item.price') : t('services_comp.typology.item.priceCustomer')
        return (
            <React.Fragment>
                {
                    this.props.error?.message.length > 0 ?
                        <FormLabel error>
                            {this.props.error.message}
                        </FormLabel>
                        : null
                }
                <MaterialTable
                    title={titleTable}
                    localization={translations}
                    columns={[
                        {
                            title: t('services_comp.typology.item.countPassenger'),
                            field: 'countPassenger',
                            type: 'numeric',
                            cellStyle: {
                                textAlign: "center"
                            }
                        },
                        {
                            title: titleTable,
                            field: 'price',
                            cellStyle: {
                                textAlign: "center"
                            },
                            render: (rowData) => { return rowData.price + '€' }
                        }
                    ]}
                    data={this.convertObjectToArray(this.state.tariff)}
                    options={{
                        actionsColumnIndex: 2
                    }}
                    editable={
                        this.props.canEdit ?
                            {
                                onRowAdd: newData =>
                                    new Promise((resolve, reject) => {
                                        setTimeout(() => {
                                            this.setState(prevState => {
                                                let tariff = Object.assign({}, prevState.tariff);
                                                if (newData.countPassenger && newData.price && !isNaN(newData.countPassenger) && !isNaN(newData.price)) {
                                                    tariff[newData.countPassenger] = parseFloat(newData.price)
                                                    if (this.props.indexTypology != null)
                                                        this.props.handleChangeUserTariff(tariff, this.props.indexDestination, this.props.indexTypology)
                                                    else
                                                        this.props.handleChangeUserTariff(tariff, this.props.indexDestination)
                                                    return { tariff };
                                                }

                                            })
                                            resolve();
                                        }, 1000)
                                    }),
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve, reject) => {
                                        setTimeout(() => {
                                            this.setState(prevState => {
                                                let tariff = Object.assign({}, prevState.tariff);
                                                if (newData.countPassenger && newData.price && !isNaN(newData.countPassenger) && !isNaN(newData.price)) {
                                                    tariff[newData.countPassenger] = parseFloat(newData.price)
                                                    if (this.props.indexTypology != null)
                                                        this.props.handleChangeUserTariff(tariff, this.props.indexDestination, this.props.indexTypology)
                                                    else
                                                        this.props.handleChangeUserTariff(tariff, this.props.indexDestination)
                                                    return { tariff };
                                                }
                                            })
                                            resolve();
                                        }, 1000);
                                    }),
                                onRowDelete: oldData =>
                                    new Promise((resolve, reject) => {
                                        setTimeout(() => {
                                            this.setState(prevState => {
                                                let tariff = Object.assign({}, prevState.tariff);
                                                delete tariff[oldData.countPassenger]
                                                if (this.props.indexTypology != null)
                                                    this.props.handleChangeUserTariff(tariff, this.props.indexDestination, this.props.indexTypology)
                                                else
                                                    this.props.handleChangeUserTariff(tariff, this.props.indexDestination)
                                                return { tariff };
                                            })
                                            resolve();
                                        }, 1000);
                                    })
                            } : null}
                />
            </React.Fragment>
        );
    }
}

export default UserTariffTypology;