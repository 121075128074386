import React from 'react';
import MapsGeofence from '../shared/geofence/mapsGeofence'

class ZonesView extends React.Component {

    handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.props.clear();

    }

    render() {
        const { zoneReducer } = this.props
        return (
            <MapsGeofence
                data={zoneReducer.results}
                onAccept={this.props.insert}
                onRemove={this.props.remove}
                onEdit={this.props.update}
                mapStateLoading={zoneReducer.pending}
                t={this.props.t}
                handleCloseAlert={this.handleCloseAlert}
                alertReducer={this.props.alertReducer}
                info={this.props.info}
                clear={this.props.clear}
            />

        );
    }
}

export default ZonesView;