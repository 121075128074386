import React from 'react';
import ServiceFormView from './page'

class ServiceForm extends React.Component {

    render() {
        return (
            <ServiceFormView
                {...this.props}
            />
        );
    }
}

export default ServiceForm;