import { Grid, Hidden } from '@material-ui/core';
import React from 'react';
import ItemListStop from './item';
import i18next from 'i18next';


const LineServiceRegular = (props) => {

    const { lineObject, serviceReducer, serviceType } = props


    return (
        <>
            {lineObject != null && lineObject?.length !== 0 && serviceReducer.serviceLineSingleStops != null && serviceReducer.serviceLineReturnStops != null && (
                <React.Fragment>
                    <Grid item md={1} implementation="css" smDown component={Hidden} />
                    <Grid item md={5}>
                        <ItemListStop
                            serviceType={serviceType}
                            title={i18next.t('services_comp.lines.configSingle')}
                            items={serviceReducer.serviceLineSingleStops}
                            line={lineObject.outbound}
                            setItems={props.setstopsSingle}
                            setAllowChangeDirection={props.setallowChangeDirectionSingle}
                            allowChangeDirection={props.allowChangeDirectionSingle}
                        />
                    </Grid>
                    <Grid item md={5}>
                        <ItemListStop
                            serviceType={serviceType}
                            title={i18next.t('services_comp.lines.configReturn')}
                            items={serviceReducer.serviceLineReturnStops}
                            line={lineObject.return}
                            setItems={props.setstopsReturn}
                            setAllowChangeDirection={props.setallowChangeDirectionReturn}
                            allowChangeDirection={props.allowChangeDirectionReturn}
                        />
                    </Grid>
                </React.Fragment>
            )}

        </>
    )

}

export default LineServiceRegular