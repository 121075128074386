import { Checkbox, FormControlLabel, Grid, Hidden } from "@material-ui/core"
import ShiftDays from "../shiftDays"
import i18next from "i18next"
import React from 'react'
import { useSelector } from "react-redux"

export const ShiftDaysOperative = ({ originIsServiceList, isServiceWithSchedule, hasRestriction, calendar, addTime, removeItem, handleChangeDateShiftDay, addRequestTime, removeRequestItem, handleChangeDateRequestShiftDay, handleRequestShiftDaysUpdates, handleShiftDaysUpdates, state, setRestriction, ...rest }) => {

    const serviceReducer = useSelector((state) => state.serviceReducer)

    return (
        <Grid container>
            {
                // only is possible config when is not regularWithSchedule and not is origin is service list
                originIsServiceList && !isServiceWithSchedule ? (
                    <>
                        <Grid item md={12}>
                            <h2>{i18next.t('services_comp.calendar.shiftDays.title')}</h2>
                        </Grid>

                        {calendar.shiftDays?.map((item, index) => (
                            <ShiftDays
                                key={'s_' + index}
                                item={item}
                                index={index}
                                detail={rest.detail}
                                length={calendar.shiftDays.length}
                                value={calendar.shiftDays[index]}
                                t={i18next.t}
                                onChange={event => handleChangeDateShiftDay(event, index)}
                                add={addTime}
                                remove={removeItem}
                                checkUpdates={(days, option) => handleShiftDaysUpdates(days, option, index)}
                                prop={'shiftDays'}
                                state={state}
                                weekDaysOptions={serviceReducer.weekDays}
                                weekDaysAllOptions={serviceReducer.weekDays}
                                disabled={rest.readOnly || rest.readEdit}
                                {...rest}
                            />
                        ))}
                    </>
                ) : null
            }

            {
                // only is possible config when is service's calendar
                originIsServiceList ? (
                    <>

                        <Grid item md={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={hasRestriction}
                                        onChange={() => setRestriction(!hasRestriction)}
                                        color="primary" />
                                }
                                label={i18next.t('services_comp.calendar.shiftDays.existRestrictions')} />
                        </Grid>


                        <Grid item md={12}>
                            {hasRestriction ? (
                                <h2>
                                    {i18next.t('services_comp.calendar.shiftDays.titleRequest')}
                                </h2>
                            ) : null}
                        </Grid>

                        {hasRestriction ? (
                            <React.Fragment>
                                {calendar.requestShiftDays?.map((item, index) => (
                                    <ShiftDays
                                        key={'r_' + index}
                                        item={item}
                                        index={index}
                                        length={calendar.requestShiftDays.length}
                                        value={calendar.requestShiftDays[index]}
                                        t={i18next.t}
                                        onChange={event => handleChangeDateRequestShiftDay(event, index)}
                                        add={addRequestTime}
                                        remove={removeRequestItem}
                                        checkUpdates={(days, option) => handleRequestShiftDaysUpdates(days, option, index)}
                                        prop={'requestShiftDays'}
                                        state={state}
                                        serviceReducer={serviceReducer}
                                        weekDaysOptions={serviceReducer.weekDays}
                                        weekDaysAllOptions={serviceReducer.weekDays}
                                        disabled={rest.readOnly || rest.readEdit || !hasRestriction}
                                        {...rest}
                                    />
                                ))}
                            </React.Fragment>
                        ) : (
                            <Grid item md={12} implementation="css" smDown component={Hidden} />
                        )}
                    </>
                ) : null
            }
        </Grid>
    )
}