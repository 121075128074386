import React from 'react';
import { FormControl, Grid, Hidden, TextField, Button, InputLabel, Select, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import utils from '../../../helpers/validations'
import { factory } from '../../../helpers/factory';
import Spinner from '../../shared/spinner';
import { history } from '../../../helpers/history';
import { allowAccessRole } from '../../../helpers/roles'
import { MODULES, OPERATIONS_ALLOWED } from '../../roles/constants';

const styles = makeStyles((theme) => ({
    ' & .MuiFormControl-root': {
        width: '100%',
    },
}));


class OtpFormView extends React.Component {

    constructor(props) {
        super()
        const otp = props.otp ? props.otp : {}
        this.state = {
            id: otp.id || '',
            name: otp.name || '',
            vatNumber: otp.vatNumber || '',
            contactPerson: otp.contactPerson || '',
            email: otp.email || '',
            phoneNumber: otp.phoneNumber || '',
            errors: {
                name: { 'result': false, 'message': '' },
                vatNumber: { 'result': false, 'message': '' },
                contactPerson: { 'result': true, 'message': '' },
                email: { 'result': false, 'message': '' },
                phoneNumber: { 'result': props.detail ? true : false, 'message': '' },
            }
        }
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this)
    }

    handleChange(event) {
        let { name, value } = event.target;
        let errors = this.state.errors;
        errors[name].message = "";
        this.setState({ [name]: value })
    }

    onClickBack() {
        history.goBack()
    }

    isFormValid() {
        let valid = true;
        let properties = Object.getOwnPropertyNames(this.state.errors)
        properties.forEach(element => {
            if (!this.state.errors[element].result)
                valid = false
        });
        return valid
    }

    validateForm() {
        let errors = this.state.errors;
        errors.name = utils.required(this.state.name)
        errors.vatNumber = utils.validateCIF_Nif(this.state.vatNumber)
        errors.contactPerson = utils.required(this.state.contactPerson)
        errors.phoneNumber = utils.required(this.state.phoneNumber)
        if (errors.phoneNumber.result && !this.props.detail) {
            errors.phoneNumber = utils.validatePhone(this.state.phoneNumber)
        }
        errors.email = utils.required(this.state.email)
        if (errors.email.result)
            errors.email = utils.validateEmail(this.state.email)
        this.setState({ errors })
    }

    onSubmit = (event) => {
        event.preventDefault();
        this.validateForm()
        if (this.isFormValid()) {
            const otp = factory.createOtp(this.state.id, this.state.name, this.state.vatNumber, this.state.contactPerson, this.state.phoneNumber, this.state.email)
            this.props.sendOtp(otp)
        }
    }

    render() {
        const { t, otpReducer } = this.props;
        return (
            <React.Fragment>
                <Spinner loading={otpReducer.pending} />
                <h2 style={{ padding: 50 }}>{this.props.title}</h2>
                <form onSubmit={this.onSubmit} autoComplete="off">
                    <Grid className={styles.root} container spacing={3}>
                        <Grid item md={1} implementation="css" smDown component={Hidden} />
                        <Grid item md={3} xs={6} sm={3} >
                            <FormControl fullWidth>
                                <TextField
                                    label={t('otp.form.page.name')}
                                    value={this.state.name || ''}
                                    variant="outlined"
                                    name="name"
                                    fullWidth
                                    onChange={this.handleChange}
                                    error={this.state.errors.name.message.length === 0 ? false : true}
                                    helperText={this.state.errors.name.message}
                                    inputProps={
                                        { readOnly: this.props.readOnly || this.props.readEdit }
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} xs={6} sm={3} >
                            <FormControl fullWidth>
                                <TextField
                                    label={t('otp.form.page.vatNumber')}
                                    value={this.state.vatNumber || ''}
                                    variant="outlined"
                                    name="vatNumber"
                                    fullWidth
                                    onChange={this.handleChange}
                                    error={this.state.errors.vatNumber.message.length === 0 ? false : true}
                                    helperText={this.state.errors.vatNumber.message}
                                    inputProps={
                                        { readOnly: this.props.readOnly || this.props.readEdit }
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} xs={6} sm={3} >
                            <FormControl fullWidth>
                                <TextField
                                    label={t('otp.form.page.contact')}
                                    value={this.state.contactPerson || ''}
                                    variant="outlined"
                                    name="contactPerson"
                                    fullWidth
                                    onChange={this.handleChange}
                                    error={this.state.errors.contactPerson.message.length === 0 ? false : true}
                                    helperText={this.state.errors.contactPerson.message}
                                    inputProps={
                                        { readOnly: this.props.readOnly || this.props.readEdit }
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} implementation="css" smDown component={Hidden} />


                        <Grid item md={1} implementation="css" smDown component={Hidden} />
                        <Grid item md={3} xs={6} sm={3} >
                            <FormControl fullWidth>
                                <TextField
                                    label={t('otp.form.page.email')}
                                    value={this.state.email || ''}
                                    variant="outlined"
                                    name="email"
                                    fullWidth
                                    onChange={this.handleChange}
                                    error={this.state.errors.email.message.length === 0 ? false : true}
                                    helperText={this.state.errors.email.message}
                                    inputProps={
                                        { readOnly: this.props.readOnly || this.props.readEdit }
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} xs={6} sm={3} >
                            <FormControl fullWidth>
                                <TextField
                                    label={t('otp.form.page.phone')}
                                    value={this.state.phoneNumber || ''}
                                    variant="outlined"
                                    name="phoneNumber"
                                    fullWidth
                                    type={'tel'}
                                    onChange={this.handleChange}
                                    error={this.state.errors.phoneNumber.message.length === 0 ? false : true}
                                    helperText={this.state.errors.phoneNumber.message}
                                    inputProps={
                                        { readOnly: this.props.readOnly || this.props.readEdit }
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={6} implementation="css" smDown component={Hidden} />


                        <Grid item md={3} xs={1} implementation="css" smDown component={Hidden} />
                        <Grid item md={2} xs={1}>
                            <Button
                                variant="outlined"
                                color="primary"
                                fullWidth
                                size="large"
                                onClick={this.onClickBack}
                            >
                                {this.props.goBack}
                            </Button>
                        </Grid>
                        <Grid item md={1} xs={1} implementation="css" smDown component={Hidden} />
                        {
                            allowAccessRole(MODULES.OTP, OPERATIONS_ALLOWED.manage) &&
                            <Grid item md={2} xs={1}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    fullWidth
                                    size="large">
                                    {this.props.buttonRight}
                                </Button>
                            </Grid>
                        }
                        <Grid item md={3} xs={1} implementation="css" smDown component={Hidden} />
                    </Grid>
                </form>
            </React.Fragment>
        );
    }
}

export default OtpFormView;