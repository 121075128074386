import React from 'react'
import i18next from 'i18next'
import { getDataForUsersChart } from './kpigroups/users/usersFunctions'
import { getDataForTripsChart } from './kpigroups/trips/tripsFunctions'
import { getDataForBookingsChart, KPI_SELECTED } from './kpigroups/bookings/bookingsFunctions'
import store from '../../redux/store'
import { getDataForDriversChart } from './kpigroups/drivers/driversFunctions'
import { getDataForVehicleChart } from './kpigroups/vehicles/vehiclesFunctions'
import GetAppIcon from '@material-ui/icons/GetApp'
import jsPDF from 'jspdf'
import { Grid, Hidden, IconButton } from '@material-ui/core'
import html2canvas from 'html2canvas'
import { getDataForJourneyChart } from './kpigroups/journeys/journeyFunctions'
import { getDataForEconomyData } from './kpigroups/economyData/economyFunctions'

export const KPI_CATEGORY = {
    GENERAL_DATA: 0,
    TRIPS: 1,
    JOURNEYS: 2,
    BOOKINGS: 3,
    USERS: 4,
    DRIVERS: 5,
    VEHICLES: 6,
    ECONOMICS_DATA: 7,
}


export const getData = async (kpiCategory, kpiSelected, chartProps) => {
    console.log('kpiCategory =>', kpiCategory)
    console.log('kpiSelected =>', kpiSelected)
    if (kpiCategory == KPI_CATEGORY.TRIPS) {
        return await getDataForTripsChart(kpiSelected, chartProps)
    } else if (kpiCategory == KPI_CATEGORY.BOOKINGS) {
        return await getDataForBookingsChart(kpiSelected, chartProps)
    } else if (kpiCategory === KPI_CATEGORY.USERS) {
        return await getDataForUsersChart(kpiSelected, chartProps)
    } else if (kpiCategory == KPI_CATEGORY.DRIVERS) {
        return await getDataForDriversChart(kpiSelected, chartProps)
    } else if (kpiCategory == KPI_CATEGORY.VEHICLES) {
        return await getDataForVehicleChart(kpiSelected, chartProps)
    } else if (kpiCategory == KPI_CATEGORY.JOURNEYS) {
        return await getDataForJourneyChart(kpiSelected, chartProps)
    } else if (kpiCategory == KPI_CATEGORY.ECONOMICS_DATA) {
        return await getDataForEconomyData(kpiSelected, chartProps)
    } else return []
}

export const ChartProps = (chartType = 'Bar', title, groupedBy, value, filterByDate = false, startDate = '', finishDate = '', timeUnits = null, serviceReducer, tripOrigin = null, groupByFilter, typologySelected) => {
    const weekDays = serviceReducer.weekDays
    return { chartType, title, groupedBy, value, filterByDate, startDate, finishDate, timeUnits, weekDays, tripOrigin, groupByFilter, typologySelected }
}

export const getChartName = groupedBy => {
    let chartNameList = {
        municipalities: i18next.t('commandBox.groupedBy.municipalities'),
        regions: i18next.t('commandBox.groupedBy.regions'),
        otps: i18next.t('commandBox.groupedBy.otps'),
        services: i18next.t('commandBox.groupedBy.services'),
        serviceName: i18next.t('commandBox.groupedBy.services'),
        tenants: i18next.t('commandBox.groupedBy.tenants'),
    }
    return chartNameList[groupedBy]
}

export const getColumnName = (kpiCategory, kpiSelected) => {
    if (kpiCategory == KPI_CATEGORY.GENERAL_DATA) {
        return 'Serveis'
    } else if (kpiCategory == KPI_CATEGORY.BOOKINGS) {
        if (kpiSelected == KPI_SELECTED.TOTAL_BOOKINGS) {
            return 'Reserves'
        }
    }
}

export const FilterOptions = (groupedBy, value, filterByDate = false, startDate = '', finishDate = '', timeUnits = null, tripOrigin = null) => {
    return { groupedBy, value, filterByDate, startDate, finishDate, timeUnits, tripOrigin }
}

export const options = (chartProps, colors = ['green'], filterTitle = null) => {
    const { title, filterByDate, startDate, finishDate } = chartProps
    const options = {
        chart: {
            title,
            subtitle: filterByDate ? i18next.t('commandBox.filterByDate', { startDate: startDate, finishDate: finishDate }) : '',
        },
        calendar: {
            daysOfWeek: 'LMMJVSD',
        },
        title: title + (filterTitle ? ' - ' + filterTitle : ''),
        vAxis: { format: '0' }
        //colors
    }
    return options
}


export const getDistinctValuesFromGroup = (data, groupedBy, value) => {
    let filtered = []
    switch (groupedBy) {
        case 'municipalities':
            filtered = data.filter(item => item.municipalities?.some(m => value.includes(m)))
            return Array.from(new Set(filtered.map(trip => trip.municipalities?.map(text => text)).flat(1)))
        case 'regions':
            filtered = data.filter(item => item.regions?.some(r => value.includes(r)))
            return Array.from(new Set(filtered.map(trip => trip.regions?.map(text => text)).flat(1)))
        case 'otps':
            filtered = data.filter(item => item.otps?.some(o => value.includes(o)))
            return Array.from(new Set(filtered.map(trip => trip.otps?.map(text => text)).flat(1)))
        case 'services':
            filtered = data.filter(item => item.services?.some(s => value.includes(s)))
            return Array.from(new Set(filtered.map(trip => trip.services?.map(text => text)).flat(1)))
        case 'tenants':
            filtered = data.filter(item => item.tenants?.some(t => value.includes(t)))
            return Array.from(new Set(filtered.map(trip => trip.tenants?.map(text => text)).flat(1)))
        default:
            return data
    }
}




export const filterDataByGroup = (data, groupedBy, value) => {
    switch (groupedBy) {
        case 'municipalities':
            return data.filter(item => item.municipalities?.includes(value))
        case 'regions':
            return data.filter(item => item.regions?.includes(value))
        case 'otps':
            return data.filter(item => item.otps?.includes(value))
        case 'services':
            return data.filter(item => item.services?.includes(value))
        case 'tenants':
            return data.filter(item => item.tenants?.includes(value))
        default:
            return data
    }
}

export const getStringTimeUnits = timeUnitKey => {
    if (store.getState().serviceReducer.KPITimeUnits) {
        return store.getState().serviceReducer.KPITimeUnits[timeUnitKey].toString()
    }
}

//Transform an array of objects to dictionary
export const transformObjectToDictionary = data => {
    let results = []
    data.forEach(el => {
        try {
            let elParsed = JSON.parse(el)
            results = [...results, elParsed.name]
        } catch (e) {
            results = results
        }
    })
    console.log('results => ', results)
    return results
}

// download chart to pdf

export const DownloadChart = props => {
    const onClickAction = () => {
        let doc = new jsPDF('l', 'mm', 'a4')
        let width = doc.internal.pageSize.getWidth() - 25
        let height = doc.internal.pageSize.getHeight() - 25
        html2canvas(document.getElementById('chart_' + props.img64.toString())).then(function (canvas) {
            const img = canvas.toDataURL('image/png')
            const imgProps = doc.getImageProperties(img)
            const widthRatio = width / imgProps.width
            const heightRatio = height / imgProps.height
            const ratio = widthRatio > heightRatio ? heightRatio : widthRatio
            const canvasWidth = imgProps.width * ratio
            const canvasHeight = imgProps.height * ratio
            const marginX = (width + 25 - canvasWidth) / 2
            const marginY = (height + 25 - canvasHeight) / 2
            doc.addImage(img, 'PNG', marginX, marginY, canvasWidth, canvasHeight)
            window.open(doc.output('bloburl'))
        })
    }

    return (
        <React.Fragment>
            <Grid container>
                <Grid item md={5} implementation="css" smDown component={Hidden} />
                <Grid item md={7}>
                    <IconButton>
                        <GetAppIcon onClick={onClickAction} />
                    </IconButton>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
