import { combineReducers } from 'redux'
import { authenticationReducer } from './authentication_reducer'
import { alertReducer } from './alert_reducer'
import { operatorReducer } from './operator_reducer'
import { otpReducer } from './otp_reducer'
import { driverReducer } from './driver_reducer'
import { vehicleReducer } from './vehicles_reducer'
import { zoneReducer } from './zones_reducer'
import { areaReducer } from './areas_reducer'
import { serviceReducer } from './services_reducer'
import { customerReducer } from './customer_reducer'
import { chatReducer } from './chat_reducer'
import { incidentReducer } from './incident_reducer'
import { notificationReducer } from './notification_reducer'
import { tripReducer } from './tripReducer'
import { tenantReducer } from './tenant_reducer'
import { rolesReducer } from './roles_reducer'
import { modulesReducer } from './modules_reducer'
import { multipleTripReducer } from './multipleTrip_reducer'
import { reportReducer } from './report_reducer'
import { kpiReducer } from './kpi_reducer'
import { kpiFiltersReducer } from './kpiFilters_reducer'

const reducer = combineReducers({
    authenticationReducer,
    alertReducer,
    operatorReducer,
    otpReducer,
    driverReducer,
    vehicleReducer,
    zoneReducer,
    areaReducer,
    serviceReducer,
    customerReducer,
    chatReducer,
    incidentReducer,
    tripReducer,
    notificationReducer,
    tenantReducer,
    rolesReducer,
    modulesReducer,
    multipleTripReducer,
    reportReducer,
    kpiReducer,
    kpiFiltersReducer,
})

export default reducer
