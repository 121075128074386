import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { incidentActions } from "../redux/actions/incident_actions"
import { notificationActions } from "../redux/actions/notification_actions"
import { chatActions } from "../redux/actions/chat_actions"
import stringConstants from "../constants/strings"
import { userService } from "../services/userService"

export function useNotificationCounts() {
    const dispatch = useDispatch()

    useEffect(() => {
        const claims = JSON.parse(localStorage.getItem('claims'))
        const user = userService.getWithExpiry('user_webAdmin')

        if (user == null || claims == null) return

        const intervalTicketingNotifications = setInterval(() => {
            dispatch(incidentActions.getNumberOfTicketsToReviewFromOpUser(claims.mongo_id));
            dispatch(chatActions.getNumberOfUnreadMessagesBySenderId())
        }, stringConstants.TIME_TICKETING_NOTIFICATIONS)

        const interval = setInterval(() => {
            dispatch(notificationActions.getNotificationsCount())
        }, stringConstants.TIME_NOTIFICATIONS)

        return () => {
            clearInterval(interval)
            clearInterval(intervalTicketingNotifications)
        }


        /* document.addEventListener('visibilitychange', event => {
            const user = userService.getWithExpiry('user_webAdmin')
            if (user) {
                dispatch(notificationActions.getNotificationsCount())
                const claims = JSON.parse(localStorage.getItem('claims'))
                if (claims != null) {
                    if (document.visibilityState === 'visible') {
                        if (allowAccessRole(MODULES.Chat, OPERATIONS_ALLOWED.read)) dispatch(chatActions.getChatDataOnTabChange())
                        if (
                            !document.URL.toString().match(
                                /incidents\/[0-9a-zA-Z]/,
                            )
                        )
                            dispatch(incidentActions.isThereSomethingToReview())
                    }
                }
            }
        }) */
    }, [])
}