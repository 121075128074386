import { incidentConstants } from '../../constants/incident_constants';

let initialState = {
    pending: false,
    pendingResults: false,
    pendingStatus: false,
    pendingPriorities: false,
    pendingCauses: false,
    isThereSomethingToReview: false,
    userWantsToReceiveEmails: false,
    userWantsToReceivePush: false,
    opUserWantsEmail: false,
    opUserWantsPush: false,
    customerWantsEmail: false,
    customerWantsPush: false,
    numberOfPendingToReview: 0,
    results: [],
    allIncidents: [],
    incident_filterPriority: [],
    incident_filterTicketStatus: [],
    incident_filterCreated: [],
    filters: null,
    ticketStatus: [],
    priorities: [],
    filterStartDate: '',
    filterEndDate: ''
};

export function incidentReducer(state = initialState, action) {
    switch (action.type) {
        case incidentConstants.GET_ALL_REQUEST:
            return {
                ...state,
                pending: true
            };
        case incidentConstants.GET_ALL_SUCCESS:
            return {
                ...state,
                results: action.results,
                allIncidents: action.all,
                pending: false
            };
        case incidentConstants.GET_ALL_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
                results: []
            };
        case incidentConstants.GET_SPECIFIC_INCIDENT_REQUEST:
            return {
                ...state,
                pending: true
            };
        case incidentConstants.GET_SPECIFIC_INCIDENT_SUCCESS:
            return {
                ...state,
                incident: action.incident,
                pending: false
            };
        case incidentConstants.GET_SPECIFIC_INCIDENT_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
                results: [],
                incident: []
            };
        case incidentConstants.ASIGN_INCIDENCE_TO_USER_REQUEST:
            return {
                ...state,
                pending: true
            };
        case incidentConstants.ASIGN_INCIDENCE_TO_USER_SUCCESS:
            return {
                ...state,
                userAsigned: action.results,
                pending: false
            };
        case incidentConstants.ASIGN_INCIDENCE_TO_USER_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
                results: []
            };
        case incidentConstants.CLOSE_INCIDENCE_REQUEST:
            return {
                ...state,
                pending: true
            };
        case incidentConstants.CLOSE_INCIDENCE_SUCCESS:
            return {
                ...state,
                result: action.results,
                pending: false
            };
        case incidentConstants.CLOSE_INCIDENCE_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
                results: []
            };
        case incidentConstants.REOPEN_INCIDENCE_REQUEST:
            return {
                ...state,
                pending: true
            };
        case incidentConstants.REOPEN_INCIDENCE_SUCCESS:
            return {
                ...state,
                result: action.results,
                pending: false
            };
        case incidentConstants.REOPEN_INCIDENCE_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
                results: []
            };
        case incidentConstants.GET_CAUSES_REQUEST:
            return {
                ...state,
                pendingCauses: true
            };
        case incidentConstants.GET_CAUSES_SUCCESS:
            return {
                ...state,
                causes: action.causes,
                pendingCauses: false
            };
        case incidentConstants.GET_CAUSES_FAILURE:
            return {
                ...state,
                pendingCauses: false,
                error: action.error,
                causes: []
            };
        case incidentConstants.GET_PRIORITIES_REQUEST:
            return {
                ...state,
                pendingPriorities: true
            };
        case incidentConstants.GET_PRIORITIES_SUCCESS:
            return {
                ...state,
                priorities: action.priorities,
                pendingPriorities: false
            };
        case incidentConstants.GET_PRIORITIES_FAILURE:
            return {
                ...state,
                pendingPriorities: false,
                error: action.error,
                priorities: []
            };
        case incidentConstants.GET_TICKET_STATUS_REQUEST:
            return {
                ...state,
                pendingStatus: true
            };
        case incidentConstants.GET_TICKET_STATUS_SUCCESS:
            return {
                ...state,
                ticketStatus: action.status,
                pendingStatus: false
            };
        case incidentConstants.GET_TICKET_STATUS_FAILURE:
            return {
                ...state,
                pendingStatus: false,
                error: action.error,
                ticketStatus: []
            };
        case incidentConstants.ADD_ACTIVITY_REQUEST:
            return {
                ...state,
                pending: true
            };
        case incidentConstants.ADD_ACTIVITY_SUCCESS:
            return {
                ...state,
                activity: action.activity,
                pending: false
            };
        case incidentConstants.ADD_ACTIVITY_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
                activity: []
            };
        case incidentConstants.SET_INCIDENCE_PRIORITY_REQUEST:
            return {
                ...state,
                pending: true
            };
        case incidentConstants.SET_INCIDENCE_PRIORITY_SUCCESS:
            return {
                ...state,
                priority: action.priority,
                pending: false
            };
        case incidentConstants.SET_INCIDENCE_PRIORITY_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
                priority: []
            };
        case incidentConstants.GET_ALL_ACTIVITIES_IN_INCIDENCE_REQUEST:
            return {
                ...state,
                pending: true
            };
        case incidentConstants.GET_ALL_ACTIVITIES_IN_INCIDENCE_SUCCESS:
            return {
                ...state,
                activities: action.activities,
                pending: false
            };
        case incidentConstants.GET_ALL_ACTIVITIES_IN_INCIDENCE_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
                activities: []
            };
        case incidentConstants.RECEIVE_EMAIL_NOTIFICATIONS_REQUEST:
            return {
                ...state,
                pending: true
            };
        case incidentConstants.RECEIVE_EMAIL_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                userWantsToReceiveEmails: action.userWantsToReceiveEmails,
                pending: false
            };
        case incidentConstants.RECEIVE_EMAIL_NOTIFICATIONS_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
                userWantsToReceiveEmails: false
            };
        case incidentConstants.RECEIVE_PUSH_NOTIFICATIONS_REQUEST:
            return {
                ...state,
                pending: true
            };
        case incidentConstants.RECEIVE_PUSH_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                userWantsToReceivePush: action.userWantsToReceivePush,
                pending: false
            };
        case incidentConstants.RECEIVE_PUSH_NOTIFICATIONS_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
                userWantsToReceivePush: false
            };
        case incidentConstants.GET_OP_USERS_WANTS_EMAIL_NOTIFICATIONS_REQUEST:
            return {
                ...state,
                pending: true
            };
        case incidentConstants.GET_OP_USERS_WANTS_EMAIL_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                opUserWantsEmail: action.opUserWantsEmail,
                pending: false
            };
        case incidentConstants.GET_OP_USERS_WANTS_EMAIL_NOTIFICATIONS_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
                opUserWantsEmail: false
            };
        case incidentConstants.GET_CUSTOMERS_WANTS_EMAIL_NOTIFICATIONS_REQUEST:
            return {
                ...state,
                pending: true
            };
        case incidentConstants.GET_CUSTOMERS_WANTS_EMAIL_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                customerWantsEmail: action.customerWantsEmail,
                pending: false
            };
        case incidentConstants.GET_CUSTOMERS_WANTS_EMAIL_NOTIFICATIONS_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
                customerWantsEmail: false
            };
        case incidentConstants.GET_OP_USERS_WANTS_PUSH_NOTIFICATIONS_REQUEST:
            return {
                ...state,
                pending: true
            };
        case incidentConstants.GET_OP_USERS_WANTS_PUSH_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                opUserWantsPush: action.opUserWantsPush,
                pending: false
            };
        case incidentConstants.GET_OP_USERS_WANTS_PUSH_NOTIFICATIONS_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
                opUserWantsPush: false
            };
        case incidentConstants.GET_CUSTOMERS_WANTS_PUSH_NOTIFICATIONS_REQUEST:
            return {
                ...state,
                pending: true
            };
        case incidentConstants.GET_CUSTOMERS_WANTS_PUSH_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                customerWantsPush: action.customerWantsPush,
                pending: false
            };
        case incidentConstants.GET_CUSTOMERS_WANTS_PUSH_NOTIFICATIONS_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
                customerWantsPush: false
            };
        case incidentConstants.SET_PENDING_TO_REVIEW_REQUEST:
            return {
                ...state,
                pending: true
            };
        case incidentConstants.SET_PENDING_TO_REVIEW_SUCCESS:
            return {
                ...state,
                pendingToReview: action.pendingToReview,
                pending: false
            };
        case incidentConstants.SET_PENDING_TO_REVIEW_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
                pendingToReview: false
            };
        case incidentConstants.IS_THERE_SOMETHING_TO_REVIEW_REQUEST:
            return {
                ...state,
                pending: true
            };
        case incidentConstants.IS_THERE_SOMETHING_TO_REVIEW_SUCCESS:
            return {
                ...state,
                isThereSomethingToReview: action.isThereSomethingToReview,
                pending: false
            };
        case incidentConstants.IS_THERE_SOMETHING_TO_REVIEW_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
                isThereSomethingToReview: false
            };
        case incidentConstants.GET_NUMBER_OF_TICKETING_TO_REVIEW_FROM_OP_USER_REQUEST:
            return {
                ...state,
                pending: true
            };
        case incidentConstants.GET_NUMBER_OF_TICKETING_TO_REVIEW_FROM_OP_USER_SUCCESS:
            return {
                ...state,
                numberOfPendingToReview: action.numberOfPendingToReview,
                pending: false
            };
        case incidentConstants.GET_NUMBER_OF_TICKETING_TO_REVIEW_FROM_OP_USER_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
                numberOfPendingToReview: false
            };
        case incidentConstants.MODIFY_INCIDENCE_REQUEST:
            return {
                ...state,
                pending: true
            };
        case incidentConstants.MODIFY_INCIDENCE_SUCCESS:
            return {
                ...state,
                incident: action.incident,
                pending: false
            };
        case incidentConstants.MODIFY_INCIDENCE_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        case incidentConstants.SET_FILTER_INCIDENTS:
            let priorityFilter = action.prop == 'priority' ? action.values : state.incident_filterPriority
            let ticketStatusFilter = action.prop == 'ticketStatus' ? action.values : state.incident_filterTicketStatus
            let createdFilter = action.prop == 'created' ? action.values : state.incident_filterCreated
            return {
                ...state,
                incident_filterPriority: priorityFilter,
                incident_filterTicketStatus: ticketStatusFilter,
                incident_filterCreated: createdFilter,
                filters: action.filters
            };
        case incidentConstants.SET_FILTER_DATE_INCIDENCES:
            if (action.typeDate == 'startDate')
                return {
                    ...state,
                    filterStartDate: action.value
                }
            else
                return {
                    ...state,
                    filterEndDate: action.value
                }
        default: return state;
    }
}