import React from 'react'
import { Checkbox, FormControl, FormControlLabel, Grid, InputAdornment, TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { REGULAR_SERVICES_TYPES, SERVICES_TYPE } from "../../../../constants/types"
import { useSelector } from "react-redux"
import { factory } from '../../../../helpers/factory'
import i18next from 'i18next'
import { InputNumberWithLabel } from './inputNumberWithLabel'
import SectionStyled from './section'



export const HeaderSection = ({ handleChange, detail, readOnly, readEdit, state, onChangeOtp, onChangeZones, onChangeServiceType, onChangeCustomers, handleChangeCheckBox, openModalStops, nextAction }) => {

    const customerReducer = useSelector((state) => state.customerReducer)
    const zones = useSelector((state) => state.zoneReducer.results)
    const otps = useSelector((state) => state.otpReducer.results)
    const types = useSelector((state) => state.serviceReducer.serviceTypes)

    return (

        <SectionStyled>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={3}>
                    <FormControl fullWidth>
                        <TextField
                            label={i18next.t('services_comp.form.page.name')}
                            value={state.name || ''}
                            variant="outlined"
                            name="name"
                            fullWidth
                            onChange={handleChange}
                            error={state.errors.name.message.length === 0 ? false : true}
                            helperText={state.errors.name.message}
                            InputProps={{
                                readOnly: readOnly,
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <FormControl fullWidth>
                        <Autocomplete
                            id="otpId"
                            value={state.otpId || ''}
                            onChange={onChangeOtp}
                            options={otps}
                            getOptionSelected={(option, value) => value != '' ?? (option.id == value || option.id == value.id)}
                            getOptionLabel={option => option.name || factory.getLabelForName(otps, state.otpId) || ""}
                            disabled={readOnly}
                            renderInput={params => <TextField {...params} variant={'outlined'} label={i18next.t('services_comp.form.page.otp')} helperText={state.errors.otpId.message} name="otpId" error={state.errors.otpId.message.length === 0 ? false : true} />}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={12} lg={6}>
                    <FormControl fullWidth>
                        <Autocomplete
                            id="zones"
                            value={state.zones || []}
                            onChange={onChangeZones}
                            options={zones}
                            multiple
                            getOptionLabel={option => (option.Name ? option.Name : option.name ? option.name : '')}
                            getOptionSelected={(option, value) => option.id == value || option.id == value.id}
                            disabled={readOnly || readEdit}
                            renderInput={params => <TextField {...params} variant={'outlined'} label={i18next.t('services_comp.form.page.zones')} helperText={state.errors.zones.message} name="zones" error={state.errors.zones.message.length === 0 ? false : true} />}
                        />
                    </FormControl>
                </Grid>
                {
                    detail ?
                        <Grid item md={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            id="regions"
                                            multiple
                                            value={state.regions || []}
                                            options={[]}
                                            getOptionLabel={option => (option)}
                                            getOptionSelected={(option, value) => option.id == value || option.id == value.id}
                                            disabled={true}
                                            renderInput={params => <TextField {...params} variant={'outlined'} label={i18next.t('services_comp.form.page.region')} />}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            id="municipalities"
                                            multiple
                                            value={state.municipalities || []}
                                            options={[]}
                                            getOptionLabel={option => (option)}
                                            getOptionSelected={(option, value) => option.id == value || option.id == value.id}
                                            disabled={readOnly || state.otpId == ''}
                                            renderInput={params => <TextField {...params} variant={'outlined'} label={i18next.t('services_comp.form.page.municipality')} />}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        : null
                }
            </Grid>



            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={3}>
                    <FormControl fullWidth>
                        <Autocomplete
                            id="serviceTypePrice"
                            name="serviceType"
                            value={state.serviceType}
                            onChange={onChangeServiceType}
                            options={types}
                            getOptionSelected={(option, value) => option.id == value.id || option.id == value}
                            getOptionLabel={(option) => option.name || types.find((item) => item.id == state.serviceType?.id)?.name || ""}
                            disabled={readOnly || nextAction == "edit"}
                            renderInput={params =>
                                <TextField {...params}
                                    variant={'outlined'}
                                    label={i18next.t('services_comp.form.page.serviceType')}
                                    helperText={state.errors.serviceType.message}
                                    name="serviceType"
                                    error={state.errors.serviceType.message.length === 0 ? false : true}
                                />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={4} lg={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.isRestricted}
                                name='isRestricted'
                                disabled={readOnly}
                                onChange={handleChangeCheckBox}
                                color="primary"
                            />
                        }
                        label={i18next.t('services_comp.form.page.isRestricted')}
                    />
                </Grid>
                <Grid item xs={12} md={4} lg={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.hasZoneWithStops}
                                name='hasZoneWithStops'
                                disabled={REGULAR_SERVICES_TYPES.includes(state.serviceType?.id) || state.serviceType == '' || readOnly}
                                onChange={openModalStops}
                                color="primary"
                            />
                        }
                        label={i18next.t('services_comp.form.page.hasZoneWithStops')}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <InputNumberWithLabel
                        value={state.assignmentMaxWaitingTimeMinutes}
                        name="assignmentMaxWaitingTimeMinutes"
                        onChange={handleChange}
                        disabled={state.serviceType == null ? false : (state.serviceType?.id != SERVICES_TYPE.assignmentWheelService && state.serviceType?.id != SERVICES_TYPE.regularWithSchedule)}
                        error={state.errors.assignmentMaxWaitingTimeMinutes.message.length === 0 ? false : true}
                        helperText={state.errors.assignmentMaxWaitingTimeMinutes.message}
                        label={i18next.t('services_comp.form.page.assignmentMaxWaitingTime')}
                        min={0}
                        readOnly={readOnly}
                    />
                </Grid>
                {state.serviceType?.id == SERVICES_TYPE.regularWithSchedule ?
                    <Grid item xs={12} md={4} lg={2}>
                        <FormControl fullWidth>
                            <TextField
                                InputLabelProps={{
                                    style: { pointerEvents: 'auto' },
                                    shrink: true,
                                }}
                                label={
                                    <div>
                                        {i18next.t('services_comp.form.page.pricePerDistance')}
                                    </div>
                                }
                                value={state.pricePerDistance}
                                variant="outlined"
                                name="pricePerDistance"
                                fullWidth
                                type={'number'}
                                onChange={handleChange}
                                error={state.errors.pricePerDistance.message.length === 0 ? false : true}
                                helperText={state.errors.pricePerDistance.message}
                                inputProps={{
                                    min: 0,
                                    step: '.01',
                                }}
                                InputProps={{
                                    readOnly: readOnly,
                                    endAdornment: <InputAdornment position='end'>€</InputAdornment>
                                }}
                            />
                        </FormControl>
                    </Grid>


                    :
                    null
                }
            </Grid>
            {state.isRestricted ? (
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                            <FormControl fullWidth>
                                <TextField
                                    id="restrictionMessageEs"
                                    label={i18next.t('services_comp.form.page.restrictionMessageEs')}
                                    name={'restrictionMessageEs'}
                                    value={state.restrictionMessageEs}
                                    multiline
                                    onChange={handleChange}
                                    error={state.errors.restrictionMessageEs.message.length === 0 ? false : true}
                                    helperText={state.errors.restrictionMessageEs.message}
                                    rows={4}
                                    variant="outlined"
                                    InputProps={{
                                        readOnly: readOnly,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControl fullWidth>
                                <TextField
                                    id="restrictionMessageEn"
                                    label={i18next.t('services_comp.form.page.restrictionMessageEn')}
                                    multiline
                                    onChange={handleChange}
                                    value={state.restrictionMessageEn}
                                    error={state.errors.restrictionMessageEn.message.length === 0 ? false : true}
                                    helperText={state.errors.restrictionMessageEn.message}
                                    rows={4}
                                    InputProps={{
                                        readOnly: readOnly,
                                    }}
                                    name={'restrictionMessageEn'}
                                    variant="outlined"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControl fullWidth>
                                <TextField
                                    id="restrictionMessageCa"
                                    label={i18next.t('services_comp.form.page.restrictionMessageCa')}
                                    multiline
                                    onChange={handleChange}
                                    value={state.restrictionMessageCa}
                                    error={state.errors.restrictionMessageCa.message.length === 0 ? false : true}
                                    helperText={state.errors.restrictionMessageCa.message}
                                    rows={4}
                                    InputProps={{
                                        readOnly: readOnly,
                                    }}
                                    name={'restrictionMessageCa'}
                                    variant="outlined"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    id="authorizedCustomers"
                                    name="authorizedCustomers"
                                    multiple
                                    value={state.authorizedCustomers || []}
                                    onChange={onChangeCustomers}
                                    options={customerReducer.results}
                                    getOptionLabel={option => (option && option.fullName ? option && option.fullName : customerReducer.results.find(x => x.id == option) ? customerReducer.results.find(x => x.id == option).fullName : '')}
                                    getOptionSelected={(option, value) => option.fullName == value || option.fullName == value.fullName}
                                    disabled={readOnly}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            variant={'outlined'}
                                            label={i18next.t('services_comp.form.page.authorizedCustomers')}
                                            helperText={state.errors.authorizedCustomers.message}
                                            name="customer"
                                            error={state.errors.authorizedCustomers.message.length === 0 ? false : true}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </>


            ) : null}
        </SectionStyled>

    )
}