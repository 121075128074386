import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, InputAdornment, TextField, Tooltip, withStyles } from '@material-ui/core';
import i18next from 'i18next';
import React from 'react';


const styles = {
    dialogPaper: {
        minHeight: '40vh',
    },
};

const ModalSetDistanceBetweenStops = (props) => {

    let array = Object.assign([], props.stopsSingle)

    if (array?.length > 0 && Array.isArray(array[0])) {
        array = array[0].map(item => item)
    }


    const itemsWithoutFirst = array?.slice(1) ?? []

    const nameWithMax = (name) => {
        return name?.length > 12 ? name.substring(0, 12) + '..' : name
    }

    const { setItems, saveLineWithSchedule } = props

    return (
        <>
            <Dialog
                open={props.open}
                onClose={() => props.setModal(false)}
                maxWidth={'lg'}
                fullWidth
                classes={{ paper: props.classes.dialogPaper }}
            >
                <DialogTitle>
                    {i18next.t('services_comp.lines.configurationDistances')}
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={1}>
                        {
                            itemsWithoutFirst.map((item, i) =>
                                <Grid item md={2} key={'g_' + i}>
                                    <Tooltip title={`${array[i].name} - ${item.name}`} key={i}>
                                        <TextField
                                            key={`stop_` + i}
                                            type='number'
                                            name={`stop_` + i}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            InputProps={{
                                                endAdornment: <InputAdornment position='end'>km</InputAdornment>
                                            }}
                                            inputProps={{
                                                step: ".01",
                                                min: 0
                                            }}
                                            onChange={(e) => {
                                                const itemUpdated = array.find(i => i.name == item.name)
                                                if (parseFloat(e.target.value) >= 0)
                                                    itemUpdated.distance = parseFloat(parseFloat(e.target.value).toFixed(2))
                                                else
                                                    itemUpdated.distance = ""
                                                setItems([...array.map(i => i.name == item.name ? itemUpdated : i)])
                                            }}
                                            variant='outlined'
                                            label={`${nameWithMax(array[i].name)} - ${nameWithMax(item.name)}`}
                                            value={item.distance ?? 0}
                                        />
                                    </Tooltip>
                                </Grid>
                            )
                        }
                        <Grid item>
                            <Box display='flex' alignItems='center'>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    size='large'
                                    onClick={saveLineWithSchedule}
                                >
                                    {i18next.t('services_comp.lines.saveButton')}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )

}

export default withStyles(styles)(ModalSetDistanceBetweenStops)