import React from 'react';
import CreateDriverView from './page';
import Dashboard from '../../dashboard';
import { connect } from 'react-redux';
import { driversActions } from '../../../redux/actions/driver_actions';
import { otpActions } from '../../../redux/actions/otp_actions';
import { vehiclesActions } from '../../../redux/actions/vehicles_actions';
import { withTranslation } from 'react-i18next'
import { compose } from 'redux';


class DriverCreate extends React.Component {

    componentDidMount() {
        this.props.getAllOTP()
    }

    render() {
        const { t } = this.props
        return (
            <Dashboard
                component={
                    <CreateDriverView
                        title={t('drivers.create.title')}
                        readOnly={false}
                        sendDriver={this.props.insert}
                        nextAction={'create'}
                        buttonRight={t('drivers.form.page.buttonRegister')}
                        goBack={t('drivers.form.page.goBack')}
                        {...this.props}
                    />
                }
            />
        );
    }
}

function mapState(state) {
    const { driverReducer, otpReducer, vehicleReducer } = state;
    return { driverReducer, otpReducer, vehicleReducer };
}

const actionCreators = {
    insert: driversActions.insert,
    getAllOTP: otpActions.getAll,
    getVehiclesByOTP: vehiclesActions.getByOtpId
}

export default compose(withTranslation('common'), connect(mapState, actionCreators))(DriverCreate)