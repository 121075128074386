import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import TimeServicesByDriver from "../../../kpigroups/drivers/timeServicesByDriver";
import KpiWrapper from "../../../components/kpiWrapper";

export default function TimePerDriversChart({ isLoading }) {
    const { t } = useTranslation()
    const driverCalendar = useSelector(state => state.kpiReducer.driverCalendar);
    const driverCalendarLoading = useSelector(state => state.kpiReducer.driverCalendarLoading)
    const weekDays = useSelector(state => state.serviceReducer.weekDays)
    isLoading = isLoading || driverCalendarLoading

    return (
        <KpiWrapper
            title={t("commandBox.drivers.timePerDriverChart.title")}
            isLoading={driverCalendarLoading}
            skeletonVariant="rect"
            skeletonHeight="840px"
        >
            <TimeServicesByDriver
                data={driverCalendar}
                chartType="ColumnChart"
                groupedBy="services"
                weekDays={weekDays}
            />
        </KpiWrapper>
    )
}