import React from 'react';
import ItemStop from '../stop';
import { Grid, Hidden, FormControl, Button } from '@material-ui/core';


class StopsForm extends React.Component {

    render() {
        const { t, zoneReducer, state } = this.props
        return (
            <React.Fragment>
                <Grid container style={{ maxHeight: 500, overflowY: 'scroll', overflowX: 'hidden', maxWidth: '100%' }}>
                    {
                        this.props.state.stops.map((item, index) => (
                            <ItemStop
                                key={item.id}
                                index={index}
                                id={item.id}
                                value={item}
                                length={this.props.state.stops.length}
                                errors={this.props.state.errors.items[index]}
                                t={t}
                                onChangeAddress={this.props.onChangeAddress}
                                onChangeLatLon={this.props.onChangeLatLon}
                                onChange={this.props.onChange}
                                add={this.props.addStop}
                                remove={this.props.removeItem}
                            />
                        ))
                    }
                    {
                        state.zone !== '' && (
                            <Grid container spacing={2} style={{ paddingBottom: 25, paddingTop: 25 }}>
                                <Grid item md={4} implementation="css" smDown component={Hidden} />
                                <Grid item md={3}>
                                    <FormControl fullWidth>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={this.props.onSubmit}
                                            disabled={zoneReducer.pending || this.props.state.zone == '' || this.props.state.zone == null ? true : false}
                                            size="large"
                                        >
                                            {t('zones.stops.form.save')}
                                        </Button>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        )
                    }

                </Grid>

            </React.Fragment>
        );
    }
}

export default StopsForm;