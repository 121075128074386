export const servicesConstants = {
    ADD_REQUEST: 'SERVICES_REGISTER_REQUEST',
    ADD_SUCCESS: 'SERVICES_REGISTER_SUCCESS',
    ADD_FAILURE: 'SERVICES_REGISTER_FAILURE',

    DETAIL_REQUEST: 'SERVICES_DETAIL_REQUEST',
    DETAIL_SUCCESS: 'SERVICES_DETAIL_SUCCESS',
    DETAIL_FAILURE: 'SERVICES_DETAIL_FAILURE',

    EDIT_REQUEST: 'SERVICES_EDIT_REQUEST',
    EDIT_SUCCESS: 'SERVICES_EDIT_SUCCESS',
    EDIT_FAILURE: 'SERVICES_EDIT_FAILURE',

    GETALL_REQUEST: 'SERVICES_GETALL_REQUEST',
    GETALL_SUCCESS: 'SERVICES_GETALL_SUCCESS',
    GETALL_FAILURE: 'SERVICES_GETALL_FAILURE',

    GETBYID_REQUEST: 'SERVICES_GETBYID_REQUEST',
    GETBYID_SUCCESS: 'SERVICES_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'SERVICES_GETBYID_FAILURE',

    GET_TYPES_REQUEST: 'SERVICES_GET_TYPES_REQUEST',
    GET_TYPES_SUCCESS: 'SERVICES_GET_TYPES_SUCCESS',
    GET_TYPES_FAILURE: 'SERVICES_GET_TYPES_FAILURE',

    GET_PRICES_REQUEST: 'SERVICES_GET_PRICES_REQUEST',
    GET_PRICES_SUCCESS: 'SERVICES_GET_PRICES_SUCCESS',
    GET_PRICES_FAILURE: 'SERVICES_GET_PRICES_FAILURE',

    GET_WEEK_DAYS_REQUEST: 'SERVICES_GET_WEEK_DAYS_REQUEST',
    GET_WEEK_DAYS_SUCCESS: 'SERVICES_GET_WEEK_DAYS_SUCCESS',
    GET_WEEK_DAYS_FAILURE: 'SERVICES_GET_WEEK_DAYS_FAILURE',

    GET_ZONES_STOPS_REQUEST: 'SERVICES_GET_ZONES_STOPS_REQUEST',
    GET_ZONES_STOPS_SUCCESS: 'SERVICES_GET_ZONES_STOPS_SUCCESS',
    GET_ZONES_STOPS_FAILURE: 'SERVICES_GET_ZONES_STOPS_FAILURE',

    UPDATE_ZONES_STOPS_REQUEST: 'SERVICES_UPDATE_ZONES_STOPS_REQUEST',
    UPDATE_ZONES_STOPS_SUCCESS: 'SERVICES_UPDATE_ZONES_STOPS_SUCCESS',
    UPDATE_ZONES_STOPS_FAILURE: 'SERVICES_UPDATE_ZONES_STOPS_FAILURE',

    DELETE_REQUEST: 'SERVICES_DELETE_REQUEST',
    DELETE_SUCCESS: 'SERVICES_DELETE_SUCCESS',
    DELETE_FAILURE: 'SERVICES_DELETE_FAILURE',

    DISABLE_REQUEST: 'SERVICES_DISABLE_REQUEST',
    DISABLE_SUCCESS: 'SERVICES_DISABLE_SUCCESS',
    DISABLE_FAILURE: 'SERVICES_DISABLE_FAILURE',

    ENABLE_REQUEST: 'SERVICES_ENABLE_REQUEST',
    ENABLE_SUCCESS: 'SERVICES_ENABLE_SUCCESS',
    ENABLE_FAILURE: 'SERVICES_ENABLE_FAILURE',

    SET_CALENDAR_REQUEST: 'SERVICES_SET_CALENDAR_REQUEST',
    SET_CALENDAR_SUCCESS: 'SERVICES_SET_CALENDAR_SUCCESS',
    SET_CALENDAR_FAILURE: 'SERVICES_SET_CALENDAR_FAILURE',


    GET_CALENDAR_SUCCESS: 'SERVICES_GET_CALENDAR_SUCCESS',

    GET_ROUTES_REQUEST: 'GET_ROUTES_REQUEST',
    GET_ROUTES_SUCCESS: 'GET_ROUTES_SUCCESS',
    GET_ROUTES_FAILURE: 'GET_ROUTES_FAILURE',

    GET_ROUTES_FROM_VEHICLE_REQUEST: 'GET_ROUTES_FROM_VEHICLE_REQUEST',
    GET_ROUTES_FROM_VEHICLE_SUCCESS: 'GET_ROUTES_FROM_VEHICLE_SUCCESS',
    GET_ROUTES_FROM_VEHICLE_FAILURE: 'GET_ROUTES_FROM_VEHICLE_FAILURE',

    GET_SPECIFIC_ROUTE_REQUEST: 'GET_SPECIFIC_ROUTE_REQUEST',
    GET_SPECIFIC_ROUTE_SUCCESS: 'GET_SPECIFIC_ROUTE_SUCCESS',
    GET_SPECIFIC_ROUTE_FAILURE: 'GET_SPECIFIC_ROUTE_FAILURE',

    SET_ROUTE_ID: 'SET_ROUTE_ID',
    GET_ROUTE_ID: 'GET_ROUTE_ID',

    SET_WORKS_DAY: 'SET_WORK_DAY',
    SET_EXCLUDE_DAYS: 'SET_EXCLUDE_DAYS',
    SET_EXCLUDED_WORKING_DAYS: 'SET_EXCLUDED_WORKING_DAYS',
    SET_RANGE_CALENDAR: 'SET_RANGE_CALENDAR',
    SET_WORKING_RANGE_CALENDAR: 'SET_WORKING_RANGE_CALENDAR',

    REMOVE_EXCLUDE_DAYS: 'REMOVE_EXCLUDE_DAYS',
    REMOVE_EXCLUDED_WORKING_DAYS: 'REMOVE_EXCLUDED_WORKING_DAYS',

    SET_SERVICE_DATA_EDIT: 'SERVICE_SET_SERVICE_DATA_EDIT',

    ADD_TIME_SHIFT_DAY: 'ADD_TIME_SHIFT_DAY',
    ADD_TIME_SHIFT_BREAK: 'ADD_TIME_SHIFT_BREAK',
    ADD_TIME_SHIFT_HOUR: 'ADD_TIME_SHIFT_HOUR',
    ADD_TIME_REQUESTSHIFT_DAY: 'ADD_TIME_REQUESTSHIFT_DAY',

    REMOVE_TIME_SHIFT_DAY: 'REMOVE_TIME_SHIFT_DAY',
    REMOVE_TIME_SHIFT_BREAK: 'REMOVE_TIME_SHIFT_BREAK',
    REMOVE_TIME_SHIFT_HOUR: 'REMOVE_TIME_SHIFT_HOUR',
    REMOVE_TIME_REQUESTSHIFT_DAY: 'REMOVE_TIME_REQUESTSHIFT_DAY',

    SET_SHIFT_DAY: 'SET_SHIFT_DAY',
    SET_SHIFT_BREAK: 'SET_SHIFT_BREAK',
    SET_REQUEST_SHIFT_DAY: 'SET_REQUEST_SHIFT_DAY',
    SET_SHIFT_HOUR: 'SET_SHIFT_HOUR',

    GET_OLD_JOURNEYS_REQUEST: 'GET_OLD_JOURNEYS_REQUEST',
    GET_OLD_JOURNEYS_SUCCESS: 'GET_OLD_JOURNEYS_SUCCESS',
    GET_OLD_JOURNEYS_FAILURE: 'GET_OLD_JOURNEYS_FAILURE',

    GET_JOURNEYS_REQUEST: 'GET_JOURNEYS_REQUEST',
    GET_JOURNEYS_SUCCESS: 'GET_JOURNEYS_SUCCESS',
    GET_JOURNEYS_FAILURE: 'GET_JOURNEYS_FAILURE',
    GET_JOURNEYS_CLEAR: 'GET_JOURNEYS_CLEAR',

    GET_ROUTE_STOPS_REQUEST: 'GET_ROUTE_STOPS_REQUEST',
    GET_ROUTE_STOPS_SUCCESS: 'GET_ROUTE_STOPS_SUCCESS',
    GET_ROUTE_STOPS_FAILURE: 'GET_ROUTE_STOPS_FAILURE',

    GET_TRIPS_REQUEST: 'GET_TRIPS_REQUEST',
    GET_TRIPS_SUCCESS: 'GET_TRIPS_SUCCESS',
    GET_TRIPS_FAILURE: 'GET_TRIPS_FAILURE',

    GET_SERVICES_TYPES_REQUEST: 'GET_SERVICES_TYPES_REQUEST',
    GET_SERVICES_TYPES_SUCCESS: 'GET_SERVICES_TYPES_SUCCESS',
    GET_SERVICES_TYPES_FAILURE: 'GET_SERVICES_TYPES_FAILURE',

    GET_TRIPS_TYPES_REQUEST: 'GET_TRIPS_TYPES_REQUEST',
    GET_TRIPS_TYPES_SUCCESS: 'GET_TRIPS_TYPES_SUCCESS',
    GET_TRIPS_TYPES_FAILURE: 'GET_TRIPS_TYPES_FAILURE',

    GET_AVAIABLE_VEHICLE_CAPACITY_REQUEST: 'GET_AVAIABLE_VEHICLE_CAPACITY_REQUEST',
    GET_AVAIABLE_VEHICLE_CAPACITY_SUCCESS: 'GET_AVAIABLE_VEHICLE_CAPACITY_SUCCESS',
    GET_AVAIABLE_VEHICLE_CAPACITY_FAILURE: 'GET_AVAIABLE_VEHICLE_CAPACITY_FAILURE',

    GET_JOURNEYS_OBSERVATIONS_REQUEST: 'GET_JOURNEYS_OBSERVATIONS_REQUEST',
    GET_JOURNEYS_OBSERVATIONS_SUCCESS: 'GET_JOURNEYS_OBSERVATIONS_SUCCESS',
    GET_JOURNEYS_OBSERVATIONS_FAILURE: 'GET_JOURNEYS_OBSERVATIONS_FAILURE',

    ADD_JOURNEYS_OBSERVATIONS_REQUEST: 'ADD_JOURNEYS_OBSERVATIONS_REQUEST',
    ADD_JOURNEYS_OBSERVATIONS_SUCCESS: 'ADD_JOURNEYS_OBSERVATIONS_SUCCESS',
    ADD_JOURNEYS_OBSERVATIONS_FAILURE: 'ADD_JOURNEYS_OBSERVATIONS_FAILURE',

    GET_DRIVER_OBSERVATIONS_REQUEST: 'GET_DRIVER_OBSERVATIONS_REQUEST',
    GET_DRIVER_OBSERVATIONS_SUCCESS: 'GET_DRIVER_OBSERVATIONS_SUCCESS',
    GET_DRIVER_OBSERVATIONS_FAILURE: 'GET_DRIVER_OBSERVATIONS_FAILURE',

    ADD_DRIVER_OBSERVATIONS_REQUEST: 'ADD_DRIVER_OBSERVATIONS_REQUEST',
    ADD_DRIVER_OBSERVATIONS_SUCCESS: 'ADD_DRIVER_OBSERVATIONS_SUCCESS',
    ADD_DRIVER_OBSERVATIONS_FAILURE: 'ADD_DRIVER_OBSERVATIONS_FAILURE',

    GET_CUSTOMER_OBSERVATIONS_REQUEST: 'GET_CUSTOMER_OBSERVATIONS_REQUEST',
    GET_CUSTOMER_OBSERVATIONS_SUCCESS: 'GET_CUSTOMER_OBSERVATIONS_SUCCESS',
    GET_CUSTOMER_OBSERVATIONS_FAILURE: 'GET_CUSTOMER_OBSERVATIONS_FAILURE',

    ADD_CUSTOMER_OBSERVATIONS_REQUEST: 'ADD_CUSTOMER_OBSERVATIONS_REQUEST',
    ADD_CUSTOMER_OBSERVATIONS_SUCCESS: 'ADD_CUSTOMER_OBSERVATIONS_SUCCESS',
    ADD_CUSTOMER_OBSERVATIONS_FAILURE: 'ADD_CUSTOMER_OBSERVATIONS_FAILURE',

    GET_TRIP_OBSERVATIONS_REQUEST: 'GET_TRIP_OBSERVATIONS_REQUEST',
    GET_TRIP_OBSERVATIONS_SUCCESS: 'GET_TRIP_OBSERVATIONS_SUCCESS',
    GET_TRIP_OBSERVATIONS_FAILURE: 'GET_TRIP_OBSERVATIONS_FAILURE',

    ADD_TRIP_OBSERVATIONS_REQUEST: 'ADD_TRIP_OBSERVATIONS_REQUEST',
    ADD_TRIP_OBSERVATIONS_SUCCESS: 'ADD_TRIP_OBSERVATIONS_SUCCESS',
    ADD_TRIP_OBSERVATIONS_FAILURE: 'ADD_TRIP_OBSERVATIONS_FAILURE',

    SET_FILTER_SERVICE: 'SERVICE_SET_FILTER',
    SET_FILTER_ROUTE: 'ROUTE_SET_FILTER',
    SET_FILTER_JOURNEY: 'JOURNEY_SET_FILTER',

    CLEAN_FILTER_SERVICE: 'SERVICE_CLEAN_FILTER',
    CLEAN_FILTER_ROUTE: 'ROUTE_CLEAN_FILTER',
    CLEAN_FILTER_JOURNEY: 'JOURNEY_CLEAN_FILTER',

    SET_ABSENCES_REQUEST: 'SET_ABSENCES_REQUEST',
    SET_ABSENCES_SUCCESS: 'SET_ABSENCES_SUCCESS',
    SET_ABSENCES_FAILURE: 'SET_ABSENCES_FAILURE',

    REMOVE_CURRENT_PENALTY_REQUEST: 'REMOVE_CURRENT_PENALTY_REQUEST',
    REMOVE_CURRENT_PENALTY_SUCCESS: 'REMOVE_CURRENT_PENALTY_SUCCESS',
    REMOVE_CURRENT_PENALTY_FAILURE: 'REMOVE_CURRENT_PENALTY_FAILURE',

    REMOVE_LINE_REQUEST: 'REMOVE_LINE_REQUEST',
    REMOVE_LINE_SUCCESS: 'REMOVE_LINE_SUCCESS',
    REMOVE_LINE_FAILURE: 'REMOVE_LINE_FAILURE',

    SET_TYPOLOGY_REQUEST: 'SET_TYPOLOGY_REQUEST',
    SET_TYPOLOGY_SUCCESS: 'SET_TYPOLOGY_SUCCESS',
    SET_TYPOLOGY_FAILURE: 'SET_TYPOLOGY_FAILURE',

    GET_TYPE_TYPOLOGY_REQUEST: 'GET_TYPE_TYPOLOGY_REQUEST',
    GET_TYPE_TYPOLOGY_SUCCESS: 'GET_TYPE_TYPOLOGY_SUCCESS',
    GET_TYPE_TYPOLOGY_FAILURE: 'GET_TYPE_TYPOLOGY_FAILURE',

    GET_TYPE_PERIODICITY_REQUEST: 'GET_TYPE_PERIODICITY_REQUEST',
    GET_TYPE_PERIODICITY_SUCCESS: 'GET_TYPE_PERIODICITY_SUCCESS',
    GET_TYPE_PERIODICITY_FAILURE: 'GET_TYPE_PERIODICITY_FAILURE',

    GET_ANTICIPATION_TYPES_REQUEST: 'SERVICE_GET_ANTICIPATION_TYPES_REQUEST',
    GET_ANTICIPATION_TYPES_SUCCESS: 'SERVICE_GET_ANTICIPATION_TYPES_SUCCESS',
    GET_ANTICIPATION_TYPES_FAILURE: 'SERVICE_GET_ANTICIPATION_TYPES_FAILURE',

    GET_REPORT_REQUEST: 'SERVICE_GET_REPORT_REQUEST',
    GET_REPORT_SUCCESS: 'SERVICE_GET_REPORT_SUCCESS',
    GET_REPORT_FAILURE: 'SERVICE_GET_REPORT_FAILURE',

    SET_FILTER_JOURNEY_DATES: 'SET_FILTER_JOURNEY_DATES',

    CLEAN_SERVICE_LINE: 'SERVICE_CLEAN_SERVICE_LINE',

    GET_SERVICE_LINE_REQUEST: 'SERVICE_GET_SERVICE_LINE_REQUEST',
    GET_SERVICE_LINE_SUCCESS: 'SERVICE_GET_SERVICE_LINE_SUCCESS',
    GET_SERVICE_LINE_FAILURE: 'SERVICE_GET_SERVICE_LINE_FAILURE',

    GET_SERVICE_LINE_STOPS_AVAILABLE_SINGLE_REQUEST: 'SERVICE_GET_SERVICE_LINE_STOPS_AVAILABLE_SINGLE_REQUEST',
    GET_SERVICE_LINE_STOPS_AVAILABLE_RETURN_REQUEST: 'SERVICE_GET_SERVICE_LINE_STOPS_AVAILABLE_RETURN_REQUEST',
    GET_SERVICE_LINE_STOPS_AVAILABLE_SINGLE_SUCCESS: 'SERVICE_GET_SERVICE_LINE_STOPS_AVAILABLE_SINGLE_SUCCESS',

    GET_SERVICE_LINE_STOPS_AVAILABLE_SINGLE_FAILURE: 'SERVICE_GET_SERVICE_LINE_STOPS_AVAILABLE_SINGLE_FAILURE',
    GET_SERVICE_LINE_STOPS_AVAILABLE_RETURN_SUCCESS: 'SERVICE_GET_SERVICE_LINE_STOPS_AVAILABLE_RETURN_SUCCESS',
    GET_SERVICE_LINE_STOPS_AVAILABLE_RETURN_FAILURE: 'SERVICE_GET_SERVICE_LINE_STOPS_AVAILABLE_RETURN_FAILURE',

    GET_SERVICE_LINE_STOPS_AVAILABLE_FAILURE: 'SERVICE_GET_SERVICE_LINE_STOPS_AVAILABLE_FAILURE',

    SET_SERVICE_LINE_REQUEST: 'SERVICE_SET_SERVICE_LINE_REQUEST',
    SET_SERVICE_LINE_SUCCESS: 'SERVICE_SET_SERVICE_LINE_SUCCESS',
    SET_SERVICE_LINE_FAILURE: 'SERVICE_SET_SERVICE_LINE_FAILURE',

    SET_EXPEDITIONS_REQUEST: 'SERVICE_SET_EXPEDITIONS_REQUEST',
    SET_EXPEDITIONS_SUCCESS: 'SERVICE_SET_EXPEDITIONS_SUCCESS',
    SET_EXPEDITIONS_FAILURE: 'SERVICE_SET_EXPEDITIONS_FAILURE',

    GET_BILLING_REQUEST: 'SERVICE_GET_BILLING_SERVICE_REQUEST',
    GET_BILLING_SUCCESS: 'SERVICE_GET_BILLING_SERVICE_SUCCESS',
    GET_BILLING_FAILURE: 'SERVICE_GET_BILLING_SERVICE_FAILURE',

    SET_BILLING_REQUEST: 'SERVICE_SET_BILLING_SERVICE_REQUEST',
    SET_BILLING_SUCCESS: 'SERVICE_SET_BILLING_SERVICE_SUCCESS',
    SET_BILLING_FAILURE: 'SERVICE_SET_BILLING_SERVICE_FAILURE',

    GET_KPI_TYPES_REQUEST: 'GET_KPI_TYPES_REQUEST',
    GET_KPI_TYPES_SUCCESS: 'GET_KPI_TYPES_SUCCESS',
    GET_KPI_TYPES_FAILURE: 'GET_KPI_TYPES_FAILURE',

    GET_KPIS_BY_TYPE_REQUEST: 'GET_KPIS_BY_TYPE_REQUEST',
    GET_KPIS_BY_TYPE_SUCCESS: 'GET_KPIS_BY_TYPE_SUCCESS',
    GET_KPIS_BY_TYPE_FAILURE: 'GET_KPIS_BY_TYPE_FAILURE',

    GET_KPIS_FILTER_GROUP_REQUEST: 'GET_KPI_FILTERS_BY_GROUP_REQUEST',
    GET_KPIS_FILTER_GROUP_SUCCESS: 'GET_KPI_FILTERS_BY_GROUP_SUCCESS',
    GET_KPIS_FILTER_GROUP_FAILURE: 'GET_KPI_FILTERS_BY_GROUP_FAILURE',

    GET_KPI_TIME_UNITS_REQUEST: 'GET_KPI_TIME_UNITS_REQUEST',
    GET_KPI_TIME_UNITS_SUCCESS: 'GET_KPI_TIME_UNITS_SUCCESS',
    GET_KPI_TIME_UNITS_FAILURE: 'GET_KPI_TIME_UNITS_FAILURE',

    GET_KPI_CHANNEL_REQUEST: 'GET_KPI_CHANNEL_REQUEST',
    GET_KPI_CHANNEL_SUCCESS: 'GET_KPI_CHANNEL_SUCCESS',
    GET_KPI_CHANNEL_FAILURE: 'GET_KPI_CHANNEL_FAILURE',

    GET_SERVICE_LIST_REQUEST: 'GET_SERVICE_LIST_REQUEST',
    GET_SERVICE_LIST_SUCCESS: 'GET_SERVICE_LIST_SUCCESS',
    GET_SERVICE_LIST_FAILURE: 'GET_SERVICE_LIST_FAILURE',

    SAVE_TABLE_FILTERS: 'SERVICE_SAVE_TABLE_FILTERS',
    SAVE_JOURNEYS_TABLE_FILTERS: 'JOURNEY_SAVE_TABLE_FILTER'
}
