import React from "react";
import CreateOperatorView from "./page";
import Dashboard from "../../dashboard";
import { connect } from "react-redux";
import { operatorsActions } from "../../../redux/actions/operators_actions";
import { tenantsActions } from '../../../redux/actions/tenant_actions';
import { otpActions } from "../../../redux/actions/otp_actions";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { rolesActions } from "../../../redux/actions/roles_actions";
import { allowAccessRole } from "../../../helpers/roles";
import { MODULES, OPERATIONS_ALLOWED } from "../../roles/constants";

class OperatorCreate extends React.Component {

    componentDidMount() {
        this.props.getAllOTP();
        if (allowAccessRole(MODULES.Tenants, OPERATIONS_ALLOWED.manage)) this.props.getAllTenants();
        this.props.getProfiles();
    }

    render() {
        const { t } = this.props;
        return (
            <Dashboard
                component={
                    <CreateOperatorView
                        title={t("operators.create.title")}
                        readOnly={false}
                        nextAction={"create"}
                        sendOperator={this.props.insert}
                        buttonRight={t("operators.form.page.buttonRegister")}
                        goBack={t("operators.form.page.goBack")}
                        entity={"Operators"}
                        profiles={this.props.rolesReducer.roles}
                        authProfile={JSON.parse(localStorage.getItem('claims'))?.value.profile}
                        {...this.props}
                    />
                }
            />
        );
    }
}

function mapState(state) {
    const { operatorReducer, otpReducer, tenantReducer, rolesReducer } = state;
    return { operatorReducer, otpReducer, tenantReducer, rolesReducer };
}

const actionCreators = {
    insert: operatorsActions.insert,
    getProfiles: rolesActions.getAll,
    getAllOTP: otpActions.getAll,
    clearOperator: operatorsActions.clearOperator,
    getAllTenants: tenantsActions.getAll,

};

export default compose(
    withTranslation("common"),
    connect(mapState, actionCreators)
)(OperatorCreate);
