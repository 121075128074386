import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Chart from "react-google-charts";
import i18next from "i18next";
import { getEconomicData } from "../../../helpers/kpiHelper";
import KpiWrapper from "../../../components/kpiWrapper";

export default function CostChart({ isLoading }) {
    const { t } = useTranslation()

    const confirmTrips = useSelector(state => state.kpiReducer.confirmTrips)
    const { type } = useSelector(state => state.kpiFiltersReducer)
    const isConfirmTripsLoading = useSelector(state => state.kpiReducer.confirmTripsLoading)

    const { data, total } = useMemo(() => {
        if (confirmTrips?.length <= 0) return { data: [], total: 0 }

        const chartData = [["filtro", i18next.t("commandBox.economyData.costChart.label")]]

        const { data } = getEconomicData(confirmTrips, type)

        const res = Object.entries(data).map(([key, value]) => [key, value.holderAmount]).sort((a, b) => b[1] - a[1])
        const total = res.reduce((acc, [_, value]) => acc + value, 0)

        chartData.push(...res)

        return {
            data: chartData,
            total
        }

    }, [confirmTrips, type])

    isLoading = isLoading || isConfirmTripsLoading
    const displayNoData = !isLoading && (!data || data.length == 0)

    return (
        <KpiWrapper
            title={t("commandBox.economyData.costChart.title") + " " + t(`commandBox.${type.toLowerCase()}`).toLowerCase()}
            displayNoData={displayNoData}
            isLoading={isLoading}
            total={`${total?.toLocaleString()}€`}
            skeletonVariant="rect"
        >
            <Chart
                chartType="ColumnChart"
                graphID={'chart_cost'}
                data={data}
                chartLanguage={i18next.language}
            />
        </KpiWrapper>
    )
}