import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { es, en, ca } from './locales';

const options = {
    interpolation: {
        escapeValue: false, // not needed for react!!
    },

    debug: process.env.NODE_ENV === 'development' ? true : false,

    // lng: 'en',

    resources: {
        es: {
            common: es.es,
        },
        en: {
            common: en.en,
        },
        ca: {
            common: ca.ca
        }
    },

    fallbackLng: 'es',

    ns: ['common'],

    defaultNS: 'common',

    react: {
        wait: false,
        bindI18n: 'languageChanged loaded',
        bindStore: 'added removed',
        nsMode: 'default'
    },
};

i18n
    .use(LanguageDetector)
    .init(options);

let language = localStorage.getItem('lang')
if (language == null) {
    i18n.changeLanguage('es', (err, t) => {
        if (err) return console.log('something went wrong loading', err);
    });
} else {
    i18n.changeLanguage(language, (err, t) => {
        if (err) return console.log('something went wrong loading', err);
    });
}



export default i18n;