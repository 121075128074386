import React from 'react';
import ZonesView from './page';
import Dashboard from '../dashboard';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { zoneActions } from '../../redux/actions/zones_actions';
import { alertActions } from '../../redux/actions/alert_actions';
import Spinner from '../shared/spinner';
import { withTheme } from '@material-ui/core/styles';

class Zones extends React.Component {

    componentDidMount() {
        this.props.getAllByType()
    }

    render() {
        const { zoneReducer } = this.props
        return (
            <Dashboard
                component={
                    zoneReducer.pending ?
                        <Spinner loading={zoneReducer.pending}  {...this.props} />
                        :
                        <ZonesView
                            {...this.props}
                        />
                }
            />
        );
    }
}

function mapState(state) {
    const { zoneReducer, alertReducer } = state;
    return { zoneReducer, alertReducer };
}

const actionCreators = {
    getAll: zoneActions.getAll,
    getAllByType: zoneActions.getAllByType,
    insert: zoneActions.insert,
    update: zoneActions.update,
    remove: zoneActions.remove,
    clear: alertActions.clear,
    info: alertActions.info,

}

export default compose(withTranslation('common'), connect(mapState, actionCreators), withTheme)(Zones);