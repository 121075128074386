import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import i18next from 'i18next';
import React from 'react';


const AutoCompleteVehicleLine = (props) => {

    const { vehicles, onChange, value, multiple } = props

    return (
        <Autocomplete
            id="vehicle"
            value={value}
            onChange={onChange}
            options={vehicles}
            getOptionLabel={option =>
                option.displayName
                    ? option.displayName
                    :
                    ''
            }
            multiple={multiple ?? null}
            getOptionSelected={(option, val) => option.id === val?.id ?? val?.includes(option.id)}
            renderInput={params => (
                <TextField
                    {...params}
                    variant={'outlined'}
                    label={i18next.t('services_comp.lines.vehicle',)}
                    name="vehicle"
                />
            )}
        />
    )
}

export default AutoCompleteVehicleLine