import React from 'react';
import OperatorsView from './page';
import Dashboard from '../dashboard';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { operatorsActions } from '../../redux/actions/operators_actions';

class Operators extends React.Component {

    componentDidMount() {
        this.props.getAll()
    }

    render() {
        return (
            <Dashboard
                component={
                    <OperatorsView
                        {...this.props}
                    />
                }
            />
        );
    }
}

function mapState(state) {
    const { operatorReducer } = state;
    return { operatorReducer };
}

const actionCreators = {
    getAll: operatorsActions.getAll,
    getById: operatorsActions.getById,
    remove: operatorsActions.remove,
    sendResetPasswordEmail: operatorsActions.forgotPassword,
    disable: operatorsActions.disable,
    enable: operatorsActions.enable,
    setCurrentPage: operatorsActions.setCurrentPage,
    getResetPasswordLink: operatorsActions.getResetPasswordLink
}

export default compose(withTranslation('common'), connect(mapState, actionCreators))(Operators);
