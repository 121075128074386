import React from 'react';
import Spinner from '../../shared/spinner';
import CustomerForm from '../form';

class CustomerEditView extends React.Component {

    render() {
        const { customerReducer } = this.props
        return (
            <React.Fragment>
                {customerReducer.pending ?
                    <Spinner loading={customerReducer.pending} /> :
                    <CustomerForm
                        {...this.props}
                    />
                }
            </React.Fragment>
        );
    }
}

export default CustomerEditView;