import moment from "moment";
import { KPIFiltersConstants } from "../../constants/kpiFilters_constant";
import { KPI_FILTER_OPTIONS } from "../../constants/types";

let initialState = {
    dateStart: moment().subtract(1, 'month').format("YYYY-MM-DD"),
    dateEnd: moment().format("YYYY-MM-DD"),
    type: KPI_FILTER_OPTIONS.services,
    filterBy: [],
}

export function kpiFiltersReducer(state = initialState, action) {
    switch (action.type) {
        case KPIFiltersConstants.SET_DATE_START:
            return {
                ...state,
                dateStart: action.dateStart
            };
        case KPIFiltersConstants.SET_DATE_END:
            return {
                ...state,
                dateEnd: action.dateEnd
            };
        case KPIFiltersConstants.SET_TYPE:
            return {
                ...state,
                type: action.typeSelected
            };
        case KPIFiltersConstants.SET_FILTER_BY:
            return {
                ...state,
                filterBy: action.filterBy
            };
        default:
            return state;
    }
}