import React from "react";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import * as locales from "react-date-range/dist/locale";
import i18next from "i18next";
import { defaultStaticRanges } from "./defaultRanges";
import { defaultInputRanges } from "./defaultRanges";
import { Grid } from "@material-ui/core";

class VehicleCalendarRangeView extends React.Component {

    constructor(props) {
        super();
        this.state = {};
    }

    componentDidMount() {
        let lng = i18next.language;
        let locale = "";
        switch (true) {
            case lng == "es":
                locale = "es";
                break;
            case lng == "cat":
                locale = "ca";
                break;
            case lng == "en":
                locale = "enGB";
                break;
            default:
                locale = "ca";
        }
        this.setState({
            language: locale,
        });
    }

    handleChange = (item) => {
        let selection = [item.selection];
        this.props.setRangeCalendar(selection);
    };

    render() {
        const { vehicleReducer, t } = this.props;
        let ranges = vehicleReducer.ranges;
        return (
            <React.Fragment>
                <Grid container>
                    <Grid item md={12}>
                        <DateRangePicker
                            firstDayOfWeek={7}
                            locale={locales[this.state.language]}
                            onChange={this.handleChange}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            months={1}
                            staticRanges={defaultStaticRanges(i18next)}
                            inputRanges={defaultInputRanges(i18next)}
                            ranges={ranges}
                            direction="horizontal"
                        />
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

export default VehicleCalendarRangeView;
