import { operatorsConstants } from "../../constants/operator_constants";
import { history } from "../../helpers/history";
import { operatorService } from "../../services/operatorService";
import { alertActions } from "../actions/alert_actions";
import { userActions } from "../actions/user_actions";
import i18next from "i18next";
import { userService } from "../../services/userService";
import { ERROR_CODES } from "../../constants/types";

const DETAIL_ERROR_CODES = {
    EmailAlreadyExists: "EmailAlreadyExists",
    DocumentCodeAlreadyExists: "DocumentCodeAlreadyExists",
    UserEmailAlreadyExists: "UserEmailAlreadyExists",
    InvalidDocument: "InvalidDocument",
    OtpManagerDataRequired: "OtpManagerDataRequired",
    ProfileCannotInsert: "ProfileCannotInsert",
    ResetPasswordEmailNotSent: "ResetPasswordEmailNotSent",
    UserEmailAlreadyExists: "UserEmailAlreadyExists",
    ProfileCannotRemove: "ProfileCannotRemove",
    RemoveSystemProfileNotAllowed: "RemoveSystemProfileNotAllowed",
    RemoveOwnUserNotAllowed: "RemoveOwnUserNotAllowed"
}

export const operatorsActions = {
    getAll,
    getById,
    insert,
    update,
    clearResults,
    filter,
    getProfiles,
    remove,
    forgotPassword,
    sendVerifyEmail,
    updatePassword,
    updateUserPasswordFromEmailChange,
    getProfilesLimited,
    setLanguage,
    clearOperator,
    disable,
    enable,
    setCurrentPage,
    getAllTenants,
    getResetPasswordLink
};

function filter(data) {
    return (dispatch) => {
        dispatch(request());
        operatorService.filter(data).then(
            (results) => {
                dispatch(success(results));
            },
            (error) => {
                if (error === 401 && userService.existRefreshToken()) {
                    dispatch(userActions.refreshToken());
                    dispatch(filter(data));
                }
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return { type: operatorsConstants.FILTER_REQUEST };
    }

    function success(results) {
        return { type: operatorsConstants.FILTER_SUCCESS, results };
    }

    function failure(error) {
        return { type: operatorsConstants.FILTER_FAILURE, error };
    }
}

function setCurrentPage(page) {
    return dispatch => {
        dispatch(request(page))
    }

    function request(page) {
        return { type: operatorsConstants.OP_SET_PAGE_TABLE, page };
    }
}

function clearOperator() {
    return dispatch => {
        dispatch(request())
    }

    function request() {
        return { type: operatorsConstants.CLEAR_OPERATOR };
    }
}

function getAll() {
    return (dispatch) => {
        dispatch(request());
        operatorService.getAll().then(
            (results) => {
                dispatch(success(results));
            },
            (error) => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken());
                        dispatch(getAll());
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t("services.operators.forbiddenGetAll")))
                        break
                    default:
                        dispatch(
                            alertActions.error(i18next.t("services.operators.getAllError"))
                        );
                }
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return { type: operatorsConstants.GETALL_REQUEST };
    }

    function success(results) {
        return { type: operatorsConstants.GETALL_SUCCESS, results };
    }

    function failure(error) {
        return { type: operatorsConstants.GETALL_FAILURE, error };
    }
}

function enable(id) {
    return (dispatch) => {
        dispatch(request());
        operatorService.enable(id).then(
            (operator) => {
                dispatch(success(id));
                dispatch(
                    alertActions.success(i18next.t("services.operators.enableSuccess"))
                );
                dispatch(getAll())
            },
            (error) => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken());
                        dispatch(enable(id));
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t("services.operators.forbiddenUpdate")))
                        break
                    default:
                        break
                }
                dispatch(failure(error));
                dispatch(alertActions.error(i18next.t("services.operators.enableError")));
            }
        );
    };

    function request() {
        return { type: operatorsConstants.OP_ENABLED_REQUEST };
    }

    function success(id) {
        return { type: operatorsConstants.OP_ENABLED_SUCCESS, id };
    }

    function failure(error) {
        return { type: operatorsConstants.OP_ENABLED_FAILURE, error };
    }
}

function disable(id) {
    return (dispatch) => {
        dispatch(request());
        operatorService.disable(id).then(
            (operator) => {
                dispatch(success(id));
                dispatch(
                    alertActions.success(i18next.t("services.operators.disableSuccess"))
                );
                dispatch(getAll())
            },
            (error) => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken());
                        dispatch(disable(id));
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t("services.operators.forbiddenUpdate")))
                        break
                    default:
                        break
                }
                dispatch(failure(error));
                dispatch(
                    alertActions.error(i18next.t("services.operators.disableError"))
                );
            }
        );
    };

    function request() {
        return { type: operatorsConstants.OP_DISABLED_REQUEST };
    }

    function success(id) {
        return { type: operatorsConstants.OP_DISABLED_SUCCESS, id };
    }

    function failure(error) {
        return { type: operatorsConstants.OP_DISABLED_FAILURE, error };
    }
}

function getProfiles() {
    return (dispatch) => {
        dispatch(request());
        operatorService.profiles().then(
            (profiles) => {
                const prof = makeEnum(profiles);
                dispatch(success(prof));
            },
            (error) => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken());
                        dispatch(getProfiles());
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t("services.operators.forbiddenGetAll")))
                        break
                    default:
                        dispatch(
                            alertActions.error(i18next.t("services.operators.getAllError"))
                        );
                }
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return { type: operatorsConstants.PROFILES_REQUEST };
    }

    function success(profiles) {
        return { type: operatorsConstants.PROFILES_SUCCESS, profiles };
    }

    function failure(error) {
        return { type: operatorsConstants.PROFILES_FAILURE, error };
    }
}

function getProfilesLimited() {
    return (dispatch) => {
        dispatch(request());
        operatorService.profilesLimited().then(
            (profiles) => {
                const prof = makeEnum(profiles);
                dispatch(success(prof));
            },
            (error) => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken());
                        dispatch(getProfiles());
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t("services.operators.forbiddenGetAll")))
                        break
                    default:
                        dispatch(
                            alertActions.error(i18next.t("services.operators.getAllError"))
                        );
                }
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return { type: operatorsConstants.PROFILES_REQUEST };
    }

    function success(profiles) {
        return { type: operatorsConstants.PROFILES_SUCCESS, profiles };
    }

    function failure(error) {
        return { type: operatorsConstants.PROFILES_FAILURE, error };
    }
}

function getById(id, redirect) {
    return (dispatch) => {
        dispatch(request(id));
        operatorService.getById(id).then(
            (operator) => {
                dispatch(success(operator));
                if (redirect) {
                    history.push("/operators/" + id);
                }
            },
            (error) => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken());
                        dispatch(getById(id));
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t("services.operators.forbiddenGetById")))
                        break
                    default:
                        dispatch(
                            alertActions.error(i18next.t("services.operators.getByIdError"))
                        );
                }
                dispatch(failure(error));
            }
        );
    };

    function request(id) {
        return { type: operatorsConstants.DETAIL_REQUEST, id };
    }

    function success(operator) {
        return { type: operatorsConstants.DETAIL_SUCCESS, operator };
    }

    function failure(error) {
        return { type: operatorsConstants.DETAIL_FAILURE, error };
    }
}

function setLanguage(id, lng) {
    return (dispatch) => {
        dispatch(request());
        operatorService.setLanguage(lng, id).then(
            (result) => {
                dispatch(success(result));
            },
            (error) => {
                if (error === 401 && userService.existRefreshToken()) {
                    dispatch(userActions.refreshToken());
                    dispatch(setLanguage(id, lng));
                } else {
                    /* dispatch(
                        alertActions.error(i18next.t("services.operators.getByIdError"))
                    ); */
                }
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return { type: operatorsConstants.SET_LANGUAGE_REQUEST };
    }

    function success(result) {
        return { type: operatorsConstants.SET_LANGUAGE_SUCCESS, result };
    }

    function failure(error) {
        return { type: operatorsConstants.SET_LANGUAGE_FAILURE, error };
    }
}

function update(operator) {
    return (dispatch) => {
        dispatch(request());
        operatorService.update(operator).then(
            (operator) => {
                dispatch(success(operator));
                if (operator.hasChangedEmailAddress) {
                    dispatch(userActions.logout())
                } else {
                    history.push("/operators");
                }
                dispatch(
                    alertActions.success(i18next.t("services.operators.updateSuccess"))
                );
            },
            (error) => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken());
                        dispatch(update(operator));
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t("services.operators.forbiddenUpdate")))
                        break
                    default:
                        try {
                            let parsedError = JSON.parse(error);
                            let detailError = parsedError.detail;
                            switch (detailError) {
                                case DETAIL_ERROR_CODES.EmailAlreadyExists:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t("services.operators.repeatEmail")
                                        )
                                    );
                                    break;
                                case DETAIL_ERROR_CODES.DocumentCodeAlreadyExists:
                                    dispatch(
                                        alertActions.error(i18next.t("services.operators.repeatDoc"))
                                    );
                                    break;
                                case DETAIL_ERROR_CODES.UserEmailAlreadyExists:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t("services.operators.UserEmailAlreadyExists")
                                        )
                                    );
                                    break;
                                default:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t("services.operators.updateError")
                                        )
                                    );
                            }
                        } catch (e) {
                            dispatch(alertActions.error(error.toString()));
                        }
                }
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return { type: operatorsConstants.EDIT_REQUEST };
    }

    function success(operator) {
        return { type: operatorsConstants.EDIT_SUCCESS, operator };
    }

    function failure(error) {
        return { type: operatorsConstants.EDIT_FAILURE, error };
    }
}

function clearResults() {
    return (dispatch) => {
        dispatch(clear());
    };

    function clear() {
        return { type: operatorsConstants.CLEAR_RESULTS };
    }
}

function insert(operator) {
    return (dispatch) => {
        dispatch(request());
        let url = window.location.origin + process.env.REACT_APP_LOGIN_PAGE;
        operatorService.insert(operator, url).then(
            (operator) => {
                dispatch(success(operator));
                history.push("/operators");
                dispatch(
                    alertActions.success(i18next.t("services.operators.insertSuccess"))
                );
            },
            (error) => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken());
                        dispatch(insert(operator));
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t("services.operators.forbiddenInsert")))
                        break
                    default:
                        try {
                            let parsedError = JSON.parse(error);
                            let detailError = parsedError.detail;
                            switch (detailError) {
                                case DETAIL_ERROR_CODES.EmailAlreadyExists:
                                    dispatch(alertActions.error(i18next.t('services.operators.repeatEmail')))
                                    break
                                case DETAIL_ERROR_CODES.UserEmailAlreadyExists:
                                    dispatch(alertActions.error(i18next.t('services.operators.UserEmailAlreadyExists')))
                                    break
                                case DETAIL_ERROR_CODES.DocumentCodeAlreadyExists:
                                    dispatch(alertActions.error(i18next.t('services.operators.repeatDoc')))
                                    break
                                case DETAIL_ERROR_CODES.InvalidDocument:
                                    dispatch(alertActions.error(i18next.t('services.operators.InvalidDocument')))
                                    break
                                case DETAIL_ERROR_CODES.OtpManagerDataRequired:
                                    dispatch(alertActions.error(i18next.t('services.operators.OtpManagerDataRequired')))
                                    break
                                case DETAIL_ERROR_CODES.ProfileCannotInsert:
                                    dispatch(alertActions.error(i18next.t('services.operators.ProfileCannotInsert')))
                                    break
                                case DETAIL_ERROR_CODES.ResetPasswordEmailNotSent:
                                    dispatch(alertActions.error(i18next.t('services.operators.sendEmailError')))
                                    break
                                default:
                                    dispatch(alertActions.error(i18next.t('services.operators.insertError')))
                            }
                        } catch (e) {
                            dispatch(alertActions.error(error.toString()));
                        }
                }
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return { type: operatorsConstants.REGISTER_REQUEST };
    }

    function success(operator) {
        return { type: operatorsConstants.REGISTER_SUCCESS, operator };
    }

    function failure(error) {
        return { type: operatorsConstants.REGISTER_FAILURE, error };
    }
}

function remove(id) {
    return (dispatch) => {
        dispatch(request());
        operatorService._delete(id).then(
            (otp) => {
                dispatch(success(id));
                dispatch(
                    alertActions.success(i18next.t("services.operators.deleteSuccess"))
                );
            },
            (error) => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken());
                        dispatch(remove(id));
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t("services.operators.forbiddenDelete")))
                        break
                    default:
                        try {
                            let parsedError = JSON.parse(error);
                            let detailError = parsedError.detail;
                            switch (detailError) {
                                case DETAIL_ERROR_CODES.ProfileCannotRemove:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t("services.operators.profileCannotRemove")
                                        )
                                    );
                                    break;
                                case DETAIL_ERROR_CODES.RemoveSystemProfileNotAllowed:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t("services.operators.removeSystemProfileNotAllowed")
                                        )
                                    );
                                    break;
                                case DETAIL_ERROR_CODES.RemoveOwnUserNotAllowed:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t("services.operators.removeOwnUserNotAllowed")
                                        )
                                    );
                                    break;
                                default:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t("services.operators.deleteError")
                                        )
                                    );
                            }
                        } catch (e) {
                            dispatch(alertActions.error(error.toString()));
                        }
                }
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return { type: operatorsConstants.DELETE_REQUEST };
    }

    function success(id) {
        return { type: operatorsConstants.DELETE_SUCCESS, id };
    }

    function failure(error) {
        return { type: operatorsConstants.DELETE_FAILURE, error };
    }
}

function forgotPassword(email) {
    return (dispatch) => {
        dispatch(request());
        operatorService.checkOpuser(email.toLowerCase()).then((result) => {
            if (result) {
                let lng = i18next.language;
                let url = window.location.origin + process.env.REACT_APP_LOGIN_PAGE;
                operatorService.sendResetPasswordEmail(email.toLowerCase(), lng, url).then(
                    (data) => {
                        dispatch(success(data));
                        dispatch(
                            alertActions.success(
                                i18next.t("services.login.resendPasswordToEmailSuccess")
                            )
                        );
                    },
                    (error) => {
                        dispatch(failure(error));
                        dispatch(
                            alertActions.error(
                                i18next.t("services.login.resendPasswordToEmailError")
                            )
                        );
                    }
                );
            } else {
                dispatch(failure(result));
                dispatch(
                    alertActions.success(
                        i18next.t("services.login.resendPasswordToEmailSuccess")
                    )
                );
            }
        });
    };

    function request() {
        return { type: operatorsConstants.RESET_PSW_REQUEST };
    }
    function success() {
        return { type: operatorsConstants.RESET_PSW_SUCCESS };
    }
    function failure(error) {
        return { type: operatorsConstants.RESET_PSW_FAILURE, error };
    }
}

function sendVerifyEmail(email) {
    return (dispatch) => {
        operatorService.sendVerifyEmail(email).then((result) => { });
    };
}

function updatePassword(email, newPassword, urlRedirect) {
    return (dispatch) => {
        dispatch(request());
        operatorService.updatePassword(email, newPassword).then(
            (result) => {
                dispatch(
                    alertActions.success(i18next.t("updatePassword.page.successUpdate"))
                );
                dispatch(success());
                if (urlRedirect != "") {
                    setTimeout(function () {
                        window.location = urlRedirect;
                    }, 1800);
                }
            },
            (error) => {
                dispatch(failure(error));
                let detailError = "";
                let status = "";
                try {
                    let parsedError = JSON.parse(error);
                    detailError = parsedError.detail;
                    status = parsedError.status;
                } catch (e) {
                    detailError = i18next.t("updatePassword.page.errorUpdate");
                }

                if (status === 400) {
                    switch (detailError) {
                        case "PasswordAlreadyUsed":
                            detailError = i18next.t(
                                "updatePassword.page.passwordAlreadyUsed"
                            );
                        default:
                            detailError = detailError;
                    }
                    dispatch(alertActions.error(detailError));
                } else {
                    dispatch(alertActions.error(detailError));
                }
            }
        );
    };

    function request() {
        return { type: operatorsConstants.UPDATE_PSW_REQUEST };
    }

    function success() {
        return { type: operatorsConstants.UPDATE_PSW_SUCCESS };
    }

    function failure(error) {
        return { type: operatorsConstants.UPDATE_PSW_FAILURE, error };
    }
}


function updateUserPasswordFromEmailChange(email, newPassword, urlRedirect) {
    return (dispatch) => {
        dispatch(request());
        operatorService.updateUserPasswordFromEmailChange(email, newPassword).then(
            (result) => {
                dispatch(
                    alertActions.success(i18next.t("updatePassword.page.successUpdate"))
                );
                dispatch(success());
                if (urlRedirect != "") {
                    setTimeout(function () {
                        window.location = urlRedirect;
                    }, 1800);
                }
            },
            (error) => {
                dispatch(failure(error));
                let detailError = "";
                let status = "";
                try {
                    let parsedError = JSON.parse(error);
                    detailError = parsedError.detail;
                    status = parsedError.status;
                } catch (e) {
                    detailError = i18next.t("updatePassword.page.errorUpdate");
                }

                if (status === 400) {
                    switch (detailError) {
                        case "PasswordAlreadyUsed":
                            detailError = i18next.t(
                                "updatePassword.page.passwordAlreadyUsed"
                            );
                        default:
                            detailError = detailError;
                    }
                    dispatch(alertActions.error(detailError));
                } else {
                    dispatch(alertActions.error(detailError));
                }
            }
        );
    };

    function request() {
        return { type: operatorsConstants.UPDATE_PSW_REQUEST };
    }

    function success() {
        return { type: operatorsConstants.UPDATE_PSW_SUCCESS };
    }

    function failure(error) {
        return { type: operatorsConstants.UPDATE_PSW_FAILURE, error };
    }
}

function getAllTenants() {

    return dispatch => {
        dispatch(request())
        operatorService.getAllTenants().then(
            results => {
                dispatch(success(results))
            },
            error => {
                dispatch(failure(error))
            }
        )
    }

    function request() {
        return { type: operatorsConstants.OP_GET_ALL_TENANTS_REQUEST }
    }

    function success(results) {
        return { type: operatorsConstants.OP_GET_ALL_TENANTS_SUCCESS, results }
    }

    function failure(error) {
        return { type: operatorsConstants.OP_GET_ALL_TENANTS_FAILURE, error }
    }
}

function makeEnum(enumObject) {
    let all = [];
    for (let key in enumObject) {
        all.push({
            id: key,
            name: enumObject[key],
        });
    }
    return all;
}

function getResetPasswordLink(email) {
    return (dispatch) => {
        let url = window.location.origin + process.env.REACT_APP_LOGIN_PAGE;
        operatorService.getResetPasswordLink(email, url).then(
            link => {
                navigator.clipboard.writeText(link).then(
                    success => {
                        dispatch(alertActions.success(i18next.t('services.operators.getLinkRecoverySuccess')))
                    },
                    error => {
                        dispatch(alertActions.error(i18next.t('services.operators.getLinkRecoveryError')))
                    }
                )
            },
            error => {
                dispatch(alertActions.error(i18next.t('services.operators.getLinkRecoveryError')))
            }
        )
    }
}

