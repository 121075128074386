import { driverConstants } from "../../constants/driver_constants";
import { tripConstants } from "../../constants/trip_constants";
import { vehiclesConstants } from "../../constants/vehicles_constants";

let initialState = {
    pending: false,
    results: [],
    allDrivers: [],
    driversForChat: [],
    driverFullName: [],
    currentPage: 0,
    modalToAssingDriver: false,
    driversToAssing: [],
    driversService: [],
    driversKPI: [],
    driversFilters: []
};

export function driverReducer(state = initialState, action) {
    switch (action.type) {
        case driverConstants.GET_FOR_CHAT_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case driverConstants.GET_FOR_CHAT_SUCCESS:
            return {
                ...state,
                pending: false,
                driversForChat: action.results,
            }
        case driverConstants.GET_FOR_CHAT_FAILURE:
            return {
                ...state,
                pending: false,
                results: [],
                error: action.error,
            }
        case driverConstants.GET_FULLNAME_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case driverConstants.GET_FULLNAME_SUCCESS:
            return {
                ...state,
                pending: false,
                driverFullName: action.results,
            }
        case driverConstants.GET_FULLNAME_FAILURE:
            return {
                ...state,
                pending: false,
                results: [],
                error: action.error,
            }
        case driverConstants.CLEAN_DRIVERS_FULLNAME:
            return {
                ...state,
                driverFullName: [],
            }
        case driverConstants.FILTER_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case driverConstants.FILTER_SUCCESS:
            return {
                ...state,
                pending: false,
                results: action.results,
            }
        case driverConstants.FILTER_FAILURE:
            return {
                ...state,
                pending: false,
                results: [],
                error: action.error,
            }
        case driverConstants.DETAIL_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case driverConstants.DETAIL_SUCCESS:
            return {
                ...state,
                driver: action.driver,
                pending: false,
            }
        case driverConstants.DETAIL_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            }
        case driverConstants.GETALL_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case driverConstants.GETALL_SUCCESS:
            return {
                ...state,
                pending: false,
                results: action.results,
                allDrivers: action.all,
            }
        case driverConstants.GETALL_FAILURE:
            return {
                ...state,
                pending: false,
                results: [],
                error: action.error,
            }
        case driverConstants.CLEAR_RESULTS:
            return {
                ...state,
                results: [],
                pending: false,
            }
        case driverConstants.ADD_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case driverConstants.ADD_SUCCESS:
            return {
                ...state,
                pending: false,
                driver: action.driver,
            }
        case driverConstants.ADD_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            }
        case driverConstants.EDIT_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case driverConstants.EDIT_SUCCESS:
            return {
                ...state,
                pending: false,
                driver: action.driver,
            }
        case driverConstants.EDIT_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            }
        case driverConstants.DELETE_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case driverConstants.DELETE_SUCCESS:
            return {
                ...state,
                pending: false,
                results: state.results.filter(x => x.driver.id !== action.id),
            }
        case driverConstants.DELETE_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            }
        case driverConstants.UPDATE_PSW_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case driverConstants.UPDATE_PSW_SUCCESS:
            return {
                ...state,
                pending: false,
            }
        case driverConstants.UPDATE_PSW_FAILURE:
            return {
                ...state,
                error: action.error,
                pending: false,
            }
        case driverConstants.VALIDATE_SUCCESS:
            state.results.find(x => x.driver.id == action.id).driver.isValidated = true
            let newResultsValidated = state.results
            return {
                ...state,
                results: newResultsValidated,
            }
        case driverConstants.ENABLE_SUCCESS:
            state.results.find(x => x.driver.id == action.id).driver.isActived = true
            let newResultsEnabled = state.results
            return {
                ...state,
                results: newResultsEnabled,
            }
        case driverConstants.DISABLE_SUCCESS:
            state.results.find(x => x.driver.id == action.id).driver.isActived = false
            let newResultsDisabled = state.results
            return {
                ...state,
                results: newResultsDisabled,
            }
        case driverConstants.DRIVER_SET_PAGE_TABLE:
            return {
                ...state,
                currentPage: action.page,
            }
        case driverConstants.CHECK_DRIVERS_TO_ASSING_REQUEST:
            return {
                ...state,
                pending: true,
                modalToAssingDriver: true,
                driversToAssing: [],
            }
        case driverConstants.CHECK_DRIVERS_TO_ASSING_SUCCESS:
            return {
                ...state,
                pending: false,
                modalToAssingDriver: true,
                driversToAssing: action.results,
            }
        case driverConstants.CHECK_DRIVERS_TO_ASSING_FAILURE:
            return {
                ...state,
                modalToAssingDriver: false,
                driversToAssing: [],
            }
        case driverConstants.CHECK_DRIVERS_CLOSE:
            return {
                ...state,
                modalToAssingDriver: false,
            }
        case tripConstants.ASSING_DRIVER_MANUAL_TRIP_REQUEST:
            return {
                ...state,
                pending: true
            }
        case tripConstants.ASSING_DRIVER_MANUAL_TRIP_SUCCESS:
            return {
                ...state,
                modalToAssingDriver: false,
            }
        case tripConstants.ASSING_DRIVER_MANUAL_TRIP_FAILURE:
            return {
                ...state,
                modalToAssingDriver: false,
            }
        case driverConstants.CHECK_DRIVERS_SERVICES_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case driverConstants.CHECK_DRIVERS_SERVICES_SUCCESS:
            return {
                ...state,
                pending: false,
                driversService: action.results,
            }
        case driverConstants.CHECK_DRIVERS_SERVICES_FAILURE:
            return {
                ...state,
                pending: false,
                driversService: [],
                error: action.error,
            }
        case vehiclesConstants.DETAIL_REQUEST:
            return {
                ...state,
                driverFullName: [],
            }
        case driverConstants.GET_REQUEST_DRIVERS_SERVICES_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case driverConstants.GET_REQUEST_DRIVERS_SERVICES_SUCCESS:
            return {
                ...state,
                pending: false,
                services: action.results,
            }
        case driverConstants.GET_REQUEST_DRIVERS_SERVICES_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            }

        case driverConstants.GET_DRIVERS_KPI_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case driverConstants.GET_DRIVERS_KPI_SUCCESS:
            return {
                ...state,
                pending: false,
                driversKPI: action.results,
            }
        case driverConstants.GET_DRIVERS_KPI_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            }
        case driverConstants.SAVE_TABLE_FILTERS:
            return {
                ...state,
                driversFilters: action.tableState
            }
        default:
            return state
    }
}
