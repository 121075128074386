import { Grid, FormControl, TextField, Hidden } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
    ' & .MuiFormControl-root': {
        width: '100%',
    },
}));

class FormContactOperator extends React.Component {

    componentDidMount() {
        //this.props.cleanFields()
    }

    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <Grid item md={1} implementation="css" smDown component={Hidden} />
                <Grid item md={3} xs={6} sm={3} >
                    <FormControl fullWidth>
                        <TextField
                            label={t('operators.form.page.emailFT')}
                            value={this.props.state.emailFT || ''}
                            variant="outlined"
                            name="emailFT"
                            fullWidth
                            onChange={this.props.handleChange}
                            type={'email'}
                            error={this.props.state.errors.emailFT.message.length === 0 ? false : true}
                            helperText={this.props.state.errors.emailFT.message}
                            inputProps={
                                { readOnly: this.props.readOnly }
                            }
                        />
                    </FormControl>
                </Grid>
                <Grid item md={3} xs={6} sm={3} >
                    <FormControl fullWidth>
                        <TextField
                            label={t('operators.form.page.phoneFT')}
                            value={this.props.state.phoneNumberFT || ''}
                            variant="outlined"
                            name="phoneNumberFT"
                            fullWidth
                            type={'tel'}
                            onChange={this.props.handleChange}
                            error={this.props.state.errors.phoneNumberFT.message.length === 0 ? false : true}
                            helperText={this.props.state.errors.phoneNumberFT.message}
                            inputProps={
                                { readOnly: this.props.readOnly }
                            }
                        />
                    </FormControl>
                </Grid>
                <Grid item md={5} implementation="css" smDown component={Hidden} />

            </React.Fragment>
        );
    }
}

export default FormContactOperator;