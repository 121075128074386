import { authHeader } from '../helpers/authHeader';
import stringConstants from '../constants/strings';
import i18next from 'i18next'
import { handleResponse } from '../helpers/services';

export const operatorService = {
    getAll,
    getById,
    insert,
    update,
    _delete,
    profiles,
    checkOpuser,
    checkSupportUser,
    sendResetPasswordEmail,
    sendVerifyEmail,
    updatePassword,
    updateUserPasswordFromEmailChange,
    getRoleByEmail,
    getRoleByClaims,
    profilesLimited,
    setLanguage,
    isActive,
    enable,
    disable,
    getAllTenants,
    getResetPasswordLink
};

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(stringConstants.URL_ApiGateway + '/opusers/v1/opusers/getOpUsers', requestOptions).then(handleResponse);
}

function isActive(email) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(stringConstants.URL_ApiGateway + '/opusers/v1/opusers/isOpUserActive?email=' + encodeURIComponent(email.toLowerCase()), requestOptions).then(handleResponse);
}


function profilesLimited() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    const lang = i18next.language
    return fetch(stringConstants.URL_ApiGateway + '/opusers/v1/opusers/profilesLimited?lang=' + lang, requestOptions).then(handleResponse);
}

function profiles() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    const lang = i18next.language
    return fetch(stringConstants.URL_ApiGateway + '/opusers/v1/opusers/profiles?lang=' + lang, requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(stringConstants.URL_ApiGateway + '/opusers/v1/opusers/' + id, requestOptions).then(handleResponse);
}

function getResetPasswordLink(email, urlRedirect) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };
    return fetch(stringConstants.URL_ApiGateway + '/opusers/v1/password/GetResetPasswordLink?email=' + encodeURIComponent(email) + '&urlRedirect=' + urlRedirect, requestOptions).then(handleResponse)
}

function insert(OpUsers, urlRedirect) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(OpUsers)
    };
    return fetch(stringConstants.URL_ApiGateway + '/opusers/v1/opusers/register?urlRedirect=' + urlRedirect, requestOptions).then(handleResponse);
}

function checkSupportUser(email) {
    const requestOptions = {
        method: 'GET'
    };
    return fetch(stringConstants.URL_ApiGateway + '/opusers/v1/opusers/isCustomerSupportOpUser?email=' + encodeURIComponent(email), requestOptions).then(handleResponse)
}

function checkOpuser(email) {
    const requestOptions = {
        method: 'GET'
    };
    return fetch(stringConstants.URL_ApiGateway + '/opusers/v1/opusers/isOpUser?email=' + encodeURIComponent(email), requestOptions).then(handleResponse)
}

function getRoleByEmail(email) {
    const requestOptions = {
        method: 'GET'
    };
    return fetch(stringConstants.URL_ApiGateway + '/opusers/v1/opusers/getRoleByEmail?email=' + encodeURIComponent(email), requestOptions).then(handleResponse)
}

function getRoleByClaims() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };
    return fetch(stringConstants.URL_ApiGateway + '/opusers/v1/opusers/GetRoleFromClaims', requestOptions).then(handleResponse)
}

function update(OpUsers) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(OpUsers)
    };
    const id = OpUsers.id
    return fetch(stringConstants.URL_ApiGateway + '/opusers/v1/opusers/update?id=' + id, requestOptions).then(handleResponse);
}

function enable(id) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };
    return fetch(stringConstants.URL_ApiGateway + '/opusers/v1/opusers/enable?id=' + id, requestOptions).then(handleResponse);
}

function disable(id) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };
    return fetch(stringConstants.URL_ApiGateway + '/opusers/v1/opusers/disable?id=' + id, requestOptions).then(handleResponse);
}

function setLanguage(lng, id) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };
    return fetch(stringConstants.URL_ApiGateway + '/opusers/v1/opusers/setLanguage?id=' + id + '&lang=' + lng, requestOptions).then(handleResponse);
}


function _delete(id) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader()
    };
    return fetch(stringConstants.URL_ApiGateway + '/opusers/v1/opusers/remove?id=' + id, requestOptions).then(handleResponse);
}

function sendResetPasswordEmail(email, lang, url) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
    return fetch(stringConstants.URL_ApiGateway + '/opusers/v1/mailing/sendResetPasswordEmail?email=' + encodeURIComponent(email) + '&lang=' + lang + '&urlRedirect=' + url, requestOptions).then(handleResponse);
}

function sendVerifyEmail(email, lang, url) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
    return fetch(stringConstants.URL_ApiGateway + '/opusers/v1/mailing/sendVerifyEmail?email=' + encodeURIComponent(email) + '&lang=' + lang + '&urlRedirect=' + url, requestOptions).then(handleResponse);
}

function updatePassword(email, newPassword) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ 'email': email, 'newPassword': newPassword })
    };
    return fetch(stringConstants.URL_ApiGateway + '/opusers/v1/password/updateUserPassword', requestOptions).then(handleResponse);
}

function updateUserPasswordFromEmailChange(email, newPassword) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ 'email': email, 'newPassword': newPassword })
    };
    return fetch(stringConstants.URL_ApiGateway + '/opusers/v1/password/updateUserPasswordFromEmailChange', requestOptions).then(handleResponse);
}

function getAllTenants() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(stringConstants.URL_ApiGateway + '/opusers/v1/Tenant/GetTenants', requestOptions).then(handleResponse)
}