import { authHeader } from '../helpers/authHeader';
import stringConstants from '../constants/strings';
import i18next from 'i18next';
import { getWeekWithOptions } from 'date-fns/fp';
import { handleResponse } from '../helpers/services';

export const customerService = {
    getAll,
    getById,
    getKPI,
    insert,
    update,
    _delete,
    sendResetPasswordEmail,
    sendVerifyEmail,
    canPerformTripFromProfileData,
    getPersonalInfo,
    checkCustomer,
    updatePassword,
    updateUserPasswordFromEmailChange,
    customerAddress,
    enable,
    disable,
    getResetPasswordLink,
    getDocumentTypes
};

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(stringConstants.URL_ApiGateway + '/customers/v1/customers/getCustomers', requestOptions).then(handleResponse);
}

function canPerformTripFromProfileData(customerId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(stringConstants.URL_ApiGateway + '/customers/v1/customers/canPerformTripFromProfileData?customerId=' + customerId, requestOptions).then(handleResponse);
}

function enable(id) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };
    return fetch(stringConstants.URL_ApiGateway + '/customers/v1/customers/enable?id=' + id, requestOptions).then(handleResponse);
}

function disable(id) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };
    return fetch(stringConstants.URL_ApiGateway + '/customers/v1/customers/disable?id=' + id, requestOptions).then(handleResponse);
}


function customerAddress() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let lng = i18next.language
    return fetch(stringConstants.URL_ApiGateway + '/customers/v1/customers/customerAddresses?lang=' + lng, requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(stringConstants.URL_ApiGateway + '/customers/v1/customers/getCustomerById?customerId=' + id, requestOptions).then(handleResponse);
}

function getKPI() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    }
    return fetch(stringConstants.URL_ApiGateway + `/customers/v1/KPI/GetCustomersKPI`, requestOptions).then(handleResponse);
}

function insert(customer, urlRedirect) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(customer)
    };
    let url = stringConstants.URL_ApiGateway + '/customers/v1/registration/register?urlRedirect=' + urlRedirect
    if (customer.email != '') {
        url = url + '&registerToFirebase=true'
    }
    return fetch(url, requestOptions).then(handleResponse);
}

function update(customer) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(customer)
    };
    const id = customer.id
    return fetch(stringConstants.URL_ApiGateway + '/customers/v1/customers/update?id=' + id, requestOptions).then(handleResponse);
}


function _delete(id) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader()
    };
    return fetch(stringConstants.URL_ApiGateway + '/customers/v1/customers/remove?customerId=' + id, requestOptions).then(handleResponse);
}

function sendResetPasswordEmail(email, lang, url) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
    return fetch(stringConstants.URL_ApiGateway + '/customers/v1/mailing/sendResetPasswordEmail?email=' + encodeURIComponent(email) + '&lang=' + lang + '&urlRedirect=' + url, requestOptions).then(handleResponse);
}

function sendVerifyEmail(email, lang, url) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
    return fetch(stringConstants.URL_ApiGateway + '/customers/v1/mailing/sendVerifyEmail?email=' + encodeURIComponent(email) + '&lang=' + lang + '&urlRedirect=' + url, requestOptions).then(handleResponse);
}

async function getPersonalInfo(customer, email, lang) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    const id = customer.id
    let emailToSend = customer.email;
    if (email != null) {
        emailToSend = email;
    }
    console.log("customerId => ", id);
    console.log("emailToSend => ", emailToSend);
    console.log("lang => ", lang);
    return fetch(stringConstants.URL_ApiGateway + '/api/customers/v1/GDPR/GDPRPetition?customerId=' + id + '&email=' + encodeURIComponent(emailToSend) + '&lang=' + lang, requestOptions).then(handleResponse);
}

function checkCustomer(email) {
    const requestOptions = {
        method: 'GET'
    };
    return fetch(stringConstants.URL_ApiGateway + '/customers/v1/Customers/IsCustomer?email=' + encodeURIComponent(email), requestOptions).then(handleResponse)
}

function getResetPasswordLink(email, urlRedirect) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };
    return fetch(stringConstants.URL_ApiGateway + '/customers/v1/password/GetResetPasswordLink?email=' + encodeURIComponent(email) + '&urlRedirect=' + urlRedirect, requestOptions).then(handleResponse)
}

function updatePassword(email, newPassword) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: email, newPassword: newPassword })
    };
    return fetch(stringConstants.URL_ApiGateway + '/customers/v1/password/updateUserPassword', requestOptions).then(handleResponse);
}

function updateUserPasswordFromEmailChange(email, newPassword) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: email, newPassword: newPassword })
    };
    return fetch(stringConstants.URL_ApiGateway + '/customers/v1/password/updateUserPasswordFromEmailChange', requestOptions).then(handleResponse);
}

async function getDocumentTypes() {
    const requesOptions = {
        method: 'GET',
        headers: authHeader(),
    }

    const lang = i18next.language
    return fetch(
        stringConstants.URL_ApiGateway +
        '/customers/v1/Customers/DocumentTypes?lang=' +
        lang,
        requesOptions
    ).then(handleResponse)
}