import MultipleBookPage from "./page"
import React, { useCallback, useEffect, useState } from 'react';
import moment from "moment";
import i18next from "i18next";
import { withSpinner } from "./dayButton";
import AlertDialog from "../../alert-dialog";
import { useDispatch, useSelector } from "react-redux";
import { multipleTripsActions } from "../../../redux/actions/multipleTrips_action";

const MultipleBook = () => {
    const [daysOfWeek, setDaysOfWeek] = useState([])
    const [startDate] = useState(moment().toDate())
    const [endDate, setEndDate] = useState(startDate)

    const isPending = useSelector((state) => state.multipleTripReducer.pending)
    const tripIdToPlanOutbound = useSelector((state) => state.multipleTripReducer.tripIdToPlanOutbound)
    const open = useSelector((state) => state.multipleTripReducer.openModalSelectFrecuency)
    const weekDays = useSelector((state) => state.serviceReducer.weekDays)
    const requestPickUpStartTime = useSelector((state) => state.multipleTripReducer.requestPickUpStartTime)
    const isTripReturn = useSelector((state) => state.tripReducer.isReturn)

    const dispatch = useDispatch()

    const setOpenModalSelectFrecuency = useCallback(() => {
        dispatch(multipleTripsActions.setOpenModalSelectFrecuency(false))
    }, [])

    useEffect(() => {
        setDaysOfWeek([])
    }, [open])

    useEffect(() => {
        if (requestPickUpStartTime) {
            const dateTimeOfTrip = moment(requestPickUpStartTime)
            const today = moment()
            setEndDate(moment.max(today, dateTimeOfTrip).add(1, 'week').toDate())
        }
    }, [requestPickUpStartTime])

    const handleAccept = useCallback(() => {
        const body = {
            tripId: tripIdToPlanOutbound,
            weekDays: daysOfWeek,
            endDate: endDate.toISOString(),
            lang: i18next.language
        }
        isTripReturn
            ? dispatch(multipleTripsActions.processMultipleTripOutboundAndReturn(body))
            : dispatch(multipleTripsActions.processMultipleTrip(body))

    }, [tripIdToPlanOutbound, daysOfWeek, endDate])

    const MultipleBook = withSpinner(MultipleBookPage)

    return (
        <AlertDialog
            open={open}
            maxWidth={'xs'}
            onClickCancel={() => setOpenModalSelectFrecuency(false)}
            onClickAccept={handleAccept}
            fullWidth
            conditionDisableCancel={isPending}
            conditionDisableAccept={!daysOfWeek.length > 0 || isPending}
            title={isPending ? i18next.t('trips.multipleBook.requestingTrips') : i18next.t('trips.multipleBook.title')}
            content={() =>
                <MultipleBook
                    isPending={isPending}
                    endDate={endDate}
                    weekDays={weekDays}
                    setEndDate={setEndDate}
                    setDayOffWeek={(newValue) => setDaysOfWeek(newValue)}
                    daysOfWeek={daysOfWeek}
                    minDate={startDate}
                />}

        />
    )
}

export default MultipleBook