import React from 'react'
import Dashboard from '../../dashboard'
import CustomerDetailsView from './page'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { customerActions } from '../../../redux/actions/customer_actions'
import { history } from '../../../helpers/history'
import { serviceActions } from '../../../redux/actions/services_actions'

class CustomerDetail extends React.Component {
    onClick(customer) {
        history.push('/customers/edit/' + customer.id)
    }

    componentDidMount() {
        const params = this.props.location.pathname.split('/')
        const id = params[params.length - 1]
        this.props.getById(id)
        this.props.customerAddresses()
        this.props.getDocumentTypes()
    }

    render() {
        const { t } = this.props
        return (
            <Dashboard
                component={
                    <CustomerDetailsView
                        {...this.props}
                        title={t('customers.details.title')}
                        buttonRight={t('customers.form.page.buttonDetail')}
                        goBack={t('customers.form.page.goBack')}
                        sendCustomer={() =>
                            this.onClick(this.props.customerReducer.customer)
                        }
                        readOnly={true}
                        detail={true}
                        action={'detail'}
                        customer={this.props.customerReducer.customer}
                    />
                }
            />
        )
    }
}

function mapState(state) {
    const { customerReducer } = state
    return { customerReducer }
}

const actionCreators = {
    getById: customerActions.getById,
    update: customerActions.update,
    addRelatedCustomer: customerActions.addRelatedCustomer,
    editRelatedCustomer: customerActions.editRelatedCustomer,
    removeRelatedCustomer: customerActions.removeRelatedCustomer,
    typeOperation: customerActions.openModal,
    getPersonalInfo: customerActions.getPersonalInfo,
    setAbsences: serviceActions.setAbsences,
    removeCurrentPenalty: serviceActions.removeCurrentPenalty,
    customerAddresses: customerActions.customerAddress,
    getDocumentTypes: customerActions.getDocumentTypes
}

export default compose(
    withTranslation('common'),
    connect(mapState, actionCreators),
)(CustomerDetail)
