import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import HomeIcon from '@material-ui/icons/Home';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import StarsIcon from '@material-ui/icons/Stars';
import MapIcon from '@material-ui/icons/Map';


const autocompleteService = { current: null };

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
}));

export default function GoogleMaps(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(props.value ? props.value : null);
    const [inputValue, setInputValue] = React.useState('');
    const [changeSomeText, setChangeSomeText] = React.useState(false);
    const [options, setOptions] = React.useState([]);

    const fetch = React.useMemo(
        () =>
            throttle((request, callback) => {
                autocompleteService.current.getPlacePredictions(request, callback);
            }, 200),
        [],
    );


    React.useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetch({ input: inputValue, componentRestrictions: { country: 'es' } }, (results) => {
            if (active && changeSomeText) {
                let newOptions = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    results.forEach(place => {
                        let addressString = "";
                        if (place.terms != null) {
                            for (let i = 0; i < place.terms.length - 1; i++) {
                                addressString += place.terms[i].value + ",";
                            }
                            addressString = addressString.replace(/,\s*$/, "");
                            //console.log(addressString);   
                            if (addressString.length > 0) place.description = addressString;
                        }
                    });
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    React.useEffect(() => {
        setValue(props.value)
        setChangeSomeText(false)
        setOptions([])
    }, [props.value])

    return (
        <Autocomplete
            data-testid={props.testId}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
            filterOptions={(x) => x}
            options={props.optionsDefault && props.optionsDefault.length > 0 ? options.concat(props.optionsDefault.filter(item => item.description !== props.otherValue?.description || item.type === 'destinationZoneAddress')) : options}
            autoComplete
            fullWidth
            includeInputInList
            filterSelectedOptions
            value={value}
            disabled={props.disabled ? props.disabled : false}
            onChange={(event, newValue) => {
                setOptions(newValue ? [newValue, ...options] : options);
                props.onChange(newValue)
                setValue(newValue);
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
                if (event)
                    setChangeSomeText(true)
                else
                    setChangeSomeText(false)
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={props.label}
                    name={props.name}
                    variant="outlined"
                    helperText={props.error.message}
                    error={props.error.message.length === 0 ? false : true}
                    fullWidth
                />
            )}
            renderOption={(option) => {
                const matches = option?.structured_formatting?.main_text_matched_substrings ?? [];
                const parts = parse(
                    option?.structured_formatting?.main_text,
                    matches?.map((match) => [match?.offset, match?.offset + match?.length]),
                );

                return (
                    <Grid container alignItems="center">
                        <Grid item>
                            {
                                option.type == "customerAddress" ?
                                    <HomeIcon className={classes.icon} />
                                    :
                                    (option.type == "favouriteAddress" ?
                                        <StarsIcon className={classes.icon} />
                                        :
                                        option.type == "destinationZoneAddress" ?
                                            <MapIcon className={classes.icon} />
                                            :
                                            <LocationOnIcon className={classes.icon} />)
                            }

                        </Grid>
                        <Grid item xs>
                            {parts.map((part, index) => (
                                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                    {part.text ?? '-'}
                                </span>
                            ))}

                            <Typography variant="body2" color="textSecondary">
                                {option?.structured_formatting?.secondary_text ?? ''}
                            </Typography>
                        </Grid>
                    </Grid>
                );
            }}
        />
    );
}