import i18next from 'i18next'
import React, { useMemo } from 'react'
import Chart from 'react-google-charts'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import KpiWrapper from "../../../components/kpiWrapper";
import { getTripsKPI } from '../../../helpers/kpiHelper'

export default function TotalTripsChart({ isLoading }) {
    const { t } = useTranslation()

    const confirmTrips = useSelector(state => state.kpiReducer.confirmTrips)
    const isConfirmTripsLoading = useSelector(state => state.kpiReducer.confirmTripsLoading)
    const { type } = useSelector(state => state.kpiFiltersReducer)

    const { totalTrips, data } = useMemo(() => {
        if (!confirmTrips.length) return { totalTrips: 0, data: [] }
        const data = [[t('commandBox.' + type?.toLowerCase()), t('commandBox.trips.totalTripsChart.label')]]
        const { totalTrips, data: dataTrips } = getTripsKPI(confirmTrips, type)

        if (dataTrips) {
            data.push(...Object.entries(dataTrips).map(e => e != null && [e[0], e[1].trips]))
        }
        return { totalTrips, data }
    }, [confirmTrips, type, t])

    isLoading = isLoading || isConfirmTripsLoading
    const displayNoData = !isLoading && (!totalTrips || totalTrips == 0)

    return (
        <KpiWrapper
            title={t("commandBox.trips.totalTripsChart.title")}
            displayNoData={displayNoData}
            total={`${String(totalTrips).toLocaleString()}`}
            isLoading={isLoading}
            skeletonVariant="rect">
            <Chart
                chartType="ColumnChart"
                graphID={'chart_total_trips'}
                data={data}
                chartLanguage={i18next.language}
                options={{
                    width: '100%',
                    vAxis: { title: t("commandBox.trips.timeSlotChart.vAxis") },
                    seriesType: "bars",
                    legend: { position: "none" },
                }}
            />
        </KpiWrapper >
    )
}