import React from "react";
import { useSelector } from "react-redux";
import SimpleTable from "../../../components/simpleTable";
import { Skeleton } from "@material-ui/lab";
import TripSVG from "../../../../../resources/svgs/tripSVG";
import { useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export default function TripStateTable({ isLoading }) {
    const { t } = useTranslation()

    const reservation = useSelector(state => state.kpiReducer.reservations)
    const isReservationsLoading = useSelector(state => state.kpiReducer.reservationsLoading)

    const theme = useTheme()
    function createData(name, unit) {
        return { name, unit };
    }
    const rows = [
        createData(t('commandBox.trips.tripStateTable.row1'), reservation.finished,),
        createData(t('commandBox.trips.tripStateTable.row2'), reservation.nonAttendance),
        createData(t('commandBox.trips.tripStateTable.row3'), reservation.notPerformed),
    ];

    const total = reservation.finished + reservation.nonAttendance + reservation.notPerformed;
    isLoading = isLoading || isReservationsLoading

    return (
        <>
            {
                isLoading
                    ? <Skeleton variant="rect" height={250} />
                    : <SimpleTable
                        icon={<TripSVG width={40} height={30} stroke={theme.palette.primary.main} />}
                        title={t('commandBox.trips.tripStateTable.title')}
                        subtitle={t('commandBox.trips.tripStateTable.subtitle')}
                        rows={rows}
                        total={total} />
            }
        </>
    )
}