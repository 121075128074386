import React from 'react';
import Dashboard from '../../dashboard'
import OperatorDetailsView from './page';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { operatorsActions } from '../../../redux/actions/operators_actions';
import { tenantsActions } from '../../../redux/actions/tenant_actions';
import { otpActions } from '../../../redux/actions/otp_actions';
import { history } from '../../../helpers/history';
import { rolesActions } from '../../../redux/actions/roles_actions';
import { allowAccessRole } from '../../../helpers/roles';
import { MODULES, OPERATIONS_ALLOWED } from '../../roles/constants';

class OperatorDetail extends React.Component {

    componentDidMount() {
        this.props.getProfiles()
        this.props.getAllOTP()
        if (allowAccessRole(MODULES.Tenants, OPERATIONS_ALLOWED.manage)) this.props.getAllTenants();

        const params = this.props.location.pathname.split('/')
        const id = params[params.length - 1]
        this.props.getById(id)
    }

    onClick(operator) {
        history.push('/operators/edit/' + operator.id)
    }

    render() {
        const { t } = this.props
        return (
            <Dashboard
                component={
                    <OperatorDetailsView
                        {...this.props}
                        title={t('operators.details.title')}
                        buttonRight={t('operators.form.page.buttonDetail')}
                        goBack={t('operators.form.page.goBack')}
                        sendOperator={() => this.onClick(this.props.operatorReducer.operator)}
                        readOnly={true}
                        nextAction={'edit'}
                        entity={"Operators"}
                        profiles={this.props.rolesReducer.roles}
                        authProfile={JSON.parse(localStorage.getItem('claims'))?.value.profile}
                        detail={true}
                        user={this.props.operatorReducer.operator}
                    />
                }
            />
        );
    }
}

function mapState(state) {
    const { operatorReducer, otpReducer, tenantReducer, rolesReducer } = state;
    return { operatorReducer, otpReducer, tenantReducer, rolesReducer };
}

const actionCreators = {
    getProfiles: rolesActions.getAll,
    getById: operatorsActions.getById,
    getAllOTP: otpActions.getAll,
    clearOperator: operatorsActions.clearOperator,
    getAllTenants: tenantsActions.getAll,
}


export default compose(withTranslation('common'), connect(mapState, actionCreators))(OperatorDetail);
