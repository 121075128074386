import { Grid } from '@material-ui/core';
import React from 'react';
import ResumeTrip from '../resume'
import MultipleBook from '../multipleBook';
import { RequestTripComponent, RequestTripWrapper } from './details';
import ModalSelectServices from '../form/modalService';
import ModalExpeditions from '../form/modalExpeditions';
import AlertDialog from '../../alert-dialog';
import ModalSelectPosibleSchedule from '../form/modalSelectPosibleSchedule';
import WaitingScreen from '../../shared/backdrop';
import ReturnTrip from '../returnTrip';


class RequestTripView extends React.Component {

    constructor(props) {
        super()
        this.child = React.createRef();
        this.setOriginAndDestination = this.setOriginAndDestination.bind(this)
        this.setRequestTripProperties = this.setRequestTripProperties.bind(this)
        this.getNoRestrictionValue = this.getNoRestrictionValue.bind(this)
    }


    setOriginAndDestination(zoneId, zoneName, point) {
        this.child.current.setOriginAndDestination(zoneId, zoneName, point);
    }

    setRequestTripProperties(customer, origin, destination, luggage, hasWheelChair, numPassengers, newTime) {
        this.child.current.setRequestTripProperties(customer, origin, destination, luggage, hasWheelChair, numPassengers, newTime)
        this.props.checkProfile(customer.customerId)
    }

    getNoRestrictionValue() {
        return this.child.current?.state?.noRestriction
    }


    render() {
        const { tripReducer, t, serviceReducer, multipleTripReducer } = this.props
        return (
            <Grid container>
                {tripReducer.trip == null && !tripReducer.detail && !multipleTripReducer.executeMultipleTrips && !tripReducer.isReturn ?
                    <RequestTripComponent
                        child={this.child}
                        getNoRestrictionValue={this.getNoRestrictionValue}
                        setOriginAndDestination={this.setOriginAndDestination}
                        tripReducer={tripReducer}
                        {...this.props}
                    />
                    :
                    <ResumeTrip
                        {...this.props}
                        setRequestTripProperties={this.setRequestTripProperties}
                        setTripToPlan={this.props.setTripToPlan}
                        openModalSelectFrecuency={this.props.openModalSelectFrecuency}
                    />

                }

                <MultipleBook />
                <ReturnTrip open={tripReducer.openModalReturnTrip} />
                <ModalSelectServices
                    open={tripReducer.modalSelectedService ?? false}
                    handClose={this.props.selectServiceClose}
                    setServiceServiceLine={this.props.setServiceServiceLine}
                    setServiceNotServiceLine={
                        this.props.setServiceNotServiceLine
                    }
                    noRestriction={this.getNoRestrictionValue}
                    t={t}
                    tripReducer={tripReducer}
                    customerReducer={this.props.customerReducer}
                />
                <ModalExpeditions
                    t={t}
                    tripReducer={tripReducer}
                    daysOfWeek={this.props.serviceReducer.weekDays}
                    open={tripReducer.modalExpeditions ?? false}
                    handClose={this.props.closeModalExpeditions}
                />
                <AlertDialog
                    open={tripReducer.modalMessage ?? false}
                    title={''}
                    content={() => (
                        <React.Fragment> {tripReducer.message}</React.Fragment>
                    )}
                    onClickAccept={this.props.closeModalRequestTrip}
                />
                <ModalSelectPosibleSchedule
                    open={tripReducer.modalSelectScheduledPickUp ?? false}
                    expeditions={tripReducer.posibleSchedule}
                    tripReducer={tripReducer}
                    insert={this.props.insert}
                    closeModalSelectScheduledPickUp={
                        this.props.closeModalSelectScheduledPickUp
                    }
                />
                <WaitingScreen
                    message={t('generics.loading')}
                    open={tripReducer.pending || multipleTripReducer.pending}
                />

            </Grid>
        );
    }
}

export default RequestTripView;