import React from "react";
import VehicleCalendarRangeView from "./page";

class VehicleCalendarRange extends React.Component {
  render() {
    return <VehicleCalendarRangeView {...this.props} />;
  }
}

export default VehicleCalendarRange;
