import React from "react";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { Chip, Tooltip, useTheme } from "@material-ui/core";
import i18next from "i18next";

const DAYS_OF_WEEK = [0, 1, 2, 3, 4, 5, 6]

export function DaysToggle({ weekDays, isExclusive = false, value, onChange }) {
    const theme = useTheme()
    const primary = theme.palette.primary.main
    const secondary = theme.palette.secondary.main

    const handleChange = (_, nextValue) => {
        onChange(nextValue)
    };

    const isSelected = (day) => {
        if (value && value.length) {
            return value.includes(day)
        }
        return value === day
    }

    return (
        <ToggleButtonGroup exclusive={isExclusive} onChange={handleChange} value={value}
            style={{ display: "flex", gap: "10px", }}>
            {
                DAYS_OF_WEEK.map((dayIndex) => {
                    return (
                        <ToggleButton value={dayIndex} key={dayIndex} style={{
                            borderRadius: "0px",
                            border: 0,
                            padding: 0,
                            backgroundColor: isSelected(dayIndex) ? primary : theme.palette.grey[300],
                            color: isSelected(dayIndex) ? secondary : theme.palette.black.main,
                        }}>
                            <ButtonDay label={weekDays.find(item => parseInt(item.id) === dayIndex)?.name} />
                        </ToggleButton>
                    )
                })
            }
        </ToggleButtonGroup>
    );
}


function ButtonDay({ label }) {
    const days = i18next.t(`trips.multipleBook.dayOfWeeks`, { returnObjects: true })

    return (
        <Tooltip title={label}>
            <Chip
                label={days[label] ?? label?.subString(0, 3)}
                size="medium"
                variant="default"
                style={{ borderRadius: 0, backgroundColor: "transparent" }}
            />
        </Tooltip>
    )
}