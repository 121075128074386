import { Button, Grid, Paper, Tab, Tabs } from "@material-ui/core";
import React from "react";
import VehicleCalendarRange from "./ranges";
import { Remove } from "@material-ui/icons";
import moment from "moment";
import { history } from "../../../helpers/history";
import { TabPanel, a11yProps } from "../../services/calendar/components/_layout";

class VehicleCalendarRangeView extends React.Component {
    constructor(props) {
        super();
        this.state = {
            indexPanel: 0
        }
        this.saveCalendarOnClick = this.saveCalendarOnClick.bind(this);
    }

    saveCalendarOnClick() {
        const { vehicle } = this.props.vehicleReducer;
        this.props.setCalendar(vehicle.excludedPeriods, vehicle.id);
    }

    onClickBack() {
        history.goBack();
    }

    render() {
        const { t, vehicleReducer } = this.props;
        const { excludedPeriods } = vehicleReducer.vehicle;
        const { vehicle } = vehicleReducer;
        let ranges = vehicleReducer.ranges;
        return (
            <React.Fragment>
                <Grid container>
                    <Paper elevation={1} style={{ width: '100%', padding: 20, marginBottom: 20 }} >
                        <Grid item md={12}>
                            <h1>{vehicle.displayName}</h1>
                        </Grid>

                        <Tabs
                            value={this.state.indexPanel}
                            onChange={(event, newValue) => {
                                this.setState({ indexPanel: newValue })
                            }}
                            indicatorColor="primary"
                            textColor="primary"
                            scrollButtons="auto"
                            variant="scrollable"
                        >
                            <Tab label={t('services_comp.calendar.panel.noOperative')} {...a11yProps(0)} value={0}></Tab>
                        </Tabs>
                    </Paper>

                    <Paper elevation={1} style={{ width: '100%', padding: 20, marginBottom: 20 }}>

                        <TabPanel value={this.state.indexPanel} index={0}>
                            <Grid container spacing={2}>
                                <Grid item md={12} >
                                    <h2>{t("vehicles.calendar.title")}</h2>
                                </Grid>
                                <Grid item md={6} sm={12}>
                                    <VehicleCalendarRange {...this.props} />
                                </Grid>
                                <Grid item md={6} sm={12}>
                                    {excludedPeriods?.map((item, index) => (
                                        <React.Fragment key={index}>
                                            <Grid container spacing={2} justify='center'>
                                                <Grid item md={4}>
                                                    <span>{t("services_comp.calendar.range.start")}: </span>
                                                    {moment(item.startPeriod).format("DD/MM/YYYY")}
                                                </Grid>
                                                <Grid item md={4}>
                                                    <span>{t("services_comp.calendar.range.end")}: </span>
                                                    {moment(item.finishPeriod).format("DD/MM/YYYY")}
                                                </Grid>
                                                <Grid item md={2}>
                                                    <Remove
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => this.props.removeExcludeDays(index)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </React.Fragment>
                                    ))}
                                </Grid>
                                <Grid
                                    container
                                    justify="flex-end"
                                    alignItems="flex-end"
                                >
                                    <Grid
                                        item
                                        md={1}
                                        style={{
                                            textAlign: "center",
                                        }}
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            size="small"
                                            onClick={() => this.props.setExcludeDays(ranges)}
                                        >
                                            {t("services_comp.calendar.range.add")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </TabPanel>
                    </Paper>


                    <Grid container justify='flex-end' spacing={2}>
                        <Grid item xl={1} md={2} sm={5}>
                            <Button
                                style={{ marginTop: 40 }}
                                variant="outlined"
                                color="primary"
                                onClick={this.onClickBack}
                                fullWidth
                                size="medium"
                            >
                                {t("services_comp.form.page.goBack")}
                            </Button>
                        </Grid>

                        <Grid item xl={1} md={2} sm={5}>
                            <Button
                                style={{ marginTop: 40 }}
                                variant="contained"
                                color="primary"
                                fullWidth
                                size="medium"
                                disabled={vehicleReducer.pending}
                                onClick={this.saveCalendarOnClick}
                            >
                                {t("services_comp.form.page.buttonEdit")}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

export default VehicleCalendarRangeView;
