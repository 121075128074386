import React from "react";
import { Checkbox, Chip, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useEffect, useState } from "react";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Spinner from "./spinner";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { useTranslation } from 'react-i18next'

function MultiSelect({
    items,
    label,
    placeholder,
    selectAllLabel,
    noOptionsText,
    limitTags,
    error,
    helperText,
    onChange,
    loading,
    initValues,
    ...props
}) {
    const { t } = useTranslation();
    const [selectedOptions, setSelectedOptions] = useState([]);

    useEffect(() => {
        if (initValues) {
            setSelectedOptions(initValues);
        }
    }, [initValues])

    const allSelected = items.length === selectedOptions.length;

    const handleToggleOption = selectedOptions =>
        setSelectedOptions(selectedOptions);

    const handleClearOptions = () => setSelectedOptions([]);

    const getOptionLabel = option => `${option.label}`;

    const handleSelectAll = isSelected => {
        if (isSelected) {
            setSelectedOptions(items);
        } else {
            handleClearOptions();
        }
    };

    const handleToggleSelectAll = () => {
        handleSelectAll && handleSelectAll(!allSelected);
    };

    const handleChange = (_, selectedOptions, reason, option) => {

        if (reason === "clear") {
            handleClearOptions();
            onChange([]);
            return
        }

        if (option.option.value === "select-all") {
            if (allSelected) {
                handleClearOptions();
                onChange([]);
                return
            } else {
                handleToggleSelectAll();

                let result = [];
                result = items.filter(el => el.value !== "select-all");
                return onChange(result);
            }
        }

        handleToggleOption && handleToggleOption(selectedOptions);
        return onChange(selectedOptions);

    };
    const optionRenderer = (option, { selected }) => {
        const selectAllProps =
            option.value === "select-all"
                ? { checked: allSelected }
                : {};
        return (
            <>
                <Checkbox
                    color="primary"
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                    {...selectAllProps}
                />
                {getOptionLabel(option)}
            </>
        );
    };
    const inputRenderer = params => (
        <TextField
            {...params}
            label={label}
            variant={'outlined'}
            placeholder={placeholder}
            error={error}
            helperText={helperText}
            InputProps={{
                ...params.InputProps,
                endAdornment: (
                    <>
                        {loading ? <Spinner loading={loading} /> : null}
                        {params.InputProps.endAdornment}
                    </>
                )

            }} />
    );
    const getOptionSelected = (option, anotherOption) =>
        option.value === anotherOption.value;
    const filter = createFilterOptions();
    return (
        <Autocomplete
            multiple
            {...props}
            limitTags={limitTags}
            options={items}
            value={selectedOptions}
            loading={loading}
            disableCloseOnSelect
            getOptionLabel={getOptionLabel}
            getOptionSelected={getOptionSelected}
            noOptionsText={noOptionsText}
            renderTags={(value, getTagProps) => {
                return allSelected
                    ? <Chip label={t("shared.multiSelect.all")} />
                    : <div style={{ display: 'flex', gap: "5px" }}>
                        <Chip label={value[0].label} />
                        {value.length > 1 && <Chip label={`+${value.length - 1}`} />}
                    </div>
            }}
            filterOptions={(options, params) => {
                if (loading || !options || options.length === 0) return options;
                const filtered = filter(options, params);
                return [{ label: selectAllLabel, value: "select-all" }, ...filtered];
            }}
            onChange={handleChange}
            renderOption={optionRenderer}
            renderInput={inputRenderer}
        />
    );
};

MultiSelect.defaultProps = {
    limitTags: 2,
    items: [],
    selectedvalues: [],
    getOptionLabel: value => value
};

export default MultiSelect;