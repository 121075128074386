export const reportConstants = {
    GETALL_REQUEST: 'REPORTS_GETALL_REQUEST',
    GETALL_SUCCESS: 'REPORTS_GETALL_SUCCESS',
    GETALL_FAILURE: 'REPORTS_GETALL_FAILURE',

    INSERT_REQUEST: 'REPORTS_INSERT_REQUEST',
    INSERT_SUCCESS: 'REPORTS_INSERT_SUCCESS',
    INSERT_FAILURE: 'REPORTS_INSERT_FAILURE',

    DELETE_REQUEST: 'REPORTS_DELETE_REQUEST',
    DELETE_SUCCESS: 'REPORTS_DELETE_SUCCESS',
    DELETE_FAILURE: 'REPORTS_DELETE_FAILURE',

    UPDATE_REQUEST: 'REPORTS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'REPORTS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'REPORTS_UPDATE_FAILURE',

    GET_PERIODICITY_REQUEST: 'REPORTS_GET_PERIODICITY_REQUEST',
    GET_PERIODICITY_SUCCESS: 'REPORTS_GET_PERIODICITY_SUCCESS',
    GET_PERIODICITY_FAILURE: 'REPORTS_GET_PERIODICITY_FAILURE',

    DETAIL_REQUEST: 'REPORTS_DETAIL_REQUEST',
    DETAIL_SUCCESS: 'REPORTS_DETAIL_SUCCESS',
    DETAIL_FAILURE: 'REPORTS_DETAIL_FAILURE',

    ADD_SCHEDULE_REPORT_REQUEST: 'REPORTS_ADD_SCHEDULE_REPORT_REQUEST',
    ADD_SCHEDULE_REPORT_SUCCESS: 'REPORTS_ADD_SCHEDULE_REPORT_SUCCESS',
    ADD_SCHEDULE_REPORT_FAILURE: 'REPORTS_ADD_SCHEDULE_REPORT_FAILURE',
}