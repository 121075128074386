import React from 'react'
import ServiceCalendarWorkingRangeView from './page'

class ServiceCalendarWorkingRange extends React.Component {
    render() {
        return <ServiceCalendarWorkingRangeView {...this.props} />
    }
}

export default ServiceCalendarWorkingRange
