import MaterialTable from 'material-table';
import React from 'react';
import { Grid, InputLabel } from "@material-ui/core";
import SectionStyled, { TitleSection } from '../../form/components/section'


class ZonesRelations extends React.Component {

    render() {
        const { t } = this.props
        const translations = t("material_table.tableStrings", { returnObjects: true, });

        return (
            <SectionStyled>
                <TitleSection>{t('services_comp.form.page.zonesRelations.titleSection')}</TitleSection>
                <Grid item md={12}>
                    <MaterialTable
                        localization={translations}
                        components={{
                            Toolbar: (props) => (
                                <React.Fragment>
                                    <InputLabel>
                                    </InputLabel>
                                </React.Fragment>
                            )
                        }}

                        columns={[
                            { title: t('services_comp.form.page.zonesRelations.origin'), field: 'originZoneName' },
                            { title: t('services_comp.form.page.zonesRelations.destination'), field: 'destinationZoneName' }
                        ]}
                        options={{ pageSize: 3 }}
                        data={this.props.results}
                    />

                </Grid>

            </SectionStyled>
        );
    }
}

export default ZonesRelations;