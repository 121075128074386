import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    emailDiv: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: 20,
    },
    languageSelectionDiv: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: 10,
    },
    languageLabel: {
        marginRight: 20, marginTop: 7
    },
    buttonDiv: {
        display: 'flex',
        flexDirection: 'row-reverse',
        marginRight: 10
    }
}));

export default useStyles;