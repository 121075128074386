/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import Dashboard from '../../../dashboard';
import Spinner from '../../../shared/spinner';
import ListOfLinesOfService from './page';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { useParams } from 'react-router-dom';
import { regularLine_actions } from '../../../../redux/actions/regularLine_actions';


const LinesByService = (props) => {

    const { id } = useParams()

    const { getServiceLines, serviceReducer, removeLine } = props

    useEffect(() => {
        getServiceLines(id)
    }, [])


    return (
        <Dashboard
            component={
                <>
                    {
                        !serviceReducer.pending && serviceReducer.serviceLine != null ?
                            <ListOfLinesOfService
                                lines={serviceReducer.serviceLine}
                                removeLine={removeLine}
                                id={id}
                            />
                            : <Spinner
                                loading={serviceReducer.pending}
                            />
                    }

                </>
            }

        />

    )

}

const mapState = (state) => {
    const { serviceReducer } = state
    return { serviceReducer }
}

const actionCreators = {
    getServiceLines: regularLine_actions.getServiceLines,
    removeLine: regularLine_actions.removeLine,

}

export default compose(withTranslation('common'), connect(mapState, actionCreators))(LinesByService)