export const zonesConstants = {
    ADD_REQUEST: 'ZONES_REGISTER_REQUEST',
    ADD_SUCCESS: 'ZONES_REGISTER_SUCCESS',
    ADD_FAILURE: 'ZONES_REGISTER_FAILURE',

    EDIT_REQUEST: 'ZONES_EDIT_REQUEST',
    EDIT_SUCCESS: 'ZONES_EDIT_SUCCESS',
    EDIT_FAILURE: 'ZONES_EDIT_FAILURE',

    DETAIL_REQUEST: 'ZONES_DETAIL_REQUEST',
    DETAIL_SUCCESS: 'ZONES_DETAIL_SUCCESS',
    DETAIL_FAILURE: 'ZONES_DETAIL_FAILURE',

    GETALL_REQUEST: 'ZONES_GETALL_REQUEST',
    GETALL_SUCCESS: 'ZONES_GETALL_SUCCESS',
    GETALL_FAILURE: 'ZONES_GETALL_FAILURE',

    GETALL_ACTIVED_STOPS_REQUEST: 'GETALL_ACTIVED_STOPS_REQUEST',
    GETALL_ACTIVED_STOPS_SUCCESS: 'GETALL_ACTIVED_STOPS_SUCCESS',
    GETALL_ACTIVED_STOPS_FAILURE: 'GETALL_ACTIVED_STOPS_FAILURE',

    GETBYID_REQUEST: 'ZONES_GETBYID_REQUEST',
    GETBYID_SUCCESS: 'ZONES_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'ZONES_GETBYID_FAILURE',

    DELETE_REQUEST: 'ZONES_DELETE_REQUEST',
    DELETE_SUCCESS: 'ZONES_DELETE_SUCCESS',
    DELETE_FAILURE: 'ZONES_DELETE_FAILURE',

    SET_ZONES_SELECTED: 'ZONES_SET_ZONES_SELECTED',
    CLEAR_ZONES_SELECTED: 'ZONES_CLEAR_ZONES_SELECTED',
    SET_COMBINATION_ZONE: 'ZONES_SET_COMBINATION_ZONE',
    CLEAR_COMBINATION_ZONE: 'ZONES_CLEAR_COMBINATION_ZONE',
    CHECK_EMPTY: 'ZONES_CHECK_EMPTY_COMBINATION',

    ADD_STOP_REQUEST: 'ZONES_ADD_STOP_REQUEST',
    ADD_STOP_SUCCESS: 'ZONES_ADD_STOP_SUCCESS',
    ADD_STOP_FAILURE: 'ZONES_ADD_STOP_FAILURE',

    SET_ZONE_STOPS: 'ZONES_SET_STOPS',
    SET_ZONE_STOPS_REFRESH: 'ZONES_SET_STOPS_REFRESH',

    CHECK_STOP_REQUEST: 'ZONES_CHECK_STOP_REQUEST',
    CHECK_STOP_SUCCESS: 'ZONES_CHECK_STOP_SUCCESS',
    CHECK_STOP_FAILURE: 'ZONES_CHECK_STOP_FAILURE',

    CLOSE_MODAL: 'ZONES_CLOSE_MODAL_SERVICES'


};