import { Grid, Hidden } from '@material-ui/core';
import React from 'react';
import ItemServiceExpedition from './item';


const RegularServiceExpedition = (props) => {

    const { entries, expeditionMinutes, errors, serviceReducer, vehicle, isRegularService, conditionToShow, setEntries } = props

    return (
        <>
            {
                conditionToShow ?
                    <>
                        {
                            isRegularService && (<Grid item md={1} implementation="css" smDown component={Hidden} />)
                        }
                        <ItemServiceExpedition
                            daysOfWeek={serviceReducer.weekDays}
                            entries={entries}
                            expeditionMinutes={expeditionMinutes}
                            onChangeExpeditionMinutes={props.onChangeExpeditionMinutes}
                            addExpeditionItem={props.addExpeditionItem}
                            addExpeditionItemServiceWithStop={props.addExpeditionItemServiceWithStop}
                            errors={errors}
                            removeExpeditionItem={props.removeExpeditionItem}
                            removeExpeditionItemServiceWithStop={props.removeExpeditionItemServiceWithStop}
                            vehicle={vehicle}
                            isRegularService={isRegularService}
                            setEntries={setEntries}
                        />
                    </>
                    : null
            }
        </>
    )
}

export default RegularServiceExpedition