import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { tripActions } from '../../../redux/actions/trip_actions';
import ResumeTripView from './page'
import { ResumeTripWrapped } from './details';
import { multipleTripsActions } from '../../../redux/actions/multipleTrips_action';

class ResumeTrip extends React.Component {

    constructor(props) {
        super();
        this.state = {
            originAddress: '',
            destinationAddress: '',
            pickupTime: '',
            dropOffTime: '',
            price: '',
            customerName: '',
            resolve: false,
        }
    }

    componentDidMount() {
        if (this.props.tripReducer.trip != null)
            this.coordsToAddress();
    }

    componentDidUpdate(prevProps) {
        if (this.props.tripReducer.trip != prevProps.tripReducer.trip) {
            if (this.props.tripReducer.trip != null)
                this.coordsToAddress()
        }
    }

    componentWillUnmount() {
        this.props.cleanMultiTrip()
        this.props.cleanTrip()
    }

    async coordsToAddress() {
        let originAddress = this.props.tripReducer.trip.pickUpLocation.address;
        let pickUpStopName = this.props.tripReducer.trip.pickUpStopName;
        let dropOffStopName = this.props.tripReducer.trip.dropOffStopName;
        let destinationAddress = this.props.tripReducer.trip.dropOffLocation.address;
        let customerName = this.props.tripReducer.trip.customerName;
        let pickupTime = moment(this.props.tripReducer.trip.schedulePickUpTime).format("DD/MM/YYYY HH:mm")
        let dropOffTime = moment(this.props.tripReducer.trip.scheduleDropOffTime).format("DD/MM/YYYY HH:mm")
        let price = this.props.tripReducer.trip.price?.amount
        let tariff = this.props.tripReducer.trip.userTariff
        this.setState({
            originAddress,
            destinationAddress,
            pickupTime,
            dropOffTime,
            resolve: true,
            price,
            pickUpStopName,
            dropOffStopName,
            customerName,
            tariff
        })
    }

    render() {
        return (
            <ResumeTripView
                originAddress={this.state.originAddress}
                destinationAddress={this.state.destinationAddress}
                pickupTime={this.state.pickupTime}
                dropOffTime={this.state.dropOffTime}
                price={this.state.price}
                customerName={this.state.customerName}
                pickUpStopName={this.state.pickUpStopName}
                dropOffStopName={this.state.dropOffStopName}
                tariff={this.state.tariff}
                loading={!this.state.resolve || this.props.multipleTripReducer.pending}
                hasMultipleTrips={this.props.multipleTripReducer.executeMultipleTrips}
                {...this.props}
            />
        )
    }

}

function mapState(state) {
    const { tripReducer, multipleTripReducer } = state;
    return { tripReducer, multipleTripReducer };
}

const actionCreators = {
    clearAllMarkers: tripActions.clearAllMarkers,
    clearTrip: tripActions.clear,
    acceptTrip: tripActions.acceptTrip,
    rejectTrip: tripActions.rejectTrip,
    acceptModifyTrip: tripActions.acceptModifyTrip,
    cancelTripPlanned: multipleTripsActions.cancelTripPlanned,
    acceptTripReturn: multipleTripsActions.acceptTripReturn,
    setOriginalReturn: multipleTripsActions.setOriginalReturn,
    cleanMultiTrip: multipleTripsActions.cleanMultiTrip,
    cleanTrip: tripActions.cleanTrip,
}

export default compose(withTranslation('common'), connect(mapState, actionCreators), ResumeTripWrapped)(ResumeTrip);



