let CryptoJS = require("crypto-js");

const secretPassphrases = "Altech AMTU uninstall lugged sulphuric credible filled"

const crypto = {
    encryptObject,
    decryptObject,
    encryptString,
    decryptString
}


function encryptObject(data) {
    return CryptoJS.AES.encrypt(JSON.stringify(data), secretPassphrases).toString()
}

function decryptObject(data) {
    let bytes = CryptoJS.AES.decrypt(data, secretPassphrases);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
}

function encryptString(data) {
    return CryptoJS.AES.encrypt(data, secretPassphrases).toString()
}

function decryptString(data) {
    let bytes = CryptoJS.AES.decrypt(data, secretPassphrases);
    return bytes.toString(CryptoJS.enc.Utf8);
}

export default crypto