import React from "react";

export default function TripSVG({ height = 44, width = 44, stroke = "#000" }) {
    return (
        <svg height={height} width={width} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44">
            <path d="M10 28h6v6h-6v-6ZM28 10h6v6h-6v-6ZM20 33h8l3-2 2-3-2-4-3-2H16l-3-2-2-3 2-4 3-2h7"
                stroke={stroke} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}