import React from 'react'
import Dashboard from '../../dashboard'
import ServiceDetailsView from './page'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { serviceActions } from '../../../redux/actions/services_actions'
import { vehiclesActions } from '../../../redux/actions/vehicles_actions'
import { customerActions } from '../../../redux/actions/customer_actions'
import { otpActions } from '../../../redux/actions/otp_actions'
import { zoneActions } from '../../../redux/actions/zones_actions'
import { history } from '../../../helpers/history'

class ServicesDetail extends React.Component {

    componentDidMount() {
        const id = this.props.match.params.id
        this.props.getById(id, false)
        this.props.getAllOTP()
        this.props.getAllZones()
        this.props.getServicesTypes()
        this.props.getAnticipationTypes()
        this.props.getAllCustomers()
    }

    onClick(service) {
        history.push('/services/edit/' + service.serviceResponse.id)
    }

    render() {
        const { t } = this.props
        return (
            <Dashboard
                component={
                    <ServiceDetailsView
                        {...this.props}
                        title={t('services_comp.details.title')}
                        buttonRight={t('services_comp.form.page.buttonDetail')}
                        goBack={t('services_comp.form.page.goBack')}
                        sendService={() => this.onClick(this.props.serviceReducer.service)}
                        detail={true}
                        nextAction={'edit'}
                        readOnly={true}
                        service={this.props.serviceReducer.service}
                    />
                }
            />
        )
    }
}

function mapState(state) {
    const { serviceReducer, zoneReducer, otpReducer, vehicleReducer, customerReducer } = state
    return { serviceReducer, zoneReducer, otpReducer, vehicleReducer, customerReducer }
}

const actionCreators = {
    getById: serviceActions.getById,
    getTypesService: serviceActions.getTypesService,
    getServicesTypes: serviceActions.getServicesTypes,
    getTypesPrices: serviceActions.getTypesPrices,
    getAllZones: zoneActions.getAll,
    getAllOTP: otpActions.getAll,
    getByOtpIdWithOutServices: vehiclesActions.getByOtpIdWithOutServices,
    getAnticipationTypes: serviceActions.getAnticipationTypes,
    getAllCustomers: customerActions.getAll,
}

export default compose(withTranslation('common'), connect(mapState, actionCreators))(ServicesDetail)
