export const tenantConstants = {
    CREATE_REQUEST: "TENANT_CREATE_REQUEST",
    CREATE_SUCCESS: "TENANT_CREATE_SUCCESS",
    CREATE_FAILURE: "TENANT_CREATE_FAILURE",

    DETAIL_REQUEST: 'TENANT_DETAIL_REQUEST',
    DETAIL_SUCCESS: 'TENANT_DETAIL_SUCCESS',
    DETAIL_FAILURE: 'TENANT_DETAIL_FAILURE',

    UPDATE_REQUEST: "TENANT_UPDATE_REQUEST",
    UPDATE_SUCCESS: "TENANT_UPDATE_SUCCESS",
    UPDATE_FAILURE: "TENANT_UPDATE_FAILURE",

    GET_TENANTS_REQUEST: "TENANT_GET_TENANTS_REQUEST",
    GET_TENANTS_SUCCESS: "TENANT_GET_TENANTS_SUCCESS",
    GET_TENANTS_FAILURE: "TENANT_GET_TENANTS_FAILURE",

    GET_TENANT_REQUEST: "TENANT_GET_TENANT_REQUEST",
    GET_TENANT_SUCCESS: "TENANT_GET_TENANT_SUCCESS",
    GET_TENANT_FAILURE: "TENANT_GET_TENANT_FAILURE",

    GET_TENANT_NAME_AND_COLORS_REQUEST: "TENANT_GET_TENANT_NAME_AND_COLORS_REQUEST",
    GET_TENANT_NAME_AND_COLORS_SUCCESS: "TENANT_GET_TENANT_NAME_AND_COLORS_SUCCESS",
    GET_TENANT_NAME_AND_COLORS_FAILURE: "TENANT_GET_TENANT_NAME_AND_COLORS_FAILURE",

    GET_TENANT_ICON_REQUEST: "TENANT_GET_TENANT_ICON_REQUEST",
    GET_TENANT_ICON_SUCCESS: "TENANT_GET_TENANT_ICON_SUCCESS",
    GET_TENANT_ICON_FAILURE: "TENANT_GET_TENANT_ICON_FAILURE",

    GET_TENANT_NAME_REQUEST: "TENANT_GET_TENANT_NAME_REQUEST",
    GET_TENANT_NAME_SUCCESS: "TENANT_GET_TENANT_NAME_SUCCESS",
    GET_TENANT_NAME_FAILURE: "TENANT_GET_TENANT_NAME_FAILURE",

    DELETE_REQUEST: "TENANT_DELETE_REQUEST",
    DELETE_SUCCESS: "TENANT_DELETE_SUCCESS",
    DELETE_FAILURE: "TENANT_DELETE_FAILURE",

    CLEAN_TENANTS_FULLNAME: "CLEAN_TENANTS_FULLNAME",
    CLEAN_SERVICES_NAME: "CLEAN_SERVICES_NAME",
    TENANT_SET_PAGE_TABLE: "TENANT_SET_TABLE_PAGE"
};
