import React from 'react'
import Dashboard from '../../dashboard'
import CustomerEditView from './page'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { customerActions } from '../../../redux/actions/customer_actions'
import { serviceActions } from '../../../redux/actions/services_actions'

class CustomerEdit extends React.Component {
    componentDidMount() {
        this.props.customerAddresses()
        this.props.getDocumentTypes()
    }

    render() {
        const { t } = this.props
        return (
            <Dashboard
                component={
                    <CustomerEditView
                        {...this.props}
                        title={t('customers.edit.title')}
                        buttonRight={t('customers.form.page.buttonEdit')}
                        goBack={t('customers.form.page.goBack')}
                        edit={true}
                        action={'edit'}
                        sendCustomer={this.props.update}
                        customer={this.props.customerReducer.customer}
                    />
                }
            />
        )
    }
}

function mapState(state) {
    const { customerReducer } = state
    return { customerReducer }
}

const actionCreators = {
    getById: customerActions.getById,
    update: customerActions.update,
    addRelatedCustomer: customerActions.addRelatedCustomer,
    editRelatedCustomer: customerActions.editRelatedCustomer,
    removeRelatedCustomer: customerActions.removeRelatedCustomer,
    typeOperation: customerActions.openModal,
    setAbsences: serviceActions.setAbsences,
    removeCurrentPenalty: serviceActions.removeCurrentPenalty,
    customerAddresses: customerActions.customerAddress,
    getDocumentTypes: customerActions.getDocumentTypes,
}

export default compose(
    withTranslation('common'),
    connect(mapState, actionCreators),
)(CustomerEdit)
