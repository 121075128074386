import React from 'react'
import { Chart } from 'react-google-charts'
import PropTypes from 'prop-types'
import { FilterOptions, options, DownloadChart } from '../../../customFunctions'

const filterDataByGroup = (data, groupedBy, valuesFilter) => {
    switch (groupedBy) {
        case 'municipalities':
            return data.filter(item => item.groupedTrips?.some(i => i.municipalities?.some(r => valuesFilter.includes(r))))
        case 'regions':
            return data.filter(item => item.groupedTrips?.some(i => i.regions?.some(r => valuesFilter.includes(r))))
        case 'otpName':
            return data.filter(item => item.groupedTrips?.some(i => valuesFilter.includes(i.otpName)))
        case 'services':
            return data.filter(item => item.groupedTrips?.some(i => i.services?.some(r => valuesFilter.includes(r))))
        case 'tenants':
            return data.filter(item => item.groupedTrips?.some(i => i.tenants?.some(r => valuesFilter.includes(r))))
        default:
            return data
    }
}

const getGroupedTrip = (data, origin, destination) => {
    let dataFilter = data.find(item => item.origin === origin && item.groupedTrips.find(trip => trip.destination === destination))?.groupedTrips.find(object => object.destination === destination)
    if (dataFilter == null) {
        return 0
    } else {
        return dataFilter.totalTrips
    }
}

function subStringName(name) {
    return name?.length > 15 ? name.substring(0, 15) + '..' : name
}

const filteredData = (data, filterOptions, title, valuesFilter) => {
    const { groupedBy } = filterOptions
    let dataValue = filterDataByGroup(data, groupedBy, valuesFilter)
    let optionsDestinations = Array.from(new Set(Array.from(new Set(dataValue.map(data => data.groupedTrips.map(item => item.destination)))).flat(1)))
    let results = []
    dataValue.forEach(function (originItem, index) {
        let parcialDestinations = []
        optionsDestinations.forEach(destination => {
            parcialDestinations[optionsDestinations.indexOf(destination)] = getGroupedTrip(data, originItem.origin, destination)
        })
        if (index === 0) results.push([''].concat(optionsDestinations))
        results.push([originItem.origin].concat(parcialDestinations))
    })
    console.log('results', results)
    results.forEach(function (element, indexR) {
        element.forEach(function (item, indexI) {
            if (typeof item === 'string') {
                results[indexR][indexI] = subStringName(item)
            }
        })
    })
    return results
}

/**
 * CASES
 *  groupedBy: municipalities & value != null
 *  groupedBy: regions & value != null
 *  groupedBy: otpId & value != null
 *  groupedBy: tenant & value != null
 *  filterByDate: startDate != null & finishDate != null & finishDate > startDate
 */

const GetOnlyChartGroupedByFilter = props => {
    return (
        <React.Fragment>
            <Chart key={Math.random()} graphID={'chart_0'} chartType={props.chartType} width="100%" height="400px" data={filteredData(props.data, props.filterOptions, props.title, props.value)} options={options(props.chartProps, null)} />
            <DownloadChart key={Math.random()} img64={0} />
        </React.Fragment>
    )
}

const GetMultipleChartByFilter = props => {
    return (
        <React.Fragment>
            {props.value.map(function (element, index) {
                return filteredData(props.data, props.filterOptions, props.title, element).length > 0 ? (
                    <React.Fragment>
                        <h2>{element}</h2>
                        <Chart key={Math.random()} graphID={'chart_' + index} chartType={props.chartType} width="100%" height="400px" data={filteredData(props.data, props.filterOptions, props.title, element)} options={options(props.chartProps, null)} />
                        <DownloadChart key={Math.random()} img64={index} />
                    </React.Fragment>
                ) : null
            })}
        </React.Fragment>
    )
}

const MatrixOriginDestinationTrips = ({ data, title, groupedBy = 'municipalities', value = [], filterByDate = true, startDate = '2022-01-01', finishDate = '2022-02-01', timeUnits, groupByFilter }) => {
    const chartProps = { title, filterByDate, startDate, finishDate }
    const filterOptions = FilterOptions(groupedBy, value, filterByDate, startDate, finishDate, timeUnits)

    const chartType = 'Table'
    return filterDataByGroup(data, filterOptions, value).length > 0 ? (
        !groupByFilter ? (
            <GetOnlyChartGroupedByFilter chartType={chartType} data={data} filterOptions={filterOptions} title={title} value={value} chartProps={chartProps} />
        ) : (
            <GetMultipleChartByFilter chartType={chartType} data={data} filterOptions={filterOptions} title={title} value={value} chartProps={chartProps} />
        )
    ) : null
}

MatrixOriginDestinationTrips.propTypes = {
    data: PropTypes.array.isRequired,
    chartType: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    groupedBy: PropTypes.oneOf(['municipalities', 'regions', 'otps', 'services', 'tenants']).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    filterByDate: PropTypes.bool.isRequired,
    startDate: PropTypes.string.isRequired,
    finishDate: PropTypes.string.isRequired,
}

export default MatrixOriginDestinationTrips
