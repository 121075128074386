import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import KpiWrapper from "../../../components/kpiWrapper";
import { getTripsByUserKPI } from "../../../helpers/kpiHelper";
import MaterialTable from 'material-table';

export default function TotalTripsByUserChart({ isLoading }) {
    const { t } = useTranslation()
    const trips = useSelector(state => state.kpiReducer.confirmTrips);
    const isConfirmTripLoading = useSelector(state => state.kpiReducer.confirmTripsLoading)

    const { type } = useSelector(state => state.kpiFiltersReducer)

    const { header, body } = useMemo(() => {
        if (!trips.length) return []
        const data = getTripsByUserKPI(trips, { groupedBy: type });

        const header = [{ name: "User", field: "user" }, ...data[0].slice(1).map(cell => ({ title: cell, field: cell }))]
        const body = data.slice(1).map(row => ({ user: row[0], ...row.slice(1).reduce((acc, cell, index) => ({ ...acc, [data[0][index + 1]]: cell }), {}) }))
        return { header, body }
    }, [trips, type]);

    const totalCustomer = body?.length

    isLoading = isLoading || isConfirmTripLoading
    const displayNoData = !isLoading && totalCustomer === 0

    return (
        totalCustomer > 0 &&
        <KpiWrapper
            title={t("commandBox.users.totalTripsByUserChart.title")}
            isLoading={isLoading}
            total={totalCustomer}
            skeletonVariant="rect"
            skeletonHeight="500px"
            displayNoData={displayNoData}
        >
            <MaterialTable
                options={{
                    sorting: true,
                    search: false,
                    exportButton: false,
                    toolbar: false,
                    paging: false
                }}
                columns={header}
                data={body}
            >
            </MaterialTable>
        </KpiWrapper >
    )
}