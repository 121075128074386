import React from 'react';
import { history } from '../../helpers/history';
import AlertDialog from '../alert-dialog';
import { Button, Grid, Typography, ListItem, ListItemText, List } from "@material-ui/core";
import MaterialTableExtend from '../material-table';
import Spinner from '../shared/spinner';
import { allowAccessRole } from '../../helpers/roles';
import { MODULES, OPERATIONS_ALLOWED } from '../roles/constants';

class OtpView extends React.Component {

    constructor(props) {
        super()
        this.state = {
            openModal: false,
            item: {}
        }
        this.onClickCancelModal = this.onClickCancelModal.bind(this);
        this.onClickAcceptModalDelete = this.onClickAcceptModalDelete.bind(this);
    }

    addOnClick() {
        history.push('/otps/create')
    }

    onClickCancelModal() {
        this.setState({ openModal: false })
    }

    onClickAcceptModalDelete() {
        this.setState({ openModal: false })
        this.props.remove(this.state.item.id)
    }

    onClickOpenModal(data) {
        const { t } = this.props
        const content = () => (
            <React.Fragment>
                <Grid container>
                    <Grid item md={3}>
                        <Typography>{t('otp.page.alert_dialog.vehiclesAssociated')}</Typography>
                        <List key={Math.random()}>
                            {
                                data.otpVehicles.map((item, index) => (
                                    <ListItem key={Math.random()}>
                                        <ListItemText>{item}</ListItemText>
                                    </ListItem>
                                ))
                            }
                        </List>
                    </Grid >
                    <Grid item md={3}>
                        <Typography>{t('otp.page.alert_dialog.driversAssociated')}</Typography>
                        <List key={Math.random()}>
                            {
                                data.otpDrivers.map((item, index) => (
                                    <ListItem key={Math.random()}>
                                        <ListItemText>{item}</ListItemText>
                                    </ListItem>
                                ))
                            }
                        </List>
                    </Grid>
                    <Grid item md={3}>
                        <Typography>{t('otp.page.alert_dialog.servicesAssociated')}</Typography>
                        <List key={Math.random()}>
                            {
                                data.otpServices.map((item, index) => (
                                    <ListItem key={Math.random()}>
                                        <ListItemText>{item}</ListItemText>
                                    </ListItem>
                                ))
                            }
                        </List>

                    </Grid>
                    <Grid item md={3}>
                        <Typography>{t('otp.page.alert_dialog.operatorsAssociated')}</Typography>
                        <List key={Math.random()}>
                            {
                                data.otpManagers.map((item, index) => (
                                    <ListItem key={Math.random()}>
                                        <ListItemText>{item}</ListItemText>
                                    </ListItem>
                                ))
                            }
                        </List>

                    </Grid>

                </Grid >
            </React.Fragment >
        )
        this.setState({ openModal: true, item: data, content: content })
    }

    render() {
        const { otpReducer, t } = this.props;
        const Wrapper = () => (
            <React.Fragment>
                {
                    allowAccessRole(MODULES.OTP, OPERATIONS_ALLOWED.manage) &&
                    <Button
                        onClick={this.addOnClick}
                        variant="contained"
                        style={{ marginLeft: '24px' }}
                        color="primary"
                        size="large">
                        {t('otp.page.wrapper.add')}
                    </Button>
                }
            </React.Fragment>
        );
        return (
            <React.Fragment>
                {!otpReducer.pending ?
                    <React.Fragment>
                        <MaterialTableExtend
                            buttons={
                                <Wrapper />
                            }
                            title={t('otp.page.title')}
                            columns={[
                                { title: t('otp.page.columns.name'), field: 'name' },
                                { title: t('otp.page.columns.vatNumber'), field: 'vatNumber' },
                                { title: 'id', field: 'id', hidden: true },
                                { title: t('otp.page.columns.phone'), field: 'phoneNumber' },
                                { title: t('otp.page.columns.email'), field: 'email' }
                            ]}
                            data={otpReducer.results}
                            actionsColumnIndex={6}
                            actions={[
                                {
                                    icon: 'view_list',
                                    tooltip: t('otp.page.actions.detail'),
                                    onClick: (event, rowData) =>
                                        this.props.getById(rowData.id, true)
                                },
                                {
                                    icon: 'delete',
                                    tooltip: t('otp.page.actions.delete'),
                                    onClick: (event, rowData) => this.onClickOpenModal(rowData),
                                    hidden: !allowAccessRole(MODULES.OTP, OPERATIONS_ALLOWED.manage)
                                }
                            ]}
                        />
                        <AlertDialog
                            open={this.state.openModal}
                            title={`${t("otp.page.alert_dialog.titleDelete")} (${this.state.item.name})`}
                            content={this.state.content}
                            onClickAccept={this.onClickAcceptModalDelete}
                            onClickCancel={this.onClickCancelModal}
                        />
                    </React.Fragment>
                    :
                    <Spinner
                        loading={otpReducer.pending}
                    />
                }

            </React.Fragment>
        );
    }
}

export default OtpView;