import React from 'react';
import OperatorEditView from './page';
import Dashboard from '../../dashboard';
import { connect } from 'react-redux';
import { operatorsActions } from '../../../redux/actions/operators_actions';
import { vehiclesActions } from '../../../redux/actions/vehicles_actions';
import { withTranslation } from 'react-i18next'
import { compose } from 'redux';

class OperatorEdit extends React.Component {
    render() {
        const { t } = this.props
        return (
            <Dashboard
                component={
                    <OperatorEditView
                        title={t('operators.edit.title')}
                        buttonRight={t('operators.form.page.buttonEdit')}
                        goBack={t('operators.form.page.goBack')}
                        user={this.props.operatorReducer.operator}
                        sendOperator={this.props.update}
                        edit={true}
                        nextAction={'edit'}
                        entity={"Operators"}
                        profiles={this.props.rolesReducer.roles}
                        authProfile={JSON.parse(localStorage.getItem('claims'))?.value.profile}
                        readEdit={true}
                        {...this.props}
                    />
                }
            />
        );
    }
}

function mapState(state) {
    const { operatorReducer, otpReducer, tenantReducer, rolesReducer } = state;
    return { operatorReducer, otpReducer, tenantReducer, rolesReducer };
}

const actionCreators = {
    update: operatorsActions.update,
    getVehiclesByOTP: vehiclesActions.getByOtpId,
    clearOperator: operatorsActions.clearOperator,
}

export default compose(withTranslation('common'), connect(mapState, actionCreators))(OperatorEdit);