
export const getVehicleId = (vehicle) => {
    return vehicle.id || ""
}

export const getVehicleBrand = (vehicle) => {
    return vehicle.brand || ""
}

export const getVehicleModel = (vehicle) => {
    return vehicle.model || ""
}

export const getVehiclePlateNumber = (vehicle) => {
    return vehicle.plateNumber || ""
}

export const getVehicleCapacity = (vehicle, create) => {
    if (create)
        return ""
    return vehicle.capacity
}

export const getVehicleLuggage = (vehicle, create) => {
    if (create)
        return ""
    return vehicle.luggageCapacity
}

export const getVehicleWheelChair = (vehicle) => {
    return vehicle.wheelchairPlaces || ""
}

export const getVehicleReturnToStart = (vehicle) => {
    return vehicle.returnToStart || false
}

export const getVehicleDescriptionEs = (vehicle) => {
    return vehicle.descriptionEs || ""
}

export const getVehicleDescriptionEn = (vehicle) => {
    return vehicle.descriptionEn || ""
}

export const getVehicleDescriptionCa = (vehicle) => {
    return vehicle.descriptionCa || ""
}

export const getVehiclePriority = (vehicle) => {
    return vehicle.vehiclePriority || ""
}

export const getVehicleOTPId = (vehicle) => {
    return vehicle.otpId || ""
}

export const getVehicleOtpName = (vehicle) => {
    return vehicle.otpName || ""
}

export const getVehicleShiftDays = (vehicle) => {
    return vehicle.shiftDays && vehicle.shiftDays.length > 0
        ? vehicle.shiftDays
        : [
            {
                weekDays: [],
                earliestStart: "",
                latestArrival: "",
                endsNextDay: true,
                driverId: "",
                driverName: "",
                serviceId: "",
                serviceName: ""
            },
        ]
}

export const getVehicleInitialPosition = (vehicle) => {
    return vehicle.initialPosition || {
        address: "",
        lat: "",
        lng: "",
    }
}
