import { Typography } from "@material-ui/core";
import i18next from "i18next";
import React from "react";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" style={{ fontFamily: 'Montserrat Medium !important', fontSize: 13 }}>
            Copyright © 2010-{new Date().getFullYear() + ' ' + i18next.t('dashboard.menu.copyright')}
        </Typography>
    );
}

class About extends React.Component {

    render() {
        return (
            <React.Fragment>
                <img
                    src={`${process.env.PUBLIC_URL}/content/img/logo-altech.png`}
                    style={{ width: 320, height: 132 }}
                    alt="logo"
                />
                <div style={{ fontFamily: 'Montserrat Medium !important', fontSize: 16 }}>
                    <p>v{this.props.version}</p>
                </div>

                <Copyright />

                <div style={{ fontFamily: 'Montserrat Medium !important', fontSize: 16 }}>
                    <a href="https://altech.es/">www.altech.es</a>
                </div>


            </React.Fragment>
        );
    }
}

export default About;