import { reportConstants } from "../../constants/report_constants"

const InitialState = {
    pending: false,
    reports: [],
    report: {},
    error: null,
    periodicity: {}
}

export function reportReducer(state = InitialState, action) {
    switch (action.type) {
        case reportConstants.GETALL_REQUEST:
            return {
                ...state,
                pending: true
            }
        case reportConstants.GETALL_SUCCESS:
            return {
                ...state,
                pending: false,
                reports: action.results
            }
        case reportConstants.GETALL_FAILURE:
            return {
                ...state,
                pending: false,
                reports: [],
                error: action.error
            }
        case reportConstants.INSERT_REQUEST:
            return {
                ...state,
                pending: true
            }
        case reportConstants.INSERT_SUCCESS:
            return {
                ...state,
                pending: false,
                reports: [...state.reports, action.results]
            }
        case reportConstants.INSERT_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case reportConstants.DELETE_REQUEST:
            return {
                ...state,
                pending: true
            }
        case reportConstants.DELETE_SUCCESS:
            return {
                ...state,
                pending: false,
                reports: state.reports.filter(report => report.id !== action.id)
            }
        case reportConstants.DELETE_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            }

        case reportConstants.DETAIL_REQUEST:
            return {
                ...state,
                pending: true
            }
        case reportConstants.DETAIL_SUCCESS:
            return {
                ...state,
                pending: false,
                report: action.result
            }
        case reportConstants.DETAIL_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case reportConstants.UPDATE_REQUEST:
            return {
                ...state,
                pending: true
            }
        case reportConstants.UPDATE_SUCCESS:
            return {
                ...state,
                pending: false,
                reports: [...state.reports, action.results]
            }
        case reportConstants.UPDATE_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case reportConstants.GET_PERIODICITY_REQUEST:
            return {
                ...state,
                pending: true
            }
        case reportConstants.GET_PERIODICITY_SUCCESS:
            return {
                ...state,
                pending: false,
                periodicity: action.results
            }
        case reportConstants.GET_PERIODICITY_FAILURE:
            return {
                ...state,
                pending: false,
                periodicity: {},
                error: action.error
            }
        default:
            return state
    }
}