import { Checkbox, Grid, Hidden, FormControlLabel, Button, Select, FormControl, InputLabel, MenuItem, FormHelperText } from '@material-ui/core'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { history } from '../../../helpers/history'
import i18next from 'i18next'

const styles = makeStyles(theme => ({
    ' & .MuiFormControl-root': {
        width: '100%',
    },
    h2: {
        padding: 10,
    },
}))

class ServiceStopsView extends React.Component {
    constructor(props) {
        super()
        this.state = {
            zonesStops: props.serviceReducer.zonesStops,
            errors: props.serviceReducer.zonesStops.map((value, index) => {
                return { result: false, message: '' }
            }),
        }

        this.handleChange = this.handleChange.bind(this)
        this.onSaveClick = this.onSaveClick.bind(this)
    }

    handleChange = e => {
        const { name, checked } = e.target
        let errors = this.state.errors
        let array = name.split('_')
        errors[array[0]].message = ''
        let zonesStops = this.state.zonesStops
        let stop = zonesStops[array[0]].zoneStops.find(x => x.id == array[1])
        stop.isAssigned = checked
        this.setState({
            zonesStops: zonesStops,
        })
    }

    handleChangeServiceZone = e => {
        const { name, value } = e.target
        let array = name.split('_')
        let errors = this.state.errors
        let index = array[1]
        errors[index].message = ''
        let zonesStops = this.state.zonesStops
        let zone = zonesStops[index]
        zone.zoneType = parseInt(value)
        if (zone.zoneType == 20) {
            zone.zoneStops.forEach(element => {
                element.isAssigned = false
            })
        }
        this.setState({
            zonesStops: zonesStops,
        })
    }

    onClickBack() {
        history.goBack()
    }

    validateForm() {
        let errors = this.state.errors
        let zonesStops = this.state.zonesStops
        zonesStops.forEach((element, index) => {
            if (element.zoneType != 20) {
                let alMostOne = element.zoneStops.find(a => a.isAssigned)
                if (!alMostOne) {
                    errors[index] = { result: false, message: i18next.t('validations.almostOne') }
                } else {
                    errors[index] = { result: true, message: '' }
                }
            } else {
                errors[index] = { result: true, message: '' }
            }
        })
        this.setState({
            errors,
        })
    }

    isFormValid() {
        let valid = true
        this.state.errors.forEach((element, index) => {
            if (!this.state.errors[index].result) valid = false
        })
        return valid
    }

    onSaveClick() {
        const params = this.props.location.pathname.split('/')
        const id = params[params.length - 1]
        this.validateForm()
        if (this.isFormValid()) {
            this.props.saveStops(id, this.state.zonesStops)
        }
    }

    render() {
        const { serviceReducer, t } = this.props
        const serviceZoneTypes = serviceReducer.typesService
        return (
            <React.Fragment>
                <h2 style={{ padding: 10 }}>{t('services_comp.stops.page.title')}</h2>
                {serviceReducer.zonesStops.length > 0 ? (
                    <Grid className={styles.root} container>
                        {serviceReducer.zonesStops.map((zone, index) => {
                            return (
                                <React.Fragment key={zone.id}>
                                    <Grid item md={1} implementation="css" smDown component={Hidden} />
                                    <Grid item md={3} style={{ alignContent: 'center' }}>
                                        <h3>{zone.name}</h3>
                                    </Grid>
                                    {zone.zoneStops.length > 0 ? (
                                        <Grid md={2} item>
                                            <FormControl fullWidth>
                                                <InputLabel shrink id="ServiceZoneLabel">
                                                    {t('services_comp.stops.page.serviceType')}
                                                </InputLabel>
                                                <Select labelId="ServiceZoneLabel" id="serviceZoneType" name={'serviceZoneType_' + index} value={zone.zoneType || 20} onChange={this.handleChangeServiceZone} displayEmpty>
                                                    {serviceZoneTypes.map(item => (
                                                        <MenuItem key={item.id} value={item.id}>
                                                            {item.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                <FormHelperText error>{this.state.errors[index].message}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                    ) : (
                                        <Grid item md={1} implementation="css" smDown component={Hidden} />
                                    )}

                                    {zone.zoneStops.map(stop => (
                                        <React.Fragment key={stop.id}>
                                            <Grid item md={4}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={this.state.zonesStops.find(a => a.zoneStops.find(x => x.id == stop.id)).zoneStops.find(x => x.id == stop.id).isAssigned}
                                                            name={index + '_' + stop.id}
                                                            onChange={this.handleChange}
                                                            disabled={zone.zoneType == null || zone.zoneType == 20 ? true : false}
                                                            color="primary"
                                                        />
                                                    }
                                                    label={stop.name + '  /  ' + (stop.address.trim() != '' ? stop.address : 'LAT: ' + stop.point.lat + '  LNG: ' + stop.point.lon)}
                                                />
                                            </Grid>
                                            <Grid item md={6} implementation="css" smDown component={Hidden} />
                                        </React.Fragment>
                                    ))}
                                    <hr style={{ width: '100%' }} />
                                </React.Fragment>
                            )
                        })}
                        <Grid item md={3} implementation="css" smDown component={Hidden} />
                        <Grid item md={2}>
                            <Button variant="contained" color="primary" onClick={this.onClickBack} fullWidth size="large">
                                {t('services_comp.stops.page.buttonBack')}
                            </Button>
                        </Grid>
                        <Grid item md={1} implementation="css" smDown component={Hidden} />
                        <Grid item md={2}>
                            <Button variant="contained" color="primary" disabled={serviceReducer.pending ? true : false} onClick={this.onSaveClick} fullWidth size="large">
                                {t('services_comp.stops.page.buttonOK')}
                            </Button>
                        </Grid>
                        <Grid item md={4} implementation="css" smDown component={Hidden} />
                    </Grid>
                ) : (
                    <div style={{ alignItems: 'center' }}>{t('services_comp.stops.page.noStops')}</div>
                )}
            </React.Fragment>
        )
    }
}

export default ServiceStopsView
