import React from 'react'
import Dashboard from '../dashboard';
import CustomerView from './page';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { customerActions } from '../../redux/actions/customer_actions';
import { tripActions } from '../../redux/actions/trip_actions';

class Customers extends React.Component {

    componentDidMount() {
        this.props.getAll();
        this.props.setOriginDistanceTime(null)
        this.props.setDestinationDistanceTime(null)
    }

    render() {
        return (
            <Dashboard
                component={
                    <CustomerView
                        {...this.props}
                    />
                }
            />
        );
    }
}

function mapState(state) {
    const { customerReducer } = state;
    return { customerReducer };
}

const actionCreators = {
    getAll: customerActions.getAll,
    setCustomer: customerActions.setCustomer,
    remove: customerActions.remove,
    getById: customerActions.getById,
    sendResetPasswordEmail: customerActions.forgotPasswordCheckingIfIsCustomer,
    setOriginDistanceTime: tripActions.setOriginDistanceTime,
    setDestinationDistanceTime: tripActions.setDestinationDistanceTime,
    cleanRepeatTrip: tripActions.cleanRepeatTrip,
    enable: customerActions.enable,
    disable: customerActions.disable,
    getDocumentTypes: customerActions.getDocumentTypes,
    setCurrentPage: customerActions.setCurrentPage,
    refreshCustomerRequestTrip: customerActions.refreshCustomerRequestTrip,
    getResetPasswordLink: customerActions.getResetPasswordLink,
    saveCustomerFilters: customerActions.saveCustomerFilters
}

export default compose(withTranslation('common'), connect(mapState, actionCreators))(Customers);