export function handleResponse(response) {
    const NOT_CONTENT = 204;
    const NOT_FOUND = 404;

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (response.status === NOT_CONTENT
            || response.status === NOT_FOUND
            || data.statusCode == NOT_CONTENT
            || data.statusCode == NOT_FOUND) {
            return []
        }
        if (!response.ok) {
            const error = (data && data.message) || text || response.status;
            return Promise.reject(error);
        }
        return data;
    });
}