import { authHeader } from '../helpers/authHeader';
import stringConstants from '../constants/strings';
import { handleResponse } from '../helpers/services';

export const positionService = {
    getAllVehicles,
    getLastPosition,
    getPositionForTrip

};

function getAllVehicles() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(stringConstants.URL_ApiGateway + '/tracking/v1/LastPosition/getAllVehicles', requestOptions).then(handleResponse);
}

function getLastPosition(vehicleId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(stringConstants.URL_ApiGateway + '/tracking/v1/LastPosition/getVehicleLastPosition?vehicleId=' + vehicleId, requestOptions).then(handleResponse);
}

function getPositionForTrip(vehicleId, pickUpDate, dropOffDate) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(stringConstants.URL_ApiGateway + '/tracking/v1/LastPosition/getVehicleTripPosition?idVehicle=' + vehicleId + '&pickupDateTime=' + pickUpDate + '&dropoffDateTime=' + dropOffDate, requestOptions).then(handleResponse);
}