import React from 'react';
import DriverEditView from './page';
import Dashboard from '../../dashboard';
import { connect } from 'react-redux';
import { driversActions } from '../../../redux/actions/driver_actions';
import { vehiclesActions } from '../../../redux/actions/vehicles_actions';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

class DriverEdit extends React.Component {
    render() {
        const { t } = this.props
        return (
            <Dashboard
                component={
                    <DriverEditView
                        title={t('drivers.edit.title')}
                        buttonRight={t('drivers.form.page.buttonEdit')}
                        goBack={t('drivers.form.page.goBack')}
                        driver={this.props.driverReducer.driver.driver}
                        sendDriver={this.props.update}
                        edit={true}
                        nextAction={'edit'}
                        vehicles={this.props.driverReducer.driver.driverVehicles}
                        {...this.props}
                    />
                }
            />
        );
    }
}

function mapState(state) {
    const { driverReducer, otpReducer, vehicleReducer } = state;
    return { driverReducer, otpReducer, vehicleReducer };
}

const actionCreators = {
    update: driversActions.update,
    getVehiclesByOTP: vehiclesActions.getByOtpId
}

export default compose(withTranslation('common'), connect(mapState, actionCreators))(DriverEdit);