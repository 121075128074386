import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { userService } from './services/userService';
import { checkAction } from './helpers/roles';


function useCleanFilters({ rest, cleanFilters }) {
    useEffect(() => {
        if (!cleanFilters) return

        const pathName = rest?.location?.pathname
        if (!pathName) return

        const path = localStorage.getItem('path')
        const newPath = pathName.split('/')[1]

        if (path !== newPath) {
            localStorage.setItem('path', newPath)
            cleanFilters(newPath)
        }
    }, [rest, cleanFilters])
}


const PrivateRoute = ({ component: Component, cleanFilters, nameTenant, modules, operationAllowed, ...rest }) => {
    useCleanFilters({ rest, cleanFilters })

    const isUserAllowed = (modules, operationAllowed) => {
        if (modules.length == null) {
            return checkAction(modules, operationAllowed)
        }

        let res = false

        modules.map((module) => {
            if (checkAction(module, operationAllowed)) {
                res = true
            }
        })

        return res
    }

    const isAuthenticated = userService.getWithExpiry('user_webAdmin')
    const isAuthorized = isUserAllowed(modules, operationAllowed)

    const renderComponent = (props) => {
        if (!isAuthenticated) return <Redirect to="/login" />
        if (!isAuthorized) return <Redirect to="/no-auth" />
        return <Component {...props} nameTenant />
    }

    return <Route {...rest} exact render={renderComponent} />
}

export default PrivateRoute;
