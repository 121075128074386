import { Container, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

export function NoAuthView() {
    const { t } = useTranslation()
    return (
        <Container style={{ padding: '100px 20px' }}>
            <Typography variant="h5" align="center">{t('noAuth.page.title')}</Typography>
        </Container>
    )
}