import React from 'react';


export function TabPanel({ children, value, index, ...other }) {
    return (
        <div
            role="tabpanel"
            style={{ width: "100%" }}
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (children)}
        </div>
    );
}

export function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
