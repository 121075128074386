export const areasConstants = {
    ADD_REQUEST: 'AREAS_REGISTER_REQUEST',
    ADD_SUCCESS: 'AREAS_REGISTER_SUCCESS',
    ADD_FAILURE: 'AREAS_REGISTER_FAILURE',

    DETAIL_REQUEST: 'AREAS_DETAIL_REQUEST',
    DETAIL_SUCCESS: 'AREAS_DETAIL_SUCCESS',
    DETAIL_FAILURE: 'AREAS_DETAIL_FAILURE',

    GETALL_REQUEST: 'AREAS_GETALL_REQUEST',
    GETALL_SUCCESS: 'AREAS_GETALL_SUCCESS',
    GETALL_FAILURE: 'AREAS_GETALL_FAILURE',

    GETBYID_REQUEST: 'AREAS_GETBYID_REQUEST',
    GETBYID_SUCCESS: 'AREAS_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'AREAS_GETBYID_FAILURE',

    DELETE_REQUEST: 'AREAS_DELETE_REQUEST',
    DELETE_SUCCESS: 'AREAS_DELETE_SUCCESS',
    DELETE_FAILURE: 'AREAS_DELETE_FAILURE',

    EDIT_REQUEST: 'DRIVER_EDIT_REQUEST',
    EDIT_SUCCESS: 'DRIVER_EDIT_SUCCESS',
    EDIT_FAILURE: 'DRIVER_EDIT_FAILURE',

    GET_ALL_MUNICIPALITIES_REQUEST: 'GET_ALL_MUNICIPALITIES_REQUEST',
    GET_ALL_MUNICIPALITIES_SUCCESS: 'GET_ALL_MUNICIPALITIES_SUCCESS',
    GET_ALL_MUNICIPALITIES_FAILURE: 'GET_ALL_MUNICIPALITIES_FAILURE',

    GET_ALL_REGIONS_REQUEST: 'GET_ALL_REGIONS_REQUEST',
    GET_ALL_REGIONS_SUCCESS: 'GET_ALL_REGIONS_SUCCESS',
    GET_ALL_REGIONS_FAILURE: 'GET_ALL_REGIONS_FAILURE'

};