import React from 'react';
import ServiceExpeditionsView from './page';
import Dashboard from '../../dashboard/index';
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { serviceActions } from '../../../redux/actions/services_actions'
import Spinner from '../../shared/spinner';
import { vehiclesActions } from '../../../redux/actions/vehicles_actions';
import i18next from 'i18next';
import { regularLine_actions } from '../../../redux/actions/regularLine_actions';
import { SERVICES_TYPE } from '../../../constants/types';
import { allowAccessRole } from '../../../helpers/roles';
import { MODULES, OPERATIONS_ALLOWED } from '../../roles/constants';

class ServiceExpeditions extends React.Component {

    componentDidMount() {
        allowAccessRole(MODULES.Services, OPERATIONS_ALLOWED.manage)

        const params = this.props.match.params
        const type = params.serviceType
        const id = params.serviceId
        const lineId = params.lineId
        const lng = i18next.language
        if (type == SERVICES_TYPE.regularService)
            this.props.getServiceLine(id)
        this.props.getWeekDays(lng)
        if (lineId) {
            this.props.getServiceLineStopsAvailable(id, lineId)
            this.props.getServiceLineRegular(lineId)
        }

    }

    render() {
        const pendingS = this.props.serviceReducer.pending
        const params = this.props.match.params
        const type = params.serviceType
        const id = params.serviceId
        const lineId = params.lineId
        return (
            <Dashboard
                component={
                    !pendingS && this.props.serviceReducer.serviceLine != null ?
                        <ServiceExpeditionsView
                            {...this.props}
                            id={id}
                            type={type}
                            lineId={lineId}
                            line={this.props.serviceReducer.serviceLine}
                        />
                        : <Spinner loading={pendingS} />
                }
            />
        );
    }
}

function mapState(state) {
    const { serviceReducer, vehicleReducer } = state
    return { serviceReducer, vehicleReducer }
}

const actionCreators = {
    getServiceLine: serviceActions.getServiceLine,
    getWeekDays: serviceActions.getWeekDays,
    getByServiceId: vehiclesActions.getByServiceId,
    setExpeditions: serviceActions.setExpeditions,
    cleanServiceLine: serviceActions.cleanServiceLine,
    getServiceLineStopsAvailable: regularLine_actions.getServiceLineStopsAvailable,
    getServiceLineRegular: regularLine_actions.getServiceLine,
    setRegularLineExpeditions: regularLine_actions.setRegularLineExpeditions
}


export default compose(withTranslation('common'), connect(mapState, actionCreators))(ServiceExpeditions)